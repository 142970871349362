import React, { useState, useEffect, useContext } from 'react'
import { Alert, Menu, MenuItem, ListItemIcon } from '@mui/material'
import Archived from '@mui/icons-material/Archive'
import ResultsList from './resultsList'
import axios from 'axios'
import AddDialog from './addDialog'
import { AuthContext } from '../auth'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonRouterLink,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { addCircle, fileTrayFull, fileTrayFullOutline, storefrontOutline } from 'ionicons/icons'
import StandardContainer from '../commonComponents/StandardContainer'
import DefaultMenu from '../commonComponents/SideMenu/DefaultMenu'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'
import AddHeader from '../commonComponents/AddHeader'
import NoPermissionsCard from '../commonComponents/NoPermissionsCard'

export default function EmployeeListView() {
  const { businessData, userData, locationId } = useContext(AuthContext)
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)
  const [employees, setEmployees] = useState([])
  const [addLocationDialogOpen, setAddLocationDialogOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [showingArchived, setShowingArchived] = React.useState(false)
  const open = Boolean(anchorEl)
  let queryParams = new URLSearchParams(window.location.search)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    getEmployeesList()
    //
  }, [showingArchived, businessData, locationId])

  const getEmployeesList = async () => {
    if (!businessData?._id) return
    setLoading(true)
    setErrorMessage('')
    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''

      let showArchivedQuery = showingArchived ? '&showArchived=true' : ''

      let response = await axios.get(
        `/employee/getList?businessId=${businessData?._id}${locQuery}${showArchivedQuery}`,
      )
      setEmployees(response.data)
    } catch (error) {
      setErrorMessage('There was an error loading. Try again')
    }
    setLoading(false)
  }

  const openAddLocationDialog = () => {
    setAddLocationDialogOpen(true)
  }
  const closeAddLocationDialog = () => {
    getEmployeesList()
    setAddLocationDialogOpen(false)
  }

  async function handleRefresh(event) {
    await getEmployeesList()
    event.detail.complete()
  }
  if (userEntitlements?.noPermissions) {
    return (
      <>
        <DefaultMenu />
        <IonPage id='main-content'>
          <AddHeader title={'Employees'} />
          <IonContent>
            <StandardContainer>
              <NoPermissionsCard />
            </StandardContainer>
          </IonContent>
        </IonPage>
      </>
    )
  }
  return (
    <>
      <DefaultMenu />
      <IonPage id='main-content'>
        <IonHeader>
          <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonButtons slot='start'>
              <IonMenuButton>
                <IonIcon icon={storefrontOutline}></IonIcon>
              </IonMenuButton>
            </IonButtons>
            <IonTitle>Employees</IonTitle>
            <IonButtons slot='end'>
              <IonButton
                onClick={() => {
                  setShowingArchived(!showingArchived)
                }}
                color={showingArchived ? 'danger' : 'default'}
              >
                <IonIcon
                  slot='icon-only'
                  icon={showingArchived ? fileTrayFull : fileTrayFullOutline}
                />
              </IonButton>
            </IonButtons>
            <IonButtons slot='end'>
              <IonButton onClick={openAddLocationDialog}>
                <IonIcon slot='icon-only' icon={addCircle} color='primary' />
              </IonButton>
            </IonButtons>
            {loading ? <IonProgressBar type='indeterminate' /> : null}
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <StandardContainer>
            {showingArchived ? (
              <>
                <Alert severity='warning'>
                  You are viewing archived employees. To unarchive an employee: Click on the
                  employee name -&gt; Advanced -&gt; Unarchived{' '}
                  <IonButton
                    onClick={() => {
                      setShowingArchived(false)
                    }}
                  >
                    Show Active Employees
                  </IonButton>
                </Alert>
                <br></br>
              </>
            ) : null}
            {errorMessage ? <p style={{ color: 'red', margin: 20 }}>{errorMessage}</p> : null}
            <ResultsList
              employees={employees}
              locationId={locationId}
              businessId={businessData?._id}
            />
            <AddDialog
              open={addLocationDialogOpen}
              handleClose={closeAddLocationDialog}
              businessId={businessData?._id}
              locationId={locationId}
            />
            <Menu
              anchorEl={anchorEl}
              id='account-menu'
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  'overflow': 'visible',
                  'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  'mt': 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <IonRouterLink
                routerLink={showingArchived ? '?' : '?showArchived=true'}
                routerAnimation='none'
              >
                <MenuItem>
                  <ListItemIcon>
                    <Archived fontSize='small' />
                  </ListItemIcon>
                  {showingArchived ? 'Show Active Employees' : 'Show Archived Employees'}
                </MenuItem>
              </IonRouterLink>
            </Menu>
          </StandardContainer>
        </IonContent>
      </IonPage>
    </>
  )
}
