import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  IonButton,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonPage,
  IonSpinner,
} from '@ionic/react'
import Box from '@mui/material/Box'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import StandardContainer from '../commonComponents/StandardContainer'
import DetailsView from './details_general'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Details({ match }) {
  const discount_id = match?.params?.id
  const [discount, setDiscount] = useState()
  const [discountTemp, setDiscountTemp] = useState()
  const [discountChanged, setDiscountChanged] = useState(false)
  const [error, setError] = useState('')
  const history = useHistory()
  const [nameError, setNameError] = useState('')
  const handleNameError = error => {
    console.log(error, 'errorerror')
    setNameError(error)
  }
  const getDiscount = async () => {
    let response = await axios.get(`/discount?id=${discount_id}`)
    setDiscount(response.data)
    setDiscountTemp(response.data)
  }
  useEffect(() => {
    getDiscount()
  }, [discount_id])

  const updateDiscount = value => {
    setDiscountTemp(value)
    setDiscountChanged(true)
    if (value.discountType === 'percentage' && value.discountValue > 100) {
      setError('Percentage discount value cannot be more than 100')
    } else {
      setError('')
    }
  }

  const save = async () => {
    let discountInput = { ...discountTemp }
    await axios.patch(`/discount?id=${discount_id}`, discountInput)
    await getDiscount()
    setDiscountChanged(false)
  }

  const cancel = () => {
    setDiscountTemp(discount)
    setDiscountChanged(false)
  }

  const deleteDiscount = async () => {
    if (window.confirm('Are you sure you want to delete the discount?')) {
      // Delete it!
      await axios.delete(`/discount?id=${discount_id}`)
      history.push(-1)
    }
  }

  if (!discount) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonButtons slot='start'>
              <IonBackButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSpinner name='dots' />
        </IonContent>
      </IonPage>
    )
  }

  const isDisabled =
    discountTemp.name.trim() === '' ||
    !discountTemp.discountType ||
    !discountTemp.couponType ||
    discountTemp.discountValue.trim() === '' ||
    error.trim() !== '' ||
    nameError.trim() !== ''

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>{discountTemp?.name || 'loading...'}</IonTitle>
          {discountChanged ? (
            <>
              <IonButtons slot='end'>
                <IonButton onClick={cancel}>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot='end'>
                <IonButton disabled={isDisabled} color='primary' onClick={save}>
                  Save
                </IonButton>
              </IonButtons>
            </>
          ) : null}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <StandardContainer>
          <DetailsView
            handleNameError={handleNameError}
            nameError={nameError}
            error={error}
            discountData={discount}
            discount={discountTemp}
            updateDiscount={updateDiscount}
          />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
