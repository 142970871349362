import {
  IonButton,
  IonButtons,
  IonCard,
  IonCheckbox,
  IonCol,
  IonContent,
  IonInput,
  IonLabel,
  IonRow,
  IonSpinner,
} from '@ionic/react'
import React from 'react'
import StandardContainer from '../../commonComponents/StandardContainer'
import commonStyles from '../../theme/commonstyles.module.css'
export default function CancelAppointmentForm({
  defaultCancellationCharges,
  defaultChargeEnabled,
  toggleDefaultCharge,
  appointment,
  customChargeValue,
  handleCustomChargeValue,
  error,
  closeModal,
  loading,
  handleCancelAppointment,
}) {
  return (
    <IonContent className='regularBackground'>
      <StandardContainer>
        <IonCard style={{ padding: '1rem' }}>
          <h5 style={{ fontWeight: 'bold', color: 'black' }}>Cancel Appointment</h5>
          <IonLabel style={{ fontWeight: 'bold' }}>
            Since you are canceling within 24 hours of the appointment you will be charged a $
            {defaultCancellationCharges} fee. Would you still like to cancel?
          </IonLabel>
          <div style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }}>
            <IonCheckbox
              slot='start'
              checked={defaultChargeEnabled}
              onIonChange={toggleDefaultCharge}
            ></IonCheckbox>

            <IonLabel onClick={toggleDefaultCharge} style={{ marginLeft: '0.5rem' }}>
              Charge {appointment?.customer?.firstName} {appointment?.customer?.lastName} a
              cancelation charge of ${defaultCancellationCharges}
            </IonLabel>
          </div>
          {!defaultChargeEnabled && (
            <div>
              <h6 style={{ color: 'black', fontSize: '12px' }}>Custom Charge</h6>
              <IonRow style={{ margin: 0, padding: 0 }}>
                <IonCol>
                  <IonInput
                    className={commonStyles.inputStyle}
                    fill='outline'
                    type='number'
                    value={customChargeValue}
                    onIonChange={e => handleCustomChargeValue(e.detail.value)}
                    placeholder='0'
                    inputmode='decimal'
                  />
                </IonCol>
              </IonRow>
            </div>
          )}
          {error && (
            <IonLabel style={{ margin: '1rem  0', color: 'red', fontSize: '12px' }}>
              {error}
            </IonLabel>
          )}
          <div style={{ marginTop: '1rem', borderTop: '1px solid #d3d3d3' }}></div>
          <IonButtons
            style={{
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IonButton onClick={closeModal} style={{ color: 'grey' }}>
              Dismiss
            </IonButton>
            {loading && <IonSpinner name='lines' />}
            {!loading && (
              <IonButton
                color={'primary'}
                disabled={
                  !((defaultChargeEnabled === true || customChargeValue != '') && error == '')
                }
                onClick={handleCancelAppointment}
              >
                Cancel Appointment
              </IonButton>
            )}
          </IonButtons>
        </IonCard>
      </StandardContainer>
    </IonContent>
  )
}
