import { useState } from 'react'
import Axios from 'axios'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { useHistory } from 'react-router-dom'
import { colors } from './theme/colors'
import {
  IonButton,
  IonContent,
  IonGrid,
  IonInput,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonRow,
} from '@ionic/react'
import StandardCenterCenter from './commonComponents/StandardCenterCenter'
import commonStyles from './theme/commonstyles.module.css'

const classes = {
  paper: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: 10,
    backgroundColor: colors.primaryBlue,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 10,
  },
  submit: {
    margin: 10,
  },
}

const SignUp = () => {
  const hist = useHistory()
  const [disabled, setDisabled] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const isEmailValid = email => {
    // Implement your email validation logic here
    // For example, you can use regex or other validation techniques
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    return emailPattern.test(email)
  }

  const isFormValid = () => {
    return isEmailValid(email) && password.trim().length >= 6
  }
  const handleSignUp = async () => {
    setDisabled(true)
    try {
      let response = await createUserWithEmailAndPassword(getAuth(), email, password)
      await Axios.post('/user', { email: email, firebaseId: response.user.uid })
      hist.push('/core/dashboard')
    } catch (error) {
      alert(error)
      setDisabled(false)
    }
  }

  // const handleSignUp = useCallback(async event => {
  //   setDisabled(true)
  //   event.preventDefault()
  //   const { email, password } = event.target.elements
  //   try {
  //     let response = await createUserWithEmailAndPassword(getAuth(), email.value, password.value)
  //     await Axios.post('/user', { email: email.value, firebaseId: response.user.uid })
  //     hist.push('/core/dashboard')
  //   } catch (error) {
  //     alert(error)
  //     setDisabled(false)
  //   }
  // }, [])

  return (
    <IonPage>
      <IonContent>
        <StandardCenterCenter>
          <IonGrid style={{ padding: '1rem' }}>
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '3rem',
                marginBottom: '0.5rem',
              }}
            >
              <IonLabel style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>SCIXXOR</IonLabel>
            </IonRow>
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <IonLabel style={{ fontSize: '1.2rem' }}>
                The easiest way to manage and grow your salon or barbershop
              </IonLabel>
            </IonRow>
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <h1 style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>Register</h1>
            </IonRow>
            <IonRow style={{ display: 'flex', flexDirection: 'column' }}>
              <IonLabel style={{ margin: '10px 0', fontWeight: 'bold' }}>Email</IonLabel>
              <IonInput
                style={{ width: '95%' }}
                className={commonStyles.inputStyle}
                name='Email'
                placeholder='john@example.com'
                value={email}
                onIonChange={e => setEmail(e.detail.value)}
              />
            </IonRow>
            <IonRow style={{ display: 'flex', flexDirection: 'column' }}>
              <IonLabel style={{ margin: '10px 0', fontWeight: 'bold' }}>Password</IonLabel>
              <IonInput
                style={{ width: '95%' }}
                className={commonStyles.inputStyle}
                name='Password'
                placeholder='password'
                type='password'
                value={password}
                onIonChange={e => setPassword(e.detail.value)}
              />
            </IonRow>
            <IonButton
              onClick={handleSignUp}
              disabled={!isFormValid() || disabled} // Disable the button if the form is not valid
              style={{ margin: '1.5rem 0' }}
              color={'dark'}
              expand='full'
            >
              Register
            </IonButton>
            <IonRow
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IonRouterLink routerLink='/login'>
                <h6 style={{ color: 'black', fontWeight: 'bold' }}>Login</h6>
              </IonRouterLink>
              <h6 style={{ fontWeight: 'bold' }}>Forgot Password</h6>
            </IonRow>
          </IonGrid>
        </StandardCenterCenter>
      </IonContent>

      {/* <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <StorefrontIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign Up
          </Typography>
          <Typography variant='body2' color='textSecondary' align='center'>
            Welcome.
          </Typography>

          <form className={classes.form} noValidate onSubmit={handleSignUp}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete='email'
                  name='email'
                  variant='outlined'
                  required
                  fullWidth
                  id='email'
                  label='Email'
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete='password'
                  name='password'
                  variant='outlined'
                  type='password'
                  required
                  fullWidth
                  id='password'
                  label='Password'
                  autoFocus
                />
              </Grid>
            </Grid>
            <Button
              type='submit'
              disabled={disabled}
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              style={{ height: 50, marginTop: 20 }}
            >
              Continue
            </Button>
          </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container> */}
    </IonPage>
  )
}

export default SignUp
