export const GetSeriesDataForLineChart = (categoriesResponse, data, dataField) => {
  console.log(data, 'data in get series chart')
  const months = categoriesResponse.monthNumbers

  // Create series data from the provided API data
  const series = [{ data: [] }]
  series.forEach(seriesItem => {
    categoriesResponse.monthNumbers.forEach(() => {
      seriesItem.data.push(0)
    })
  })

  // Update series data with fetched API data
  data.forEach(item => {
    const paddedMonth = String(item.month).padStart(2, '0') // Padding the month number
    const lastTwoDigitsOfYear = item.year % 100
    const monthYear = paddedMonth + '-' + lastTwoDigitsOfYear

    const index = months.findIndex(month => month === monthYear)

    if (index !== -1) {
      const hours = item[dataField]
      series[0].data[index] = hours
    }
  })
  return series
}
