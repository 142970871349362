import Chip from '@mui/material/Chip'
import { IonCol, IonGrid, IonItem, IonLabel, IonList, IonRow } from '@ionic/react'
import Avatar from '../commonComponents/avatar'
import StatusCircle from '../commonComponents/statusCircle'
import { colors } from '../theme/colors'

export default function ResultsListItem({ row, businessId }) {
  return (
    <IonItem
      button
      detail={true}
      routerLink={`/core/appointments/${row._id}`}
      routerDirection='forward'
      key={row._id}
    >
      <IonLabel style={{ maxWidth: 50 }}>
        <StatusCircle status={row.status} />
      </IonLabel>
      <IonLabel>
        <IonGrid style={{ marginLeft: -10, marginBottom: -10, marginTop: -10 }}>
          <IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <IonCol size='auto'>
              <h4
                style={{ fontWeight: '600', color: 'black', fontSize: 18 }}
              >{`${row.customer.firstName} ${row.customer.lastName}`}</h4>
            </IonCol>
            <IonCol></IonCol>
            <IonCol size='auto' style={{ display: 'flex', flexDirection: 'row' }}>
              <Avatar employee={row.barber} businessId={businessId} mini={true} />
            </IonCol>
          </IonRow>
        </IonGrid>

        <p>
          {row?.services?.map(s => {
            return `${s.name} `
          })}
        </p>
        <p style={{ color: 'black' }}>
          {row?.type == 'Walkin' ? null : (
            <>
              {row?.startTimeText?.replace(' PM', '')?.replace(' AM', '')} - {row?.endTimeText}
              &nbsp;&nbsp;
            </>
          )}
          ({row?.durationMin} min)
        </p>
        <p style={{ color: 'black' }}>{row?.selectedDate}</p>
        <p></p>
      </IonLabel>
    </IonItem>
  )
}
