// Import necessary modules and utilities
import { IonCard, IonContent, IonPage, IonSpinner } from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import CommonHeader from '../commonComponents/CommonHeader'
import { useEffect, useState } from 'react'
import { getAllDaysInCurrentMonth, getDaysInCurrentMonth } from '../utils'
import { getMonthRange } from '../Utils/GetDates'
import axios from 'axios'
import { GetXAxisMonths } from '../Utils/GetXAxisMonths'
import GetPickerMonths from '../Utils/GetPickerMonths'
import Picker from '../commonComponents/Picker'
import Chart from 'react-apexcharts'

export default function ImpressionsByRefId({ refId }) {
  // const refId = match?.params?.id

  // Initialize state variables and constants
  let initialNumOfMonths = 6
  const [loadingData, setLoadingData] = useState(false)
  const [selectedOption, setSelectedOption] = useState(initialNumOfMonths - 1)
  const pickerItems = GetPickerMonths()
  const [data, setData] = useState(false)

  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 250,
      stacked: true,
      toolbar: false,
    },
    xaxis: {
      categories: [],
      tickAmount: 5,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '90%',
        endingShape: 'rounded',
      },
    },

    stroke: {
      show: false,
      width: 2,
      colors: ['transparent'],
    },
    grid: {
      borderColor: '#fff',
    },
    yaxis: {
      title: {
        text: 'Customers',
      },
    },
    toolbar: {
      show: false,
      tools: {
        download: false,
      },
    },
    export: {
      show: false,
    },

    legend: {
      show: true,
      offsetY: 0,
    },
  })
  const [series, setSeries] = useState([])

  // Function to fetch data based on reference ID, start date, end date, and number of months
  const getAcquistionData = async (startDate, endDate, noOfMonths) => {
    try {
      let url = `/customerAcquisition/reports/impressions?fromDate=${startDate}&toDate=${endDate}&referenceId=${refId}`

      const response = await axios.get(url)

      setLoadingData(false)

      const data = response.data
      const daysInCurrentMonth = getDaysInCurrentMonth()

      const categoriesResponse = GetXAxisMonths(noOfMonths)

      const months = categoriesResponse.monthNumbers
      // Create series data from the provided API data
      const series = [{ data: [] }]

      categoriesResponse.monthNumbers.forEach(() => {
        series[0].data =
          noOfMonths === 0 || noOfMonths === 1
            ? Array(daysInCurrentMonth).fill(0)
            : Array(months.length).fill(0)
      })

      // Update series data with fetched API data
      if (data) {
        data.counts.forEach(item => {
          if (noOfMonths === 0 || noOfMonths === 1) {
            const dayIndex = item.day - 1
            series[0].data[dayIndex] = item.count
          } else {
            const paddedMonth = String(item.month).padStart(2, '0') // Padding the month number
            const lastTwoDigitsOfYear = item.year % 100
            const monthYear = paddedMonth + '-' + lastTwoDigitsOfYear

            const index = months.findIndex(month => month === monthYear)

            if (index !== -1) {
              series[0].data[index] = item.count
            }
          }
        })
      }
      setSeries(series)
      setOptions(prevOptions => ({
        ...prevOptions,
        yaxis: {
          tickAmount: 4, // This specifies the number of ticks on the y-axis
          forceNiceScale: true, // This ensures the y-axis scale adheres to tickAmount
        },
        xaxis: {
          ...prevOptions.xaxis,
          categories:
            noOfMonths === 0 || noOfMonths === 1
              ? getAllDaysInCurrentMonth(noOfMonths)
              : categoriesResponse.categories,
        },
      }))
      setData(response.data)
    } catch (error) {
      // Handle errors if any
    }
  }

  // Function to handle the selected index change
  const handleSelectedIndex = indexValue => {
    const data = getMonthRange(indexValue)

    const selectedValue = pickerItems.find((item, index) => index === indexValue)
    if (selectedOption !== selectedValue.text) {
      setLoadingData(true)
      setSelectedOption(selectedValue.text)
      getAcquistionData(data.startDate, data.endDate, indexValue)
    }
  }
  useEffect(() => {
    const data = getMonthRange(initialNumOfMonths)
    getAcquistionData(data.startDate, data.endDate, initialNumOfMonths)
  }, [refId])
  // Initial data fetching on component mount
  useEffect(() => {
    const data = getMonthRange(initialNumOfMonths)
    getAcquistionData(data.startDate, data.endDate, initialNumOfMonths)
  }, [])

  return (
    // <IonPage>
    //   <CommonHeader backIcon={true} title={updateTitle(data?.name)} />
    //   <IonContent>
    //     <StandardContainer>
    <IonCard className='flat' style={{ padding: '1rem' }}>
      <h4 style={{ color: 'black', marginTop: 0 }}> Impressions</h4>
      <Picker
        options={pickerItems}
        selectedItemIndex={initialNumOfMonths}
        selectedItem={selectedOption}
        handleSelectedIndex={handleSelectedIndex}
      />
      {loadingData && <IonSpinner name='dots' />}
      {!loadingData && (
        <>
          <Chart options={options} series={series} type='bar' height={250} />
        </>
      )}
    </IonCard>
    //     </StandardContainer>
    //   </IonContent>
    // </IonPage>
  )
}
