import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { useContext, useState } from 'react'
import { AuthContext } from '../../auth'
import { Avatar } from '@mui/material'
import axios from 'axios'
import { swapHorizontalOutline } from 'ionicons/icons'

export default function SwitchBusiness() {
  const { businessList, businessData, setBusinessData, getBusinessData } = useContext(AuthContext)
  const [isSwitchBusinessModalOpen, setSwitchBusinessModal] = useState(false)

  const setDefaultbusiness = selectedBusiness => {
    axios.defaults.headers.common['prop-businessId'] = selectedBusiness._id
    localStorage.setItem('defaultBusiness', selectedBusiness._id)
    //when ever am switching business i am removing the default location selected
    localStorage.removeItem('defaultLocation')
    setBusinessData(selectedBusiness)
    setSwitchBusinessModal(false)
    getBusinessData()
  }
  // Inside your component

  const handleSwitchBusinessClick = () => {
    setSwitchBusinessModal(!isSwitchBusinessModalOpen)
  }
  const handleCloseSwitchBusinessModal = () => {
    setSwitchBusinessModal(false)
  }

  return (
    <>
      <IonButton style={{ marginTop: '1rem' }} fill='clear' onClick={handleSwitchBusinessClick}>
        <IonIcon icon={swapHorizontalOutline} />
      </IonButton>
      <IonModal isOpen={isSwitchBusinessModalOpen}>
        <IonHeader>
          <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonTitle>Switch Business</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={handleCloseSwitchBusinessModal}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonText color='medium' style={{ margin: '1rem' }}>
          <i>Click On Any Business To switch</i>
        </IonText>
        {businessList != 'loading' &&
          businessList.length > 0 &&
          businessList.map(business => {
            // Split the input string by spaces, extract first letters, and join them
            const businessMnemonic = business.name
              .split(' ')
              .map(word => word.charAt(0).toUpperCase())
              .join('')
            return (
              <IonItem
                key={businessData._id}
                onClick={() => setDefaultbusiness(business)}
                style={{
                  'cursor': 'pointer',
                  '--ion-item-background': businessData._id === business._id ? '#dadada' : '',
                }}
              >
                <IonAvatar>
                  <Avatar variant='square'>{businessMnemonic}</Avatar>
                </IonAvatar>
                <IonLabel style={{ marginLeft: '0.5rem' }}>
                  <p>{business.name}</p>
                </IonLabel>
              </IonItem>
            )
          })}
      </IonModal>
    </>
  )
}
