import * as React from 'react';
import {Button, FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios'
import ImageUpload from '../commonComponents/uploadImage'

export default function ProfilePicDialog({ open, handleClose, businessId, locationId, employee }) {

  function successCallback(){
    handleClose(true)
  }

  function failureCallback(){
    alert("Sorry there was an issue")
  }
  

  return (

    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ marginRight: 100 }}>Profile Pic Upload</DialogTitle>
      <DialogContent>
        <DialogContentText>
        </DialogContentText>
       <ImageUpload successCallback={successCallback} failureCallback={failureCallback}  postUrl='/employee/profilePic' headerMetadata={{cc_businessId:businessId, cc_employeeId: employee._id}}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {/* <Button disabled={isDisabled} color='primary' variant='contained' onClick={createButtonClicked}>Create</Button> */}
      </DialogActions>
    </Dialog>
  );
}