import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
  IonTextarea,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import axios from 'axios'
import { add, checkmark, pencil } from 'ionicons/icons'
import { GetOrdinalText } from '../Utils/GetOrdinalText'
import ListView from './ListView'
import ChooseAudience from './ChooseAudience'
import { useHistory } from 'react-router'
import CheckEmailRemarketingRuleExists from '../Utils/CheckEmailRemarketingRuleExists'
import { colors } from '../theme/colors'

export default function DetailView({ match }) {
  const [loading, setLoading] = useState(true)
  const [remarketingData, setRemarketingData] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [loadingRuleName, setLoadingRuleName] = useState(false)
  let [remarketingTempData, setRemarketingTempData] = useState(false)
  const [ruleName, setRuleName] = useState('')
  const [ruleNameTemp, setRuleNameTemp] = useState('')
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deletingData, setDeletingData] = useState(false)
  const [addingStep, setAddingStep] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  let remarketingId = match?.params?.id
  const history = useHistory()
  const updateRemarketingRules = async input => {
    console.log(input, 'inputinputinputinput') // Log the input object to check its contents

    try {
      const updateRemarketingResponse = await axios.patch(
        `/emailRemarketing/rule/?id=${remarketingTempData._id}`,
        input,
      )
      if (updateRemarketingResponse.status == 200) {
        refreshRemarketingDetails()
        setLoadingRuleName(false)
        setAddingStep(false)
        setEditMode(false)
        // refreshRules()
      }
    } catch (error) {
      console.log(error, 'error============')
    }
  }
  const addEmailToRule = () => {
    setAddingStep(true)
    const emailNumber = remarketingTempData.emailSteps.length + 1

    const emailName = GetOrdinalText(emailNumber) + ' email'
    const newEmailObject = {
      id: emailNumber,
      name: emailName,
      followUpDays: false,
    }

    const newRules = [...remarketingTempData.emailSteps, newEmailObject]

    setRemarketingTempData(prevData => ({
      ...prevData,
      emailSteps: newRules,
    }))
    const remarketingInput = {
      smtpSettingsId: remarketingTempData.smtpSettingsId,
      ruleName: ruleName,
      audiences: remarketingTempData.audiences,
      emailSteps: newRules,
    }
    console.log(remarketingInput, 'remarketingInputremarketingInput')

    updateRemarketingRules(remarketingInput)

    //
  }
  const handleDeleteModal = value => {
    setDeleteModalVisible(value)
  }
  const handleEditClick = () => {
    setEditMode(true)
  }
  const handleRuleNameChange = async e => {
    // const ruleNameValue = e.target.value

    // setRuleName(ruleNameValue)
    const ruleNameValue = e.target.value
    setRuleName(ruleNameValue)
    if (ruleNameTemp != ruleNameValue) {
      try {
        const ruleExistsResponse = await CheckEmailRemarketingRuleExists(ruleNameValue)
        //if emailValidationMessage then seterror message as email already exists
        const validationMessage = ruleExistsResponse.ruleExists ? 'Rule Name Already In Use' : false
        setErrorMessage(validationMessage)
      } catch (error) {}
    }
  }

  const handleSaveRuleName = async () => {
    setLoadingRuleName(true)

    const remarketingInput = {
      smtpSettingsId: remarketingTempData.smtpSettingsId,
      ruleName: ruleName,
      audiences: remarketingTempData.audiences,
      emailSteps: remarketingTempData.emailSteps,
    }
    updateRemarketingRules(remarketingInput)
  }
  const getRemarketingDetails = async () => {
    try {
      let response = await axios.get(`/emailRemarketing/rule?id=${remarketingId}`)

      if (response.status === 200) {
        setRemarketingData(response.data)
        setRemarketingTempData(response.data)
        setRuleName(response?.data?.ruleName)
        setRuleNameTemp(response?.data?.ruleName)
        setLoading(false)
      }
    } catch (error) {}
  }
  const handleDelete = async () => {
    setDeletingData(true)
    try {
      const deleteResponse = await axios.delete(`/emailRemarketing/rule/?id=${remarketingData._id}`)
      if (deleteResponse.status === 200) {
        setDeletingData(false)
        handleDeleteModal(false)
        history.goBack()
      }
    } catch (error) {}
  }
  const refreshRemarketingDetails = () => {
    getRemarketingDetails()
  }
  useEffect(() => {
    getRemarketingDetails()
  }, [remarketingId])
  return (
    <IonPage id='main-content'>
      <CommonHeader
        title={'Rule'}
        handleDeleteModal={handleDeleteModal}
        deleteModalVisible={deleteModalVisible}
        deleteButton={true}
        deleteMessage={'Are You Sure You want to delete this rule?'}
        deleteTitle={'Delete Rule'}
        handleDelete={handleDelete}
        deletingData={deletingData}
        backIcon={true}
      ></CommonHeader>
      <IonContent>
        <StandardContainer>
          <div style={{ padding: '0 1rem 1rem 1rem' }}>
            {loading && (
              <IonRow style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center' }}>
                <IonSpinner name='dots' />
              </IonRow>
            )}

            {!loading && remarketingTempData && (
              <>
                {editMode ? (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '1rem',
                      }}
                    >
                      <IonList style={{ width: '85%' }} mode='ios'>
                        <IonItem>
                          <IonTextarea
                            style={{ fontSize: '1.2rem', lineHeight: '1.5rem', fontWeight: 'bold' }}
                            value={ruleName}
                            autoGrow={true}
                            onIonChange={handleRuleNameChange}
                          ></IonTextarea>
                        </IonItem>
                      </IonList>

                      {loadingRuleName && <IonSpinner name='lines' />}
                      {!loadingRuleName && !errorMessage && (
                        <IonIcon
                          onClick={handleSaveRuleName}
                          icon={checkmark}
                          color='primary'
                          style={{ fontSize: '2rem' }}
                        />
                      )}
                    </div>
                    {errorMessage && (
                      <div>
                        <IonLabel style={{ color: colors.errorText, fontSize: '1rem' }}>
                          {errorMessage}
                        </IonLabel>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <h2 style={{ fontWeight: 'bold' }}>
                      {ruleName}
                      <IonIcon
                        color='primary'
                        icon={pencil}
                        onClick={handleEditClick}
                        style={{ cursor: 'pointer', marginLeft: '1rem' }}
                      />
                    </h2>
                  </>
                )}
                <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
                  <ChooseAudience
                    smtpSettingsId={remarketingTempData?.smtpSettingsId}
                    ruleName={ruleName}
                    emailSteps={remarketingTempData?.emailSteps}
                    id={remarketingTempData?._id}
                    audiencesList={remarketingTempData?.audiences}
                    refreshRemarketingDetails={refreshRemarketingDetails}
                    // remarketingTempData={remarketingTempData}
                  />
                </IonList>
                <ListView
                  refreshRemarketingDetails={refreshRemarketingDetails}
                  id={remarketingTempData?._id}
                  smtpSettingsId={remarketingTempData?.smtpSettingsId}
                  ruleName={remarketingTempData?.ruleName}
                  audiences={remarketingTempData?.audiences}
                  emailSteps={remarketingTempData?.emailSteps}
                  // remarketingTempData={remarketingTempData}
                  // emailSteps={remarketingTempData?.emailSteps}
                />
                {addingStep && <IonSpinner name='dots' />}
                {!addingStep && (
                  <IonRow
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={addEmailToRule}
                  >
                    <IonIcon size='small' color='primary' icon={add} />
                    <IonLabel color='primary'>Add an Email</IonLabel>
                  </IonRow>
                )}
              </>
            )}
          </div>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
