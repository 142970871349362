import React, { useState, useContext, useEffect } from 'react'
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonAvatar,
  IonItem,
  IonLabel,
  IonList,
} from '@ionic/react'
import { AuthContext } from '../../auth'

import axios from 'axios'
import Avatar from '../../commonComponents/avatar'

function Example({ isOpen, setEmployeeModalOpen, locationId, appointmentId, updateAppointment }) {
  const { businessData } = useContext(AuthContext)
  const [employees, setEmployees] = useState([])
  const [loading, setLoading] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  function dismiss() {
    setEmployeeModalOpen(false)
  }

  useEffect(() => {
    getEmployeesList()
  }, [])

  const getEmployeesList = async () => {
    if (!businessData?._id) return
    setLoading(true)
    setErrorMessage('')
    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      let response = await axios.get(`/employee/getList?businessId=${businessData._id}${locQuery}`)
      setEmployees(response.data)
    } catch (error) {
      setErrorMessage('There was an error loading. Try again')
    }
    setLoading(false)
  }

  const updateEmployee = async id => {
    setSaving(true)
    try {
      await axios.patch(`/appointment/updateBarber`, { barberId: id, appointmentId })
      await updateAppointment()
      dismiss()
    } catch (error) {
      alert('There was an issue saving CE-1000')
    }
    setSaving(false)
  }

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonTitle>Change Professional</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => dismiss()}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='regularBackground'>
        <h6 style={{ color: 'grey', marginLeft: 20 }}>Employees</h6>
        <IonList inset={true} mode='ios'>
          {employees?.map(row => (
            <IonItem
              button
              onClick={() => {
                updateEmployee(row._id)
              }}
            >
              <IonAvatar slot='start' style={{ marginTop: 10, marginBottom: 10 }}>
                <Avatar employee={row} businessId={businessData._id} small />
              </IonAvatar>
              <IonLabel>
                <h4
                  style={{ fontWeight: '600', color: 'black', fontSize: 18 }}
                >{`${row.firstName} ${row.lastName}`}</h4>
                {/* <p>{row.role}</p> */}
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  )
}

export default Example
