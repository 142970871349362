import { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import GeneralDetails from './details_general'
import axios from 'axios'
import { AuthContext } from '../auth'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { useHistory } from 'react-router-dom'
import StandardContainer from '../commonComponents/StandardContainer'
import FeaturesList from './featuresList'
import SubscriptionOptionsList from './SubscriptionOptionsList'
import ConfirmationModal from '../commonComponents/ConfirmationModal'

// Function to define a tab panel
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

// Main React functional component
export default function Details({ match }) {
  // Extract subscriptionPackage_id from match params
  let subscriptionPackage_id = match?.params?.id

  // State hooks for managing component state
  const [value, setValue] = useState(0)
  const [subscriptionPackage, setSubscriptionPackage] = useState()
  const [subscriptionPackageTemp, setSubscriptionPackageTemp] = useState()
  const [subscriptionPackageChanged, setSubscriptionPackageChanged] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deletingData, setDeletingData] = useState(false)
  const [activeSubscriptionsCount, setActiveSubscriptionsCount] = useState(false)
  // Context data
  const { businessData, locationId } = useContext(AuthContext)

  // History hook for programmatic navigation
  const history = useHistory()
  const getActiveSubscriptions = async () => {
    try {
      const response = await axios.get(
        `/subscription/count?subscriptionPackageId=${subscriptionPackage_id}`,
      )
      console.log(response, 'responseresponse')
      setActiveSubscriptionsCount(response?.data?.count)
      console.log(response, 'response')
    } catch (error) {}
  }
  // Function to fetch subscription package data
  const getSubscriptionPackage = async () => {
    let response = await axios.get(`/subscriptionPackage?id=${subscriptionPackage_id}`)
    setSubscriptionPackage(response.data)
    setSubscriptionPackageTemp(response.data)
    setSubscriptionPackageChanged(false)
  }

  // useEffect to fetch data on component mount
  useEffect(() => {
    getSubscriptionPackage()
    getActiveSubscriptions()
  }, [subscriptionPackage_id])

  // Event handler for tab change
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // Function to update subscription package state
  const updateSubscriptionPackage = value => {
    setSubscriptionPackageTemp(value)
    setSubscriptionPackageChanged(true)
  }

  // Function to save subscription package changes
  const save = async () => {
    try {
      let subscriptionInput = { ...subscriptionPackageTemp }

      // Create prices
      const createPricePromises = subscriptionInput.prices.map(async price => {
        if (price.createPrice) {
          const priceInput = { ...price, createPrice: undefined }
          try {
            const response = await axios.post(`/subscriptionPackage/createPrice`, priceInput)
            return { ...price, stripePriceId: response?.data?.id }
          } catch (error) {
            console.error(error)
            return price
          }
        }
        return price
      })

      // Wait for all price creation promises to resolve
      subscriptionInput.prices = await Promise.all(createPricePromises)

      // Update prices
      const updatePricePromises = subscriptionInput.prices
        .filter(price => price.changed && price.stripePriceId)
        .map(async price => {
          try {
            const response = await axios.patch(
              `/subscriptionPackage/updatePrice?priceId=${price.stripePriceId}&status=${price.active}`,
            )
            // Handle the response as needed
          } catch (error) {
            console.error(error)
          }
        })

      // Wait for all price update promises to resolve
      await Promise.all(updatePricePromises)

      // Remove unnecessary properties
      delete subscriptionInput._id
      delete subscriptionInput.stripeProductId
      delete subscriptionInput.monthlyPrice
      delete subscriptionInput.yearlyPrice
      delete subscriptionInput.yearlyPriceByMonth

      subscriptionInput.prices.forEach(price => {
        //removing the flags that are used for local purpose
        delete price.createPrice
        delete price.changed
        delete price.stripeProductId
      })

      // Patch request to update subscription package
      const response = await axios.patch(
        `/subscriptionPackage?id=${subscriptionPackage_id}`,
        subscriptionInput,
      )
      if (response.status === 200) {
        setSubscriptionPackageChanged(false)
      }

      // Fetch updated data after save
      await getSubscriptionPackage()
    } catch (error) {
      console.error(error)
    }
  }

  // Function to cancel changes
  const cancel = () => {
    setSubscriptionPackageTemp(subscriptionPackage)
    setSubscriptionPackageChanged(false)
    getSubscriptionPackage()
  }

  // Function to add or update pricing
  const addUpdatePricingToList = async (action, option) => {
    let subscriptionTemp = { ...subscriptionPackageTemp }

    if (action === 'add') {
      try {
        option.createPrice = true
        option.stripeProductId = subscriptionTemp.stripeProductId
        subscriptionTemp.prices.push(option)
        updateSubscriptionPackage(subscriptionTemp)
        // }
      } catch (error) {
        // Handle error if needed
      }
    } else if (action === 'update') {
      // Find the index of the object with the matching stripePriceId
      const index = subscriptionTemp?.prices.findIndex(
        item => item.stripePriceId === option.stripePriceId,
      )

      // If the object is found, update the object
      if (index !== -1) {
        subscriptionTemp.prices[index] = option
      }
      updateSubscriptionPackage(subscriptionTemp)
    }
  }

  const openDeleteModal = () => {
    setDeleteModalOpen(true)
  }
  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
  }
  // Function to delete subscription package
  const deleteSubscriptionPackage = async () => {
    try {
      setDeletingData(true)
      // Delete subscription package and navigate back
      const response = await axios.delete(`/subscriptionPackage?id=${subscriptionPackage_id}`)
      if (response.status === 200) {
        await getSubscriptionPackage()
        setDeletingData(false)
        setDeleteModalOpen(false)
      }
      history.goBack()
    } catch (error) {}
  }

  // Render loading spinner if data is not yet available
  if (!subscriptionPackage)
    return (
      <IonPage>
        <IonContent></IonContent>
      </IonPage>
    )

  // Render the main content of the component

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>
            {subscriptionPackageTemp ? subscriptionPackageTemp?.name : 'loading...'}
          </IonTitle>
          {subscriptionPackageChanged ? (
            <>
              <IonButtons slot='end'>
                <IonButton onClick={cancel}>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot='end'>
                <IonButton color='primary' onClick={save}>
                  Save
                </IonButton>
              </IonButtons>
            </>
          ) : null}
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {subscriptionPackage ? (
          <StandardContainer>
            {/* General Details Component */}
            <GeneralDetails
              activeSubscriptionsCount={activeSubscriptionsCount}
              subscriptionPackage={subscriptionPackageTemp}
              updateSubscriptionPackage={updateSubscriptionPackage}
              locationId={locationId}
            />
            {/* Subscription Options Component */}
            <SubscriptionOptionsList
              getSubscriptionPackage={getSubscriptionPackage}
              subscriptionPackage_id={subscriptionPackage_id}
              subscriptionPackage={subscriptionPackageTemp}
              updateSubscriptionPackage={updateSubscriptionPackage}
              pricingOptions={subscriptionPackageTemp?.prices}
              addUpdatePricingToList={addUpdatePricingToList}
            />
            {/* Features List Component */}
            <FeaturesList
              subscriptionPackage={subscriptionPackageTemp}
              updateSubscriptionPackage={updateSubscriptionPackage}
            />
            {/* Delete Button */}
            {activeSubscriptionsCount > 0 ? null : (
              <div style={{ padding: 10, marginTop: 100 }}>
                <IonButton color='warning' fill='solid' expand='block' onClick={openDeleteModal}>
                  Delete
                </IonButton>
              </div>
            )}
            <IonModal isOpen={deleteModalOpen}>
              <ConfirmationModal
                isOpen={deleteModalOpen}
                message={'Are You Sure You want to delete this package?'}
                cancel={() => closeDeleteModal()}
                loading={deletingData}
                save={deleteSubscriptionPackage}
                saveText={'Delete'}
              />
            </IonModal>
          </StandardContainer>
        ) : (
          // Show loading spinner while data is being fetched
          <IonSpinner name='dots' />
        )}
      </IonContent>
    </IonPage>
  )
}
