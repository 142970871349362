import { IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow } from '@ionic/react'
import { mail, settings, settingsOutline } from 'ionicons/icons'
import React from 'react'
import { useHistory } from 'react-router'

export default function ConfigureEmailSettings({ smtpSettings }) {
  const history = useHistory()
  const navigateToConfigureEmailPage = () => {
    history.push('/core/emailConfiguration')
  }
  const updateEmailConfiguration = () => {
    console.log(smtpSettings, 'smtpSettingssmtpSettings')
    history.push(`/core/emailConfiguration/${smtpSettings?._id}`)
  }
  return smtpSettings ? (
    <IonList inset={true} mode='ios' style={{ margin: '1rem 0' }}>
      <IonItem routerLink={`/core/emailConfiguration/${smtpSettings?._id}`} detail={true}>
        <IonLabel>Update Email Configuration</IonLabel>
      </IonItem>
    </IonList>
  ) : (
    <IonRow
      style={{
        margin: '1rem 0',
        borderLeft: '0.7rem solid #E64A19',
        padding: '0.5rem 0',
        backgroundColor: 'white',
      }}
    >
      <IonGrid>
        <IonRow style={{ display: 'flex', alignItems: 'start' }}>
          <IonCol size='auto' style={{ marginTop: '0.5rem' }}>
            <IonIcon
              icon={mail}
              style={{
                height: '1.5rem',
                width: '1.5rem',
                color: 'white',
                backgroundColor: '#E64A19',
                padding: '10px',
                borderRadius: '50%',
                marginRight: '0.5rem',
              }}
            />
          </IonCol>
          <IonCol>
            <h6 style={{ fontWeight: 'bold' }}>Email Settings Not Configured</h6>
            <h6 style={{ fontSize: '0.9rem' }}>
              To send emails to customers , your email settings need to be configured
            </h6>

            <IonRow style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon
                icon={settingsOutline}
                style={{ height: 22, width: 22, marginRight: '0.4rem' }}
                color='primary'
              />
              <IonLabel
                onClick={navigateToConfigureEmailPage}
                style={{ fontWeight: 'bold' }}
                color={'primary'}
              >
                {'Configure Now'}
              </IonLabel>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonRow>
  )
}
