import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSpinner,
  IonTextarea,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router'
import { cancelSubscriptionOptions } from '../StaticData/StaticData'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { calculateNearestFutureSubscriptionEndDate, getFormattedMediumDate } from '../utils'
import { colors } from '../theme/colors'

export default function CancelSubscription({ match }) {
  const subscription_id = match?.params?.id
  const [cancellationType, setSelectedCancelOption] = useState('immediately')
  const cancelOptions = cancelSubscriptionOptions
  const [selectedValue, setSelectedValue] = useState('')
  const [cancellingMembership, setCancellingMembership] = useState(false)
  const [cancelledMembership, setCancelledMembership] = useState(false)
  const [subscriptionData, setSubscription] = useState(false)
  const [reason, setReason] = useState('')
  const history = useHistory()
  const handleChange = event => {
    setSelectedValue(event.detail.value)
  }
  const handleReason = event => {
    setReason(event.detail.value)
  }
  const getSubscription = async () => {
    try {
      let response = await axios.get(`/subscription?id=${subscription_id}`)
      if (response && response.data) {
        setSubscription(response.data)
      }
    } catch (error) {}
  }
  let nearestEndDate = false
  if (subscriptionData) {
    nearestEndDate = calculateNearestFutureSubscriptionEndDate(
      subscriptionData?.startDate,
      subscriptionData?.period?.toLowerCase(),
    )
  }

  const handleCancelMembership = async () => {
    setCancellingMembership(true)
    try {
      const cancelSubscriptionInput = {
        cancellationReason: selectedValue,
        writtenFeedback: reason,
        cancellationType,
        cancellationDate:
          cancellationType == 'immediately' ? getFormattedMediumDate(new Date()) : nearestEndDate,
      }

      const response = await axios.patch(
        `/subscription/cancel?id=${subscription_id}&customerId=${subscriptionData.customerId}`,
        cancelSubscriptionInput,
      )

      if (response?.data?.success) {
        setCancelledMembership(true)
      }
      getSubscription()
    } catch (error) {
      alert(error)
    }
  }
  const handleCancellationType = () => {
    setSelectedCancelOption(cancellationType == 'immediately' ? 'endOfPeriod' : 'immediately')
  }

  const handleGoBackHome = () => {
    setCancellingMembership(false)
    setCancelledMembership(false)
    history.goBack()
  }
  useEffect(() => {
    getSubscription()
  }, [])

  return (
    <IonPage id='main-content'>
      <CommonHeader title={'Cancel Membership'} backIcon={!cancelledMembership}></CommonHeader>
      <IonContent>
        <StandardContainer>
          <IonCard style={{ padding: '1rem' }}>
            {cancelledMembership && (
              <>
                <h5 style={{ color: 'black', fontWeight: 'bold' }}>Membership Cancelled</h5>
                {/* <IonRow style={{ paddingBottom: '0.8rem' }}>
                  <IonLabel style={{ fontWeight: 'bold' }}>Sorry to see you go</IonLabel>
                </IonRow> */}
                <IonButton onClick={handleGoBackHome} style={{ width: '100%' }}>
                  Back To Home
                </IonButton>
              </>
            )}
            {!cancelledMembership && (
              <>
                <h2 style={{ color: 'black', fontWeight: 'bold', marginTop: 10 }}>
                  Cancel Membership
                </h2>
                <IonGrid>
                  <IonRow>
                    <IonCol style={{ marginRight: '1rem' }} size='auto'>
                      <h6 style={{ color: 'black', fontWeight: 'bold' }}>Cancel</h6>
                    </IonCol>
                    <IonCol>
                      <IonRadioGroup value={cancellationType} onIonChange={handleCancellationType}>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }}>
                          <IonRadio
                            key='immediately'
                            slot='start'
                            mode='md'
                            checked={cancellationType == 'immediately' ? true : false}
                            onIonChange={handleCancellationType}
                          ></IonRadio>

                          <IonLabel
                            onClick={handleCancellationType}
                            style={{
                              color: cancellationType == 'immediately' ? colors.primaryBlue : '',
                              marginLeft: '0.5rem',
                            }}
                          >
                            Immediately {getFormattedMediumDate(new Date())}
                          </IonLabel>
                        </div>{' '}
                        <div style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }}>
                          <IonRadio
                            key='endOfPeriod'
                            mode='md'
                            slot='start'
                            checked={cancellationType == 'endOfPeriod' ? true : false}
                            onIonChange={handleCancellationType}
                          ></IonRadio>

                          <IonLabel
                            onClick={handleCancellationType}
                            style={{
                              color: cancellationType == 'endOfPeriod' ? colors.primaryBlue : '',
                              marginLeft: '0.5rem',
                            }}
                          >
                            End Of Period {nearestEndDate}
                          </IonLabel>
                        </div>{' '}
                      </IonRadioGroup>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonLabel style={{ color: 'black' }}>
                  Why is the customer canceling their membership?
                </IonLabel>
                <IonGrid>
                  <IonRadioGroup value={selectedValue} onIonChange={handleChange}>
                    {cancelOptions.map((item, index) => (
                      <IonRow
                        key={index}
                        style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }}
                        onClick={() => setSelectedValue(item.value)}
                      >
                        <IonLabel
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px', // Adjust the gap size as needed
                          }}
                        >
                          <IonRadio slot='start' checked={selectedValue === item.value} mode='md' />
                          {item.label}
                        </IonLabel>
                      </IonRow>
                    ))}
                  </IonRadioGroup>
                </IonGrid>
                {selectedValue == 'other' && (
                  <IonTextarea
                    value={reason}
                    onIonChange={handleReason}
                    style={{
                      padding: '1rem',
                      border: '1px solid #ccc', // Example border style
                      borderRadius: '5px', // Example border radius
                      width: '100%', // Example width
                      marginBottom: '1rem',
                      minHeight: '6rem',
                    }}
                    placeholder='Please Explain'
                  ></IonTextarea>
                )}
                <IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {cancellingMembership && (
                    <IonButton
                      expand='block'
                      onClick={handleCancelMembership}
                      disabled={!selectedValue}
                    >
                      <IonSpinner name='lines' />
                      <IonLabel>Cancelling Membership</IonLabel>
                    </IonButton>
                  )}
                </IonRow>
                {!cancellingMembership && (
                  <IonButton
                    expand='block'
                    onClick={handleCancelMembership}
                    disabled={!selectedValue}
                  >
                    <IonLabel>Submit and Cancel Membership</IonLabel>
                  </IonButton>
                )}
              </>
            )}
          </IonCard>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
