
import { Box, Button, Input, LinearProgress } from "@mui/material";
import React, { useState } from "react";
import axios from 'axios'

  
export default function UploadImage({postUrl, headerMetadata, successCallback, failureCallback}) {
    const [file, setFile] = useState();
    const [fileObject, setFileObject] = useState()
    const [saving, setSaving] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
        setFileObject(e.target.files[0])
    }
  
    async function onSave(){
        setSaving(true)
        const formData = new FormData();
        formData.append("selectedFile", fileObject);

        
        try {
          const response = await axios({
            method: "post",
            url: postUrl,
            data: formData,
            headers: { ...{"Content-Type": "multipart/form-data" }, ...(headerMetadata ? headerMetadata : {}) }
          });
          if(response.status != 200) throw Error()

          successCallback()

        } catch(error) {
          console.log(error)
          setErrorMessage('Sorry there was an error, please try again in a few minutes.')
          setSaving(false)
          failureCallback(error)
        }
    
    }


    return (
        <div className="App">
            
            <Input type="file" accept="image/*" onChange={handleChange} />
            <br></br>
            <Box style={{margin:20}}>
                <img src={file} style={{borderRadius:200, height:'100%', maxWidth:200}}/>
            </Box>

            {file && !saving ? <Button variant="contained" onClick={onSave}>Save Picture</Button> : null}
            {saving ? <><LinearDeterminate/> saving...</> : null}
  
        </div>
  
    );
}



function LinearDeterminate() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress > 95) return oldProgress

        let multiplier = 10
        if (oldProgress > 80) multiplier = 1
        if (oldProgress > 50) multiplier = 2

        const diff = Math.random() * multiplier;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
}