import React, { useState, useEffect, useContext } from 'react';
import { Grid, TextField } from '@mui/material';
import { IonCard } from '@ionic/react';

export default function DetailsView({ location, updateLocation, checkLocation, locations, locationId }) {

  let onValueChange = (field, value) => {
    updateLocation({ ...location, [field]: value })
  }

  if (!location) return "loading..."


  return (
    <IonCard style={{padding:20}}>
      <Grid container>
        <Grid item xs="12">

          {/* <Typography variant="p">Details {location.name} {location.lastName}</Typography> */}

            <TextField
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              variant="standard"
              value={location.name}
              onChange={(e) => { onValueChange('name', e.target.value) }}
              style={{ marginRight: 10 }}
            />
          <TextField
            margin="dense"
            id="email"
            label="Email"
            type="text"
            fullWidth
            variant="standard"
            value={location.email}
            onChange={(e) => { onValueChange('email', e.target.value) }}
            style={{ marginTop: 20 }}
          />

          <TextField
            margin="dense"
            id="phoneNumber"
            label="Phone Number"
            type="text"
            fullWidth
            variant="standard"
            value={location.phoneNumber}
            onChange={(e) => { onValueChange('phoneNumber', e.target.value) }}
            style={{ marginTop: 20 }}
          />

          <TextField
            margin="dense"
            id="addressStreet1"
            label="Address"
            type="text"
            fullWidth
            variant="standard"
            value={location.addressStreet1}
            onChange={(e) => { onValueChange('addressStreet1', e.target.value) }}
            style={{ marginTop: 20 }}
          />
          <TextField
            margin="dense"
            id="addressStreet2"
            label="Address (line 2)"
            type="text"
            fullWidth
            variant="standard"
            value={location.addressStreet2}
            onChange={(e) => { onValueChange('addressStreet2', e.target.value) }}
            style={{ marginTop: 0 }}
          />
          <div style={{ flex: 1, flexDirection: 'row', width: '100%' }}>
            <TextField
              margin="dense"
              id="city"
              label="City"
              type="text"
              variant="standard"
              value={location.addressCity}
              onChange={(e) => { onValueChange('addressCity', e.target.value) }}
              style={{ flex: 1, marginRight: 10 }}
            />
            <TextField
              margin="dense"
              id="state"
              label="State"
              type="text"
              variant="standard"
              value={location.addressState}
              onChange={(e) => { onValueChange('addressState', e.target.value) }}
              style={{ flex: 1, marginRight: 10 }}
            />
            <TextField
              margin="dense"
              id="Zipcode"
              label="Zipcode"
              type="text"
              variant="standard"
              value={location.addressZipcode}
              onChange={(e) => { onValueChange('addressZipcode', e.target.value) }}
              style={{ flex: 1, marginRight: 10 }}
            />
          </div>
        </Grid>
      </Grid>
    </IonCard>
  )
}


