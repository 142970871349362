import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonProgressBar,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { add, ellipsisVerticalOutline, settingsOutline } from 'ionicons/icons'
import { useState } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import ConfirmationModal from './ConfirmationModal'

export default function CommonHeader({
  title,
  backIcon,
  closeButton,
  closeModal,
  handleSaveButton,
  saveButton,
  saveDisabled,
  updatingData,
  showSpinner,
  deleteButton,
  deleteMessage,
  deleteTitle,
  handleDelete,
  handleDeleteModal,
  deletingData,
  deleteModalVisible,
  loading,
  settingsIcon,
  settingsLink,
  progressBar,
  actionIcon,
  addIcon,
  handleAddButton,
  menuItems = [],
  additionalToolbar
}) {
  const styles = {
    menuItemStyle: {
      fontSize: '0.8rem',
      fontWeight: 'bold',
      color: 'grey',
      display: 'flex',
      justifyContent: 'space-between',
    },
    iconStyle: {
      heght: 22,
      width: 22,
      color: 'grey',
    },
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const handleDeleteModalAndMenu = value => {
    handleDeleteModal(value)
    handleClose()
  }
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleMenuItemClick = menuItem => {
    handleClose()
    menuItem.onClick()
  }

  return (
    <IonHeader>
      <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
        {progressBar ? <IonProgressBar type='indeterminate' /> : null}

        <IonTitle class='ion-text-center'>{title}</IonTitle>
        <IonButtons slot='start'>
          {backIcon && <IonBackButton />}
          {closeButton && (
            <IonButton color={'primary'} onClick={closeModal}>
              Close
            </IonButton>
          )}
        </IonButtons>
        <IonButtons slot='end'>
          {showSpinner && updatingData && saveButton && <IonSpinner name='lines' />}
          {loading && <IonSpinner name='lines' />}
          {saveButton && (
            <IonButton disabled={saveDisabled} onClick={handleSaveButton} color={'primary'}>
              Save
            </IonButton>
          )}
          {settingsIcon && (
            <IonButton
              routerLink={settingsLink}
              style={{ cursor: 'pointer' }}
              disabled={saveDisabled}
              onClick={handleSaveButton}
            >
              <IonIcon icon={settingsOutline} />
            </IonButton>
          )}
          {addIcon && (
            <IonButton color={'primary'} onClick={handleAddButton}>
              <IonIcon icon={add} />
              <IonLabel>Add</IonLabel>
            </IonButton>
          )}
          {actionIcon && (
            <>
              <Button
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <IonIcon size='large' style={styles.iconStyle} icon={ellipsisVerticalOutline} />
              </Button>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {menuItems.map((menuItem, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleMenuItemClick(menuItem)}
                    style={styles.menuItemStyle}
                  >
                    <IonCol size='auto'>
                      <IonLabel>{menuItem.label}</IonLabel>
                    </IonCol>

                    {menuItem.icon && (
                      <IonCol size='2'>
                        <IonIcon size='small' icon={menuItem.icon} style={{ marginRight: '8px' }} />
                      </IonCol>
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
          {deleteButton && (
            <>
              <Button
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <IonIcon size='large' style={styles.iconStyle} icon={ellipsisVerticalOutline} />
              </Button>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleDeleteModalAndMenu(true)
                  }}
                  style={styles.menuItemStyle}
                >
                  {deleteTitle}
                </MenuItem>
              </Menu>
            </>
          )}
          {deleteModalVisible && (
            <IonModal isOpen={deleteModalVisible}>
              <ConfirmationModal
                isOpen={deleteModalVisible}
                message={deleteMessage}
                cancel={() => handleDeleteModal(false)}
                loading={deletingData}
                save={handleDelete}
                saveText={'Delete'}
              />
            </IonModal>
          )}
        </IonButtons>
      </IonToolbar>
      {additionalToolbar ? (
        <IonToolbar style={{ background: 'white' }} color={'default'}>
          {additionalToolbar}
          {additionalToolbar ? (
            <>{loading ? <IonProgressBar type='indeterminate' /> : null}</>
          ) : null}
        </IonToolbar>
      ) : null}
    </IonHeader>
  )
}
