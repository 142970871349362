import React, { useState, useContext, useEffect } from 'react'
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonList,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
} from '@ionic/react'
import axios from 'axios'

import { AuthContext } from '../../auth'
import StandardContainer from '../../commonComponents/StandardContainer'
import { calculatePayment } from '../../sharedComponents/paymentCalculation'

function ServicesProductsAndDiscounts({
  isOpen,
  setOpen,
  appointment,
  updateAppointment,
  subscription,
}) {
  const { businessData } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedServices, setSelectedServices] = useState([])
  const [services, setServices] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [products, setProducts] = useState([])
  const [selectedDiscounts, setSelectedDiscounts] = useState([])
  const [discounts, setDiscounts] = useState([])
  const [activeTab, setActiveTab] = useState('Services')
  const [servicesHasUnsavedChanges, setServicesHasUnsavedChanges] = useState(false)
  const [productsHasUnsavedChanges, setProductsHasUnsavedChanges] = useState(false)
  const [discountsHasUnsavedChanges, setDiscountsHasUnsavedChanges] = useState(false)
  const [isSaving, setSaving] = useState(false)

  useEffect(() => {
    if (isOpen && businessData) {
      setSelectedServices(appointment.services || [])
      getServicesList()
      setSelectedProducts(appointment.products || [])
      getProductsList()
      setSelectedDiscounts(appointment.discounts || [])
      getDiscountsList()
    }
  }, [isOpen, businessData])

  useEffect(() => {
    setActiveTab(isOpen)
  }, [isOpen])

  useEffect(() => {
    const hasChanges = JSON.stringify(selectedServices) !== JSON.stringify(appointment.services)
    setServicesHasUnsavedChanges(hasChanges)
  }, [selectedServices, appointment.services])

  useEffect(() => {
    const hasChanges =
      JSON.stringify(selectedProducts) !== JSON.stringify(appointment.products || [])
    setProductsHasUnsavedChanges(hasChanges)
  }, [selectedProducts, appointment.products])

  useEffect(() => {
    const hasChanges =
      JSON.stringify(selectedDiscounts) !== JSON.stringify(appointment.discounts || [])
    setDiscountsHasUnsavedChanges(hasChanges)
  }, [selectedDiscounts, appointment.discounts])

  const dismiss = () => {
    setOpen(false)
  }

  const getServicesList = async () => {
    try {
      setLoading(true)
      setErrorMessage(null)
      const response = await axios.get(`/service/getList?businessId=${businessData._id}`)
      setServices(response.data)
    } catch (error) {
      setErrorMessage('Sorry, there was an error fetching the data')
    } finally {
      setLoading(false)
    }
  }

  const getProductsList = async () => {
    try {
      setLoading(true)
      setErrorMessage(null)
      const response = await axios.get(`/product/getList?businessId=${businessData._id}`)
      setProducts(response.data)
    } catch (error) {
      setErrorMessage('Sorry, there was an error fetching the data')
    } finally {
      setLoading(false)
    }
  }

  const getDiscountsList = async () => {
    try {
      setLoading(true)
      setErrorMessage(null)
      const response = await axios.get(`/discount/getList?businessId=${businessData._id}`)
      setDiscounts(response.data)
    } catch (error) {
      setErrorMessage('Sorry, there was an error fetching the data')
    } finally {
      setLoading(false)
    }
  }

  const handleServiceSelect = service => {
    const serviceIndex = selectedServices.findIndex(
      selectedService => selectedService._id === service._id,
    )

    if (serviceIndex !== -1) {
      const updatedServices = [...selectedServices]
      updatedServices.splice(serviceIndex, 1)
      setSelectedServices(updatedServices)
    } else {
      setSelectedServices([...selectedServices, service])
    }
  }

  const handleProductSelect = product => {
    const productIndex = selectedProducts.findIndex(
      selectedProduct => selectedProduct._id === product._id,
    )

    if (productIndex !== -1) {
      const updatedProducts = [...selectedProducts]
      updatedProducts.splice(productIndex, 1)
      setSelectedProducts(updatedProducts)
    } else {
      setSelectedProducts([...selectedProducts, product])
    }
  }

  const handleDiscountSelect = discount => {
    const discountIndex = selectedDiscounts.findIndex(
      selectedDiscount => selectedDiscount._id === discount._id,
    )

    if (discountIndex !== -1) {
      const updatedDiscounts = [...selectedDiscounts]
      updatedDiscounts.splice(discountIndex, 1)
      setSelectedDiscounts(updatedDiscounts)
    } else {
      setSelectedDiscounts([...selectedDiscounts, discount])
    }
  }
  const updateAppointmentPaymentObject = async paymentObject => {
    const input = {
      payment: paymentObject,
    }

    try {
      const updatePaymentObjectResponse = await axios.patch(
        `/appointment?id=${appointment?._id}`,
        input,
      )
    } catch (error) {}
  }
  const handleSave = async () => {
    setSaving(true)
    try {
      if (servicesHasUnsavedChanges) {
        await axios.patch(`/appointment/updateServices`, {
          appointmentId: appointment?._id,
          services: selectedServices,
        })
      }
      if (productsHasUnsavedChanges) {
        await axios.patch(`/appointment/updateProducts`, {
          appointmentId: appointment?._id,
          products: selectedProducts,
        })
      }
      if (discountsHasUnsavedChanges) {
        await axios.patch(`/appointment/updateDiscounts`, {
          appointmentId: appointment?._id,
          discounts: selectedDiscounts,
        })
      }
      const data = calculatePayment(
        selectedServices,
        selectedProducts,
        selectedDiscounts,
        subscription?.data?.subscription?.subscriptionPackageId,
        null,
        null,
        null,
      )
      await updateAppointmentPaymentObject(data)
      await updateAppointment()
      dismiss()
    } catch (error) {
      alert(error)
    }
    setSaving(false)
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Services':
        return (
          <IonList mode='ios' inset={true}>
            {loading ? (
              <IonItem>Loading...</IonItem>
            ) : errorMessage ? (
              <IonItem>{errorMessage}</IonItem>
            ) : (
              services.map(service => (
                <IonItem key={service._id}>
                  <h6>{service.name}</h6>
                  <IonLabel slot='end'>${service.price}</IonLabel>
                  <IonCheckbox
                    slot='end'
                    checked={selectedServices.some(
                      selectedService => selectedService._id === service._id,
                    )}
                    onIonChange={() => handleServiceSelect(service)}
                  />
                </IonItem>
              ))
            )}
          </IonList>
        )
      case 'Products':
        return (
          <IonList mode='ios' inset={true}>
            {loading ? (
              <IonItem>Loading...</IonItem>
            ) : errorMessage ? (
              <IonItem>{errorMessage}</IonItem>
            ) : (
              products.map(product => (
                <IonItem key={product._id}>
                  <h6>{product.name}</h6>
                  <IonLabel slot='end'>${product.price}</IonLabel>
                  <IonCheckbox
                    slot='end'
                    checked={selectedProducts.some(
                      selectedProduct => selectedProduct._id === product._id,
                    )}
                    onIonChange={() => handleProductSelect(product)}
                  />
                </IonItem>
              ))
            )}
          </IonList>
        )
      case 'Discounts':
        return (
          <IonList inset={true} mode='ios'>
            {loading ? (
              <IonItem>Loading...</IonItem>
            ) : errorMessage ? (
              <IonItem>{errorMessage}</IonItem>
            ) : (
              <>
                {discounts.map((discount, index) => {
                  return (
                    discount.couponType == 'servicesAndProducts' && (
                      <IonItem key={discount._id}>
                        <h6>{discount.name}</h6>
                        <IonLabel slot='end'>
                          <DiscountType row={discount} />
                        </IonLabel>
                        {/* <IonLabel><h4 style={{ fontWeight: '600', fontSize: 18 }}>{discount.name}</h4> <span style={{ color: 'grey', fontSize: 14 }}><DiscountType row={discount} /></span></IonLabel> */}

                        <IonCheckbox
                          slot='end'
                          checked={selectedDiscounts.some(
                            selectedDiscount => selectedDiscount._id === discount._id,
                          )}
                          onIonChange={() => handleDiscountSelect(discount)}
                        />
                      </IonItem>
                    )
                  )
                })}
              </>
            )}
          </IonList>
        )
      // Add additional cases for other tabs (Products, Discounts, etc.)
      default:
        return null
    }
  }

  return (
    <IonModal isOpen={isOpen} className='fullscreen'>
      <IonHeader>
        <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButton slot='start' fill='clear' onClick={dismiss}>
            Close
          </IonButton>
          <IonTitle style={{ color: 'black' }}>Edit or Add {activeTab}</IonTitle>
          {servicesHasUnsavedChanges || productsHasUnsavedChanges || discountsHasUnsavedChanges ? (
            <IonButton slot='end' fill='clear' onClick={handleSave} disabled={isSaving}>
              {isSaving ? <IonSpinner name='dots' /> : <>Save</>}
            </IonButton>
          ) : null}
        </IonToolbar>
        <IonToolbar color='white' style={{ backgroundColor: 'white' }}>
          <IonSegment
            value={activeTab}
            color='dark'
            onIonChange={e => setActiveTab(e.detail.value)}
          >
            <IonSegmentButton value='Services'>
              <IonLabel>Services</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value='Products'>
              <IonLabel>Products</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value='Discounts'>
              <IonLabel>Discounts</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent className='regularBackground'>
        <StandardContainer medium={true}>{renderTabContent()}</StandardContainer>
      </IonContent>
    </IonModal>
  )
}

function DiscountType({ row }) {
  if (row?.discountType == 'percentage') {
    return <>{row?.discountValue}% off</>
  } else if (row?.discountType == 'fixed') {
    return <>${row?.discountValue} off</>
  } else {
    return null
  }
}

export default ServicesProductsAndDiscounts
