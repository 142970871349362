import React, { useState, useEffect, useContext } from 'react';
import { Typography, Button, Paper, Card, Grid, Box, Divider } from '@mui/material';
import axios from 'axios'
import Avatar from '../commonComponents/avatar'
import { AuthContext } from '../auth'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IonAvatar, IonImg, IonSpinner } from '@ionic/react';

const styles = {
    loadingBox: {
        display: 'flex',
        justifyContent: 'center',
    }
}

export default function StepPickBarber({ businessId, scheduleData, updateScheduleData, step, stepNext, stepBack, totalSteps, trackStep }) {

    console.log(scheduleData)

    const [employees, setEmployees] = useState()
    useEffect(() => {
        getEmployeesList()
    }, [scheduleData?.location?._id, businessId])

    useEffect(()=>{
        trackStep("Barber", step, scheduleData)
    },[])

    const getEmployeesList = async () => {
        let response = await axios.get(`/employee/getPublicList?businessId=${businessId}&locationId=${scheduleData?.location?._id}`)
        setEmployees(response.data)
    }

    function selectEmployee(employee) {
        updateScheduleData('barber', employee)
        stepNext()
    }
    function selectAnyProfessional(){
        updateScheduleData('barber', 'Anyone')
        stepNext()
    }

    if (!employees) return <div style={styles.loadingBox}><IonSpinner name='dots' /></div>


    return (
        <>

            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <AnyProfessional key="Anyone" selectAnyProfessional={selectAnyProfessional} />
                {employees.map(e => (
                    <BarberCard employee={e} selectEmployee={selectEmployee} key={e._id} businessId={businessId} />
                ))}
            </List>


        </>
    );
}

function BarberCard({ employee, selectEmployee, businessId }) {

    return (
        <>
            <ListItem onClick={() => { selectEmployee(employee) }} style={{ cursor: 'pointer' }}>
                <ListItemAvatar>
                    <Avatar employee={employee} businessId={businessId} medium />
                </ListItemAvatar>
                <ListItemText
                    primary={<h4 style={{ margin: 0, marginTop: -5, marginLeft: 8 }}>{employee.firstName + " " + employee.lastName?.charAt(0)}</h4>}
                // secondary={employee.role}
                />
                <ChevronRightIcon style={{ color: 'grey' }} />
            </ListItem>
            <Divider component="li" />
        </>
    )
}

function AnyProfessional({ selectAnyProfessional }) {

    return (
        <>
            <ListItem onClick={selectAnyProfessional} style={{ cursor: 'pointer' }}>
                <ListItemAvatar>
                    <IonAvatar>
                        <img alt="Silhouette of a person's head" src="/assets/logos/blackCircle.png"  style={{height:48, width:48, borderRadius:35}}/>
                    </IonAvatar>
                </ListItemAvatar>
                <ListItemText
                    primary={<h4 style={{ margin: 0, marginTop: -5, marginLeft: 8 }}>Any Professional</h4>}
                // secondary={employee.role}
                />
                <ChevronRightIcon style={{ color: 'grey' }} />
            </ListItem>
            <Divider component="li" />
        </>
    )
}
