import { useEffect, useState } from 'react'
import GetPickerMonths from '../Utils/GetPickerMonths'
import axios from 'axios'
import { GetXAxisMonths } from '../Utils/GetXAxisMonths'
import { getMonthRange } from '../Utils/GetDates'
import { IonCard, IonContent, IonPage, IonSpinner } from '@ionic/react'
import Picker from '../commonComponents/Picker'
import Chart from 'react-apexcharts'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { colors } from '../theme/colors'
import { getAllDaysInCurrentMonth, getDaysInCurrentMonth } from '../utils'

export default function AccountsReport() {
  // Initialize state variables and constants
  const pickerItems = GetPickerMonths()
  let initialNumOfMonths = 6
  const [selectedOption, setSelectedOption] = useState(initialNumOfMonths - 1)
  const [loadingData, setLoadingData] = useState(false)
  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 250,
      stacked: true,
      toolbar: false,
    },
    xaxis: {
      categories: [],
      tickAmount: 5,
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '90%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [colors.primaryBlue, colors.orange],
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    grid: {
      borderColor: '#f1f1f1',
    },
    yaxis: {
      title: {
        text: 'Accounts & Appointments',
      },
    },
    toolbar: {
      show: false,
      tools: {
        download: false,
      },
    },
    export: {
      show: false,
    },

    legend: {
      show: true,
    },
  })
  const [series, setSeries] = useState([])

  // Function to fetch data based on reference ID, start date, end date, and number of months
  const getAcquistionData = async (startDate, endDate, noOfMonths) => {
    try {
      // Make API call to get report data
      const response = await axios.get(`customer/stats?fromDate=${startDate}&toDate=${endDate}`)

      //added comment
      // Set loading state to false and process response data if status is 200
      setLoadingData(false)
      if (response.status === 200) {
        // Extract x-axis categories based on unique months
        const data = response?.data
        const categoriesResponse = GetXAxisMonths(noOfMonths)
        const months = categoriesResponse.monthNumbers
        const daysInCurrentMonth = getDaysInCurrentMonth()

        // Create series data from the provided API data
        const series = [
          { name: 'With Appointment', data: [] },
          { name: 'Without Appointment', data: [] },
        ]
        series.forEach(seriesItem => {
          seriesItem.data =
            noOfMonths === 0 || noOfMonths === 1
              ? Array(daysInCurrentMonth).fill(0)
              : Array(months.length).fill(0)
        })

        // Update series data with fetched API data
        data.forEach(item => {
          if (noOfMonths === 0 || noOfMonths === 1) {
            const dayIndex = item.day - 1

            series[0].data[dayIndex] = item.accountsCreated - item.noAppointments
            series[1].data[dayIndex] = item.noAppointments
          } else {
            const paddedMonth = String(item.month).padStart(2, '0') // Padding the month number
            const lastTwoDigitsOfYear = item.year % 100
            const monthYear = paddedMonth + '-' + lastTwoDigitsOfYear
            const index = months.findIndex(month => month === monthYear)
            if (index !== -1) {
              series[0].data[index] = item.accountsCreated - item.noAppointments
              series[1].data[index] = item.noAppointments
            }
          }
        })

        // Update state with processed series and categories
        setSeries(series)
        setOptions(prevOptions => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories:
              noOfMonths === 0 || noOfMonths === 1
                ? getAllDaysInCurrentMonth(noOfMonths)
                : categoriesResponse.categories,
          },
        }))
      }
    } catch (error) {
      // Handle errors if any
    }
  }

  // Function to handle the selected index change
  const handleSelectedIndex = indexValue => {
    const data = getMonthRange(indexValue)

    const selectedValue = pickerItems.find((item, index) => index === indexValue)
    if (selectedOption !== selectedValue.text) {
      setLoadingData(true)
      setSelectedOption(selectedValue.text)
      getAcquistionData(data.startDate, data.endDate, indexValue)
    }
  }

  // Initial data fetching on component mount
  useEffect(() => {
    const data = getMonthRange(initialNumOfMonths)
    getAcquistionData(data.startDate, data.endDate, initialNumOfMonths)
  }, [])

  return (
    <IonPage>
      <CommonHeader title={'New Accounts'} backIcon={true} />
      <IonContent>
        <StandardContainer>
          <IonCard className='flat' style={{ padding: '1rem' }}>
            <h5 style={{ marginTop: 0, color: 'black' }}>New Accounts</h5>
            <Picker
              options={pickerItems}
              selectedItemIndex={initialNumOfMonths}
              selectedItem={selectedOption}
              handleSelectedIndex={handleSelectedIndex}
            />
            {loadingData && <IonSpinner name='dots' />}
            {!loadingData && (
              <div style={{ marginTop: '1rem' }}>
                <Chart options={options} series={series} type='bar' style={{ marginBottom: -20 }} />
              </div>
            )}
          </IonCard>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
