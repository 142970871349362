import './firebase'
import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
// import Login from './login2'

import { AuthProvider } from './auth'

import Login from './login'
import SignUp from './signup'
import SetupBusiness from './setupBusiness'
import MainTabs from './maintabs'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import RegisterNewUserToBusiness from './registerNewUser'
import HomePage from './homepage'
import UserNotice from './Notice'
import PendingInvitationsList from './users/PendingInvitations'
import SetupNewBusiness from './SetupNewBusiness'
import SetupBusinessDetails from './SetupBusinessDetails'

setupIonicReact()

const App = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <AuthProvider>
          <Route exact path='/login' component={Login} />
          <Route exact path='/homePage' component={HomePage} />
          <Route exact path='/notice' component={UserNotice} />
          <Route exact path='/signup' component={SignUp} />
          <Route exact path='/registerNewUser' component={RegisterNewUserToBusiness} />
          {/* <Route exact path='/setupBusiness' component={SetupBusiness} /> */}
          <Route exact path='/setupBusiness' component={SetupNewBusiness} />
          <Route exact path='/setupBusinessDetails' component={SetupBusinessDetails} />
          <Route exact path='/pendingInvitations' component={PendingInvitationsList} />
          <Route path='/core/*' component={MainTabs} />
        </AuthProvider>
        <Route exact path='/'>
          <Redirect to='/core/dashboard' />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
    {/* <IonReactRouter>
      <AuthProvider>
        <Route path="/" component={NewTabs} />
      </AuthProvider>
    </IonReactRouter> */}
  </IonApp>
)

export default App
