// PaymentConfirmationSection.jsx
import React, { useState } from 'react'
import {
  IonButton,
  IonSpinner,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
} from '@ionic/react'
import { add, chevronDown, chevronForward } from 'ionicons/icons'
import ChooseDiscountAndCoupon from './ChooseDiscountAndCoupon'
import SignatureCanva from '../commonComponents/SignatureCanva'

function PaymentConfirmationSection({
  selectedCoupon,
  customer,
  selectedPackage,
  selectedPrice,
  freeTrialDays,
  openAdvanced,
  setOpenAdvanced,
  errorMessage,
  isSubscribing,
  subscriberUserToPlan,
  setFreeTrialDays,
  updateCoupon,
  coupons,
  removeCoupon,
  updateCustomerSignature,
  customerSignatureImage,
}) {
  return (
    <>
      <div style={{ padding: 20, paddingBottom: 0, paddingTop: 4 }}>
        <h6>3. Confirm & Pay</h6>
      </div>

      <IonList inset={true} mode='ios'>
        <IonItem>
          <IonLabel>
            <p style={{ color: 'black', fontWeight: '600' }}>
              {customer.firstName} {customer.lastName}
            </p>
            <p style={{ color: 'black' }}>{customer.email}</p>
            <p style={{ color: 'black' }}>{customer.phoneNumber}</p>
            <br></br>

            <IonGrid style={{ margin: 0, padding: 0, marginLeft: -4 }}>
              <IonRow style={{ margin: 0 }}>
                <IonCol>
                  <h6 style={{ fontWeight: 'bold' }}>{selectedPackage?.name}</h6>
                </IonCol>
              </IonRow>
              <IonRow style={{ margin: 0 }}>
                <IonCol>{selectedPrice?.name}</IonCol>

                <IonCol size='auto'>
                  ${Math.abs(selectedPrice.amount)} /
                  {selectedPrice.interval_count == 1 ? '' : selectedPrice.interval_count}{' '}
                  {selectedPrice.interval_count > 1
                    ? `${selectedPrice.interval}s`
                    : selectedPrice.interval}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <div style={{ height: 1, widht: '100%', backgroundColor: '#dfdfdf' }} />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <strong>Amount Due {freeTrialDays > 0 ? ' After Trial' : 'Now'}</strong>
                </IonCol>
                <IonCol size='auto'>
                  <strong> ${Math.abs(selectedPrice.amount)}</strong>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonLabel>
        </IonItem>
      </IonList>
      <IonGrid style={{ padding: 0, margin: 0 }}>
        {selectedCoupon && (
          <>
            <IonRow
              style={{
                margin: '0 2rem',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'start',
                flexDirection: 'column',
              }}
            >
              <h6 style={{ margin: 0, fontWeight: 'bold' }}>Applied Coupon</h6>
              <IonRow
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <IonLabel>{selectedCoupon?.name}</IonLabel>
                <IonButton
                  onClick={removeCoupon}
                  style={{ fontSize: 14, marginLeft: 10 }}
                  fill='clear'
                >
                  <IonLabel style={{ color: 'red' }}>Remove</IonLabel>
                </IonButton>
              </IonRow>
            </IonRow>
          </>
        )}
        <IonRow style={{ padding: 0, margin: 0 }}>
          <IonCol style={{ padding: 0, margin: 0 }}>
            <ChooseDiscountAndCoupon
              selectedCoupon={selectedCoupon}
              coupons={coupons}
              customerId={customer._id}
              updateCoupon={updateCoupon}
            />
          </IonCol>
          <IonCol size='auto' style={{ padding: 0, margin: 0 }}>
            <IonButton
              onClick={() => {
                setOpenAdvanced(!openAdvanced)
              }}
              style={{ fontSize: 14, marginLeft: 10 }}
              fill='clear'
            >
              Advanced{' '}
              {!openAdvanced ? <IonIcon icon={chevronDown} /> : <IonIcon icon={chevronForward} />}
            </IonButton>
          </IonCol>
        </IonRow>
        {openAdvanced ? (
          <IonRow style={{ padding: 0, margin: 0 }}>
            <IonCol style={{ padding: 0, margin: 0 }}>
              <IonList inset={true} style={{ marginTop: 0 }}>
                <IonItem>
                  <IonLabel>Free Trial Days</IonLabel>
                  <IonInput
                    slot='end'
                    clearInput={true}
                    placeholder='0'
                    onIonChange={e => {
                      setFreeTrialDays(e.detail.value)
                    }}
                  ></IonInput>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        ) : null}
      </IonGrid>
      <br></br>
      <SignatureCanva
        isSubscribing={isSubscribing}
        updateCustomerSignature={updateCustomerSignature}
      />
      <div style={{ padding: 14, paddingBottom: 0, paddingTop: 0 }}>
        {errorMessage ? <p style={{ color: 'red', fontSize: 12 }}>**{errorMessage}</p> : null}
        <IonButton disabled={isSubscribing} expand='block' onClick={subscriberUserToPlan}>
          {isSubscribing ? (
            <IonSpinner name='dots' />
          ) : (
            <>
              Subscribe and Pay ${Math.abs(selectedPrice.amount)}
              {freeTrialDays > 0 ? ' After Trial' : ''}
            </>
          )}
        </IonButton>
      </div>
    </>
  )
}

export default PaymentConfirmationSection
