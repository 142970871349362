import React, { useState, useEffect, useContext } from 'react';
import { Typography, Button, Paper, Card, Avatar, Grid, Box, ListItemAvatar } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import UserIcon from '@mui/icons-material/Person'
import PlaceIcon from '@mui/icons-material/Place';
import DateIcon from '@mui/icons-material/CalendarToday';
import { useHistory } from "react-router-dom";

import Review from './reviewOrder';
import { IonButton, IonCard, IonIcon, IonImg, IonRouterLink } from '@ionic/react';
import StandardContainer from '../commonComponents/StandardContainer';
import { colors } from '../theme/colors';
import { trackPromotionImpression } from '../analytics';
import { checkmarkCircleOutline } from 'ionicons/icons';
import StandardCenterCenter from '../commonComponents/StandardCenterContainer';
import { AnimatedCheckmark, MODES } from '../commonComponents/AnimatedCheckmark/AnimatedCheckmark';



export default function StepSuccess({ isSubscribed, scheduleData, stepNext, getAppointments, isWalkin, step, trackStep, setSubscriptionReferrer }) {
    const history = useHistory()

    useEffect(() => {
        getAppointments()
    }, [])

    useEffect(() => {
        trackStep("Success", step, scheduleData)
    }, [])

    function complete() {
        stepNext()
    }

    return (
        <>
                <>
                    <div style={{ padding: 20 }}>
                        <LocationSummary location={scheduleData && scheduleData.location || null} />
                        <BarberSummary barber={scheduleData?.barber || null} anyProfessional={scheduleData?.anyProfessional} />
                        <DateAndTimeSummary durationMin={scheduleData && scheduleData.durationMin} dateTime={scheduleData && scheduleData.dateTime || null} services={scheduleData.services} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ marginTop: 50, marginBottom: 20 }} >Success! Thanks for {`${isWalkin ? "Checking in" : "Booking"}`}!</Typography>
                        {/* <Typography >You will receive an email confirming your appointment.</Typography> */}
                        <IonButton expand='block' style={{ width: 200 }} onClick={complete}>Done</IonButton>
                    </div>
                </>


        </>
    );
}

function BarberSummary({ barber, anyProfessional }) {

    if (!barber) return null

    return (
        <List dense={true} style={{ marginLeft: -16, marginRight: -8, marginBottom: -20 }}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar style={{ backgroundColor: '#e6e6e6' }}>
                        <UserIcon style={{ color: '#2175EA' }} />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={anyProfessional ? "Any Professional" : `${barber?.firstName} ${barber?.lastName?.charAt(0)}`}
                />
            </ListItem>
        </List>
    )
}

function LocationSummary({ location, setStep }) {

    if (!location) return null

    return (
        <List dense={true} style={{ marginLeft: -16, marginRight: -8, marginBottom: -20 }}>
            <ListItem
            // secondaryAction={
            //             <IconButton edge="end" aria-label="edit" onClick={()=>{setStep('location')}}>
            //               <EditIcon />
            //             </IconButton>
            //           }
            >
                <ListItemAvatar>
                    <Avatar style={{ backgroundColor: '#e6e6e6' }}>
                        <PlaceIcon style={{ color: '#2175EA' }} />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={`${location.name}`}
                    secondary={`${location.addressStreet1}, ${location.addressCity}, ${location.addressState} ${location.addressZipcode}`}
                />
            </ListItem>
        </List>
    )
}

function DateAndTimeSummary({ dateTime, services, durationMin }) {

    if (!dateTime) return null

    return (
        <List dense={true} style={{ marginLeft: -16, marginRight: -8, }}>
            <ListItem
            // secondaryAction={
            //             <IconButton edge="end" aria-label="edit" onClick={()=>{setStep('dateTime')}}>
            //               <EditIcon />
            //             </IconButton>
            //           }
            >
                <ListItemAvatar>
                    <Avatar style={{ backgroundColor: '#e6e6e6' }}>
                        <DateIcon style={{ color: '#2175EA' }} />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={`${dateTime.startTimeText}, ${dateTime.dateText}`}
                    secondary={`${durationMin} Minutes`}
                />
            </ListItem>
        </List>
    )
}

function GrandOpeningSale({ complete, setSubscriptionReferrer, history, isWalkin }) {
    let height = 470
    let leftMargin = 30

    let refe = "ScheduleSuccessPagePromotion"
    let vers = 'V1'

    function handleSignMeUp() {
        setSubscriptionReferrer({ referrer: refe, promotionVersion: vers })
        history.push('/subscription')
    }

    useEffect(() => {
        trackPromotionImpression(refe, vers)
    }, [])


    return (
        <>
            <div style={{ borderRadius: 16, padding: 0, backgroundColor: colors.green, margin: 18, marginTop: 4, marginBottom: -10 }}>
                <h1 style={{ padding: 14, textAlign: 'center', margin: 0 }}>
                    Success! Thanks for {`${isWalkin ? "Checking in" : "Booking"}`}!
                </h1>
            </div>


            <IonCard style={{ position: 'relative', display: 'flex', flexDirection: 'column', backgroundColor: 'black', borderRadius: 20 }}>
                <IonImg style={{ width: '100%', height: height, objectFit: 'cover', marginTop: -1, borderRadius: 20 }} src={'/assets/blackBackground.png'} ></IonImg>
                <StandardContainer padding={0}>
                    <div style={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', height: '100%', position: 'absolute', zIndex: 99, top: 0, color: '#fff', marginTop: 0, width: '100%', maxWidth: 800 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center', marginTop: 10, width: '100%', maxWidth: 800 }}>
                            <img src='assets/logos/CLUBCUTS_WHITE.png' height={50} width={80} style={{}} />
                        </div>
                        <span style={{ marginLeft: leftMargin, fontFamily: 'Reenie Beanie', color: colors.green, fontSize: 25, marginTop: -10 }}>#staycut</span>
                        <h1 style={{ marginLeft: leftMargin, marginTop: 0, fontWeight: '800', fontSize: 50 }}>GRAND</h1>
                        <h1 style={{ marginLeft: leftMargin, marginTop: -18, fontWeight: '800', fontSize: 50 }}>OPENING</h1>
                        <h1 style={{ marginLeft: leftMargin, marginTop: -18, fontWeight: '800', fontSize: 50 }}>SALE</h1>
                        <p style={{ fontFamily: 'Montserrat, Arial', marginTop: 0, marginLeft: leftMargin, marginBottom: -6, fontWeight: '600', fontSize: 20 }}>UNLIMITED HAIRCUTS</p>
                        <p style={{ fontFamily: 'Montserrat, Arial', marginTop: 0, marginLeft: leftMargin, marginBottom: -20, fontWeight: '600', fontSize: 20 }}>for only <span style={{ color: colors.green, fontSize: 28 }}>$30</span>/mo </p>
                        <br></br>
                        {/* <IonRouterLink routerLink='/subscription' routerDirection='none'> */}
                        <div onClick={handleSignMeUp} style={{ fontFamily: 'Montserrat, Arial', padding: 10, marginLeft: leftMargin, fontWeight: '600', fontSize: 16, color: 'black', backgroundColor: colors.green, border: `1px solid ${colors.green}`, borderRadius: 6, width: 250, textAlign: 'center', marginTop: 20 }}>
                            Yes, Sign Me Up!
                        </div>
                        {/* </IonRouterLink> */}
                        <div onClick={complete} style={{ fontFamily: 'Montserrat, Arial', padding: 10, marginLeft: leftMargin, fontWeight: '600', fontSize: 16, color: 'black', backgroundColor: 'white', border: `1px solid white`, borderRadius: 6, width: 250, textAlign: 'center', marginTop: 10 }}>
                            No Thanks
                        </div>

                    </div>
                </StandardContainer>
            </IonCard>
        </>

    )
}
