import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import axios from 'axios'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


function getStyles(name, employeeLocations=[], theme) {
  return {
    fontWeight:
    (employeeLocations || []).indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip({employeeLocations=[], onValueChange, locations, locationId}) {

  const theme = useTheme();

  const handleChange = (event) => {
    onValueChange('locations', event.target.value)
  }; 

  if(!locations) return "loading more..."

  return (
      <FormControl fullWidth style={{marginTop:20, marginBottom:200}} variant="standard">
        <InputLabel id="demo-multiple-chip-label" variant="standard">Locations</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          disabled={locationId ? true : false}
          value={employeeLocations || []}
          variant="standard"
          onChange={handleChange}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => {
                let foundVal = locations.find(x => x._id === value)
                let labelText = foundVal && foundVal.name || value
                //if(!foundVal) return null
                return (
                <Chip key={value} label={labelText} />
              )})}
            </Box>
          )}
        >
          {locations.map((location) => (
            <MenuItem
              key={location._id}
              value={location._id}
              style={getStyles(location, employeeLocations, theme)}
            >
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}
