import { IonContent, IonPage, IonSpinner } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import axios from 'axios'
import { AuthContext } from '../auth'
import CohortGraph from 'react-cohort-graph'
import RetentionReportDataVisualization from './RetentionReportDataVisualization'

export default function RetentionReport() {
  const { locationId } = useContext(AuthContext)
  const [reportData, setRetentionReportData] = useState(false)
  const [loading, setLoading] = useState(false)
  const getRetentionReportData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`/retention/report?locationId=${locationId}`)
      const formattedData = response.data.reduce((accumulator, item, index) => {
        accumulator[item.month] = [item.uniqueCustomers, ...item.retention]
        return accumulator
      }, {})
      console.log(formattedData)
      setRetentionReportData(formattedData)
      setLoading(false)
    } catch (error) {}
  }

  //test
  useEffect(() => {
    getRetentionReportData()
  }, [])
  const months = reportData ? Object.keys(reportData) : []
  const keys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
  return (
    <IonPage>
      <CommonHeader title={'Retention Report'} backIcon={true} />
      <IonContent>
        <div style={{ padding: '1rem' }}>
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IonSpinner name='dots' />
            </div>
            // test
          )}
          {!loading && <RetentionReportDataVisualization data={reportData} />}
          {/* {!loading && (
            <CohortGraph
              data={{
                months: reportData,
              }}
              defaultValueType={'percent'}
            />
          )} */}
        </div>
      </IonContent>
    </IonPage>
  )
}
