import { useState, useEffect, useContext } from 'react'
import Axios from 'axios'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import StorefrontIcon from '@mui/icons-material/Storefront'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import Copyright from './copyright'
import { useHistory } from 'react-router-dom'
import { colors } from './theme/colors'
import { IonRow, IonSpinner } from '@ionic/react'
import { AuthContext } from './auth'
import checkEmailExists from './Utils/CheckEmailValidation'

// Styles for Material-UI components
const classes = {
  paper: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: 10,
    backgroundColor: colors.primaryBlue,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 10,
  },
  submit: {
    margin: 10,
  },
}

const RegisterNewUserToBusiness = () => {
  const hist = useHistory()
  const authContextData = useContext(AuthContext)
  const userData = authContextData ? authContextData.userData : undefined
  const [disabled, setDisabled] = useState(true)
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(true)
  const searchParams = new URLSearchParams(window.location.search)
  const businessId = searchParams.get('businessId')
  const code = searchParams.get('code')
  const email = searchParams.get('email')
  // useEffect to check user and invitation status on component mount
  useEffect(() => {
    const checkUserAndInvitation = async () => {
      setLoadingPage(true)

      if (userData && userData.email === email) {
        // User is logged in

        try {
          const verifyResponse = await Axios.get(`/user/invite/check?code=${code}`)
          if (verifyResponse.data.invitationExists) {
            // Invitation not accepted, redirect to pending invitations screen
            hist.push(`/pendingInvitations?email=${email}`)
          } else {
            // Invitation is accepted, redirect to dashboard
            hist.push('/core/dashboard')
          }
        } catch (error) {
          console.error(error, 'Error checking invitation status')
        }
      } else {
        // User is not logged in
        // Check if the user has an account
        try {
          const accountCheckResponse = await checkEmailExists('user', email)

          if (accountCheckResponse.emailExists) {
            // User has an account, set in localStorage and redirect to login page
            localStorage.setItem('redirectTo', 'pendingInvitations')
            hist.push('/login')
          } else {
            setLoadingPage(false)

            // Use localStorage value for redirectedFromPendingInvitations
            localStorage.setItem('redirectTo', 'pendingInvitations')
          }
        } catch (error) {
          console.error(error, 'Error checking account status or creating account')
        }
      }
    }

    // Call the function to check user and invitation status
    checkUserAndInvitation()
  }, [userData, email, businessId, code, hist])

  // useEffect to log businessId and link from search parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const businessId = searchParams.get('bid')
    const link = searchParams.get('link')
  }, [])

  // Event handler for password input
  const handlePassword = event => {
    setPassword(event.target.value)
    setDisabled(!event.target.value)
  }

  // Event handler for form submission
  const handleSignUp = async event => {
    setDisabled(true)
    event.preventDefault()

    setLoading(true)
    try {
      const businessId = searchParams.get('businessId')
      const code = searchParams.get('code')
      let verifyResponse = await Axios.post('/user/verifyCode', {
        businessId,
        code,
        email: email,
      })
      if (verifyResponse.data.success) {
        let response = await createUserWithEmailAndPassword(getAuth(), email, password)
        try {
          const registerAndClaimCodeResponse = await Axios.post('/user/registerAndClaimCode', {
            email: email,
            firebaseId: response.user.uid,
            code,
            businessId,
          })
          if (registerAndClaimCodeResponse.status) {
            // Successfully registered and claimed code
            const redirectTo = localStorage.getItem('redirectTo')
            if (redirectTo === 'pendingInvitations') {
              hist.push(`/pendingInvitations?email=${email}`)
              localStorage.removeItem('redirectTo')
            } else {
              hist.push('/core/dashboard')
            }
          } else {
            // Redirect to login page on failure
            hist.push('/login')
          }
        } catch (error) {
          hist.push('/login')
        }
      } else {
        // Verification failed
        throw new Error('Not Verified')
      }
    } catch (error) {
      alert(error)
      setDisabled(false)
    }
  }

  // Redirect to dashboard if essential parameters are missing
  if (!code || !businessId || !email) {
    hist.push('/core/dashboard')
  }
  return (
    <Container component='main' maxWidth='xs'>
      <>
        <CssBaseline />
        <div className={classes.paper}>
          {loadingPage && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IonSpinner name='dots' />
            </div>
          )}
          {!loadingPage && (
            <>
              <div
                style={{
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  padding: 20,
                  flexDirection: 'column',
                }}
              >
                <Avatar className={classes.avatar}>
                  <StorefrontIcon />
                </Avatar>
                <Typography component='h1' variant='h5'>
                  Create Account
                </Typography>
                <Typography variant='body2' color='textSecondary' align='center'>
                  Welcome
                </Typography>
              </div>
              {/* <Typography variant="body2" color="textSecondary" align="center" style={{color:'red'}}>
          {message}
      </Typography> */}
              <form className={classes.form} noValidate onSubmit={handleSignUp}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      disabled={true}
                      autoComplete='email'
                      name='email'
                      variant='outlined'
                      required
                      fullWidth
                      id='email'
                      label='Email'
                      autoFocus
                      value={email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      autoComplete='password'
                      name='password'
                      variant='outlined'
                      type='password'
                      required
                      fullWidth
                      id='password'
                      label='Password'
                      autoFocus
                      onChange={handlePassword}
                    />
                  </Grid>
                </Grid>
                <Button
                  type='submit'
                  disabled={disabled}
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  style={{ height: 50, marginTop: 20 }}
                >
                  Continue
                </Button>
                <IonRow style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                  {loading && <IonSpinner name='dots' />}
                </IonRow>
                {/* <Grid container justify="flex-end">
          <Grid item>
            <Link href="#" variant="body2">
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid> */}
              </form>
            </>
          )}
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </>
    </Container>
  )
}

export default RegisterNewUserToBusiness
