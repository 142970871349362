import React, { useState, useContext, useEffect } from 'react';
import { IonButtons, IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonAvatar, IonItem, IonLabel, IonList, IonIcon, IonSpinner, IonGrid, IonRow, IonCol } from '@ionic/react';
import { AuthContext } from '../auth'

import axios from 'axios'
import StandardContainer from '../commonComponents/StandardContainer';
import StepController from './stepController';


function SchedulerModal({ isOpen, setIsOpen, onSuccess, customerId, isWalkin }) {

    const [subscription, setSubscription] = useState({ data: null, loading: false, error: null })
    const [isSubscribed, setIsSubscribed] = useState({ data: null, loading: false, error: null })

    const getCustomerSubscription = async () => {
        if (customerId) {
            try {
                setSubscription({ data: subscription.data, loading: true, error: null })
                let response = await axios.get(`/subscription/byCustomerId?id=${customerId}`)
                setSubscription({ data: response.data, loading: false, error: null })
                setIsSubscribed(response?.data?.isSubscribed || false)
            } catch (error) {
                console.log(error)
                setSubscription({ data: null, loading: false, error: 'There was an issue getting your data' })
            }
        }

    }

    useEffect(() => { getCustomerSubscription() }, [customerId])

    return (

        <IonModal isOpen={isOpen}>
            <IonContent className='regularBackground'>

                <StandardContainer>
                    <StepController
                        isOpen={isOpen}
                        closeModal={() => { setIsOpen({isOpen:false, isWalkin:false}) }}
                        isWalkin={isWalkin}
                        setSubscriptionReferrer={() => { }}
                        customerId={customerId}
                        getAppointments={() => {}}
                        customerSubscription={subscription?.data?.subscription}
                        customerIsSubscribed={isSubscribed}
                    />

                </StandardContainer>
            </IonContent>
        </IonModal>
    );
}

export default SchedulerModal;