import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import axios from 'axios'

import { Grid, Box, IconButton } from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'
import { IonButton } from '@ionic/react'
import AddBreak from './addBreak'

export default function Breaks({ standardSchedule, locationId, employeeId, businessId }) {
  const [open, setOpen] = React.useState(false)
  const [breaks, setBreaks] = React.useState()

  const getBreaks = async () => {
    let response = await axios.get(`/employee/break?employeeId=${employeeId}`)
    console.log(response.data)
    setBreaks(response.data)
  }

  React.useEffect(() => {
    getBreaks()
  }, [employeeId])

  const handleSave = async data => {
    console.log(data, 'dataaa')
    const breakInput = {
      ...{ locationId, businessId, employeeId },
      ...data,
      repeat: data.repeat,
    }
    console.log(breakInput, 'breakInputbreakInput')
    try {
      await axios.post(`/employee/break`, breakInput)
      getBreaks()
      handleClose()
    } catch (error) {
      alert('Sorry there was an issue saving. Try again later.')
    }
  }

  const handleDelete = async id => {
    try {
      if (window.confirm('Are you sure you want to delete the break?')) {
        // Delete it!
        try {
          await axios.delete(`/employee/break?id=${id}`)
          getBreaks()
        } catch (error) {
          window.alert('Looks like there was an issue. Try again later.')
        }
      }
    } catch (error) {}
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = value => {
    setOpen(false)
  }

  let data = breaks

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <Box display='flex' alignItems='center'>
            <h5>Breaks Schedule</h5>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display='flex' justifyContent='flex-end'>
            <IonButton color='primary' onClick={handleClickOpen} fill='clear'>
              + Add
            </IonButton>
          </Box>
        </Grid>
      </Grid>
      <TableContainer>
        <Table aria-label='simple table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: 'grey', fontSize: 12 }}>DATE</TableCell>
              <TableCell style={{ color: 'grey', fontSize: 12 }}>START TIME</TableCell>
              <TableCell style={{ color: 'grey', fontSize: 12 }}>END TIME</TableCell>
              <TableCell style={{ color: 'grey', fontSize: 12 }}>DELETE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map(d => (
                <TableRow key={d._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    <div style={{ minWidth: 90 }}>{d.dateText}</div>
                  </TableCell>
                  <TableCell>{d.startTime}</TableCell>
                  <TableCell>{d.endTime}</TableCell>
                  <TableCell>
                    <IconButton aria-label='delete'>
                      <DeleteIcon
                        fontSize='small'
                        onClick={() => {
                          handleDelete(d._id)
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddBreak
        open={open}
        onClose={handleClose}
        standardSchedule={standardSchedule}
        handleSave={handleSave}
      />
    </>
  )
}
