export const colors = {
  primaryBlue: '#3880ff',
  primaryBlue50: '#3880ff8a',
  primaryBlue90: '#3880ff14',
  darkBlue: '#3880ff',
  green: '#00B050',
  green50: '#50c878e6',
  purple: '#7030A0',
  grey: '#A5A8A9',
  orange: '#FF892C',
  black: '#222222',
  white: '#ffffff',
  lightGrey: '#d6d6d6',
  errorText: '#d32f2f',
  regularBackground: '#F0F5F8',
}
