import React, { useState, useEffect, useContext } from 'react';
import {Typography, Button, FormControl, InputLabel, MenuItem, Select, TextField, Grid, InputAdornment, OutlinedInput, Input} from '@mui/material';
import axios from 'axios'
import LocationsMultiSelect from './locationsMultiSelect';
import SubscriptionPackageMultiSelect from './subscriptionPackageMultiSelect'

export default function DetailsView({service, updateService, checkService, locations, subscriptionPackages, locationId}){

    let onValueChange = (field, value) => {
      console.log(field, value)
      updateService({...service, [field]: value})
    }

    if (!service) return "loading..."
    console.log(service)

    return (
        <>
        <Grid container spacing={8} padding={2}>
          <Grid item xs={12} md={6}>

        {/* <Typography variant="p">Details {service.firstName} {service.lastName}</Typography> */}
        
          <TextField
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={service.name}
            onChange={(e) => { onValueChange('name',e.target.value) }}
            style={{ flex: 1, marginRight: 10 }}
          />

          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            variant="standard"
            fullWidth
            value={service.description}
            onChange={(e) => { onValueChange('description',e.target.value) }}
            style={{ flex: 1 }}
          />
        <FormControl fullWidth style={{marginTop:20}}>
          <InputLabel id="demo-simple-select-label" 
            variant="standard">Duration</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={service.durationMin}
            label="durationMin"
            variant="standard"
            onChange={(e) => { onValueChange('durationMin',e.target.value) }}
          >
            <MenuItem value={10}>10 min</MenuItem>
            <MenuItem value={15}>15 min</MenuItem>
            <MenuItem value={30}>30 min</MenuItem>
            <MenuItem value={45}>45 min</MenuItem>
            <MenuItem value={60}>60 min</MenuItem>
            <MenuItem value={90}>90 min</MenuItem>

          </Select>
        </FormControl>

      <FormControl fullWidth variant="standard" style={{marginTop:20}}>
          <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>
          <Input
            id="standard-adornment-amount"
            value={service.price}
            onChange={(e) => { onValueChange('price',e.target.value) }}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            
          />
        </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocationsMultiSelect locations={locations} serviceLocations={service.locations} onValueChange={onValueChange} locationId={locationId}/>
          <SubscriptionPackageMultiSelect subscriptionPackages={subscriptionPackages} includedInSubscriptions={service.includedInSubscriptions} onValueChange={onValueChange} locationId={locationId}/>
        </Grid>
        </Grid>
        
        </>
    )
}


