import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { AuthContext } from '../../auth'
import { IonButton, IonCard, IonContent, IonPage, IonSpinner } from '@ionic/react'

import DetailsHeader from '../../commonComponents/DetailsHeader'
import StandardContainer from '../../commonComponents/StandardContainer'
import { colors } from '../../theme/colors'
import ChangeEmployeeModal from './changeEmployeeModal'
import FinalizePaymentAndTip from './finalizePaymentAndTip'
import CustomerCard from './CustomerCard'
import ProfessionalCard from './ProfessionalCard'
import { calculatePayment } from '../../sharedComponents/paymentCalculation'
import ServicesProductsAndDiscounts from './servicesProductsAndDiscounts'
import Invoice from './invoice'
import ActionMenu from './actionMenu'
import AppointmentInfoCard from './AppointmentInfoCard'
import EditServicesProductAndDiscounts from './EditServicesProductAndDiscounts'
import CustomerNotesModal from '../../commonComponents/customerNotes'
import ProgressStepper from './ProgresStepper'

import AddCashTip from './AddCashTip'

export default function Details({ match }) {
  const appointment_id = match.params.id
  const [appointment, setAppointment] = useState()
  const [subscription, setSubscription] = useState({ data: null, loading: false, error: null })
  const [updatingStatus, setUpdatingStatus] = useState(false)
  const [employeeModalOpen, setEmployeeModalOpen] = useState(false)
  const [finalziePaymentModalOpen, setFinalizePaymentModalOpen] = useState(false)
  const [serviceEditModalOpen, setServiceEditModalOpen] = useState(false)
  const [notesOpen, setNotesOpen] = useState(false)
  const [payment, setPayment] = useState([])
  const [tip, setTip] = useState()
  const { businessData, userData, getBusinessData } = useContext(AuthContext)

  const getAppointment = async () => {
    let response = await axios.get(`/appointment?id=${appointment_id}`)

    setAppointment(response.data)
  }
  const refreshAppointment = async () => {
    await getAppointment()
  }
  useEffect(() => {
    getAppointment()
  }, [appointment_id])

  const getCustomerSubscription = async () => {
    if (appointment?.customer?._id) {
      try {
        setSubscription({ data: subscription.data, loading: true, error: null })
        let response = await axios.get(
          `/subscription/byCustomerId?id=${appointment?.customer?._id}`,
        )
        setSubscription({ data: response.data, loading: false, error: null })
      } catch (error) {
        setSubscription({
          data: null,
          loading: false,
          error: 'There was an issue getting your data',
        })
      }
    }
  }

  useEffect(() => {
    getCustomerSubscription()
  }, [appointment])

  useEffect(() => {
    // if (appointment && appointment.status != 'Complete') {
    setPayment(
      calculatePayment(
        appointment?.services,
        appointment?.products,
        appointment?.discounts,
        subscription?.data?.subscription?.subscriptionPackageId,
        tip || appointment?.payment?.tipAmount,
        tip?.id || appointment?.payment?.tipId,
        null,
        appointment?.payment?.cashTipAmount || 0,
      ),
    )
    // } else if (appointment) {
    //   setPayment(appointment?.payment)
    // }
  }, [subscription, appointment, tip])

  const payedExternal = async () => {
    if (
      window.confirm(
        'Has the customer paid through other ways than this app? This should be uncommon. Click "Ok" to continue',
      )
    ) {
      setUpdatingStatus(true)
      try {
        let response = await axios.patch(`/appointment/updateStatus`, {
          appointmentId: appointment?._id,
          status: 'Complete',
          paidExternal: true,
        })
        await getAppointment()
      } catch (error) {}
      setUpdatingStatus(false)
    }
  }

  function cancelAppointment() {
    if (
      window.confirm(`Confirm you would like to update the appointment to Cancel the appointment`)
    ) {
      updateStatus('Canceled')
    }
  }

  const updateStatus = async status => {
    setUpdatingStatus(true)
    try {
      let response = await axios.patch(`/appointment/updateStatus`, {
        appointmentId: appointment?._id,
        status,
      })
      await getAppointment()
    } catch (error) {}
    setUpdatingStatus(false)
  }

  function handleEmployeeChange() {
    setEmployeeModalOpen(true)
  }

  const updateAppointmentData = () => {
    getAppointment()
  }
  // const deleteAppointment = async () => {
  //     if (window.confirm('Are you sure you want to delete the appointment?')) {
  //         // Delete it!
  //         await axios.delete(`/appointment?id=${appointment_id}`)
  //         history.push(-1)
  //     }
  // }

  if (!appointment) {
    return (
      <IonPage>
        <DetailsHeader title={'Appointment'} />
        <IonContent>
          <IonSpinner name='dots' />
        </IonContent>
      </IonPage>
    )
  }

  let duration = 0
  for (let s of appointment?.services) {
    duration += s.durationMin
  }

  let calculatedAppointment = { ...appointment }
  calculatedAppointment.payment = payment

  return (
    <IonPage>
      <DetailsHeader
        title={'Appointment'}
        headerItem={
          <ActionMenu
            appointment={appointment}
            userData={userData}
            payedExternal={payedExternal}
            status={calculatedAppointment?.status}
            updateStatus={updateStatus}
            cancelAppointment={cancelAppointment}
            getAppointment={getAppointment}
          />
        }
      />
      <IonContent>
        <StandardContainer medium={true}>
          <ProgressStepper
            status={calculatedAppointment?.status}
            isWalkin={calculatedAppointment.type == 'Walkin'}
          />
          <CustomerCard
            appointment={calculatedAppointment}
            subscription={subscription}
            setNotesOpen={setNotesOpen}
            refreshData={getCustomerSubscription}
          />
          <ProfessionalCard
            appointment={calculatedAppointment}
            handleEmployeeChange={handleEmployeeChange}
            businessData={businessData}
            status={calculatedAppointment?.status}
          />
          <AppointmentInfoCard
            getAppointment={getAppointment}
            updateAppointmentData={updateAppointmentData}
            appointment={calculatedAppointment}
            updatingStatus={updatingStatus}
            updateStatus={updateStatus}
          />
          <EditServicesProductAndDiscounts
            appointment={calculatedAppointment}
            setServiceEditModalOpen={setServiceEditModalOpen}
          />
          {}
          {['In Progress', 'Complete'].includes(calculatedAppointment?.status) ? (
            <>
              <IonCard style={{ color: 'black', padding: 18 }}>
                <Invoice appointment={calculatedAppointment} />

                {['Complete'].includes(calculatedAppointment?.status) ? (
                  <div
                    style={{
                      borderRadius: 50,
                      backgroundColor: colors.primaryBlue,
                      marginTop: 10,
                      padding: 5,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 100,
                    }}
                  >
                    <h6 style={{ margin: 0, fontSize: 12, color: 'white' }}>
                      {calculatedAppointment.paidExternal ? 'Paid External' : 'Paid'}
                    </h6>
                  </div>
                ) : null}
                {['In Progress'].includes(calculatedAppointment?.status) ? (
                  <IonButton
                    style={{ marginTop: 20 }}
                    disabled={updatingStatus}
                    expand='block'
                    fill='solid'
                    onClick={() => {
                      setFinalizePaymentModalOpen(true)
                      // updateStatus('Complete')
                    }}
                  >
                    Finalize Payment + Tip
                  </IonButton>
                ) : null}
              </IonCard>
              {['Complete'].includes(calculatedAppointment?.status) ? (
                <AddCashTip
                  refreshAppointment={refreshAppointment}
                  appointment={calculatedAppointment}
                />
              ) : null}
            </>
          ) : null}
        </StandardContainer>
        <ChangeEmployeeModal
          isOpen={employeeModalOpen}
          setEmployeeModalOpen={setEmployeeModalOpen}
          appointmentId={calculatedAppointment._id}
          updateAppointment={getAppointment}
        />
        <FinalizePaymentAndTip
          isOpen={finalziePaymentModalOpen}
          setOpen={setFinalizePaymentModalOpen}
          appointment={calculatedAppointment}
          updateAppointment={getAppointment}
          tip={tip}
          setTip={setTip}
          payment={payment}
        />
        <ServicesProductsAndDiscounts
          isOpen={serviceEditModalOpen}
          setOpen={setServiceEditModalOpen}
          appointment={calculatedAppointment}
          updateAppointment={getAppointment}
          subscription={subscription}
        />
        <CustomerNotesModal
          isOpen={notesOpen}
          setOpen={setNotesOpen}
          customerId={calculatedAppointment?.customer?._id}
        />
      </IonContent>
    </IonPage>
  )
}
