import * as React from 'react';
import {Button, FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios'
import { useHistory } from 'react-router-dom';

export default function CreateTestDialog({ open, handleClose, businessId, locationId }) {
  let [isDisabled, setIsDisabled] = React.useState(false)
  let [firstName, setFirstName] = React.useState('')
  let [lastName, setLastName] = React.useState('')
  let [email, setEmail] = React.useState('')
  let [phoneNumber, setPhoneNumber] = React.useState('')
  let [role, setRole] = React.useState('')
  const history = useHistory()
  const createButtonClicked = async (e) => {
    setIsDisabled(true)
    let locData = locationId ? {locations : [locationId]} : {}
    let response = await axios.post(`/employee`, { firstName, lastName, email, businessId, phoneNumber, role, ...locData })
    console.log(response)
    if (response.status === 200) {
      history.push(`employees/${response.data._id}/details`)
      close()
    } else {
      setIsDisabled(false)
    }

  }

  const close = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setPhoneNumber('')
    setRole('')
    setIsDisabled(false);
    handleClose()
  }

  return (

    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ marginRight: 100 }}>Add Employee</DialogTitle>
      <DialogContent>
        <DialogContentText>
        </DialogContentText>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Role</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={role}
            label="Role"
            onChange={(e) => { setRole(e.target.value) }}
          >
            <MenuItem value={'Barber'}>Barber</MenuItem>
            <MenuItem value={"Receptionist"}>Receptionist</MenuItem>
            <MenuItem value={"Office"}>Office</MenuItem>
          </Select>
        </FormControl>
        <div style={{ flex: 1, flexDirection: 'row' }}>
          <TextField
            autoFocus
            margin="dense"
            id="firstName"
            label="First Name"
            fullWidth
            type="text"
            variant="outlined"
            value={firstName}
            onChange={(e) => { setFirstName(e.target.value) }}
            style={{ flex: 1, marginRight: 10 }}
          />

          <TextField
            margin="dense"
            id="lastName"
            label="Last Name"
            type="text"
            fullWidth
            variant="outlined"
            value={lastName}
            onChange={(e) => { setLastName(e.target.value) }}
            style={{ flex: 1 }}
          />
        </div>
        <TextField
          margin="dense"
          id="email"
          label="Email"
          type="text"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => { setEmail(e.target.value) }}
        />
        <TextField
          margin="dense"
          id="phoneNumber"
          label="Phone Number"
          type="text"
          fullWidth
          variant="outlined"
          value={phoneNumber}
          onChange={(e) => { setPhoneNumber(e.target.value) }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button disabled={isDisabled} color='primary' variant='contained' onClick={createButtonClicked}>Create</Button>
      </DialogActions>
    </Dialog>
  );
}