export default function StandardContainer({ children, medium }){
    let w = 800
    if(medium){
        w = 600
    }

    return (
        <div style={{ justifyContent: 'center', width: '100%', display: 'flex' }}>
            <div style={{ maxWidth: w, width: '100%' }}>
                {children}
            </div>
        </div>
    )
}