import React, { useState, useEffect, useContext } from 'react';
import { Typography, Button, Paper, Card, Grid, Box, Divider } from '@mui/material';
import axios from 'axios'
import Avatar from '../commonComponents/avatar'
import { AuthContext } from '../auth'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HeaderComponent from './headerComponent';


export default function StepPickBarber({ businessId, scheduleData, updateScheduleData, setStep}) {
    const [employees, setEmployees] = useState([])
    useEffect(()=>{
        getEmployeesList()
    },[])

    const getEmployeesList = async () =>{
        let locQuery = (scheduleData && scheduleData.location && scheduleData.location._id || null) ? `&locationId=${scheduleData.location._id}` : ''
        let response = await axios.get(`/employee/getPublicList?businessId=${businessId}${locQuery}`)
        setEmployees(response.data)
    }

    function selectEmployee(employee){
        updateScheduleData('barber', employee)
        setStep('service')
    }


    return (
        <>
            <HeaderComponent title="Pick a Stylist" handleBack={()=>{setStep('location')}} progress={{total:5, step:2}}/>

            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {employees.map(e=>(
                        <BarberCard employee={e} selectEmployee={selectEmployee} key={e._id} businessId={businessId}/>
                    ))}
            </List>

           
        </>
    );
}

function BarberCard({employee, selectEmployee, businessId }) {

    return (
        <>
            <ListItem onClick={()=>{selectEmployee(employee)}} style={{cursor:'pointer'}}>
                <ListItemAvatar>
                    <Avatar employee={employee} businessId={businessId} small />
                </ListItemAvatar>
                <ListItemText 
                    primary={employee.firstName + " " + employee.lastName} 
                    secondary={employee.role}/>
                    <ChevronRightIcon  style={{color:'grey'}}/>
            </ListItem>
            <Divider component="li" />
        </>
    )
}
