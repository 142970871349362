import { IonIcon, IonModal } from '@ionic/react'
import { ellipsisHorizontal } from 'ionicons/icons'
import { useState } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import axios from 'axios'
import ConfirmationModal from '../commonComponents/ConfirmationModal'
export default function DeleteEmailStep({
  stepData,
  emailSteps,
  smtpSettingsId,
  ruleName,
  audiences,
  id,
  refreshRemarketingDetails,
  refreshEmailSteps,
  mode,
}) {
  const styles = {
    exportToCSVTitleStyle: {
      fontSize: '0.8rem',
      fontWeight: 'bold',
      color: 'grey',
    },
    moreIconStyle: {
      heght: 22,
      width: 22,
      color: 'grey',
    },
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const open = Boolean(anchorEl)
  const [deletingData, setDeletingData] = useState(false)
  const handleDeleteModal = value => {
    setDeleteModalVisible(value)
    // close the menu item if we are closing the modal
    handleClose()
  }
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const removeObjectById = (dataArray, targetId) => {
    return dataArray.filter(item => item.id !== targetId)
  }
  const updateRemarketingRules = async input => {
    // Log the input object to check its contents

    try {
      const updateRemarketingResponse = await axios.patch(`/emailRemarketing/rule/?id=${id}`, input)
      if (updateRemarketingResponse.status == 200) {
        setDeletingData(false)
        handleDeleteModal(false)
        refreshRemarketingDetails()
      }
    } catch (error) {}
  }
  const handleDelete = () => {
    if (mode == 'add') {
    } else {
      setDeletingData(true)
    }

    // Removing object with selected step id
    let updatedStepsData = removeObjectById(emailSteps, stepData.id)
    const remarketingInput = {
      smtpSettingsId: smtpSettingsId,
      ruleName: ruleName,
      audiences: audiences,
      emailSteps: updatedStepsData,
    }
    if (mode == 'add') {
      handleDeleteModal(false)

      refreshEmailSteps(updatedStepsData)
    } else {
      updateRemarketingRules(remarketingInput)
    }
  }
  return (
    <>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <IonIcon size='large' style={styles.moreIconStyle} icon={ellipsisHorizontal} />
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleDeleteModal(true)
          }}
          style={styles.exportToCSVTitleStyle}
        >
          Delete Email Step
        </MenuItem>
      </Menu>
      {deleteModalVisible && (
        <IonModal isOpen={deleteModalVisible}>
          <ConfirmationModal
            isOpen={deleteModalVisible}
            message={'Are You Sure You want to delete this rule?'}
            cancel={() => handleDeleteModal(false)}
            loading={deletingData}
            save={handleDelete}
            saveText={'Delete'}
          />
        </IonModal>
      )}
    </>
  )
}
