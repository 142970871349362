import React from 'react';
import { Typography } from '@mui/material';
import { colors } from '../theme/colors';
import { bodyOutline, calendarOutline, checkmark, closeOutline, eyeOffOutline, hourglass, hourglassOutline, time, timeOutline, watchOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';


export default function StatusCircle({ status, xs }) {
  let Lookup = {
    'Scheduled': { color: '#454545', icon:calendarOutline},
    'Arrived': { color: "#ff5722d9", icon: bodyOutline},
    'Canceled': { color: "#ff5722d9", icon: closeOutline},
    'Complete': { color: colors.green, icon:checkmark },
    'In Progress': { color: colors.primaryBlue, icon:hourglassOutline },
    'No Show' : {color: 'grey', icon:eyeOffOutline}
  }

  let size = {width:40, height:40, iconSize:26}
  if(xs){
    size = {width:25, height:25, iconSize: 18}
  }

  let options = Lookup[status] || { color: 'grey' }

  return (
    <div style={{ width: size.width, height: size.height, borderRadius: '50%', backgroundColor: options.color, display:'flex', justifyContent:'center', alignItems:'center' }}>
      <IonIcon icon={options.icon} style={{color:'white', width:size.iconSize, height:size.iconSize}}/>
    </div>
  )
}