import React from 'react';
import axios from 'axios'
import {  IonButton, IonCard, IonCol, IonGrid, IonIcon, IonRow, IonSpinner,  } from '@ionic/react';
import MiniHeader from '../../commonComponents/MiniHeader';
import { documentOutline } from 'ionicons/icons';
import SubscriberInfo from '../../commonComponents/SubscriberInfo';


export default function CustomerCard({ appointment, subscription, setNotesOpen, refreshData }) {
    return (
        <IonCard style={{ color: 'black', padding: 18, marginTop: 10 }}>
            <MiniHeader title="Client" />
            <h3 style={{ margin: 0 }}>{appointment?.customer?.firstName} {appointment?.customer?.lastName}</h3>
            {/* {subscription?.loading ? <IonSpinner name='dots'/> : null}
            {
                subscription?.data?.isSubscribed ?
                    <IonGrid style={{ margin: 0, marginLeft:-10 }}>
                        <IonRow style={{ margin: 0 }}>
                            <IonCol size='auto'>
                                <div style={{ color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 45, width: 45, background: 'linear-gradient(to top right, #FFD700, #FFDF00, #FFEA00)', borderRadius: 8 }}>
                                    <h1 style={{ margin: 0, marginTop:-4 }}>∞</h1>
                                </div>
                            </IonCol>
                            <IonCol>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                                    <h5 style={{ margin: 0, marginTop:4 }}>{subscription?.data?.subscription?.name} Package</h5>
                                    <h6 style={{ margin: 0, fontWeight:'600', fontSize:14 }}>Subscriber</h6>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    :
                    null
            } */}
            <SubscriberInfo customer={appointment?.customer} refreshData={refreshData}/>
            <div style={{backgroundColor:'#e6e6e6', height:1, width:'100%', marginTop:8}}/>
        <IonButton fill={'clear'} expand='block' onClick={()=>{setNotesOpen(true)}} style={{marginLeft:-14, marginTop:0, marginBottom:-16, fontSize:14}}><IonIcon icon={documentOutline}/> &nbsp;&nbsp;Customer Notes</IonButton>
        </IonCard>
    )
}

// function subscriptionTile(){
//     return (

//     )
// }

