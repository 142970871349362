import { useContext, useState } from 'react'
import { IonCard, IonContent, IonInput, IonItem, IonLabel, IonList, IonPage } from '@ionic/react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import SubscriptionOptionsList from './SubscriptionOptionsList'
import { AuthContext } from '../auth'
import axios from 'axios'
import { useHistory } from 'react-router'

const CreateSubscription = () => {
  const history = useHistory()
  const [subscriptionName, setSubscriptionName] = useState('')
  const [subscriptionDesc, setSubscriptionDesc] = useState('')
  const [pricingOptions, setPricingOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const { businessData } = useContext(AuthContext)

  const addUpdatePricing = input => {}
  const addUpdatePricingToList = (mode, option, index) => {
    if (mode == 'add') {
      let pricingOptionsTemp = [...pricingOptions]
      pricingOptionsTemp.push(option)
      setPricingOptions(pricingOptionsTemp)
    } else {
      let pricingOptionsTemp = [...pricingOptions]
      pricingOptionsTemp[index] = option
      setPricingOptions(pricingOptionsTemp)
    }
  }
  const handleCreateSubscription = async () => {
    const pricingOptionsTemp = [...pricingOptions]
    pricingOptionsTemp.map((item, index) => {
      item.active = true
    })
    const subsInput = {
      name: subscriptionName,
      businessId: businessData?._id,
      description: subscriptionDesc,
      prices: pricingOptionsTemp,
      enabled: true,
    }

    setLoading(true)
    try {
      const createSubscriptionResponse = await axios.post('/subscriptionPackage', subsInput)
      if (createSubscriptionResponse.status === 200) {
        history.goBack()
      }
    } catch (error) {}
  }
  return (
    <IonPage id='main-content'>
      <CommonHeader
        title={'Create Subscription Package'}
        backIcon={true}
        saveButton={true}
        handleSaveButton={handleCreateSubscription}
        loading={loading}
        saveDisabled={!subscriptionName || !subscriptionDesc || pricingOptions.length == 0}
      ></CommonHeader>
      <IonContent>
        <StandardContainer>
          <IonCard>
            <IonList mode='ios'>
              <h5 style={{ fontWeight: 'bold', margin: ' 1rem 1rem' }}>General Details</h5>
              <IonItem>
                <IonLabel position='stacked'>Name</IonLabel>
                <IonInput
                  onIonChange={e => setSubscriptionName(e.detail.value)}
                  placeholder='Subscription Name'
                  type='text'
                  value={subscriptionName}
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position='stacked'>Description</IonLabel>
                <IonInput
                  onIonChange={e => setSubscriptionDesc(e.detail.value)}
                  placeholder='Description'
                  type='text'
                  value={subscriptionDesc}
                ></IonInput>
              </IonItem>
              {renderSubscriptionOptions()}
            </IonList>
          </IonCard>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )

  function renderSubscriptionOptions() {
    return (
      <>
        <SubscriptionOptionsList
          subscriptionPackage={{}}
          addOption={true}
          addUpdatePricingToList={addUpdatePricingToList}
          pricingOptions={pricingOptions}
          addUpdatePricing={addUpdatePricing}
        />
      </>
    )
  }
}

export default CreateSubscription
