import { IonContent, IonPage } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../auth'
import axios from 'axios'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/StandardContainer'
import { getMonthRange } from '../../Utils/GetDates'
import GetMonthsBasedOnStartAndEndIndex from '../../Utils/GetMonthsBasedOnStartAndEndIndex'
import { GetSeriesDataForLineChart } from '../../Utils/GetSeriesDataForLineChart'
import ServiceTimeGraph from './ServiceTimeGraph'
export default function TimeGraph({ match, type, title }) {
  console.log(type, 'typetypetype')
  const employee_id = match?.params?.id
  const { businessData } = useContext(AuthContext)

  return (
    <IonPage>
      <CommonHeader title={title} backIcon={true} />
      <IonContent>
        <StandardContainer>
          <ServiceTimeGraph id={employee_id} type={'serviceTimeStats'} />
          <ServiceTimeGraph id={employee_id} type={'durationTimeStats'} />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
