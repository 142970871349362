import { IonContent, IonInput, IonItem, IonLabel, IonList, IonModal } from '@ionic/react'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../auth'
import axios from 'axios'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'

export default function BookingThreshold() {
  const { locationId, businessData, locationData, getBusinessData } = useContext(AuthContext)
  const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)

  const [thresholdValue, setThresholdValue] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [valueChanged, setValueChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }
  const handleSave = async () => {
    setLoading(true)
    try {
      const input = {
        businessId: businessData?._id,
        appointmentSettings: {
          ...selectedLocationData?.appointmentSettings,
          futureAppointmentsLimit: parseInt(thresholdValue),
        },
      }

      const response = await axios.patch(`/location?id=${locationId}`, input)
      if (response.status === 200) {
        setLoading(false)
        getBusinessData()
        closeModal()
      }
    } catch (error) {}
  }
  const handleThresholdValue = async e => {
    setValueChanged(true)

    const value = e.detail.value

    // Validate if the value is a positive number
    if (!/^\d*\.?\d*$/.test(value) || parseFloat(value) < 0) {
      // If not a valid positive number, you can set an error message or take appropriate action
      // For example, set an error state or display an error message
      setError('Please enter a valid positive number')
    } else {
      setError('')
    }

    // If the value is a valid positive number, update the threshold value state
    setThresholdValue(value)
  }

  useEffect(() => {
    setThresholdValue(selectedLocationData?.appointmentSettings?.futureAppointmentsLimit)
  }, [locationId, businessData, modalOpen])

  return (
    <>
      <IonList inset={true} mode='ios'>
        <IonItem onClick={openModal} detail={true}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IonLabel>Booking Threshold </IonLabel>
            <IonLabel style={{ marginLeft: '0.5rem', fontSize: '12px', color: 'grey' }}>
              {`(${selectedLocationData?.appointmentSettings?.futureAppointmentsLimit})`}
            </IonLabel>
          </div>
        </IonItem>
      </IonList>
      <IonModal isOpen={modalOpen} onDidDismiss={closeModal}>
        <CommonHeader
          title={'Booking Threshold'}
          closeButton={true}
          loading={loading}
          saveButton={true}
          saveDisabled={!thresholdValue || !valueChanged || error != ''}
          closeModal={closeModal}
          handleSaveButton={handleSave}
        ></CommonHeader>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <IonList inset={true} mode='ios'>
              <IonItem>
                <IonLabel position='stacked'>Booking Threshold</IonLabel>
                <IonInput
                  value={thresholdValue}
                  onIonChange={handleThresholdValue}
                  type='number'
                  placeholder='Enter threshold value'
                ></IonInput>
              </IonItem>
              <IonLabel style={{ margin: '1rem', color: 'red', fontSize: '12px' }}>
                {error}
              </IonLabel>
            </IonList>
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}
