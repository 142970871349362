import React, { useState, useEffect, useContext } from 'react'
import { Typography, Button, Paper, Card, Avatar, Grid, Box } from '@mui/material'
import axios from 'axios'
import dayjs from 'dayjs'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { getFormattedMediumDate } from '../utils'
import HeaderComponent from './headerComponent'

export default function StepPickDateTime({
  businessId,
  updateScheduleData,
  setStep,
  scheduleData,
}) {
  const [dateAndTimeData, setDateAndTimeData] = useState([])
  const [value, setValue] = React.useState(dayjs())

  useEffect(() => {
    getAvailability()
  }, [value])

  const getAvailability = async () => {
    let response = await axios.get(
      `/appointment/getAvailable?businessId=${businessId}&locationId=${
        scheduleData?.location?._id || null
      }&barberId=${scheduleData?.barber?._id || null}&serviceId=${
        scheduleData.service._id || null
      }&customerId=${
        (scheduleData.barber && scheduleData.customer._id) || null
      }&selectedDate=${value}`,
    )
    console.log(response)
    setDateAndTimeData(response.data)
  }

  function handleTimeClick(data) {
    updateScheduleData('dateTime', data)
    setStep('summary')
  }

  return (
    <>
      <HeaderComponent
        title={'Pick a time'}
        handleBack={() => {
          setStep('service')
        }}
        progress={{ total: 5, step: 4 }}
      />
      <DateTimeBody
        value={value}
        setValue={setValue}
        dateAndTimeData={dateAndTimeData}
        handleTimeClick={handleTimeClick}
      />
    </>
  )
}

function DateTimeBody({ value, setValue, dateAndTimeData, handleTimeClick }) {
  return (
    <>
      <Box
        style={{
          backgroundColor: 'white',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {/* <div>{value.toISOString()}</div> */}
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              displayStaticWrapperAs='desktop'
              openTo='day'
              value={dayjs(value)}
              onChange={newValue => {
                setValue(newValue)
              }}
              renderInput={params => <TextField {...params} />}
            />
          </LocalizationProvider>

          {/* MORNING  */}
          <Box
            style={{
              width: 300,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid container style={{ display: 'flex', justifyContent: 'left' }}>
              <Grid item>
                <Typography variant='subtitle2'>
                  {dateAndTimeData.outOfBounds == true
                    ? 'SCHEDULE NOT AVAILABLE YET'
                    : dateAndTimeData.locationOpen
                    ? 'OPEN'
                    : 'STORE CLOSED'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid item container style={{ display: 'flex' }}>
                {(dateAndTimeData &&
                  dateAndTimeData.availableTimes &&
                  dateAndTimeData.availableTimes.map(timeSlot => (
                    <TimeSlotButton
                      available={timeSlot.available}
                      timeSlot={timeSlot}
                      handleTimeClick={handleTimeClick}
                      key={timeSlot.startTimeText}
                    />
                  ))) ||
                  null}
              </Grid>
            </Grid>
          </Box>
        </div>

        <br></br>
        <br></br>
      </Box>
    </>
  )
}

function TimeSlotButton({ timeSlot, handleTimeClick, available }) {
  const activeBorderColor = available ? '#aaa7a7' : null
  const activeColor = available ? 'black' : null
  return (
    <Grid
      item
      style={{ margin: 2 }}
      onClick={() => {
        handleTimeClick(timeSlot)
      }}
    >
      <Button
        style={{ width: 95, fontSize: 12, borderColor: activeBorderColor, color: activeColor }}
        disabled={!available}
        variant='outlined'
      >
        {timeSlot.startTimeText}
      </Button>
    </Grid>
  )
}
