import { useContext, useEffect, useState } from 'react'
import { format, startOfDay, addHours } from 'date-fns'
import {
  IonGrid,
  IonRow,
  IonCol,
  IonRouterLink,
  IonLabel,
  IonModal,
  IonContent,
  IonList,
  IonCard,
} from '@ionic/react'
import StandardCenterCenter from '../commonComponents/StandardCenterCenter'
import WalkinAppointments from '../Calendar/WalkinAppointments'
import CommonHeader from '../commonComponents/CommonHeader'
import { calculateEventStyles, getFormattedMediumDate, getWeekday } from '../utils'
import AddHeader from '../commonComponents/AddHeader'
import { colors } from '../theme/colors'
import StandardContainer from '../commonComponents/StandardContainer'
import ResultItem from './ResultItem'
import { AuthContext } from '../auth'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'
import CalendarHeader from './CalendarHeader'
import './calendarView.css'
// import { colors } from '@mui/material'

const CalendarDayView = ({
  breaks,
  appointments,
  selectedDate,
  handleView,
  loading,
  setSelectedDate,
}) => {
  const statusColors = {
    'Scheduled': { bgColor: '#45454552', textColor: '#454545' },
    'Arrived': { bgColor: '#ff572238', textColor: '#ff5722' },
    'Canceled': { bgColor: '#ff572229', textColor: '#ff5722' },
    'Complete': { bgColor: '#00b05057', textColor: '#00b050' },
    'In Progress': { bgColor: '#3880ff4d', textColor: colors.primaryBlue },
    'No Show': { bgColor: '#d6d6d678', textColor: '#919191' },
  }
  const [locationSchedule, setLocationSchedule] = useState(false)
  const { businessData, locationData, locationId } = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false)
  const [modalAppointments, setModalAppointments] = useState([])
  const [personEvents, setPersonEvents] = useState({})
  const [allDayEvents, setAllDayEvents] = useState([])
  const [walkinsList, setFilteredWalkins] = useState([])
  const [hours, setHours] = useState([])

  const openModal = appointments => {
    setModalAppointments(appointments)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const handlePrevWeek = () => {
    const newSelectedDate = new Date(selectedDate)
    newSelectedDate.setDate(newSelectedDate.getDate() - 7)
    setSelectedDate(newSelectedDate)
  }

  const handleNextWeek = () => {
    const newSelectedDate = new Date(selectedDate)
    newSelectedDate.setDate(newSelectedDate.getDate() + 7)
    setSelectedDate(newSelectedDate)
  }

  useEffect(() => {
    const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)

    setLocationSchedule(selectedLocationData.standardSchedule)
  }, [locationId])

  const parseTime = (date, timeString) => {
    const [time, period] = timeString.split(' ')
    let [hour, minute] = time.split(':').map(x => parseInt(x, 10))

    // Correctly handle 12 AM as 0 hours and PM times except 12 PM
    if (period === 'PM' && hour !== 12) {
      hour += 12
    } else if (period === 'AM' && hour === 12) {
      hour = 0
    }

    const dateText = new Date(date)
    return new Date(dateText.getFullYear(), dateText.getMonth(), dateText.getDate(), hour, minute)
  }
  const setTimeFromDateStr = (timeStr, date = new Date()) => {
    const dateWithNoTime = new Date(date.setHours(0, 0, 0, 0)) // Reset hours, minutes, seconds, and milliseconds

    const [time, modifier] = timeStr.split(' ')
    let [hours, minutes] = time.split(':')

    // Convert hours to 24-hour format based on AM/PM
    if (hours === '12') {
      hours = '0'
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12
    }

    dateWithNoTime.setHours(hours)
    dateWithNoTime.setMinutes(minutes)

    return dateWithNoTime
  }

  useEffect(() => {
    let weekday = getWeekday(selectedDate)
    const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)

    let standardScheduleForDay = selectedLocationData?.standardSchedule[weekday]

    const startTime = standardScheduleForDay.startTime
    const endTime = standardScheduleForDay.endTime
    let appointmentStart
    let appointmentEnd
    if (startTime) {
      appointmentStart = setTimeFromDateStr(startTime, selectedDate)
      appointmentEnd = setTimeFromDateStr(endTime, selectedDate)
    }

    const startHour = appointmentStart
    const endHour = addHours(startOfDay(selectedDate), 24)
    let hours = []
    let currentHour = startHour

    while (currentHour < endHour) {
      hours.push(currentHour)
      currentHour = addHours(currentHour, 1)
    }

    setHours(hours)
    let allDayEvents = {}
    let regularEvents = {}

    for (let a of appointments) {
      if (a?.status !== 'Canceled') {
        let barberName = `${a?.barber?.firstName} ${a?.barber?.lastName}`
        if (a?.barber) {
          if (!allDayEvents[barberName]) {
            allDayEvents[barberName] = []
            regularEvents[barberName] = []
          }
          let dataItem = {
            title: a?.services?.map(service => service?.name).join(', '),
            _id: a?._id,
            ...a,
          }
          if (a?.barber?._id) {
            if (!a.endTime) {
              dataItem.allDay = true
              dataItem.start = new Date(selectedDate)
              dataItem.end = new Date(selectedDate)
              allDayEvents[barberName].push(dataItem)
            } else {
              dataItem.start = setTimeFromDateStr(a?.startTimeText)
              dataItem.end = setTimeFromDateStr(a?.endTimeText)
              regularEvents[barberName].push(dataItem)
            }
          }
        }
      }
    }

    breaks.map((item, index) => {
      // Parse start time and end time strings to Date objects
      const startDateWithTime = parseTime(item.dateText, item.startTime)
      const endDateWithTime = parseTime(item.dateText, item.endTime)

      let employeeData = item?.employee?.[0]
      let name = `${employeeData?.firstName} ${employeeData?.lastName}`
      let dataItem = {
        isBreak: true,
        title: 'Break',
        _id: item?._id,
        start: startDateWithTime,
        end: endDateWithTime,
        ...item,
      }
      if (!regularEvents[name]) {
        regularEvents[name] = []
      }
      regularEvents[name].push(dataItem)
    })

    let filteredWalkins = appointments?.filter(a => a.type === 'Walkin' && !a?.barber?._id)
    setFilteredWalkins(filteredWalkins)
    setAllDayEvents(allDayEvents)
    setPersonEvents(regularEvents)
  }, [appointments])

  const people = Object.keys(personEvents).sort((a, b) => a.localeCompare(b))

  // const hours = Array.from({ length: 24 }).map((_, index) =>
  //   addHours(startOfDay(new Date()), index),
  // )

  const eventData = event => {
    if (event.isBreak) {
    }
    // Calculate the difference in milliseconds
    const timeDifference = event.end.getTime() - event.start.getTime()

    // Convert milliseconds to minutes
    const minutesDifference = Math.floor(timeDifference / (1000 * 60))
    const textColor = statusColors[event.status]?.textColor || '#000'
    const services = event?.services?.map(item => item.name) || []
    return (
      <IonRow
        style={{
          marginLeft: '5px',
          fontWeight: event.isBreak ? 'bold' : '',
          color: event.isBreak ? '#0c00df' : textColor,
        }}
      >
        {event.allDay && (
          <IonLabel style={{ fontSize: '10px', marginRight: '5px' }}>{`(Walk-in) | ${services.join(
            ',',
          )} `}</IonLabel>
        )}
        {event.isBreak && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <IonLabel>Break</IonLabel>
            <IonLabel
              style={{ fontSize: '10px', marginLeft: '5px' }}
            >{`(${event.startTime}-${event.endTime})(${minutesDifference}min)`}</IonLabel>
          </div>
        )}
        {!event.allDay && !event.isBreak && (
          <>
            <IonLabel style={{ fontSize: '10px' }}>
              {`${event.customer.firstName} ${event.customer.lastName} | `}
            </IonLabel>
            <IonLabel style={{ fontSize: '10px', marginLeft: '5px' }}>
              {' '}
              {services.join(',')} |
            </IonLabel>
            <IonLabel
              style={{ fontSize: '10px', marginLeft: '5px' }}
            >{`(${event.durationMin}min)`}</IonLabel>
          </>
        )}
      </IonRow>
    )
  }

  const renderEvent = (event, person, index) => {
    let weekday = getWeekday(selectedDate)
    const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)
    let standardScheduleForDay = selectedLocationData?.standardSchedule[weekday]
    let startTime = standardScheduleForDay.startTime
    startTime = setTimeFromDateStr(startTime)

    return event.isBreak ? (
      <div
        class={event.isBreak ? 'break-event' : ''}
        style={calculateEventStyles(event, person, index, personEvents, startTime)}
      >
        {eventData(event)}
      </div>
    ) : (
      <IonRouterLink
        class={event.isBreak ? 'break-event' : ''}
        style={calculateEventStyles(event, person, index, personEvents, startTime)}
        routerLink={event.isBreak ? '' : `/core/appointments/${event?._id}`}
      >
        {eventData(event)}
      </IonRouterLink>
    )
  }

  const isStoreClosed = () => {
    const dayOfWeek = selectedDate.getDay()
    const dayOfWeekString = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ][dayOfWeek]
    const todaySchedule = locationSchedule[dayOfWeekString]

    return !todaySchedule.enabled
  }

  return (
    <>
      <AddHeader
        title={getFormattedMediumDate(selectedDate)}
        loading={loading}
        defaultView={'calendar'}
        handleView={handleView}
        // handleAddClick={openAddDialog}
        additionalToolbar={
          <CalendarHeader
            selectedDate={selectedDate}
            handlePrevWeek={handlePrevWeek}
            loading={loading}
            handleNextWeek={handleNextWeek}
            setSelectedDate={setSelectedDate}
          />
        }
      />
      <div style={{ background: 'white' }}>
        {walkinsList && walkinsList.length > 0 && <WalkinAppointments walkinsList={walkinsList} />}
      </div>

      {/* header toolbar for selecting dates */}

      <IonContent>
        {/* // Render store closed message if the store is closed on the selected day */}
        {locationSchedule && isStoreClosed() ? (
          <StandardContainer>
            <IonCard
              style={{
                padding: '3rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h6 style={{ color: 'grey', textAlign: 'center' }}> Closed</h6>
            </IonCard>
          </StandardContainer>
        ) : (
          <div
            style={{
              display: 'flex',
              width: '100%',
              overflowX: 'auto',
              backgroundColor: 'white',
            }}
          >
            {/* Fixed Time Column */}
            <div style={{ flexShrink: 0, marginTop: 37 }}>
              <div
                key={'Walkin'}
                style={{
                  height: '60px',
                  display: 'flex',
                  textAlign: 'right',
                  padding: '10px',
                  color: 'grey',
                  fontSize: '11px',
                }}
              >
                {'Walkin'}
              </div>
              {hours.map((hour, index) => (
                <div
                  key={index}
                  style={{
                    height: '60px',
                    display: 'flex',
                    textAlign: 'right',
                    padding: '10px',
                    color: 'grey',
                    fontSize: '12px',
                  }}
                >
                  {format(hour, 'h a') == '12 PM' ? 'Noon' : format(hour, 'h a')}
                </div>
              ))}
            </div>

            {/* Scrollable Event Columns */}
            <div style={{ display: 'flex', overflowX: 'auto' }}>
              <IonGrid
                style={{
                  minWidth: people.length * 300,
                  position: 'relative',
                  backgroundColor: 'white',
                }}
              >
                <IonRow
                  style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                    backgroundColor: 'inherit',
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {people.map((person, columnIndex) => (
                    <IonCol
                      key={person}
                      style={{
                        margin: 0,
                        padding: 0,
                        borderRight: '1px solid #eee',
                        borderBottom: '1px solid grey',
                      }}
                    >
                      <StandardCenterCenter>
                        <h4>{person}</h4>
                      </StandardCenterCenter>
                    </IonCol>
                  ))}
                </IonRow>

                {hours.map((hour, index) => (
                  <IonRow key={index} style={{ height: '60px' }}>
                    {people.map(person => (
                      <IonCol
                        key={person}
                        style={{ borderBottom: '1px solid #ccc', borderRight: '1px solid #eee' }}
                      />
                    ))}
                  </IonRow>
                ))}
                {/* Adjusted overlay events */}
                {people.map((person, columnIndex) => (
                  <div
                    key={person}
                    style={{
                      position: 'absolute',
                      top: 56,
                      left: columnIndex * 300 + 2,
                      right: 4,
                      width: 292,
                      zIndex: 1000,
                    }}
                  >
                    {allDayEvents[person]?.slice(0, 2).map((event, index) => {
                      return renderEvent(event, person, index)
                    })}
                    {allDayEvents[person]?.length > 2 && (
                      <div
                        style={{
                          marginTop: '40px',
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                      >
                        <IonLabel
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            openModal(allDayEvents[person]?.slice(2, allDayEvents[person].length))
                          }
                          color={'primary'}
                        >
                          +{allDayEvents[person].length - 2} more
                        </IonLabel>
                      </div>
                    )}
                    {personEvents[person].map((event, index) => {
                      return renderEvent(event, person, index)
                    })}
                  </div>
                ))}
              </IonGrid>
              <IonModal isOpen={showModal} onDidDismiss={closeModal}>
                <CommonHeader
                  title={'All Day Appointments'}
                  closeButton={true}
                  closeModal={closeModal}
                ></CommonHeader>
                <IonContent className='regularBackground'>
                  <StandardContainer>
                    <IonList inset={true} mode='ios'>
                      {modalAppointments?.map(row => (
                        <ResultItem
                          closeModal={closeModal}
                          row={row}
                          businessId={businessData?._id}
                          key={row?._id}
                        />
                      ))}
                    </IonList>
                  </StandardContainer>
                </IonContent>
              </IonModal>
            </div>
          </div>
        )}
      </IonContent>
    </>
  )
}

export default CalendarDayView
