import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import StandardCenterCenter from '../commonComponents/StandardCenterCenter'
import { IonSpinner } from '@ionic/react'

const BarChart = ({ data, statusKeys, colors, xAxisCategories }) => {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: 'bar',
        height: 250,
        stacked: true,
        toolbar: false,
      },
      xaxis: {
        categories: [],
        tickAmount: 5,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '90%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      grid: {
        borderColor: '#f1f1f1',
      },
      yaxis: {
        title: {
          text: 'Appointments',
        },
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
      export: {
        show: false,
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val) {
            return val
          },
        },
      },
      legend: {
        show: true,
        offsetY: -10,
      },
      colors: colors || ['#FF5733', '#C70039', '#900C3F', '#581845'], // Add your custom colors here
    },
    series: [],
  })

  useEffect(() => {
    if (!data || data.length === 0) {
      return
    }

    const series = statusKeys.map(status => {
      return {
        name: status,
        data: data.map(datum => datum[status] || 0),
      }
    })

    const categories = data.map(datum => datum.date)
    const newChartData = {
      ...chartData,
      options: {
        ...chartData.options,
        xaxis: {
          ...chartData.options.xaxis,
          categories: xAxisCategories ? xAxisCategories : categories,
        },
      },
      series,
    }
    //if we dont get any categories from the parent component then format the labels
    if (!xAxisCategories) {
      newChartData.options.xaxis.labels = {
        show: true,
        formatter: function (value, timestamp, index) {
          const date = new Date(value)
          return date.toLocaleString('default', { month: 'short', day: 'numeric' })
        },
      }
    }

    setChartData(newChartData)
  }, [data])

  if (!data || data.length === 0 || !chartData) {
    return null
  }

  if(chartData?.series?.length === 0){
    return <StandardCenterCenter><IonSpinner style={{marginTop:40, marginBottom:40}} name='dots'/></StandardCenterCenter>
  }

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type='bar'
      height={250}
      style={{ marginBottom: -40 }}
    />
  )
}

export default BarChart

function reformatDate(value) {
  const n = 2
  const date = new Date(value)
  return date.toLocaleString('default', { month: 'short', day: 'numeric' })
}
