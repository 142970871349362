import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import axios from 'axios'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


function getStyles(name, includedInSubscriptions=[], theme) {
  return {
    fontWeight:
    (includedInSubscriptions || []).indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SubscriptionPackageMultiSelect({includedInSubscriptions=[], onValueChange, subscriptionPackages, locationId}) {

  const theme = useTheme();

  const handleChange = (event) => {
    onValueChange('includedInSubscriptions', event.target.value)
  }; 

  if(!subscriptionPackages) return <div>"loading subscription packages..."</div>

  return (
      <FormControl fullWidth style={{marginTop:20, marginBottom:200}} variant="standard">
        <InputLabel id="demo-multiple-chip-label" variant="standard">Included with Subscription Package</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          // disabled={locationId ? true : false}
          value={includedInSubscriptions || []}
          variant="standard"
          onChange={handleChange}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => {
                let foundVal = subscriptionPackages.find(x => x._id === value)
                let labelText = foundVal && foundVal.name || value
                //if(!foundVal) return null
                return (
                <Chip key={value} label={labelText} />
              )})}
            </Box>
          )}
        >
          {subscriptionPackages.map((subscriptionPackage) => (
            <MenuItem
              key={subscriptionPackage._id}
              value={subscriptionPackage._id}
              style={getStyles(subscriptionPackage, includedInSubscriptions, theme)}
            >
              {subscriptionPackage.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}
