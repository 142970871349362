import React, { useState, useEffect, useContext } from 'react'
import ResultsList from './resultsList'
import axios from 'axios'
import AddDialog from './addDialog'
import { AuthContext } from '../auth'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import StandardContainer from '../commonComponents/StandardContainer'

export default function ProductListView() {
  const { businessData, locationId } = useContext(AuthContext)
  const [products, setProducts] = useState([])
  const [addProductDialogOpen, setAddProductDialogOpen] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    getProductsList()
  }, [businessData, locationId])

  const getProductsList = async ignoreLoading => {
    if (!businessData?._id) return
    setLoading(!ignoreLoading)
    setErrorMessage(null)
    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      let response = await axios.get(`/product/getList?businessId=${businessData._id}${locQuery}`)
      setProducts(response.data)
    } catch (error) {
      setErrorMessage('Sorry there was an error fetching the data')
    }
    setLoading(false)
  }

  const openAddProductDialog = () => {
    setAddProductDialogOpen(true)
  }
  const closeAddProductDialog = () => {
    getProductsList()
    setAddProductDialogOpen(false)
  }

  async function handleRefresh(event) {
    await getProductsList(true)
    event.detail.complete()
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonTitle>Products</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={openAddProductDialog}>
              <IonIcon slot='icon-only' icon={addCircle} color='primary' />
            </IonButton>
          </IonButtons>
          {loading ? <IonProgressBar type='indeterminate' /> : null}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <StandardContainer>
          <ResultsList products={products} locationId={locationId} />
        </StandardContainer>
      </IonContent>
      <AddDialog
        open={addProductDialogOpen}
        handleClose={closeAddProductDialog}
        businessId={businessData._id}
        locationId={locationId}
      />
    </IonPage>
  )
}
