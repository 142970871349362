import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonLabel,
  IonModal,
  IonText,
} from '@ionic/react'
import { add } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import { reviewOptions } from '../StaticData/StaticData'
import SetupReview from './SetupReview'
export default function ChooseReview({
  reviewSites,
  // function to update the review sites list in main page
  updateReviewSitesList,
}) {
  const options = reviewOptions
  const [reviewModalVisible, setReviewModalVisible] = useState(false)
  const [selectedReviewOption, setSelectedReviewOption] = useState('')

  const openReviewModal = () => {
    setReviewModalVisible(true)
  }
  const closeReviewModal = () => {
    // console.log('inside closereview modal')
    setSelectedReviewOption('')
    setReviewModalVisible(false)
    updateReviewSitesList()
  }

  const handleReviewOption = option => {
    setSelectedReviewOption(option)
  }
  // useEffect(() => {
  //   setReviewModalVisible(reviewModalVisible)
  // }, [reviewModalVisible])
  const updateList = async () => {
    // console.log('close review modal================')
    // closeReviewModal()
    // console.log('inside update list===================')
    updateReviewSitesList()
  }
  return (
    <>
      {!(reviewSites && reviewSites.length) && (
        <IonButton onClick={openReviewModal} fill='outline' expand='full'>
          <IonIcon icon={add}></IonIcon>
          <IonLabel>Add A Review Site</IonLabel>
        </IonButton>
      )}
      {reviewSites && reviewSites.length > 0 && (
        <IonButton onClick={openReviewModal} fill='clear'>
          <IonIcon icon={add}></IonIcon>
          <IonLabel>Add A Review Site</IonLabel>
        </IonButton>
      )}
      <IonModal isOpen={reviewModalVisible}>
        <CommonHeader
          title={'Choose A Review Site'}
          closeButton={true}
          closeModal={closeReviewModal}
        ></CommonHeader>
        <IonContent className='regularBackground'>
          <div style={{ padding: '1rem' }}>
            <IonLabel style={{ color: 'grey', lineHeight: '1.5rem' }}>
              When a customer completes an appointment send them a text asking them to review your
              business online
            </IonLabel>

            <IonGrid
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                color: 'black',
                gap: '1rem',
                marginTop: '1rem',
              }}
            >
              {options.map((option, index) => {
                const isSelected = option.name === selectedReviewOption.name
                return (
                  <IonText
                    key={`reviewOption${index}`}
                    onClick={() => handleReviewOption(option)}
                    style={{
                      borderRadius: '1rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: isSelected ? '#e2e2e2' : 'white',
                      height: '8rem',
                      width: '47%',
                    }}
                  >
                    {option.custom ? (
                      <h3>{option.text}</h3>
                    ) : (
                      <IonImg
                        style={{
                          width: '80%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                        src={option.imagesrc}
                      ></IonImg>
                    )}
                  </IonText>
                )
              })}
            </IonGrid>
            {selectedReviewOption && (
              <SetupReview
                reviewSites={reviewSites}
                fromList={false}
                updateReviewSitesList={updateList}
                selectedReviewOption={selectedReviewOption}
                closeReviewModal={closeReviewModal}
                // setupReviewModalVisible={setupReviewModalVisible}
                // closeSetupReviewModal={closeSetupReviewModal}
              />
            )}
          </div>
        </IonContent>
      </IonModal>
    </>
  )
}
