import React, { useState, useEffect, useContext } from 'react';
import {Typography, Button, FormControl, InputLabel, MenuItem, Select, TextField, Grid, InputAdornment, OutlinedInput, Input} from '@mui/material';
import axios from 'axios'
import LocationsMultiSelect from './locationsMultiSelect';
import SubscriptionPackageMultiSelect from './subscriptionPackageMultiSelect'

export default function DetailsView({product, updateProduct, checkProduct, locations, subscriptionPackages, locationId}){

    let onValueChange = (field, value) => {
      console.log(field, value)
      updateProduct({...product, [field]: value})
    }

    if (!product) return "loading..."
    console.log(product)

    return (
        <>
        <Grid container spacing={8} padding={2}>
          <Grid item xs={12} md={6}>

        {/* <Typography variant="p">Details {product.firstName} {product.lastName}</Typography> */}
        
          <TextField
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={product.name}
            onChange={(e) => { onValueChange('name',e.target.value) }}
            style={{ flex: 1, marginRight: 10 }}
          />

          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            variant="standard"
            fullWidth
            value={product.description}
            onChange={(e) => { onValueChange('description',e.target.value) }}
            style={{ flex: 1 }}
          />

      <FormControl fullWidth variant="standard" style={{marginTop:20}}>
          <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>
          <Input
            id="standard-adornment-amount"
            value={product.price}
            onChange={(e) => { onValueChange('price',e.target.value) }}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            
          />
        </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocationsMultiSelect locations={locations} productLocations={product.locations} onValueChange={onValueChange} locationId={locationId}/>
        </Grid>
        </Grid>
        
        </>
    )
}


