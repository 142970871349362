import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonModal } from '@ionic/react'
import React from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { checkmark } from 'ionicons/icons'

export default function CustomerListModal({
  customersModalOpen,
  closeCustomersModal,
  chooseCustomer,
  customers,
  selectedCustomer,
}) {
  return (
    <IonModal isOpen={customersModalOpen} onDidDismiss={closeCustomersModal}>
      <CommonHeader
        closeButton={true}
        closeModal={closeCustomersModal}
        title={'Choose Customer'}
      ></CommonHeader>
      <IonContent className='regularBackground'>
        <StandardContainer>
          <IonList mode='ios' inset={true}>
            {customers?.map(row => (
              <IonItem onClick={() => chooseCustomer(row)} key={row._id}>
                <IonLabel>
                  <h4
                    style={{ fontWeight: '600', color: 'black', fontSize: 18 }}
                  >{`${row.firstName} ${row.lastName}`}</h4>
                  <p>{row.email}</p>
                </IonLabel>
                {selectedCustomer._id == row._id && <IonIcon icon={checkmark} />}
              </IonItem>
            ))}
          </IonList>{' '}
        </StandardContainer>
      </IonContent>
    </IonModal>
  )
}
