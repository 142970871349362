import { useEffect, useState } from 'react'
import { IonItem, IonLabel, IonList, IonReorder, IonReorderGroup, IonButton, IonIcon, IonPopover, IonRow, IonGrid, IonCol } from '@ionic/react';
import axios from 'axios'
import { ellipsisVertical } from 'ionicons/icons';

export default function ResultsList({ products = [] }) {
  const [productsList, setProductsList] = useState(products)
  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(undefined);
  const [reorderEnabled, setReorderEnabled] = useState(false);

  useEffect(() => {
    setProductsList(products)
  }, [products])
  const handleReorder = async event => {
    const fromIndex = event.detail.from
    const toIndex = event.detail.to

    // Update the order of the productsList array
    const updatedProducts = [...productsList]
    const [movedItem] = updatedProducts.splice(fromIndex, 1)
    updatedProducts.splice(toIndex, 0, movedItem)

    // Update the state with the new order
    const reorderedData = updatedProducts.map((item, index) => ({
      id: item._id,
      position: index + 1,
    }))
    // Finish the reorder
    const reorderInput = {
      products: reorderedData,
    }
    try {
      const response = await axios.patch('/product/changePositions', reorderInput)

      if (response && response.status === 200) {
        setProductsList(updatedProducts)
        event.detail.complete()
      }
    } catch (error) {
      event.detail.complete()
    }
    // Log or use the reordered data as needed
  }

  const openPopover = (e) => {
    e.persist();
    setPopoverEvent(e);
    setShowPopover(true);
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 20px' }}>
        <h6 style={{ color: 'grey' }}>Subscription Packages</h6>
        <IonButton fill="clear" onClick={openPopover} style={{ paddingTop: 4, marginRight: -20 }} color={'dark'}>
          <IonIcon icon={ellipsisVertical} />
        </IonButton>
      </div>
      <IonPopover
        isOpen={showPopover}
        event={popoverEvent}
        onDidDismiss={() => setShowPopover(false)}

      >
        <IonList>
          <IonItem button onClick={() => { setReorderEnabled(!reorderEnabled); setShowPopover(false); }}>{!reorderEnabled ? "Reorder Subscriptions" : "Done with Reorder"}</IonItem>
        </IonList>
      </IonPopover>
      {reorderEnabled ? <IonButton fill='clear' color={'danger'} style={{}} onClick={() => { setReorderEnabled(!reorderEnabled) }}>Done with Reorder</IonButton> : null}
      <IonList inset={true} mode='ios'>
        <IonReorderGroup disabled={!reorderEnabled} onIonItemReorder={handleReorder}>
          {products?.map(row => (
            <IonItem
              button
              detail={true}
              routerLink={`/core/products/${row._id}`}
              routerDirection='forward'
              key={row._id}
            >
              <IonReorder>

                <IonGrid style={{ padding: 0, margin: 0 }}>
                  <IonRow style={{ padding: 0, margin: 0 }}>
                    {reorderEnabled ?
                      <IonCol style={{ padding: 0, margin: 0 }} size='auto'>
                        <div style={{ height: 2, width: 20, backgroundColor: "grey", margin: 4, marginTop: 20, marginLeft: 4 }} />
                        <div style={{ height: 2, width: 20, backgroundColor: "grey", margin: 4 }} />
                        <div style={{ height: 2, width: 20, backgroundColor: "grey", margin: 4 }} />
                        <div style={{ height: 2, width: 20, backgroundColor: "transparent", marginLeft: 20 }} />
                      </IonCol>
                      : null}
                    <IonCol style={{ padding: 0, margin: 0 }}>
                      <IonLabel style={{ paddingTop: 8, paddingBottom: 8 }}>
                        <h4
                          style={{ fontWeight: '600', color: 'black', fontSize: 18 }}
                        >{`${row.name}`}</h4>
                        <p>${row.price}</p>
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonReorder>
            </IonItem>
          ))}
        </IonReorderGroup>
      </IonList>
    </>
  )
}
