import React, { useState, useEffect, useContext } from 'react'
import ResultsList from './resultsList'
import axios from 'axios'
import AddDialog from './addDialog'
import { AuthContext } from '../auth'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import StandardContainer from '../commonComponents/StandardContainer'

export default function SubscriptionPackageListView({ locationId }) {
  const { businessData } = useContext(AuthContext)
  const [subscriptionPackages, setSubscriptionPackages] = useState([])
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const getSubscriptionPackagesList = async () => {
    if (!businessData._id) return

    setLoading(true)
    setErrorMessage(null)
    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      let response = await axios.get(
        `/subscriptionPackage/getList?businessId=${businessData._id}${locQuery}`,
      )

      setSubscriptionPackages(response.data)
    } catch (error) {
      setErrorMessage('Sorry there was an issue')
    }
    setLoading(false)
  }
  useEffect(() => {
    getSubscriptionPackagesList()
  }, [businessData])
  useEffect(() => {
    getSubscriptionPackagesList()
  }, [])
  useIonViewWillEnter(() => {
    getSubscriptionPackagesList()
  })
  async function handleRefresh(event) {
    await getSubscriptionPackagesList()
    event.detail.complete()
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonTitle>Subscription Packages</IonTitle>
          <IonButtons slot='end'>
            {/* <IonButton onClick={openAddSubscriptionPackageDialog}>
              <IonIcon slot='icon-only' icon={addCircle} color='primary' />
            </IonButton> */}
            <IonButton routerLink='/core/subscription/create'>
              <IonIcon slot='icon-only' icon={addCircle} color='primary' />
            </IonButton>
          </IonButtons>

          {loading ? <IonProgressBar type='indeterminate' /> : null}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <StandardContainer>
          {errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : null}
          <ResultsList
            getSubscriptionPackagesList={getSubscriptionPackagesList}
            subscriptionPackages={subscriptionPackages}
            locationId={locationId}
          />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
