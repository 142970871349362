import { Grid, TextField } from '@mui/material'
import PhoneNumberTextField from '../commonComponents/phoneNumberTextField'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react'

export default function EditPaneModal({
  customer,
  onValueChange,
  emailValidationMessage,
  editPaneOpen,
  closeEditPane,
  save,
}) {
  const handleSave = () => {
    //saving the data and closing the popup
    save()
    setTimeout(() => {
      closeEditPane()
    }, 200)
  }
  return (
    <IonModal isOpen={editPaneOpen}>
      <IonHeader>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonTitle>Update Customer Details</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => closeEditPane()}>Close</IonButton>
            {!emailValidationMessage && (
              <IonButton color={'primary'} onClick={() => handleSave()}>
                Save
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='regularBackground'>
        <Grid container padding={2} spacing={8}>
          <Grid item sm={12} md={8}>
            <div style={{ flex: 1, flexDirection: 'row', width: '100%' }}>
              <TextField
                margin='dense'
                id='firstName'
                label='First Name'
                type='text'
                variant='standard'
                value={customer.firstName}
                onChange={e => {
                  onValueChange('firstName', e.target.value)
                }}
                style={{ flex: 1, marginRight: 10 }}
              />

              <TextField
                margin='dense'
                id='lastName'
                label='Last Name'
                type='text'
                variant='standard'
                value={customer.lastName}
                onChange={e => {
                  onValueChange('lastName', e.target.value)
                }}
                style={{ flex: 1 }}
              />
            </div>
            {}
            <TextField
              error={emailValidationMessage}
              helperText={emailValidationMessage ? emailValidationMessage : ' '}
              margin='dense'
              id='email'
              label='Email'
              type='text'
              fullWidth
              variant='standard'
              value={customer.email}
              onChange={e => {
                onValueChange('email', e.target.value)
              }}
              style={{ marginTop: 20 }}
            />
            {/* <p style={{ marginBottom: -20 }}>{customer.email}</p> */}
            <PhoneNumberTextField
              value={customer.phoneNumber}
              onChange={e => {
                onValueChange('phoneNumber', e.target.value)
              }}
            />
          </Grid>
        </Grid>
      </IonContent>
    </IonModal>
  )
}
