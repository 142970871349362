import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonInput,
  IonModal,
  IonRow,
  IonSpinner,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import commonStyles from '../../theme/commonstyles.module.css'
import spacingStyles from '../../theme/spacing.module.css'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/StandardContainer'
import axios from 'axios'
export default function AddCashTip({ appointment, refreshAppointment }) {
  const [cashTipModalOpen, setCashTipModalOpen] = useState(false)
  const [tip, setTip] = useState(appointment?.payment?.cashTipAmount)
  const [updatingTip, setUpdatingTip] = useState(false)
  const openCashTipModal = () => {
    setCashTipModalOpen(true)
  }
  const closeCashTipModal = () => {
    setCashTipModalOpen(false)
  }
  const handleTip = value => {
    setTip(value)
  }
  useEffect(() => {
    setTip(
      appointment?.payment?.cashTipAmount !== undefined &&
        appointment?.payment?.cashTipAmount !== null &&
        appointment?.payment?.cashTipAmount !== 0
        ? appointment?.payment?.cashTipAmount
        : null,
    )
  }, [appointment, cashTipModalOpen])

  const handleAddUpdateTip = async () => {
    setUpdatingTip(true)
    const totalPrice =
      parseFloat(appointment?.payment?.servicesTotal ?? 0) +
      parseFloat(appointment?.payment?.tipAmount ?? 0) +
      parseFloat(appointment?.payment?.taxes ?? 0) +
      parseFloat(appointment?.payment?.productsTotal ?? 0) -
      parseFloat(appointment?.payment?.discountsTotal ?? 0) +
      parseFloat(tip ?? 0)

    const input = {
      payment: {
        ...appointment.payment,
        cashTipAmount: parseFloat(tip),
        totalPrice: parseFloat(totalPrice),
      },
    }

    try {
      const addOrUpdateTipResponse = await axios.patch(`/appointment?id=${appointment?._id}`, input)

      if (addOrUpdateTipResponse?.status === 200) {
        setCashTipModalOpen(false)
        refreshAppointment()
      }
      setUpdatingTip(false)
    } catch (error) {
      setUpdatingTip(false)
    }
  }
  return (
    <>
      <IonRow
        className={`${spacingStyles.w100} ${spacingStyles.pb4} ${spacingStyles.pr2} ${commonStyles.displayFlex} ${commonStyles.justifyEnd} ${commonStyles.alignEnd}`}
      >
        <IonButton fill='clear' onClick={openCashTipModal}>
          {appointment?.payment?.cashTipAmount ? 'Update Cash Tip' : 'Add Cash Tip'}
        </IonButton>
      </IonRow>
      <IonModal isOpen={cashTipModalOpen} onDidDismiss={closeCashTipModal}>
        <CommonHeader
          title={appointment?.payment?.cashTipAmount ? 'Update Cash Tip' : 'Add Cash Tip'}
          closeButton={true}
          closeModal={closeCashTipModal}
        ></CommonHeader>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <IonCard className={spacingStyles.p4}>
              <h5>Cash Tip Amount</h5>
              <IonRow style={{ margin: 0, padding: 0, border: ' 1px solid #d6d6d6', borderRadius: '5px' }}>
                <IonCol size='auto' style={{ paddingTop: 15, margin: 0, marginLeft: 6, color: 'grey' }}>
                  $
                </IonCol>
                <IonCol >
                  <IonInput
                    value={tip}
                    onIonChange={e => handleTip(e.detail.value)}
                    label='Outline input'
                    labelPlacement='floating'
                    fill='outline'
                    placeholder="0.00"
                    inputmode="decimal"
                  ></IonInput>
                </IonCol>
              </IonRow>

              <div className={spacingStyles.mt4}>
                <IonButton onClick={handleAddUpdateTip} expand='block'>
                  {updatingTip && <IonSpinner name='lines' />}
                  Done
                </IonButton>
                <IonButton onClick={closeCashTipModal} expand='block' fill='clear'>
                  Cancel
                </IonButton>
              </div>
            </IonCard>
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}
