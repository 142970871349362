import {
  IonCheckbox,
  IonCol,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonToggle,
} from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { limitDecimalPlaces } from '../utils'
import { AuthContext } from '../auth'
import axios from 'axios'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'

export default function ChargeCustomer({ type, title }) {
  const chargesType = type == 'cancel' ? 'cancellationSettings' : 'noShowSettings'

  const { businessData, locationId, locationData, getBusinessData } = useContext(AuthContext)
  const [chargeType, setChargeType] = useState('percentage')
  const [chargeValue, setChargeValue] = useState(false)
  const [error, setError] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [chargesChanged, setChargesChanged] = useState(false)
  const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)
  const [chargeCustomer, setChargeCustomer] = useState(false)
  const appointmentSettings = selectedLocationData?.appointmentSettings?.[chargesType]

  const handleChargeType = value => {
    setChargesChanged(true)
    setChargeType(value)
    if (value === 'percentage' && chargeValue > 100) {
      setError('Percentage charge value cannot be more than 100')
    } else {
      setError('')
    }
  }
  const handleChargeValue = value => {
    setChargesChanged(true)

    // Validate if the value is a positive number
    if (!/^\d*\.?\d*$/.test(value) || parseFloat(value) < 0) {
      setError('Please enter a valid positive number')
      setChargeValue(value) // Keep the previous value if the new one is invalid
      return
    }

    // Check if the charge type is percentage and value exceeds 100
    if (chargeType === 'percentage' && parseFloat(value) > 100) {
      setError('Percentage charge value cannot be more than 100')
      setChargeValue(value) // Keep the previous value if the new one exceeds 100
      return
    }

    // Clear any existing error message
    setError('')
    // Update the charge value state
    setChargeValue(value)
  }

  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
    setChargeType('percentage')
    setChargeValue('')
    setError('')
    setLoading(false)
  }

  const toggleChargeCustomer = value => {
    setChargeCustomer(value)
    setChargesChanged(true)
  }
  const handleSave = async () => {
    setLoading(true)
    try {
      const input = {
        businessId: businessData?._id,
        appointmentSettings: {
          ...selectedLocationData?.appointmentSettings,
          [chargesType]: {
            charge: chargeCustomer,
            type: chargeType,
            value: parseFloat(chargeValue),
          },
        },
      }
      const response = await axios.patch(`/location?id=${locationId}`, input)
      if (response.status === 200) {
        setLoading(false)
        getBusinessData()
        closeModal()
      }
    } catch (error) {}
  }
  useEffect(() => {
    let formattedValue = limitDecimalPlaces(chargeValue, 2)
    setChargeValue(formattedValue)
  }, [chargeValue])
  useEffect(() => {
    setChargeType(appointmentSettings?.type)
    setChargeValue(appointmentSettings?.value)
    setChargeCustomer(appointmentSettings?.charge)
  }, [modalOpen])

  return (
    <>
      <IonList inset={true} mode='ios'>
        <IonItem onClick={openModal} detail={true}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IonLabel>{title}</IonLabel>
            <IonLabel style={{ marginLeft: '0.5rem', fontSize: '12px', color: 'grey' }}>
              {appointmentSettings?.charge
                ? `(${appointmentSettings?.type} - ${
                    appointmentSettings?.type === 'fixed' ? '$' : ''
                  }${appointmentSettings?.value}${
                    appointmentSettings?.type === 'percentage' ? '%' : ''
                  })`
                : 'Disabled'}
            </IonLabel>
          </div>
        </IonItem>
      </IonList>
      <IonModal isOpen={modalOpen} onDidDismiss={closeModal}>
        <CommonHeader
          title={title}
          closeButton={true}
          saveButton={true}
          loading={loading}
          handleSaveButton={handleSave}
          saveDisabled={!chargesChanged || error != '' || !chargeValue || !chargeType}
          closeModal={closeModal}
        ></CommonHeader>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
              <IonItem>
                <IonCol size='9'>
                  <IonLabel>Charge</IonLabel>
                </IonCol>
                <IonCol size='3' style={{ display: 'flex', justifyContent: 'end' }}>
                  <IonToggle
                    justify='space-between'
                    size='small'
                    checked={chargeCustomer}
                    onIonChange={e => {
                      toggleChargeCustomer(e.detail.checked)
                    }}
                  />
                </IonCol>
              </IonItem>
            </IonList>

            {chargeCustomer && (
              <>
                <div
                  style={{
                    marginTop: '1rem',
                  }}
                >
                  <IonLabel
                    style={{
                      marginLeft: '1rem',
                      fontWeight: '600',
                      marginBottom: -20,
                      color: 'grey',
                    }}
                  >
                    Type
                  </IonLabel>
                  <IonList inset='true'>
                    <IonItem
                      onClick={() =>
                        handleChargeType(chargeType === 'percentage' ? 'fixed' : 'percentage')
                      }
                    >
                      <IonCheckbox
                        slot='start'
                        checked={chargeType === 'percentage'}
                        onIonChange={() =>
                          handleChargeType(chargeType === 'percentage' ? 'fixed' : 'percentage')
                        }
                      ></IonCheckbox>
                      <IonLabel>Percentage Charge</IonLabel>
                    </IonItem>
                    <IonItem
                      onClick={() =>
                        handleChargeType(chargeType === 'fixed' ? 'percentage' : 'fixed')
                      }
                    >
                      <IonCheckbox
                        slot='start'
                        checked={chargeType === 'fixed'}
                        onIonChange={() =>
                          handleChargeType(chargeType === 'fixed' ? 'percentage' : 'fixed')
                        }
                      ></IonCheckbox>
                      <IonLabel>Fixed Price Charge</IonLabel>
                    </IonItem>
                  </IonList>
                </div>
                <IonList inset='true'>
                  {chargeType === 'percentage' ? (
                    <IonItem>
                      <IonLabel position='stacked'>Percentage</IonLabel>
                      <IonRow style={{ margin: 0, padding: 0 }}>
                        <IonCol>
                          <IonInput
                            type='number'
                            min={0}
                            value={chargeValue}
                            onIonChange={e => handleChargeValue(e.detail.value)}
                            placeholder='0'
                            inputmode='decimal'
                          />
                        </IonCol>
                        <IonCol
                          size='auto'
                          style={{ paddingTop: 13, margin: 0, marginLeft: -5, color: 'grey' }}
                        >
                          %
                        </IonCol>
                      </IonRow>
                    </IonItem>
                  ) : (
                    <IonItem>
                      <IonLabel position='stacked'>Charge Amount</IonLabel>
                      <IonRow style={{ margin: 0, padding: 0 }}>
                        <IonCol
                          size='auto'
                          style={{ paddingTop: 13, margin: 0, marginLeft: -5, color: 'grey' }}
                        >
                          $
                        </IonCol>
                        <IonCol>
                          <IonInput
                            type='number'
                            value={chargeValue}
                            onIonChange={e => handleChargeValue(e.detail.value)}
                            placeholder='0.00'
                            min={0}
                            inputmode='decimal'
                          />
                        </IonCol>
                      </IonRow>
                    </IonItem>
                  )}
                </IonList>
                <IonLabel style={{ margin: '1rem', color: 'red', fontSize: '12px' }}>
                  {error}
                </IonLabel>
              </>
            )}
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}
