import {
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonActionSheet,
  IonButton,
  IonImg,
  IonSpinner,
} from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import axios from 'axios'
import { AuthContext } from '../auth'
import { dataURLtoBlob } from '../utils'

const BusinessDetails = ({ match }) => {
  const businessId = match?.params?.id
  const { getBusinessData, businessData } = useContext(AuthContext)
  const [loadingLogoImage, setLoadingLogoImage] = useState(false)
  const [actionSheetOpen, setActionSheetOpen] = useState(false)
  const [businessName, setBusinessName] = useState(businessData?.name || '')
  const [businessImage, setBusinessImage] = useState('')
  const [dataChanged, setDataChanged] = useState(false)
  const [updatingBusiness, setUpdatingBusiness] = useState(false)
  const [termsAndConditionsLink, setTermsAndConditionsLink] = useState(
    businessData?.termsAndConditionsLink || '',
  )
  const handleLogoImageClick = () => {
    setActionSheetOpen(true)
  }

  const handleBusinessName = event => {
    setBusinessName(event.detail.value)
    setDataChanged(true)
  }
  const handleTermsLink = event => {
    setTermsAndConditionsLink(event.detail.value)
    setDataChanged(true)
  }
  const uploadBusinessImage = async image => {
    setLoadingLogoImage(true)
    try {
      const businessImage = dataURLtoBlob(image)
      let data = new FormData()
      data.append('id', businessData?._id)
      data.append('selectedFile', businessImage)
      const response = await axios.post('/business/logo', data)
      if (response?.data?.success === 'success') {
        setLoadingLogoImage(false)
        setBusinessImage(image)
      }
    } catch (error) {
      setLoadingLogoImage(false)
      console.error('Error adding image to gallery:', error)
    }
  }
  const handleActionSheetSelect = async option => {
    setActionSheetOpen(false)
    if (option === 'Take Photo') {
      try {
        const photo = await Camera.getPhoto({
          resultType: CameraResultType.DataUrl,
          source: CameraSource.Camera,
        })
        uploadBusinessImage(photo.dataUrl)
      } catch (error) {
        console.error('Error taking photo:', error)
      }
    } else if (option === 'Choose from Gallery') {
      try {
        const photo = await Camera.getPhoto({
          resultType: CameraResultType.DataUrl,
          source: CameraSource.Photos,
        })
        uploadBusinessImage(photo.dataUrl)
      } catch (error) {
        console.error('Error choosing from gallery:', error)
      }
    }
  }

  const handleUpdateBusiness = async () => {
    setUpdatingBusiness(true)
    const businessInpt = {
      name: businessName,
      termsAndConditionsLink: termsAndConditionsLink,
      // image: businessImage,
    }
    try {
      const updateBusinessResponse = await axios.patch(`/business?id=${businessId}`, businessInpt)
      if (updateBusinessResponse && updateBusinessResponse.status === 200) {
        getBusinessData()
        setDataChanged(false)
        setUpdatingBusiness(false)
      }
    } catch (error) {
      setUpdatingBusiness(false)
    }
  }

  const getBusinessLogo = async () => {
    setLoadingLogoImage(true)
    try {
      const businessLogoResponse = await axios.get(`/business/logo?id=${businessData?._id}`)
      if (businessLogoResponse?.status === 200) {
        setBusinessImage(businessLogoResponse.data.data)
        setLoadingLogoImage(false)
      }
    } catch (error) {
      setLoadingLogoImage(false)
    }
  }

  useEffect(() => {
    setBusinessName(businessData?.name || '')
    getBusinessLogo()
    setTermsAndConditionsLink(businessData?.termsAndConditionsLink || '')
  }, [businessData])

  return (
    <IonPage id='main-content'>
      <CommonHeader title={'Business Details'} backIcon={true}></CommonHeader>

      <IonContent>
        <StandardContainer>
          <IonList inset={true} mode='ios'>
            <IonItem>
              <IonLabel position='stacked'>Business Name</IonLabel>
              <IonInput
                value={businessName}
                onIonChange={handleBusinessName}
                type='text'
                placeholder='Enter the business name'
              ></IonInput>
            </IonItem>
          </IonList>

          <IonList inset={true} mode='ios'>
            <IonItem>
              <IonLabel position='stacked'>Terms And Conditions Link</IonLabel>
              <IonInput
                value={termsAndConditionsLink}
                onIonChange={handleTermsLink}
                type='text'
                placeholder='Enter the business T&C Link'
              ></IonInput>
            </IonItem>
          </IonList>

          <IonList inset={true} mode='ios'>
            <IonItem>
              <IonLabel style={{ display: 'flex', alignItems: 'center' }} position='stacked'>
                Business Logo
                {loadingLogoImage && <IonSpinner style={{ marginLeft: '1rem' }} name='lines' />}
              </IonLabel>
              <div
                onClick={handleLogoImageClick}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '1rem',
                  cursor: 'pointer',
                }}
              >
                <div style={{ width: '100%' }}>
                  {businessImage ? (
                    <IonImg
                      src={businessImage}
                      alt='Logo Preview'
                      style={{ width: '100%', height: '15rem', maxHeight: '15rem' }}
                    />
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: '15rem',
                        backgroundColor: '#c5c5c5',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <p>Click to add logo</p>
                    </div>
                  )}
                </div>
              </div>
            </IonItem>
          </IonList>
          {dataChanged && (
            <div style={{ margin: '1rem' }}>
              <IonButton onClick={handleUpdateBusiness} disabled={!dataChanged} expand='full'>
                {updatingBusiness && <IonSpinner style={{ marginRight: '1rem' }} name='lines' />}
                Update Business
              </IonButton>
            </div>
          )}

          <IonActionSheet
            isOpen={actionSheetOpen}
            onDidDismiss={() => setActionSheetOpen(false)}
            buttons={[
              {
                text: 'Take Photo',
                handler: () => handleActionSheetSelect('Take Photo'),
              },
              {
                text: 'Choose from Gallery',
                handler: () => handleActionSheetSelect('Choose from Gallery'),
              },
              {
                text: 'Cancel',
                role: 'cancel',
              },
            ]}
          ></IonActionSheet>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

export default BusinessDetails
