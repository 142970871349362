// Import necessary modules and utilities
import Chart from 'react-apexcharts'
import axios from 'axios'
import { getMonthRange } from '../Utils/GetDates'
import Picker from '../commonComponents/Picker'
import GetPickerMonths from '../Utils/GetPickerMonths'
import { GetXAxisMonths } from '../Utils/GetXAxisMonths'
import {
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonSpinner,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { chevronForward } from 'ionicons/icons'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { cancelSubscriptionOptions } from '../StaticData/StaticData'
import { getAllDaysInCurrentMonth, getDaysInCurrentMonth } from '../utils'
export default function MembershipsLineChart({ refId }) {
  // Initialize state variables and constants
  let initialNumOfMonths = 6
  const [selectedOption, setSelectedOption] = useState(initialNumOfMonths - 1)
  const pickerItems = GetPickerMonths()
  const [loading, setLoading] = useState(false)
  const [reasons, setReasons] = useState([])
  const [feedbackPopupOpen, setFeedbackPopupOpen] = useState(false)
  const [selectedreasonForPopup, setSelectedReasonForPopup] = useState(false)
  const [loadingCancelations, setLoadingCancelations] = useState(false)
  const [averageCount, setAverageCount] = useState(false)
  const [data, setData] = useState(false)

  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 250,
      stacked: true,
      toolbar: false,
    },
    xaxis: {
      categories: [],
      tickAmount: 5,
    },
    colors: ['#ffac33'], // Add your custom colors here

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '90%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    grid: {
      borderColor: '#f1f1f1',
    },
    yaxis: {
      title: {
        text: 'Appointments',
      },
    },
    toolbar: {
      show: false,
      tools: {
        download: false,
      },
    },
    export: {
      show: false,
    },

    legend: {
      show: true,
      offsetY: -10,
    },
  })
  const [series, setSeries] = useState([])
  const getReasons = async (fromDate, toDate) => {
    setLoading(true)
    try {
      const url = `/subscription/reports/cancelations/reasons?fromDate=${fromDate}&toDate=${toDate}`

      let response = await axios.get(url)

      if (response && response.data) {
        setReasons(response?.data?.reasons)
      }
      setLoading(false)
    } catch (error) {}
  }

  // Function to fetch data based on reference ID, start date, end date, and number of months
  const getCancellationData = async (startDate, endDate, noOfMonths) => {
    try {
      const url = `/subscription/reports/cancelations/trends?fromDate=${startDate}&toDate=${endDate}`

      // Make API call to get report data
      const response = await axios.get(url)

      // Set loading state to false and process response data if status is 200
      setLoadingCancelations(false)
      if (response.status === 200) {
        const data = response.data
        const daysInCurrentMonth = getDaysInCurrentMonth()

        const categoriesResponse = GetXAxisMonths(noOfMonths)

        const months = categoriesResponse.monthNumbers
        // Create series data from the provided API data
        const series = [{ data: [] }]

        categoriesResponse.monthNumbers.forEach(() => {
          series[0].data =
            noOfMonths === 0 || noOfMonths === 1
              ? Array(daysInCurrentMonth).fill(0)
              : Array(months.length).fill(0)
        })

        // Update series data with fetched API data
        if (data) {
          const averageCount =
            noOfMonths === 0 || noOfMonths === 1
              ? response?.data?.totalCount
              : response && response.data && response.data.totalCount
              ? response.data.totalCount / parseInt(noOfMonths)
              : false

          data.months.forEach(item => {
            if (noOfMonths === 0 || noOfMonths === 1) {
              const dayIndex = item.day - 1
              series[0].data[dayIndex] = item.count
            } else {
              const paddedMonth = String(item.month).padStart(2, '0') // Padding the month number
              const lastTwoDigitsOfYear = item.year % 100
              const monthYear = paddedMonth + '-' + lastTwoDigitsOfYear

              const index = months.findIndex(month => month === monthYear)

              if (index !== -1) {
                series[0].data[index] = item.count
              }
            }
          })
          setAverageCount(averageCount.toFixed(2))
        }
        setSeries(series)
        setOptions(prevOptions => ({
          ...prevOptions,
          yaxis: {
            tickAmount: 4, // This specifies the number of ticks on the y-axis
            forceNiceScale: true, // This ensures the y-axis scale adheres to tickAmount
          },
          xaxis: {
            ...prevOptions.xaxis,
            categories:
              noOfMonths === 0 || noOfMonths === 1
                ? getAllDaysInCurrentMonth(noOfMonths)
                : categoriesResponse.categories,
          },
        }))
        setData(response.data)
      }
    } catch (error) {
      // Handle errors if any
    }
  }

  // Function to handle the selected index change
  const handleSelectedIndex = indexValue => {
    const data = getMonthRange(indexValue)
    const selectedValue = pickerItems.find((item, index) => index === indexValue)
    if (selectedOption !== selectedValue.text) {
      setLoadingCancelations(true)
      setSelectedOption(selectedValue.text)
      getReasons(data.startDate, data.endDate)
      getCancellationData(data.startDate, data.endDate, indexValue)
    }
  }
  const openFeedbackPopup = item => {
    setFeedbackPopupOpen(true)
    setSelectedReasonForPopup(item)
  }

  const closeFeedbackPopup = () => {
    setFeedbackPopupOpen(false)
    setSelectedReasonForPopup(false)
  }

  // Initial data fetching on component mount
  useEffect(() => {
    const data = getMonthRange(initialNumOfMonths)
    getReasons(data.startDate, data.endDate)

    getCancellationData(data.startDate, data.endDate, initialNumOfMonths)
  }, [])

  return (
    <IonPage>
      <CommonHeader title={'Membership Cancellations'} backIcon={true} />
      <IonContent>
        <StandardContainer>
          <IonCard className='flat' style={{ padding: '1rem' }}>
            <h4 style={{ color: 'black', marginTop: 0 }}>Membership Cancelations</h4>
            <IonGrid style={{ margin: 0, padding: 0 }}>
              <IonRow>
                <IonCol size='8'>
                  <Picker
                    options={pickerItems}
                    selectedItemIndex={initialNumOfMonths}
                    selectedItem={selectedOption}
                    handleSelectedIndex={handleSelectedIndex}
                  />
                </IonCol>
                <IonCol style={{ display: 'flex', justifyContent: 'end', margin: 0, padding: 0 }}>
                  {averageCount && (
                    <IonCol
                      size='4'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <IonRow>
                        <IonLabel
                          style={{ fontWeight: 'bold', fontSize: '1.3rem', color: '#363535' }}
                        >
                          {averageCount}
                        </IonLabel>
                      </IonRow>
                      <IonRow>
                        <IonLabel style={{ color: '#b6b6b6' }}>Average</IonLabel>
                      </IonRow>
                    </IonCol>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
            {loadingCancelations && <IonSpinner name='dots' />}
            {!loadingCancelations && data && (
              <>
                <Chart options={options} series={series} type='bar' height={200} />
              </>
            )}
            {!loading && (
              <div style={{ marginTop: '2rem' }}>
                <h6 style={{ color: 'black' }}>Reasons</h6>
                {reasons.map((item, index) => {
                  const reasonItem = cancelSubscriptionOptions.find(
                    (subscriptionItem, subscriptionIndex) => subscriptionItem.value === item.reason,
                  )
                  //
                  return (
                    <IonGrid>
                      <IonRow style={{ color: 'black' }}>
                        <IonCol>
                          <IonLabel style={{ fontWeight: 'bold' }}>
                            {reasonItem ? reasonItem.label : item.reason}
                          </IonLabel>
                        </IonCol>
                        <IonCol size='auto'>
                          <IonLabel>{item.count}</IonLabel>
                        </IonCol>
                        <IonCol style={{ display: 'flex', justifyContent: 'end' }} size='3'>
                          <IonLabel>
                            {item?.percentage?.toFixed(1)}
                            {' %'}
                          </IonLabel>
                        </IonCol>
                        {item.writtenFeedback && (
                          <IonRow
                            onClick={() => openFeedbackPopup(item)}
                            color='primary'
                            style={{
                              margin: '0.5rem 0',
                              width: ' 100%',
                              display: 'flex',
                              justifyContent: 'end',
                              alignItems: 'center',
                            }}
                          >
                            <IonLabel style={{ cursor: 'pointer' }} color={'primary'}>
                              View Written Feedback
                            </IonLabel>
                            <IonIcon color={'primary'} icon={chevronForward} />
                          </IonRow>
                        )}
                      </IonRow>
                    </IonGrid>
                  )
                })}
                <IonModal isOpen={feedbackPopupOpen}>
                  <CommonHeader
                    title={'Written Feedback'}
                    closeButton={true}
                    closeModal={closeFeedbackPopup}
                  ></CommonHeader>
                  <IonContent className='regularBackground'>
                    <StandardContainer>
                      <IonList inset={true} mode='ios'>
                        {selectedreasonForPopup?.writtenFeedback?.map((item, index) => {
                          return (
                            <IonItem>
                              <IonLabel>
                                {index + 1}.{item}
                              </IonLabel>
                            </IonItem>
                          )
                        })}
                      </IonList>
                    </StandardContainer>
                  </IonContent>
                </IonModal>
              </div>
            )}
          </IonCard>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
