import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { IonItem, IonLabel, IonList, IonSpinner } from '@ionic/react'
import { AuthContext } from '../auth'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'

function StatsBlock({ employee, startDate, endDate, viewMode, shouldLoad }) {
  const [stats, setStats] = useState()
  const [loading, setLoading] = useState(true)
  const { businessData, locationId, userData } = useContext(AuthContext)
  const [appointmentStats, setAppointmentStats] = useState(false)
  const [serviceValueStats, setServiceValueStatsData] = useState({ loading: false, data: null })
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)
  const formatDate = date => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }
  async function getStats() {
    try {
      setLoading(true)
      if (startDate && endDate && shouldLoad) {
        let start = formatDate(startDate)
        let end = formatDate(endDate)
        let r = await axios.get(
          `/employee/barberStats?barberId=${employee._id}&startDate=${start}&endDate=${end}`,
        )

        setStats(r.data)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  // Convert minutes to days, hours, and rounded minutes
  const convertToDaysHoursMinutes = minutes => {
    const days = Math.floor(minutes / (24 * 60))
    const remainingHours = Math.floor((minutes % (24 * 60)) / 60)
    const remainingMinutes = Math.round(minutes % 60)

    let result = ''

    if (days > 0) {
      result += `${days}d `
    }

    if (remainingHours > 0) {
      result += `${remainingHours}h `
    }

    if (remainingMinutes > 0) {
      result += `${remainingMinutes}min`
    }

    return result.trim()
  }
  const getBarberStats = async () => {
    if (businessData?._id && employee?._id && startDate && endDate && shouldLoad) {
      try {
        let start = formatDate(startDate)
        let end = formatDate(endDate)
        const statsResponse = await axios.get(
          `/employee/appointmentStats?businessId=${businessData?._id}&barberId=${employee?._id}&startDate=${start}&endDate=${end}`,
        )
        if (statsResponse && statsResponse.status === 200) {
          setAppointmentStats(statsResponse?.data)
        }
      } catch (error) { }

    }
  }

  const getEmployeeServiceValueStats = async () => {
    if (businessData?._id && employee?._id && startDate && endDate && shouldLoad) {
      setServiceValueStatsData({ loading: true, data: null })
      let start = formatDate(startDate)
      let end = formatDate(endDate)
      try {
        const response = await axios.get(
          `/employee/serviceValueStats?businessId=${businessData?._id}&barberId=${employee?._id}&startDate=${start}&endDate=${end}`,
        )
        if (response.status == 200) {
          setServiceValueStatsData({ loading: false, data: response.data })
        }
        console.log(response.data, 'responseresponseresponseresponse')
      } catch (error) {
        setServiceValueStatsData({ loading: false, data: null, error: error })
      }
    }

  }

  useEffect(() => {
    getStats()
    getBarberStats()
    getEmployeeServiceValueStats()
  }, [viewMode, startDate, endDate, shouldLoad])

  const statistics = [
    { label: 'Total # of Services', value: 'countAppointments' },
    { label: 'Value of Services', value: 'totalValueOfServices', prefix:"$" },
    { label: 'Value of Products', value: 'totalValueofProducts', prefix:"$" },
    { label: 'Tips (Credit Card)', value: 'totalCardTips', prefix:"$" },
    { label: 'Tips (Cash)', value: 'totalCashTips', prefix:"$" },
    { label: 'Total Tips', value: 'totalTips', prefix:"$" },
    { label: 'Subscriptions Sold', value: 'countSubscriptionsSold' },
  ]

  const additionalStatistics = [
    { label: 'Avg Wait Time', value: 'averageWaitTime' },
    { label: 'Avg Late End Time', value: 'avgLateEndTime' },
    { label: 'Avg Service Time Min', value: 'avgServiceTimeMin' },
    { label: 'Median Service Time Min', value: 'medianServiceTime' },
    {
      label: 'Median Expected Time Difference',
      value: 'medianExpectedTimeDifference',
    },
  ]
  const serviceValueStatsArr = [
    { label: 'Service Value for a Subscriber', value: 'SVSub' },
    { label: 'Service Value for a Non-Subscriber', value: 'SVNonSub' },
    { label: 'Blended Service Value', value: 'BlendedSV' },
  ]
  return (
    <>
      <IonList inset={true} mode='ios'>
        {(userEntitlements?.isAdmin ||
          userEntitlements?.isProfessional ||
          userEntitlements?.isManager) && (
            <>
              {statistics.map(statistic => {
                return (
                  <IonItem key={statistic.value}>
                    <IonLabel>
                      <strong>{statistic.label}</strong>
                    </IonLabel>
                    <IonLabel slot='end'>
                      {loading ? (
                        <IonSpinner name='dots' style={{ marginTop: -9, marginBottom: -9 }} />
                      ) : <>

                        {statistic?.prefix ? statistic?.prefix : null}
                        {stats?.[statistic?.value] || 0}
                        </>}
                    </IonLabel>
                  </IonItem>
                )
              })}
            </>
          )}

        {userEntitlements?.isAdmin
          ? additionalStatistics.map(statistic => {
            const statValue = appointmentStats?.[statistic.value]?.toFixed(2)
            return (
              <IonItem key={statistic.value}>
                <IonLabel>
                  <strong>{statistic.label}</strong>
                </IonLabel>
                <IonLabel slot='end'>
                  {loading ? (
                    <IonSpinner name='dots' style={{ marginTop: -9, marginBottom: -9 }} />
                  ) : statValue > 60 ? (
                    convertToDaysHoursMinutes(statValue) || 0
                  ) : (
                    statValue || 0
                  )}
                </IonLabel>
              </IonItem>
            )
          })
          : null}
        {userEntitlements?.isAdmin
          ? serviceValueStatsArr.map(statistic => {
            const statValue = serviceValueStats?.data?.[statistic.value]?.toFixed(2)
            return (
              <IonItem key={serviceValueStats.value}>
                <IonLabel>
                  <strong>{statistic.label}</strong>
                </IonLabel>
                <IonLabel slot='end'>
                  {serviceValueStats.loading ? (
                    <IonSpinner name='dots' style={{ marginTop: -9, marginBottom: -9 }} />
                  ) : <>
                  ${statValue || 0}
                  </>}
                </IonLabel>
              </IonItem>
            )
          })
          : null}
      </IonList>
    </>
  )
}
export default StatsBlock
