import React, { useState, useEffect, useContext } from 'react'
import {
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
} from '@mui/material'
import axios from 'axios'
import StandardHoursTable from './StandardHoursTable'
import ScheduledDaysInAdvance from './ScheduledDaysInAdvance'
import ModifiedSchedule from './modifiedSchedule'
import AddNewSchedule from './addNewSchedule'
import { IonCard } from '@ionic/react'
import StandardContainer from '../../commonComponents/StandardContainer'
import Breaks from './breaks'

export default function DetailsView({ employee, updateEmployee, locationId, businessId }) {
  function updateStandardSchedule(value) {
    updateEmployee({ ...employee, ...{ standardSchedule: value } })
  }

  function updateLunchTime(value) {
    updateEmployee({ ...employee, ...{ lunchTime: value } })
  }

  if (!employee) return 'loading...'

  return (
    <StandardContainer>
      <IonCard style={{ padding: 20 }}>
        <StandardHoursTable
          standardSchedule={employee.standardSchedule}
          updateStandardSchedule={updateStandardSchedule}
        />
      </IonCard>
      {/* <Grid item xs={12} md={6}style={{flexDirection:'row', padding:20}}>
                <DaysOff/>
                <br></br>
                <hr></hr>
                <br></br>
                <LunchTime/>
                <br></br>
                <hr></hr>
                <br></br>
                <ScheduledDaysInAdvance/>
            </Grid> */}
      <IonCard style={{ padding: 20, width: '100%' }}>
        {console.log(employee, 'employeeemployee')}
        <ModifiedSchedule
          standardSchedule={employee.standardSchedule}
          employeeId={employee._id}
          businessId={businessId}
          locationId={locationId}
        />
        <br></br>
        <br></br>
      </IonCard>

      <IonCard style={{ padding: 20, width: '100%' }}>
        <Breaks
          standardSchedule={employee.standardSchedule}
          employeeId={employee._id}
          businessId={businessId}
          locationId={locationId}
        />
        <br></br>
        <br></br>
      </IonCard>
    </StandardContainer>
  )
}
