import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonToggle,
} from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import ChooseReview from './ChooseReview'
import CommonHeader from '../commonComponents/CommonHeader'
import SetupReview from './SetupReview'
import { AuthContext } from '../auth'
import { useHistory } from 'react-router'
import axios from 'axios'
import StandardContainer from '../commonComponents/StandardContainer'
import ReviewSitesList from './ReviewSitesList'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'

export default function RequestReviewHomePage() {
  const { locationData, businessData, locationId, userData, businessId, getLocations } =
    useContext(AuthContext)
  const selectedLocation = GetLocationDataBasedOnLocationId(locationData, locationId)

  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)

  const history = useHistory()
  const [setupReviewModalVisible, setSetupReviewModalVisible] = useState(false)
  const [enableReviewText, setToggleEnableReviewText] = useState(false)
  const [selectedReviewData, setSelectedReviewData] = useState(false)
  const [reviewSites, setReviewSites] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingReviewtext, setLoadingReviewText] = useState(false)
  const [savingApiKey, setSavingApiKey] = useState(false)
  const [apiKey, setAPIKey] = useState(businessData?.googleBusinessAPIKey)
  const [apiKeyChanged, setApiKeyChanged] = useState(false)
  const handleAPIKey = value => {
    value ? setApiKeyChanged(true) : setApiKeyChanged(false)
    setAPIKey(value)
  }
  const handleUpdateApiKey = async () => {
    setSavingApiKey(true)
    try {
      const response = await axios.patch(`/business?id=${businessData?._id}`, {
        googleBusinessAPIKey: apiKey,
      })
      if (response && response.status === 200) {
        setSavingApiKey(false)
        setApiKeyChanged(false)
      }
    } catch (error) {}
  }
  const getLocation = async setStateValues => {
    try {
      let response = await axios.get(`/location?id=${locationId}`)

      if (response.status == 200 && setStateValues) {
        const reviewSites = response ? response?.data?.reviewSettings?.websites : []
        setReviewSites(reviewSites)
        setToggleEnableReviewText(response?.data?.reviewSettings?.reviewRequestsEnabled)
      } else {
        return response
      }
      setLoading(false)
    } catch (error) {}
  }

  const toggleEnableReviewText = async value => {
    setLoadingReviewText(true)
    const response = await getLocation()
    if (response && response.status === 200) {
      const reviewSettingsInput = {
        businessId: response.data.businessId,
        reviewSettings: {
          reviewRequestsEnabled: value,
          websites: response ? response?.data?.reviewSettings?.websites : [],
        },
      }

      try {
        const updateLocationResponse = await axios.patch(
          `/location?id=${locationId}`,
          reviewSettingsInput,
        )

        if (updateLocationResponse.status === 200) {
          setLoadingReviewText(false)
          setToggleEnableReviewText(value)
        }
      } catch (error) {
        setLoadingReviewText(false)
      }
    }
  }

  const openSetupReviewSite = data => {
    setSetupReviewModalVisible(true)
    setSelectedReviewData(data)
  }
  const closeSetupReviewSite = () => {
    setSetupReviewModalVisible(false)
  }
  const updateReviewSitesList = async () => {
    setLoading(true)

    await getLocation(true)
  }
  useEffect(() => {
    setLoading(true)
    getLocation(true)
    setAPIKey(businessData?.googleBusinessAPIKey)
  }, [locationId])
  useEffect(() => {
    setAPIKey(businessData?.googleBusinessAPIKey)
  }, [businessData])

  useEffect(() => {
    setSetupReviewModalVisible(setupReviewModalVisible)
  }, [setupReviewModalVisible])

  // useEffect(() => {
  //   setLoading(true)
  //   getLocation(true)
  // }, [])

  const activeItems = reviewSites && reviewSites.filter(item => item.status === 'active').length
  // Calculate the percentage of reviews for each item
  reviewSites &&
    reviewSites.forEach(item => {
      const reviewsPerActiveItem = Math.floor(100 / activeItems)
      if (item.status === 'active') {
        item.reviews = `${reviewsPerActiveItem}% of reviews`
      } else {
        item.reviews = `0% of reviews`
      }
    })
  const isBadReviewDeflectionEnabled =
    selectedLocation?.deflectionSettings?.badReviewDeflectionEnabled
  return (
    <>
      <IonPage id='main-content'>
        <CommonHeader title={'Review Settings'} backIcon={true}></CommonHeader>
        <IonContent>
          <StandardContainer>
            {loading && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IonSpinner style={{ color: 'grey' }} name='dots' />
              </div>
            )}

            {!loading && (
              <>
                <div style={{ padding: '0 1rem' }}>
                  <h2 style={{ fontWeight: 'bold' }}>Request a Review Settings</h2>
                  <IonLabel style={{ color: 'grey', lineHeight: '1.5rem' }}>
                    When a customer completes an appointment send them a text asking them to review
                    your business online
                  </IonLabel>
                  {userEntitlements.isAdmin && (
                    <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
                      <IonItem>
                        <IonLabel position='stacked'>Google API Key</IonLabel>
                        <IonInput
                          onIonChange={e => handleAPIKey(e.detail.value)}
                          placeholder='Google Business API Key'
                          type='password'
                          value={apiKey}
                        ></IonInput>
                      </IonItem>
                      {apiKeyChanged && (
                        <IonButtons style={{ display: 'flex', justifyContent: 'end' }}>
                          <IonButton
                            slot='end'
                            fill='clear'
                            disabled={savingApiKey}
                            onClick={handleUpdateApiKey}
                          >
                            {savingApiKey && <IonSpinner name='lines' />}
                            Save Api Key
                          </IonButton>
                        </IonButtons>
                      )}
                    </IonList>
                  )}
                  <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
                    <IonItem>
                      <IonCol size='9'>
                        <IonLabel>Enable Review Text</IonLabel>
                      </IonCol>
                      <IonCol size='3' style={{ display: 'flex', justifyContent: 'end' }}>
                        {loadingReviewtext && <IonSpinner name='lines' />}
                        {!loadingReviewtext && (
                          <IonToggle
                            justify='space-between'
                            size='small'
                            checked={enableReviewText}
                            onIonChange={e => {
                              toggleEnableReviewText(e.detail.checked)
                            }}
                          />
                        )}
                      </IonCol>
                    </IonItem>
                    <IonItem routerLink='/core/deflectionSettings' detail={true}>
                      <IonCol size='9'>
                        <IonLabel>Bad Review Deflection</IonLabel>
                      </IonCol>
                      <IonCol size='3' style={{ display: 'flex', justifyContent: 'end' }}>
                        {isBadReviewDeflectionEnabled ? 'On' : 'Off'}
                      </IonCol>
                    </IonItem>
                  </IonList>
                </div>

                <h6 style={{ marginLeft: 16, marginTop: 30, fontWeight: '600' }}>Review Sites</h6>
                <ReviewSitesList
                  reviewSites={reviewSites}
                  openSetupReviewSite={openSetupReviewSite}
                />

                <ChooseReview
                  reviewSites={reviewSites}
                  updateReviewSitesList={updateReviewSitesList}
                />

                <SetupReview
                  reviewSites={reviewSites}
                  updateReviewSitesList={updateReviewSitesList}
                  reviewData={selectedReviewData}
                  closeSetupReviewSite={closeSetupReviewSite}
                  fromList={true}
                  // hideText={true}
                  openModal={setupReviewModalVisible}
                />
              </>
            )}
          </StandardContainer>
        </IonContent>
      </IonPage>
    </>
  )
}
