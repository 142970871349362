import {
  IonCard,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSpinner,
} from '@ionic/react'
import {
  calendarOutline,
  cashOutline,
  chatboxEllipsesOutline,
  documentOutline,
  imageOutline,
  list,
  notificationsOutline,
  walkOutline,
  walletOutline,
} from 'ionicons/icons'
import { useContext, useState } from 'react'
import CustomerNotesModal from '../commonComponents/customerNotes'
import SchedulerModal from '../Scheduler/modal'
import { colors } from '../theme/colors'
import { AuthContext } from '../auth'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import './CustomerDetailListOptions.css'
import NoFutureApptsCard from '../commonComponents/NoFutureApptsCard'
import { useHistory } from 'react-router'
import axios from 'axios'
export default function CustomerDetailsListOptions({
  apptCount,
  userEntitlements,
  customerTemp,
  customer_id,
  balance,
  creditLoading,
  conversationDetails,
}) {
  const history = useHistory()
  const { locationData, locationId, businessData, userData } = useContext(AuthContext)
  const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)
  const [loadingConversation, setLoadingConversation] = useState(false)
  const maxLocationAppts = selectedLocationData?.appointmentSettings?.futureAppointmentsLimit
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [notesOpen, setNotesOpen] = useState(false)
  const [openScheduler, setOpenScheduler] = useState({ isOpen: false, isWalkin: false })
  const openApptAlertModal = () => {
    setAlertModalOpen(true)
  }
  const closeApptAlertModal = () => {
    setAlertModalOpen(false)
  }

  const handleMessageCustomer = async () => {
    if (conversationDetails?.twilioConversationId) {
      history.push(`/core/messages/${conversationDetails?.twilioConversationId}`)
    } else {
      setLoadingConversation(true)
      try {
        const threadInput = {
          locationId: locationId,
          businessId: businessData?._id,
          userId: userData?._id,
          customerId: customer_id,
        }
        const threadResponse = await axios.post('/conversation', threadInput)

        if (threadResponse?.data?.message == 'success') {
          setLoadingConversation(false)

          history.push(`/core/messages/${threadResponse?.data?.twilioConversationId}`)
        }
      } catch (error) {}
    }
  }
  const creditBalanceStyles =
    balance >= 0
      ? null
      : {
          color: colors.primaryBlue,
        }
  return (
    <>
      <IonList inset={true} mode='ios'>
        <IonItem detail={true} onClick={handleMessageCustomer}>
          <IonIcon icon={chatboxEllipsesOutline} slot='start'></IonIcon>
          <IonLabel>Message</IonLabel>
          {loadingConversation && <IonSpinner name='lines' />}
        </IonItem>
        <IonItem detail={true} routerLink={`/core/history/${customer_id}`}>
          <IonIcon icon={list} slot='start'></IonIcon>
          <IonLabel>Appointment History</IonLabel>
        </IonItem>
        <IonItem detail={true} routerLink={`/core/paymentHistory/${customer_id}`}>
          <IonIcon icon={cashOutline} slot='start'></IonIcon>
          <IonLabel>Payment History</IonLabel>
        </IonItem>
        {customerTemp?.stripeId && (userEntitlements?.isManager || userEntitlements?.isAdmin) && (
          <IonItem detail={true} routerLink={`/core/creditBalance/${customer_id}`}>
            <IonIcon icon={walletOutline} slot='start'></IonIcon>
            <IonLabel>Credit Balance</IonLabel>
            {!creditLoading && balance < 0 && (
              <>
                {balance !== 0 && (
                  <IonLabel style={creditBalanceStyles} slot='end'>
                    {/* {balance > 0 ? '' : '-'}${balance / 100?.toString().replace('-', '')} */}
                    {formatDollarValue(balance / 100)}
                  </IonLabel>
                )}
              </>
            )}
          </IonItem>
        )}
        {}
        <IonItem detail={true} routerLink={`/core/manageNotifications/${customer_id}`}>
          <IonIcon icon={notificationsOutline} slot='start'></IonIcon>
          <IonLabel>Manage Notifications</IonLabel>
        </IonItem>

        <IonItem
          detail={true}
          onClick={() => {
            setNotesOpen(true)
          }}
        >
          <IonIcon icon={documentOutline} slot='start'></IonIcon>
          <IonLabel>Customer Notes</IonLabel>
        </IonItem>
        <IonItem detail={true} routerLink={`/core/gallery/${customer_id}`}>
          <IonIcon icon={imageOutline} slot='start'></IonIcon>
          <IonLabel>Customer Pictures</IonLabel>
        </IonItem>
        <IonItem
          detail={true}
          onClick={() => {
            apptCount >= maxLocationAppts
              ? openApptAlertModal()
              : setOpenScheduler({ isOpen: true, isWalkin: false })
          }}
        >
          <IonIcon icon={calendarOutline} slot='start'></IonIcon>
          <IonLabel>Book Appointment</IonLabel>
        </IonItem>
        <IonItem
          detail={true}
          onClick={() => {
            setOpenScheduler({ isOpen: true, isWalkin: true })
          }}
        >
          <IonIcon icon={walkOutline} slot='start'></IonIcon>
          <IonLabel>Book Walk-in</IonLabel>
        </IonItem>
      </IonList>
      <IonModal isOpen={alertModalOpen} onDidDismiss={closeApptAlertModal}>
        <CommonHeader closeButton={true} closeModal={closeApptAlertModal}></CommonHeader>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <NoFutureApptsCard apptCount={apptCount} />
          </StandardContainer>
        </IonContent>
      </IonModal>
      <CustomerNotesModal isOpen={notesOpen} setOpen={setNotesOpen} customerId={customer_id} />
      <SchedulerModal
        isOpen={openScheduler?.isOpen || false}
        isWalkin={openScheduler?.isWalkin || false}
        setIsOpen={setOpenScheduler}
        onSuccess={() => {}}
        customerId={customer_id}
      />
    </>
  )
}

function formatDollarValue(value) {
  if (value < 0) {
    const formattedValue = Math.abs(value).toFixed(2)
    return `($${formattedValue})`
  } else {
    return `$${value.toFixed(2)}`
  }
}
