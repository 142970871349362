import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
} from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { closeCircleOutline, personSharp, trashOutline } from 'ionicons/icons'
import commonStyles from '../theme/commonstyles.module.css'
import BusinessPermissions from './NewUser/BusinessPermissions'
import { useHistory } from 'react-router'
import checkEmailExists from '../Utils/CheckEmailValidation'
import { AuthContext } from '../auth'
import { colors } from '../theme/colors'
import axios from 'axios'
import LocationPermissions from './NewUser/LocationPermissions'
export default function AddNewUser() {
  const { businessData, locationId, locationData } = useContext(AuthContext)
  const [invitationSent, setInvitationSent] = useState(false)
  const [sendingInvitation, setSendingInvitation] = useState(false)
  const [resendInvitation, setResendInvitation] = useState(false)
  const [resendCount, setResendCount] = useState(false)
  let [isDisabled, setIsDisabled] = useState(true)
  const [entitlementsData, setEntitlementsData] = useState(false)

  let [email, setEmail] = useState('')

  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  let history = useHistory()
  const menuItems = [
    {
      label: 'Remove User',
      icon: trashOutline, // IonIcon for the "Delete" item
    },
    {
      label: 'Disable Account',
      icon: closeCircleOutline,
    },
    // Add more menu items as needed
  ]

  const updateEntitlements = async entitlements => {
    setIsDisabled(false)
    setEntitlementsData(entitlements)
  }
  const handleEmail = async event => {
    const emailValue = event.target.value
    setEmail(emailValue)
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const isValid = emailPattern.test(emailValue)
    if (emailValue) {
      if (isValid) {
        setEmailErrorMessage(false)
        try {
          const checkEmailAndInviteExistsResponse = await checkEmailExists('user', emailValue)

          const errorMessage = checkEmailAndInviteExistsResponse.emailExists
            ? 'Email Already In Use'
            : checkEmailAndInviteExistsResponse.userInvitationExists
            ? 'Invitation already exists for this email'
            : false

          setEmailErrorMessage(errorMessage)
          setIsDisabled(errorMessage ? true : false)
        } catch (error) {
          //
        }
      } else {
        setEmailErrorMessage('Email Is Not Valid')
        setIsDisabled(true)
      }
    } else {
      setIsDisabled(true)

      setEmailErrorMessage('')
    }
  }
  const sendInvitation = async () => {
    const addUserInviteInput = {
      email,
      businessId: businessData?._id,
      locations: entitlementsData.locations,
    }

    try {
      let response = await axios.post(`/user/addInvite`, addUserInviteInput)
      return response
    } catch (error) {
      return error
    }
  }
  const resendInvite = async () => {
    setSendingInvitation(true)

    const sendInviteResponse = await sendInvitation()

    if (sendInviteResponse && sendInviteResponse.status === 200) {
      setResendInvitation(false)
      setSendingInvitation(false)
      setResendCount(true)
    } else {
      setSendingInvitation(false)
    }
  }
  const handleInvitation = async () => {
    setSendingInvitation(true)
    setIsDisabled(true)
    const sendInviteResponse = await sendInvitation()
    if (sendInviteResponse && sendInviteResponse.status === 200) {
      setInvitationSent(true)
      setTimeout(() => {
        setResendInvitation(true)
      }, 2000)
      setSendingInvitation(false)
    } else {
      setIsDisabled(false)
      setSendingInvitation(false)
    }
  }
  useEffect(() => {
    const entitlementsData = {
      id: businessData?._id,
      locations: locationData?.map((item, index) => {
        return {
          _id: item._id,
          entitlements: {
            admin: false,
            manager: false,
            professional: false,
            noPermissions: true,
          },
        }
      }),
    }
    setEntitlementsData(entitlementsData)
  }, [])

  return (
    <IonPage id='main-content'>
      <CommonHeader
        saveButton={true}
        loading={sendingInvitation}
        saveDisabled={isDisabled}
        handleSaveButton={handleInvitation}
        title={'New User'}
        backIcon={true}
      ></CommonHeader>
      <IonContent>
        <StandardContainer>
          <IonCard style={{ padding: '1rem' }}>
            {!invitationSent && (
              <>
                <IonInput
                  fill='outline'
                  labelPlacement='stacked'
                  label='email'
                  style={{ height: '3rem' }}
                  className={commonStyles.inputStyle}
                  placeholder='example@test.com'
                  value={email}
                  onIonChange={handleEmail}
                ></IonInput>

                {emailErrorMessage && (
                  <div style={{ marginTop: '0.5rem', marginLeft: '1rem' }}>
                    <IonLabel style={{ color: colors.errorText, fontSize: '12px' }}>
                      {emailErrorMessage}
                    </IonLabel>
                  </div>
                )}
                {/* <IonButton
                  disabled={isDisabled}
                  onClick={handleInvitation}
                  expand='full'
                  style={{ marginTop: '1rem' }}
                >
                  {sendingInvitation && <IonSpinner style={{ marginRight: '1rem' }} name='lines' />}
                  Save and Send Invitation
                </IonButton> */}
              </>
            )}
            {invitationSent && (
              <IonGrid>
                <IonRow style={{ display: 'flex', alignItems: 'center' }}>
                  <IonCol size='auto'>
                    <IonIcon style={{ height: 30, width: 30 }} icon={personSharp} />
                  </IonCol>
                  <IonCol size='10' style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4 style={{ margin: '0 0 10px 0', fontWeight: 'bold', color: 'black' }}>
                      {email}
                    </h4>
                  </IonCol>
                </IonRow>
                <IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div
                    style={{
                      padding: '0.5rem 2rem',
                      backgroundColor: ' #d1ffd1',
                      color: 'green',
                      borderRadius: '5rem',
                    }}
                  >
                    <IonLabel>{'Invite Sent. Awaiting Response'}</IonLabel>
                  </div>
                </IonRow>
              </IonGrid>
            )}
            {resendInvitation && !resendCount && (
              <IonButton onClick={resendInvite} expand='full' style={{ marginTop: '1rem' }}>
                {sendingInvitation && <IonSpinner style={{ marginRight: '1rem' }} name='lines' />}
                Send Invite Again
              </IonButton>
            )}
            {resendCount && (
              <IonButton
                strong={true}
                style={{
                  'marginTop': '1rem',
                  '--background': ' #c2c2c2',
                  '--color': '#101010',
                }}
                expand='full'
                disabled={true}
              >
                Sent
              </IonButton>
            )}
          </IonCard>
          <IonCard style={{ padding: '10px' }}>
            <BusinessPermissions
              invitationSent={invitationSent}
              updateEntitlements={updateEntitlements}
              entitlementsData={entitlementsData}
            />
          </IonCard>
          <IonCard style={{ padding: '10px' }}>
            <LocationPermissions
              updateEntitlements={updateEntitlements}
              entitlementsData={entitlementsData}
            />
          </IonCard>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
