import React, { useState, useEffect, useContext } from 'react';
import { Typography, Button, Paper, Card, Avatar, Grid, Box } from '@mui/material';
import axios from 'axios'
import StepPickBarber from './stepPickBarber';
import StepPickLocation from './stepPickLocation';
import StepPickDateTime from './stepPickDateTime';
import StepPickService from './stepPickService'
import StepSummary from './stepSummary';
import StepPickCustomer from './stepPickCustomer';
import StepSuccess from './stepSuccess';


export default function StepController({businessId, locationId, customerId, noContainer}) {
    console.log('locationId', locationId)
    
    const [step, setStep] = useState((!customerId ? 'customer' : (!locationId ? 'location' : 'barber')))
    const [scheduleData, setScheduleData] = useState({
        businessId: businessId,
        location:{_id: locationId} || null,
        barber:null,
        anyBarber: false,
        service: null,
        dateTime: null,
        customer: {_id: customerId} || null,
        payment:null
    })

    function updateScheduleData(key, value){
        let updatedData = {...scheduleData}
        updatedData[key] = value
        setScheduleData(updatedData)
        console.log(updatedData)
    }

    let stepComponent = null

    if(step=='barber'){
        stepComponent =  <StepPickBarber businessId={businessId} scheduleData={scheduleData} updateScheduleData={updateScheduleData} setStep={setStep}/>
    } else if(step=='customer'){
        stepComponent =  <StepPickCustomer businessId={businessId} scheduleData={scheduleData} updateScheduleData={updateScheduleData} setStep={setStep}/>
    } else if(step=='location'){
        stepComponent =  <StepPickLocation businessId={businessId} scheduleData={scheduleData} updateScheduleData={updateScheduleData} setStep={setStep}/>
    } else if(step=='service'){
        stepComponent =  <StepPickService businessId={businessId} scheduleData={scheduleData} updateScheduleData={updateScheduleData} setStep={setStep}/>
    } else if(step=='dateTime'){
        stepComponent =  <StepPickDateTime businessId={businessId} scheduleData={scheduleData} updateScheduleData={updateScheduleData} setStep={setStep}/>
    } else if(step=='summary'){
        stepComponent =  <StepSummary businessId={businessId} scheduleData={scheduleData} updateScheduleData={updateScheduleData} setStep={setStep}/>
    } else if(step=='success'){
        stepComponent =  <StepSuccess businessId={businessId} scheduleData={scheduleData} updateScheduleData={updateScheduleData} setStep={setStep}/>
    } else {
        stepComponent =  <div>None</div>
    }

    if(noContainer){
        return (
            <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item container xs={12} sm={8} md={5} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Box style={{minWidth:'100%'}}>
                            {stepComponent}
                        </Box>
                </Grid>
            </Grid>
            )
    }

    return (
        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item container xs={12} sm={8} md={5} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card style={{ backgroundColor: 'white', padding: 40, minWidth:'100%' }}>
                        <Box style={{minWidth:'100%'}}>
                            {stepComponent}
                        </Box>
                    </Card>
                </Grid>
            </Grid>
    )

}
