import React from 'react'
import { GetReviewStylesBasedOnStatus } from '../RequestCustomerReviews/GetReviewStylesBasedOnStatus'
import { IonCol, IonIcon, IonItem, IonLabel, IonList, IonRow, IonText } from '@ionic/react'
import { add } from 'ionicons/icons'

export default function RulesList({ loading, rulesList, handleAddRuleClick }) {
  const activeStyles = GetReviewStylesBasedOnStatus('active')
  console.log(rulesList, 'rulesListrulesListrulesList')
  return (
    <>
      {!loading && rulesList && rulesList.length > 0 && (
        <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
          {rulesList &&
            rulesList?.map((item, index) => {
              return (
                <IonItem detail={true} routerLink={`/core/emailRemarketing/${item._id}`}>
                  <IonCol>
                    <h6 style={{ fontWeight: 'bold' }}>{item.ruleName}</h6>
                  </IonCol>
                  <IonCol size='auto' style={{ display: 'flex', justifyContent: 'end' }}>
                    <IonText style={activeStyles}>
                      {item?.emailSteps?.length}
                      {' emails'}
                    </IonText>
                  </IonCol>
                </IonItem>
              )
            })}
        </IonList>
      )}
      <IonRow
        style={{ marginTop: '1rem', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        onClick={handleAddRuleClick}
      >
        <IonIcon size='small' color='primary' icon={add} />
        <IonLabel color='primary'>Add a Rule</IonLabel>
      </IonRow>
    </>
  )
}
