import * as React from 'react';
import {Button, duration, FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios'
import { useHistory } from 'react-router-dom';

export default function CreateTestDialog({ open, handleClose, businessId, locationId }) {
  let [isDisabled, setIsDisabled] = React.useState(false)
  let [name, setName] = React.useState('')
  let [description, setDescription] = React.useState('')
  let [price, setPrice] = React.useState('')
  let [role, setRole] = React.useState('')
  let history = useHistory()
  const createButtonClicked = async (e) => {
    setIsDisabled(true)
    let locData = locationId ? {locations : [locationId]} : {}
    let response = await axios.post(`/product`, { name, description, businessId, price, ...locData })
    console.log(response)
    if (response.status === 200) {
      history.push(`products/${response.data._id}/details`)
      close()
    } else {
      setIsDisabled(false)
    }

  }

  const close = () => {
    setName('')
    setDescription('')
    setPrice('')
    setIsDisabled(false);
    handleClose()
  }

  return (

    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ marginRight: 100 }}>Add Product</DialogTitle>
      <DialogContent>
        <DialogContentText>
        </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => { setName(e.target.value) }}
          />
          <TextField
          margin="dense"
          id="description"
          label="Description"
          type="text"
          fullWidth
          variant="outlined"
          value={description}
          onChange={(e) => { setDescription(e.target.value) }}
        />
        <TextField
          margin="dense"
          id="price"
          label="Price"
          type="text"
          fullWidth
          variant="outlined"
          value={price}
          onChange={(e) => { setPrice(e.target.value) }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button disabled={isDisabled} color='primary' variant='contained' onClick={createButtonClicked}>Create</Button>
      </DialogActions>
    </Dialog>
  );
}