import React, { useState, useEffect, useContext } from 'react'
import ResultsList from './resultsList'
import axios from 'axios'
import AddDialog from './addDialog'
import EditDialog from './editDialog'
import { AuthContext } from '../auth'
import { getFormattedMediumDate } from '../utils'
import LVHeader from './listViewHeader'
import CalendarView from './calendarView3'
import { IonContent, IonPage, IonRefresher, IonRefresherContent } from '@ionic/react'
import AddHeader from '../commonComponents/AddHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import FilterModal from './filterModal'
import { useLocation } from 'react-router-dom'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'
import DefaultMenu from '../commonComponents/SideMenu/DefaultMenu'
import NoPermissionsCard from '../commonComponents/NoPermissionsCard'
import MyCalendar from '../Calendar'

export default function AppointmentListView() {
  const { businessData, userData, locationId } = useContext(AuthContext)
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)
  const [appointments, setAppointments] = useState()
  const [addDialogOpen, setAddDialogOpen] = React.useState(false)
  const [editDialogOpen, setEditDialogOpen] = React.useState(false)
  const [filterModalOpen, setFilterModalOpen] = React.useState(false)
  const [filteredItems, setFilteredItems] = React.useState([])
  const [selectedAppointment, setSelectedAppointment] = React.useState()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [viewType, setViewType] = React.useState('list')
  const [loading, setLoading] = React.useState(false)
  const [breaks, setBreaks] = React.useState()
  const [errorMessage, setErrorMessage] = React.useState('')
  const location = useLocation()
  const [defaultView, setDefaultView] = useState('list')
  const [employees, setEmployees] = useState([])
  const [events, setEvents] = useState()

  const handleViewType = (event, newAlignment) => {
    setViewType(newAlignment)
  }
  const getEmployeesList = async eventsData => {
    if (!businessData?._id) return
    setLoading(true)

    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      // Fetch employees data from the server based on businessId and optional locationId
      let response = await axios.get(`/employee/getList?businessId=${businessData?._id}${locQuery}`)

      // Extract unique employee IDs from the events
      const employeeIdsInEvents = [...new Set(eventsData.map(event => event?.barber?._id))]

      // Filter employees to include only those with events
      const filteredEmployeesInEvents = response.data.filter(employee =>
        employeeIdsInEvents.includes(employee._id),
      )

      // Format filtered employees data
      const filteredEmployeeFormattedData = filteredEmployeesInEvents.map((item, index) => ({
        id: item._id,
        title: item.firstName + ' ' + item.lastName,
      }))

      // Sort employees alphabetically by title
      const sortedEmployeeByName = filteredEmployeeFormattedData.sort((a, b) =>
        a.title.localeCompare(b.title),
      )

      // Set sorted and formatted employees data in the state
      setEmployees(sortedEmployeeByName)
    } catch (error) {
      // Handle errors by setting an error message
    }

    // Set loading state to false after API call is complete
    setLoading(false)
  }
  useEffect(() => {
    getAppointmentsList()
    getBreaks()

    const interval = setInterval(() => {
      getAppointmentsList(true)
    }, 15000)
    return () => clearInterval(interval)
  }, [selectedDate, businessData, location, locationId])

  const getAppointmentsList = async (hideLoader = false) => {
    if (!businessData?._id) return
    setLoading(!hideLoader)
    setErrorMessage(null)
    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      let date = getFormattedMediumDate(selectedDate)

      let response = await axios.get(
        `/appointment/getList?businessId=${businessData?._id}${locQuery}&dateText=${date}`,
      )
      const filteredData = response.data.filter((item, index) => item.status != 'Canceled')
      const data = filteredData.map((item, index) => {
        return {
          resourceId: item?.barber?._id,
          start: new Date(item.startTime),
          end: new Date(item.endTime),
          extendedProps: { ...item },
        }
      })
      setEvents(data)
      getEmployeesList(response.data)
      setAppointments(response.data)
    } catch (error) {
      setErrorMessage('There was an issue fetching the data')
    }
    setLoading(false)
  }

  const getBreaks = async () => {
    if (!businessData?._id) return
    let locQuery = locationId ? `&locationId=${locationId}` : ''
    let date = getFormattedMediumDate(selectedDate)
    try {
      let response = await axios.get(
        `/employee/searchForBreaksByDate?businessId=${businessData?._id}${locQuery}&dateText=${date}`,
      )
      setBreaks(response.data)
    } catch (error) {}
  }

  async function handleRefresh(event) {
    await getAppointmentsList()
    await getBreaks()
    event.detail.complete()
  }

  const openAddDialog = () => {
    setAddDialogOpen(true)
  }
  const closeAddDialog = () => {
    getAppointmentsList()
    setAddDialogOpen(false)
  }

  const openEditDialog = () => {
    setEditDialogOpen(true)
  }
  const closeEditDialog = () => {
    getAppointmentsList()
    setEditDialogOpen(false)
    setSelectedAppointment(null)
  }

  const addDayToDate = number => {
    // Create new Date instance
    var date = new Date(selectedDate)

    // Add a day
    date.setDate(date.getDate() + number)
    setSelectedDate(date)
  }
  if (userEntitlements?.noPermissions) {
    return (
      <>
        <DefaultMenu />
        <IonPage id='main-content'>
          <AddHeader title={'Appointments'} />
          <IonContent>
            <StandardContainer>
              <NoPermissionsCard />
            </StandardContainer>
          </IonContent>
        </IonPage>
      </>
    )
  }
  let filteredAppointmentsList = appointments?.filter(appointment => {
    if (filteredItems.length === 0) {
      return true // Keep all appointments if filteredItems is empty
    }

    return filteredItems.includes(appointment.barber._id) // Keep appointments where barber._id is in filteredItems
  })
  const handleView = () => {
    setDefaultView(defaultView === 'list' ? 'calendar' : 'list')
  }

  return (
    <IonPage>
      {defaultView === 'list' ? (
        <>
          <AddHeader
            title='Appointments'
            loading={loading}
            defaultView={defaultView}
            handleView={handleView}
            // handleAddClick={openAddDialog}
            additionalToolbar={
              defaultView == 'list' ? (
                <LVHeader
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  addDayToDate={addDayToDate}
                  viewType={viewType}
                  handleViewType={handleViewType}
                  setFilterModalOpen={setFilterModalOpen}
                  filteredItemsCount={filteredItems.length}
                />
              ) : null
            }
          />
          <IonContent>
            <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            <StandardContainer>
              <br></br>

              <ResultsList
                appointments={filteredAppointmentsList}
                loading={loading}
                locationId={locationId}
                setSelectedAppointment={setSelectedAppointment}
                openEditDialog={openEditDialog}
                businessId={businessData?._id}
                breaks={breaks}
              />
            </StandardContainer>
          </IonContent>
        </>
      ) : (
        <>
          {/* <AddHeader
            title='Appointments'
            loading={loading}
            defaultView={defaultView}
            handleView={handleView}
            // handleAddClick={openAddDialog}
            additionalToolbar={
              // defaultView == 'list' ? (
                <LVHeader
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  addDayToDate={addDayToDate}
                  viewType={viewType}
                  handleViewType={handleViewType}
                  setFilterModalOpen={setFilterModalOpen}
                  filteredItemsCount={filteredItems.length}
                />
              // ) : null
            }
          /> */}
            <CalendarView
              breaks={breaks}
              handleView={handleView}
              loading={loading}
              appointments={appointments}
              selectedDate={selectedDate}
              locationId={locationId}
              setSelectedAppointment={setSelectedAppointment}
              openEditDialog={openEditDialog}
              setSelectedDate={setSelectedDate}
            />

        </>
        // <MyCalendar
        //   loading={loading}
        //   setSelectedDate={setSelectedDate}
        //   events={events}
        //   employees={employees}
        //   selectedDate={selectedDate}
        // />
        // <MyCalendar
        //   handleView={handleView}
        //   // loading={loading}
        //   // setSelectedDate={setSelectedDate}
        //   // events={events}
        //   // employees={employees}
        //   // selectedDate={selectedDate}
        // />
      )}
      <AddDialog
        open={addDialogOpen}
        handleClose={closeAddDialog}
        businessId={businessData?._id}
        locationId={locationId}
      />
      <EditDialog
        open={editDialogOpen}
        handleClose={closeEditDialog}
        businessId={businessData?._id}
        locationId={locationId}
        selectedAppointment={selectedAppointment}
      />
      <FilterModal
        isOpen={filterModalOpen}
        setIsOpen={setFilterModalOpen}
        setFilteredItems={setFilteredItems}
        locationId={locationId}
        businessId={businessData?._id}
      />
    </IonPage>
  )
}
