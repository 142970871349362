import React, { useState, useEffect, useContext } from 'react';
import { Typography, Button, FormControl, InputLabel, MenuItem, Select, TextField, Grid } from '@mui/material';
import axios from 'axios'
import StandardHoursTable from './StandardHoursTable';
import ModifiedSchedule from './modifiedSchedule';
import ScheduledDaysInAdvance from './ScheduledDaysInAdvance';
import { IonCard } from '@ionic/react';

export default function DetailsView({ location, updateLocation }) {

    function updateStandardSchedule(value) {
        updateLocation({ ...location, ...{ "standardSchedule": value } })
    }

    function updateDaysInAdvance(value) {
        updateLocation({ ...location, ...{ "daysYouCanScheduleInAdvance": value } })
    }

    if (!location) return "loading..."

    return (

        <Grid container>
            <IonCard style={{ padding: 20, width:'100%' }}>
                    <StandardHoursTable standardSchedule={location.standardSchedule} updateStandardSchedule={updateStandardSchedule} />
            </IonCard>
            <IonCard style={{ padding: 20, width:'100%' }}>
                    <ModifiedSchedule
                        standardSchedule={location.standardSchedule}
                        locationId={location._id}
                        businessId={location.businessId}
                    />
                    <br></br>
                    <br></br>
            </IonCard>
            <IonCard style={{ padding: 20, width:'100%' }}>
                    <ScheduledDaysInAdvance daysYouCanScheduleInAdvance={location.daysYouCanScheduleInAdvance} updateDaysInAdvance={updateDaysInAdvance} />
            </IonCard>
        </Grid>

    )
}


