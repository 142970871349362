import { IonButton, IonContent, IonPage, IonSpinner } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { colors } from '../theme/colors'
import { AuthContext } from '../auth'
import axios from 'axios'
import { calculatePayment } from '../sharedComponents/paymentCalculation'
import AddCustomerOrEmployee from './AddCustomerOrEmployee'
import CartItemsList from './CartItemsList'
import CustomerListModal from './CustomerListModal'
import EmployeeListModal from './EmployeeListModal'
import ProductListModal from './ProductListModal'
import DiscountListModal from './DiscountListModal'
import CustomAmountModal from './CustomAmountModal'

export default function CheckoutList() {
  const [productsModalOpen, setProductsModal] = useState(false)
  const [selectedServices, setSelectedServices] = useState([])
  const [services, setServices] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [products, setProducts] = useState([])
  const [selectedDiscounts, setSelectedDiscounts] = useState([])
  const [discounts, setDiscounts] = useState([])
  const [customers, setCustomers] = useState([])
  const [employees, setEmployees] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState(false)
  const [customersModalOpen, setCustomerModalOpen] = useState(false)
  const [employeesModalOpen, setEmployeeModalOpen] = useState(false)
  const { businessData, locationId } = useContext(AuthContext)
  const [discountModalOpen, setDiscountModalOpen] = useState(false)
  const [customAmountModalOpen, setCustomAmountModalOpen] = useState(false)
  const [customAmount, setCustomAmount] = useState('')
  const [loading, setLoading] = useState(false)
  const getServicesList = async () => {
    try {
      const response = await axios.get(`/service/getList?businessId=${businessData._id}`)
      setServices(response.data)
    } catch (error) {
    } finally {
    }
  }

  const getProductsList = async () => {
    try {
      const response = await axios.get(`/product/getList?businessId=${businessData._id}`)
      setProducts(response.data)
    } catch (error) {
    } finally {
    }
  }

  const getDiscountsList = async () => {
    try {
      const response = await axios.get(`/discount/getList?businessId=${businessData._id}`)
      setDiscounts(response.data)
    } catch (error) {
    } finally {
    }
  }
  const handleServiceSelect = service => {
    const serviceIndex = selectedServices.findIndex(
      selectedService => selectedService._id === service._id,
    )

    if (serviceIndex !== -1) {
      const updatedServices = [...selectedServices]
      updatedServices.splice(serviceIndex, 1)
      setSelectedServices(updatedServices)
    } else {
      setSelectedServices([...selectedServices, service])
    }
  }

  const handleProductSelect = product => {
    const productIndex = selectedProducts.findIndex(
      selectedProduct => selectedProduct._id === product._id,
    )

    if (productIndex !== -1) {
      const updatedProducts = [...selectedProducts]
      updatedProducts.splice(productIndex, 1)
      setSelectedProducts(updatedProducts)
    } else {
      setSelectedProducts([...selectedProducts, product])
    }
  }

  const handleDiscountSelect = discount => {
    const discountIndex = selectedDiscounts.findIndex(
      selectedDiscount => selectedDiscount._id === discount._id,
    )

    if (discountIndex !== -1) {
      const updatedDiscounts = [...selectedDiscounts]
      updatedDiscounts.splice(discountIndex, 1)
      setSelectedDiscounts(updatedDiscounts)
    } else {
      setSelectedDiscounts([...selectedDiscounts, discount])
    }
  }
  const getEmployeesList = async () => {
    if (!businessData?._id) return
    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''

      let showArchivedQuery = ''

      let response = await axios.get(
        `/employee/getList?businessId=${businessData?._id}${locQuery}${showArchivedQuery}`,
      )
      setEmployees(response.data)
    } catch (error) {}
  }
  const getCustomersList = async () => {
    if (!businessData?._id) return
    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''

      let response = await axios.get(`/customer/getList?businessId=${businessData._id}${locQuery}`)

      setCustomers(response.data)
    } catch (error) {}
  }

  const openCustomersModal = () => {
    setCustomerModalOpen(true)
  }
  const closeCustomersModal = () => {
    setCustomerModalOpen(false)
  }
  const openCustomAmountModal = () => {
    setCustomAmountModalOpen(true)
  }
  const closeCustomAmountModal = () => {
    setCustomAmountModalOpen(false)
  }
  const openEmployeesModal = () => {
    setEmployeeModalOpen(true)
  }
  const closeEmployeesModal = () => {
    setEmployeeModalOpen(false)
  }
  const openDiscountsModal = () => {
    setDiscountModalOpen(true)
  }
  const closeDiscountsModal = () => {
    setDiscountModalOpen(false)
  }
  const openProductsModal = () => {
    setProductsModal(true)
  }
  const closeProductsModal = () => {
    setProductsModal(false)
  }
  const chooseCustomer = row => {
    setSelectedCustomer(row)
  }
  const chooseEmployee = row => {
    setSelectedEmployee(row)
  }
  const handleCustomAmount = event => {
    setCustomAmount(event.detail.value)
  }
  useEffect(() => {
    if (productsModalOpen && businessData) {
      getServicesList()
      getProductsList()
    }
  }, [productsModalOpen, businessData])
  useEffect(() => {
    getDiscountsList()
  }, [discountModalOpen])

  useEffect(() => {
    getCustomersList()
    getEmployeesList()
  }, [])

  useEffect(() => {
    setSelectedDiscounts([])
    setSelectedProducts([])
    setSelectedServices([])
    setCustomers([])
    setSelectedCustomer(false)
    setSelectedEmployee(false)
  }, [])
  const removeItem = (itemToRemove, type) => {
    let filteredItems

    switch (type) {
      case 'service':
        filteredItems = selectedServices.filter(item => item._id !== itemToRemove._id)
        setSelectedServices(filteredItems)
        break
      case 'product':
        filteredItems = selectedProducts.filter(item => item._id !== itemToRemove._id)
        setSelectedProducts(filteredItems)
        break
      case 'discount':
        filteredItems = selectedDiscounts.filter(item => item._id !== itemToRemove._id)
        setSelectedDiscounts(filteredItems)
        break
      default:
        break
    }
  }

  const data = calculatePayment(
    selectedServices,
    selectedProducts,
    selectedDiscounts,
    null,
    null,
    null,
    null,
  )
  const resetData = () => {
    setSelectedServices([])
    setSelectedProducts([])
    setSelectedDiscounts([])
    setCustomers([])
    setSelectedCustomer(false)
    setSelectedEmployee(false)
    setCustomAmount('')
    setLoading(false)
  }
  const chargeSale = async () => {
    setLoading(true)
    let input = {
      customerId: selectedCustomer?._id,
      businessId: businessData?._id,
      locationId: locationId,
      charge: parseFloat(data.totalPrice) + parseFloat(customAmount || 0),
      serviceInfo: selectedServices.map((item, index) => {
        return {
          _id: item._id,
          price: parseFloat(item.price),
        }
      }),
      productInfo: selectedProducts.map((item, index) => {
        return {
          _id: item._id,
          price: parseFloat(item.price),
        }
      }),
      discountInfo: selectedDiscounts.map((item, index) => {
        return {
          _id: item._id,
          value: parseFloat(item.discountValue),
          type: item.discountType,
        }
      }),
      tax: data.taxes,
      customAmount: parseFloat(customAmount),
    }
    if (selectedEmployee?._id) {
      input.employeeId = selectedEmployee?._id
    }
    try {
      const response = await axios.post('/sale/', input)
      console.log(response, 'responseresponse')
      if (response.status === 200) {
        setLoading(false)
        resetData()
      }
    } catch (error) {}
    console.log(input, 'inputinput')
  }

  return (
    <IonPage id='main-content'>
      <CommonHeader title={'Sale Details'} backIcon={true}></CommonHeader>
      <IonContent>
        <StandardContainer>
          <AddCustomerOrEmployee
            openCustomersModal={openCustomersModal}
            selectedCustomer={selectedCustomer}
            openEmployeesModal={openEmployeesModal}
            selectedEmployee={selectedEmployee}
          />
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <IonButton
              onClick={openProductsModal}
              style={{ color: colors.primaryBlue }}
              fill='none'
            >
              Add Item
            </IonButton>
            <IonButton
              onClick={openCustomAmountModal}
              style={{ color: colors.primaryBlue }}
              fill='none'
            >
              Add Custom Amount
            </IonButton>
          </div>
          {(selectedDiscounts.length > 0 ||
            selectedProducts.length > 0 ||
            selectedServices.length > 0 ||
            customAmount > 0) && (
            <CartItemsList
              customAmount={customAmount}
              selectedServices={selectedServices}
              selectedProducts={selectedProducts}
              selectedDiscounts={selectedDiscounts}
              removeItem={removeItem}
              handleServiceSelect={handleServiceSelect}
              handleDiscountSelect={handleDiscountSelect}
              handleProductSelect={handleProductSelect}
              openDiscountsModal={openDiscountsModal}
            />
          )}

          <div style={{ margin: '0 1rem' }}>
            <IonButton
              onClick={chargeSale}
              disabled={
                (!selectedServices.length && !selectedProducts.length && !customAmount) ||
                !(selectedCustomer && selectedCustomer?._id) ||
                loading
              }
              expand='full'
            >
              {loading && <IonSpinner style={{ marginRight: '0.8rem' }} name='lines' />}
              Charge{' '}
              {data && data.totalPrice
                ? `$${parseFloat(data.totalPrice) + parseFloat(customAmount ? customAmount : 0)}`
                : ''}
            </IonButton>
          </div>
          <CustomerListModal
            customersModalOpen={customersModalOpen}
            closeCustomersModal={closeCustomersModal}
            chooseCustomer={chooseCustomer}
            customers={customers}
            selectedCustomer={selectedCustomer}
          />
          <EmployeeListModal
            employeesModalOpen={employeesModalOpen}
            closeEmployeesModal={closeEmployeesModal}
            chooseEmployee={chooseEmployee}
            employees={employees}
            selectedEmployee={selectedEmployee}
          />
          <ProductListModal
            productsModalOpen={productsModalOpen}
            services={services}
            products={products}
            closeProductsModal={closeProductsModal}
            selectedServices={selectedServices}
            selectedProducts={selectedProducts}
            handleProductSelect={handleProductSelect}
            handleServiceSelect={handleServiceSelect}
            removeItem={removeItem}
          />
          <CustomAmountModal
            customAmountModalOpen={customAmountModalOpen}
            openCustomAmountModal={openCustomAmountModal}
            closeCustomAmountModal={closeCustomAmountModal}
            customAmount={customAmount}
            handleCustomAmount={handleCustomAmount}
          />
          <DiscountListModal
            discountModalOpen={discountModalOpen}
            closeDiscountsModal={closeDiscountsModal}
            discounts={discounts}
            selectedDiscounts={selectedDiscounts}
            handleDiscountSelect={handleDiscountSelect}
            removeItem={removeItem}
          />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
