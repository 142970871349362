import React, { useState, useEffect, useContext } from 'react';
import { Typography, Button } from '@mui/material';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import { IonCard } from '@ionic/react';

export default function ArchiveEmployee({ employee, getEmployee }) {
    const history = useHistory
    const archiveEmployee = async () => {
        if (window.confirm('Are you sure you want to archive the employee?')) {
            // Delete it!
            try {
                await axios.delete(`/employee?id=${employee._id}`)
                history.push(-1)
            } catch (error) {
                alert("I'm sorry there was a problem. Please try again later AE-1002")
            }

        }
    }

    const unArchiveEmployee = async () => {
        if (window.confirm('Are you sure you want to unarchive the employee?')) {
            // Delete it!
            try {
                await axios.post(`/employee/unarchive`, { id: employee._id })
                getEmployee()
            } catch (error) {
                alert("I'm sorry there was a problem. Please try again later UAE-1001")
            }

        }
    }

    return (
        <>
            <IonCard style={{padding:20, display:'flex', justifyContent:'center'}}>
                {employee.isArchived ?
                    <Button color="warning" variant="contained" onClick={unArchiveEmployee}>Unarchive Employee</Button>
                    :
                    <Button color="warning" variant="contained" onClick={archiveEmployee}>Archive Employee</Button>
                }
            </IonCard>
        </>
    )
}
