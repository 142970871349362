import { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import axios from 'axios'
import List from './list'
import { IonCard } from '@ionic/react'

export default function DetailsServicesView({ businessId, locationId, employee, updateEmployee }) {
  const [locationServices, setLocationServices] = useState()
  useEffect(() => {
    getServicesList()
  }, [])

  const getServicesList = async () => {
    let locQuery = locationId ? `&locationId=${locationId}` : ''
    let response = await axios.get(`/service/getList?businessId=${businessId}${locQuery}`)
    setLocationServices(response.data)
  }

  function updateServices(value) {
    updateEmployee({ ...employee, ...{ services: value } })
  }
  function updateServicesDurationInfo(value) {
    updateEmployee({ ...employee, ...{ servicesInfo: value } })
  }

  return (
    <IonCard style={{ padding: 20 }}>
      <Typography variant='subtitle1'>Services {employee.firstName} Provides</Typography>

      <List
        locationServices={locationServices}
        updateServices={updateServices}
        updateServicesDurationInfo={updateServicesDurationInfo}
        employeeServices={employee.services}
        employeeServicesInfo={employee.servicesInfo}
      />
    </IonCard>
  )
}
