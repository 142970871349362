import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import PersonIcon from '@mui/icons-material/Person'
import AddIcon from '@mui/icons-material/Add'
import TextField from '@mui/material/TextField'
import { blue } from '@mui/material/colors'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import Switch from '@mui/material/Switch'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import dayjs from 'dayjs'
import { getFormattedMediumDate, getWeekday } from '../../utils'
import { DialogActions, Typography } from '@mui/material'
import TimeSelector from '../timeSelector'

const times = [
  '5:00 AM',
  '5:30 AM',
  '6:00 AM',
  '6:30 AM',
  '7:00 AM',
  '7:30 AM',
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
  '8:00 PM',
  '8:30 PM',
  '9:00 PM',
  '9:30 PM',
  '10:00 PM',
  '10:30 PM',
]

function getDefaultData() {
  return {
    dateText: getFormattedMediumDate(new Date()),
    dateEpoch: new Date().getTime(),
    enabled: false,
    startTime: null,
    endTime: null,
  }
}

export default function SimpleDialog({ onClose, open, handleSave, standardSchedule }) {
  const [data, setData] = React.useState(getDefaultData())
  const [errorMessage, setErrorMessage] = React.useState('error')
  const [isSaving, setIsSaving] = React.useState(false)

  React.useEffect(() => {
    setData(getDefaultData())
    setIsSaving(false)
    setErrorMessage('')
  }, [open])

  const save = () => {
    setErrorMessage('')
    if (data.enabled) {
      if (!data.startTime || !data.endTime) {
        setErrorMessage('**Open time and close time must be filled out')
        return
      }
    }
    setIsSaving(true)
    handleSave(data)
  }

  const handleClose = () => {
    onClose()
  }

  const handleListItemClick = (value, d) => {
    onClose(value)
  }

  function handleChange(id, fieldKey, newValue) {
    setData({ ...data, ...{ [fieldKey]: newValue } })
  }

  function updateDate(id, date) {
    let weekday = getWeekday(date)
    let standardScheduleForDay = standardSchedule[weekday]
    let startTime = standardScheduleForDay.startTime
    let endTime = standardScheduleForDay.endTime
    let enabled = standardScheduleForDay.enabled

    setData({
      ...data,
      ...{
        startTime,
        endTime,
        enabled,
        dateText: getFormattedMediumDate(date),
        dateEpoch: date.getTime(),
      },
    })
  }

  return (
    <Dialog onClose={handleClose} open={open} sx={{ margin: 3, borderRadius: 4 }} fullScreen>
      <DialogTitle sx={{ borderRadius: 4 }}>Add a Modified Schedule</DialogTitle>
      <TableContainer sx={{ borderRadus: 4 }}>
        <Table aria-label='simple table' size='small'>
          <TableBody>
            <TableRow key={data._id} sx={{ '& > *': { borderBottom: 'none' } }}>
              <TableCell>
                <CalendarSelect dateText={data.dateText} id={data._id} updateDate={updateDate} />
              </TableCell>
              <TableCell align='right'>
                <Switch
                  checked={data.enabled}
                  onChange={e => {
                    handleChange(data, 'enabled', e.target.checked)
                  }}
                />
                {data.enabled ? ' Open ' : 'Closed'}
              </TableCell>
            </TableRow>

            <TableRow key={data._id} sx={{ '& > *': { borderBottom: 'none' } }}>
              <TableCell>
                {data.enabled ? (
                  <div style={{ width: 90, height: 10 }}>Start Time</div>
                ) : (
                  <div style={{ width: 90, height: 1 }} />
                )}
              </TableCell>
              <TableCell>
                {data.enabled ? (
                  <div style={{ width: 90, height: 10 }}>End Time</div>
                ) : (
                  <div style={{ width: 90, height: 1 }} />
                )}
              </TableCell>
            </TableRow>

            <TableRow key={data._id} sx={{ '& > *': { borderBottom: 'none' } }}>
              <TableCell>
                {data.enabled ? (
                  <TimeSelector
                    dayKey={data._id}
                    fieldKey='startTime'
                    value={data.startTime}
                    handleChange={handleChange}
                  />
                ) : (
                  <div style={{ width: 90, height: 1 }} />
                )}
              </TableCell>
              <TableCell>
                {data.enabled ? (
                  <TimeSelector
                    dayKey={data._id}
                    fieldKey='endTime'
                    value={data.endTime}
                    handleChange={handleChange}
                  />
                ) : (
                  <div style={{ width: 90, height: 1 }} />
                )}
              </TableCell>
            </TableRow>
            <TableRow key={data._id} sx={{ '& > *': { borderBottom: 'none' } }}>
              <TableCell>
                <div style={{ width: 90, height: 10 }} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Typography variant='subtitle2' style={{ color: 'red', marginLeft: 18 }}>
          {errorMessage}
        </Typography>
      </TableContainer>
      <DialogActions>
        <Button onClick={handleClose} fullWidth variant='outlined'>
          Cancel
        </Button>
        <Button fullWidth disableElevation variant='contained' disabled={isSaving} onClick={save}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function CalendarSelect({ dateText, updateDate, id }) {
  const [value, setValue] = React.useState(dayjs(dateText))

  const handleChange = newValue => {
    updateDate(id, newValue.$d)
    setValue(newValue)
  }

  return (
    <div style={{ minWidth: 120 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          size='small'
          inputFormat='MM/DD/YYYY'
          value={dayjs(value)}
          onChange={handleChange}
          renderInput={params => <TextField size='small' style={{ width: 150 }} {...params} />}
        />
      </LocalizationProvider>
    </div>
  )
}
