import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { getFormattedMediumDate, getFormattedMediumDateWithoutTimeZone } from '../utils'
import { colors } from '../theme/colors'
import './MyCalendar.css'
import { useHistory } from 'react-router'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'
import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRouterLink,
  IonRow,
  useIonViewWillEnter,
} from '@ionic/react'
import { chevronBack, chevronForward } from 'ionicons/icons'
import { AuthContext } from '../auth'
import axios from 'axios'
import AddHeader from '../commonComponents/AddHeader'
import WalkinAppointments from './WalkinAppointments'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'

const MyCalendar = ({ handleView }) => {
  // Define colors based on different statuses
  const statusColors = {
    'Scheduled': { bgColor: '#45454552', textColor: '#454545' },
    'Arrived': { bgColor: '#ff572238', textColor: '#ff5722' },
    'Canceled': { bgColor: '#ff572229', textColor: '#ff5722' },
    'Complete': { bgColor: '#00b05057', textColor: '#00b050' },
    'In Progress': { bgColor: '#3880ff4d', textColor: colors.primaryBlue },
    'No Show': { bgColor: '#d6d6d678', textColor: '#919191' },
  }
  const [events, setEvents] = useState()
  const { businessData, userData, locationId, locationData } = useContext(AuthContext)

  const [schedule, setSchedule] = useState(false)
  const [loading, setLoading] = useState(false)
  const [employees, setEmployees] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [viewType, setViewType] = useState('day')
  const [walkinsList, setFilteredWalkins] = useState([])
  const prevSelectedDateRef = useRef(selectedDate)

  const history = useHistory()
  const handleEventClick = eventClickInfo => {
    history.push(`/core/appointments/${eventClickInfo?.extendedProps?._id}`)
  }

  const handleDateClick = async (clickedDate, onNavigate) => {
    // Update the selected date
    const clicked = getFormattedMediumDateWithoutTimeZone(new Date(clickedDate?._d))
    const selected = getFormattedMediumDateWithoutTimeZone(new Date(selectedDate))

    const daysDifference = moment(clicked).diff(moment(selected), 'days')

    // Perform onNavigate the absolute value of daysDifference times
    if (daysDifference !== 0) {
      const absDaysDifference = Math.abs(daysDifference)

      for (let i = 0; i < absDaysDifference; i++) {
        // Adjust the direction based on the daysDifference
        const direction = daysDifference >= 0 ? 'NEXT' : 'PREV'
        await onNavigate(direction)
      }

      setSelectedDate(clicked) // Update the selected date after navigation
    }
  }

  const handleViewChange = newView => {
    // Handle the view change here

    setViewType(newView)
    // You can perform additional actions based on the new view
  }

  const handlePrevWeek = async onNavigate => {
    const prevWeek = moment(selectedDate).subtract(7, 'days')
    setSelectedDate(getFormattedMediumDateWithoutTimeZone(prevWeek.toDate()))
    for (let i = 0; i < Math.abs(7); i++) {
      await onNavigate('PREV')
    }
  }

  const handleNextWeek = async onNavigate => {
    const nextWeek = moment(selectedDate).add(7, 'days')
    setSelectedDate(getFormattedMediumDateWithoutTimeZone(nextWeek.toDate()))
    for (let i = 0; i < Math.abs(7); i++) {
      await onNavigate('NEXT')
    }
  }
  const getEmployeesList = async eventsData => {
    if (!businessData?._id) return
    setLoading(true)

    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      // Fetch employees data from the server based on businessId and optional locationId
      let response = await axios.get(`/employee/getList?businessId=${businessData?._id}${locQuery}`)

      // Extract unique employee IDs from the events
      const employeeIdsInEvents = [...new Set(eventsData.map(event => event?.barber?._id))]

      // Filter employees to include only those with events
      const filteredEmployeesInEvents = response.data.filter(employee =>
        employeeIdsInEvents.includes(employee._id),
      )

      // Format filtered employees data
      const filteredEmployeeFormattedData = filteredEmployeesInEvents.map((item, index) => ({
        id: item._id,
        title: item.firstName + ' ' + item.lastName,
      }))

      // Sort employees alphabetically by title
      const sortedEmployeeByName = filteredEmployeeFormattedData.sort((a, b) =>
        a.title.localeCompare(b.title),
      )

      // Set sorted and formatted employees data in the state
      setEmployees(sortedEmployeeByName)
    } catch (error) {
      // Handle errors by setting an error message
    }

    // Set loading state to false after API call is complete
    setLoading(false)
  }
  const getDateFromStartTimeAndEndTime = data => {
    // Example data

    // Convert selectedDate to a Date object
    const selectedDate = new Date(data.selectedDate)

    // Parse the time strings to get the hours and minutes
    const startTimeComponents = data?.startTimeText?.match(/(\d{1,2}):(\d{2}) (\w{2})/)
    const endTimeComponents = data?.endTimeText?.match(/(\d{1,2}):(\d{2}) (\w{2})/)

    // Convert the time strings to 24-hour format
    let startHour = parseInt(startTimeComponents?.[1])
    const startMinute = parseInt(startTimeComponents?.[2])
    const startMeridiem = startTimeComponents?.[3].toUpperCase()
    if (startMeridiem === 'PM' && startHour !== 12) {
      startHour += 12
    } else if (startMeridiem === 'AM' && startHour === 12) {
      startHour = 0
    }

    let endHour = parseInt(endTimeComponents?.[1])
    const endMinute = parseInt(endTimeComponents?.[2])
    const endMeridiem = endTimeComponents?.[3].toUpperCase()
    if (endMeridiem === 'PM' && endHour !== 12) {
      endHour += 12
    } else if (endMeridiem === 'AM' && endHour === 12) {
      endHour = 0
    }

    // Set the time components to the selectedDate
    selectedDate.setHours(startHour, startMinute)

    // Create new Date object for startTime
    const startTime = new Date(selectedDate)

    // Set the time components to the selectedDate
    selectedDate.setHours(endHour, endMinute)

    // Create new Date object for endTime
    const endTime = new Date(selectedDate)
    return { startTime, endTime }
  }
  const getAppointmentsList = async dateInput => {
    console.log(dateInput, 'dateInputdateInput')
    if (!businessData?._id) return
    setLoading(true)
    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      let date = getFormattedMediumDate(dateInput)

      let response = await axios.get(
        `/appointment/getList?businessId=${businessData?._id}${locQuery}&dateText=${date}`,
      )
      let filteredAppointments = response.data.filter(item => item.status !== 'Canceled')
      const data = filteredAppointments
        // Filter out items with status 'Canceled'
        .map((item, index) => {
          const inputData = {
            endTimeText: item.endTimeText,
            selectedDate: item.selectedDate,
            startTimeText: item.startTimeText,
          }
          const formattedDate = getDateFromStartTimeAndEndTime(inputData)
          let dataitem = {
            resourceId: item?.barber?._id,
            start: formattedDate.startTime,
            end: formattedDate.endTime,
            extendedProps: { ...item },
          }
          if (item?.barber?._id && !item.endTime) {
            dataitem.allDay = true
            dataitem.start = new Date(dateInput)
            dataitem.end = new Date(dateInput)
          }
          return dataitem
        })

      let filteredWalkins = response.data?.filter(a => a.type == 'Walkin' && !a?.barber?._id)
      console.log(filteredWalkins, 'filteredWalkins')
      setFilteredWalkins(filteredWalkins)
      setEvents(data)
      getEmployeesList(filteredAppointments)
    } catch (error) {
      console.log(error, 'errorrrrrrrrrrrrrrrrrrrrrrrrrrrrr')
    }
    setLoading(false)
  }
  useEffect(() => {
    getAppointmentsList(new Date())
    const scheduleData = getScheduleForDate(selectedDate)
    setSchedule(scheduleData)
  }, [])
  useEffect(() => {
    prevSelectedDateRef.current = selectedDate
    getAppointmentsList(selectedDate)
    const scheduleData = getScheduleForDate(selectedDate)
    setSchedule(scheduleData)
  }, [selectedDate, businessData, locationId])
  const getScheduleForDate = selectedDate => {
    const selectedLocation = GetLocationDataBasedOnLocationId(locationData, locationId)
    let schedule = selectedLocation.standardSchedule
    const dayOfWeek = new Date(selectedDate).getDay() // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    const dayName = Object.keys(schedule).find(day => schedule[day].dayOfTheWeek === dayOfWeek + 1)

    if (dayName && schedule[dayName].enabled) {
      return {
        startTime: schedule[dayName].startTime,
        endTime: schedule[dayName].endTime,
      }
    } else {
      return {
        startTime: 'Closed',
        endTime: 'Closed',
      }
    }
  }

  useEffect(() => {
    const scheduleData = getScheduleForDate(selectedDate)
    setSchedule(scheduleData)
  }, [locationId])

  useIonViewWillEnter(() => {
    getAppointmentsList(prevSelectedDateRef.current)
  })
  const Toolbar = ({ date, view, views, label, onView, onNavigate, localizer }) => {
    const currentWeek = moment(selectedDate).startOf('week')
    const weekDays = Array.from({ length: 7 }, (_, i) => moment(currentWeek).add(i, 'days'))

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          backgroundColor: 'white',
          zIndex: 999,
          position: 'sticky',
          top: 0,
        }}
      >
        <div className='toolbar-header'>
          {viewType === 'day' && (
            <>
              <div className='week-dates-container' style={{ margin: '1rem 0', width: '100%' }}>
                <IonIcon
                  onClick={() => (loading ? null : handlePrevWeek(onNavigate))}
                  className='arrow-icon'
                  icon={chevronBack}
                />
                {weekDays.map((day, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <div className='day'>
                      <IonLabel
                        style={{
                          fontSize: '10px',
                          color:
                            day.isoWeekday() === 6 || day.isoWeekday() === 7 ? 'grey' : 'inherit',
                        }}
                      >
                        {day.format('ddd').slice(0, 1)}
                      </IonLabel>
                    </div>
                    <button
                      style={{
                        color: day.isoWeekday() === 6 || day.isoWeekday() === 7 ? 'grey' : '',
                      }}
                      onClick={() => (loading ? null : handleDateClick(day, onNavigate))}
                      className={`circle-button ${
                        day.isSame(selectedDate, 'day') ? ' active' : ''
                      }`}
                    >
                      <div className='date'>{day.format('D')}</div>
                    </button>
                  </div>
                ))}
                <IonIcon
                  onClick={() => (loading ? null : handleNextWeek(onNavigate))}
                  className='arrow-icon'
                  icon={chevronForward}
                />
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
  const eventPropGetter = useCallback((event, start, end, isSelected) => {
    const item = event.extendedProps

    // Get colors based on the status of the event

    const backgroundColor = statusColors[item.status]?.bgColor || '#ccc'
    const textColor = statusColors[item.status]?.textColor || '#000'

    return {
      style: {
        padding: 'unset',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        display: 'block',
        backgroundColor: backgroundColor,
        whiteSpace: 'pre-wrap',
        fontWeight: 'bold',
        color: textColor,
        borderRadius: '2px',
        borderTop: 'unset',
        borderBottom: 'unset',
        borderRight: 'unset',
        borderLeft: `4px solid ${textColor}`,
      },
    }
  }, [])

  const localizer = momentLocalizer(moment)
  const customTimeFormat = time => {
    const formattedDate = moment(time).format('h A')
    return formattedDate == '12 PM' ? 'Noon' : formattedDate
  }

  return (
    <>
      <AddHeader
        title={getFormattedMediumDate(selectedDate)}
        loading={loading}
        defaultView={'calendar'}
        handleView={handleView}
        // handleAddClick={openAddDialog}
      />
      {}
      <IonContent>
        <IonRow className='calendar-container'>
          {walkinsList && walkinsList.length > 0 && (
            <WalkinAppointments walkinsList={walkinsList} />
          )}

          <Calendar
            formats={{ timeGutterFormat: customTimeFormat }} // Use the customTimeFormat for time slots
            components={{
              toolbar: Toolbar,
              event: eventInfo => {
                // Get colors based on the status of the event
                const item = eventInfo.event.extendedProps

                const textColor = statusColors[item.status]?.textColor || '#000'
                const services = item?.services?.map(item => item.name) || []
                return (
                  <>
                    <IonRouterLink
                      style={{ height: '100%', marginTop: '5px' }}
                      routerLink={`/core/appointments/${item?._id}`}
                    >
                      <IonRow style={{ marginLeft: '5px', color: textColor }}>
                        {eventInfo.event.allDay && (
                          <IonLabel style={{ fontSize: '10px' }}>{'(Walk-in)'}</IonLabel>
                        )}
                        <IonLabel style={{ fontSize: '10px' }}>
                          {`${item.customer.firstName} ${item.customer.lastName} | `}
                        </IonLabel>
                        <IonLabel style={{ fontSize: '10px' }}>{services.join(',')} | </IonLabel>
                        <IonLabel
                          style={{ fontSize: '10px' }}
                        >{`(${item.durationMin}min)`}</IonLabel>
                      </IonRow>
                    </IonRouterLink>
                  </>
                )
              },
            }}
            localizer={localizer}
            min={new Date(`${new Date().toISOString().split('T')[0]} ${schedule.startTime}`)}
            max={new Date(`${new Date().toISOString().split('T')[0]} ${schedule.endTime}`)}
            events={events}
            eventPropGetter={eventPropGetter}
            resources={employees}
            resourceIdAccessor='id'
            resourceTitleAccessor='title'
            startAccessor='start'
            endAccessor='end'
            view={viewType}
            onView={view => handleViewChange(view)}
            onDoubleClickEvent={handleEventClick}
            views={{
              month: true,
              day: true,
            }}
          />
        </IonRow>
      </IonContent>
    </>
  )
}

export default MyCalendar
