import { IonCheckbox, IonCol, IonIcon, IonItem, IonLabel, IonList, IonRow } from '@ionic/react'
import { removeCircleOutline } from 'ionicons/icons'
import { calculatePayment } from '../sharedComponents/paymentCalculation'
import { colors } from '../theme/colors'
import ServicesList from './ServicesList'
import ProductsList from './ProductsList'

export default function CartItemsList({
  selectedServices,
  selectedProducts,
  selectedDiscounts,
  removeItem,
  handleServiceSelect,
  handleDiscountSelect,
  handleProductSelect,
  openDiscountsModal,
  customAmount,
}) {
  function DiscountType({ row }) {
    if (row?.discountType == 'percentage') {
      return <>{row?.discountValue}% off</>
    } else if (row?.discountType == 'fixed') {
      return <>${row?.discountValue} off</>
    } else {
      return null
    }
  }
  const renderDiscounts = (discounts, displaycheckbox) => {
    return discounts.map((discount, index) => {
      return (
        discount.couponType == 'servicesAndProducts' && (
          <IonItem key={discount._id}>
            <h6>{discount.name}</h6>
            <IonLabel slot='end'>
              <DiscountType row={discount} />
            </IonLabel>

            {displaycheckbox && (
              <IonCheckbox
                mode='ios'
                slot='end'
                checked={selectedDiscounts.some(
                  selectedDiscount => selectedDiscount._id === discount._id,
                )}
                onIonChange={() => handleDiscountSelect(discount)}
              />
            )}
            {!displaycheckbox && (
              <IonIcon
                slot='end'
                style={{ cursor: 'pointer', color: 'red' }}
                onClick={() => removeItem(discount, 'discount')}
                icon={removeCircleOutline}
              />
            )}
          </IonItem>
        )
      )
    })
  }
  const data = calculatePayment(
    selectedServices,
    selectedProducts,
    selectedDiscounts,
    null,
    null,
    null,
    null,
  )
  console.log(data, 'datadatadatadata')
  return (
    <>
      <div style={{ padding: '0 1rem' }}>
        <h2>Cart Items</h2>
      </div>
      {selectedServices && selectedServices.length > 0 && (
        <IonList mode='ios' inset={true}>
          <h6 style={{ marginLeft: '1rem', color: 'grey' }}>Services</h6>
          <ServicesList
            services={selectedServices}
            displaycheckbox={false}
            selectedServices={selectedServices}
            handleServiceSelect={handleServiceSelect}
            removeItem={removeItem}
          />
        </IonList>
      )}
      {selectedProducts && selectedProducts.length > 0 && (
        <IonList mode='ios' inset={true}>
          <h6 style={{ marginLeft: '1rem', color: 'grey' }}>Products</h6>
          <ProductsList
            products={selectedProducts}
            displaycheckbox={false}
            selectedProducts={selectedProducts}
            removeItem={removeItem}
            handleProductSelect={handleProductSelect}
          />
        </IonList>
      )}
      {selectedDiscounts && selectedDiscounts.length > 0 && (
        <IonList mode='ios' inset={true}>
          <h6 style={{ marginLeft: '1rem', color: 'grey' }}>Discounts</h6>
          {renderDiscounts(selectedDiscounts, false)}
        </IonList>
      )}

      <IonList style={{ margin: '0 1rem' }} inset={true} mode='ios'>
        {(selectedProducts.length > 0 || selectedServices.length > 0) && (
          <IonItem>
            <IonLabel>{'Products And Services Total'}</IonLabel>
            <IonLabel style={{ marginRight: '3rem' }} slot='end'>
              ${parseFloat(data.productsTotal) + parseFloat(data.servicesTotal)}
            </IonLabel>
          </IonItem>
        )}
        {selectedDiscounts.length > 0 && (
          <IonItem>
            <IonLabel>{'Discounts Total'}</IonLabel>
            <IonLabel style={{ marginRight: '3rem' }} slot='end'>
              ${parseFloat(data.discountsTotal)}
            </IonLabel>
          </IonItem>
        )}
        {customAmount > 0 && (
          <IonItem>
            <IonLabel>{'Custom Amount'}</IonLabel>
            <IonLabel style={{ marginRight: '3rem' }} slot='end'>
              ${customAmount}
            </IonLabel>
          </IonItem>
        )}
        <IonItem>
          <IonLabel>Tax</IonLabel>
          <IonLabel style={{ marginRight: '3rem' }} slot='end'>
            ${data.taxes}
          </IonLabel>
        </IonItem>
      </IonList>

      <IonRow style={{ margin: '0 1rem 1rem 1rem' }} key={'TOTAL'}>
        <IonCol>
          <h6 style={{ fontWeight: 'bold', marginLeft: '1rem' }}>{'TOTAL' || ''}</h6>
        </IonCol>
        <IonCol size='auto'>
          <h6 style={{ fontWeight: 'bold', marginRight: '3rem' }}>
            ${parseFloat(data.totalPrice) + parseFloat(customAmount || 0)}
          </h6>
        </IonCol>
      </IonRow>
      {(selectedServices.length > 0 || selectedProducts.length > 0) && (
        <div style={{ margin: '0 1rem 1rem 1rem' }}>
          <IonLabel onClick={openDiscountsModal} style={{ color: colors.primaryBlue }}>
            Add Discount
          </IonLabel>
        </div>
      )}
    </>
  )
}
