import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonReorder,
  IonRow,
  IonToggle,
} from '@ionic/react'
import React, { useState } from 'react'
import { calculateCostPerMonth, getPricing } from '../utils'
import { pencilOutline } from 'ionicons/icons'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { colors } from '../theme/colors'

export default function SubscriptionOptionItem({
  pricingList,
  subscriptionPackage_id,
  handleUpdateOption,
  reorderEnabled,
  addOption,
  handlePriceToggle,
  handleNameChange,
}) {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [optionToEdit, setOptionToEdit] = useState(false)
  const [dataChanged, setDataChanged] = useState(false)
  const [name, setName] = useState(false)
  const [priceEnabled, setPriceEnabled] = useState(false)
  const openEditModal = option => {
    setOptionToEdit(option)
    setName(option.name)
    setPriceEnabled(option.active)
    setEditModalOpen(true)
  }
  const closeEditModal = () => {
    setEditModalOpen(false)
    setOptionToEdit(false)
    setDataChanged(false)
    setName(false)
    setPriceEnabled(false)
  }
  const changeName = (value, option) => {
    setDataChanged(true)
    setName(value)
  }
  const changePrice = (value, option) => {
    setDataChanged(true)
    setPriceEnabled(value)
  }
  const saveUpdatedData = () => {
    handleNameChange(name, optionToEdit)
    handlePriceToggle(priceEnabled, optionToEdit)
    closeEditModal()
  }
  return (
    <>
      {pricingList &&
        pricingList.map((option, index) => {
          return (
            <IonItem
              key={option.stripePriceId}
              style={{
                pading: 0,
                margin: 0,
              }}
              detail={!subscriptionPackage_id}
              onClick={() => (!subscriptionPackage_id ? handleUpdateOption(option, index) : null)}
            >
              {reorderEnabled ? (
                <IonCol style={{ padding: 0, margin: 0 }} size='auto'>
                  <div
                    style={{
                      height: 2,
                      width: 20,
                      backgroundColor: 'grey',
                      margin: 4,
                      marginTop: 20,
                      marginLeft: 4,
                    }}
                  />
                  <div style={{ height: 2, width: 20, backgroundColor: 'grey', margin: 4 }} />
                  <div style={{ height: 2, width: 20, backgroundColor: 'grey', margin: 4 }} />
                  <div
                    style={{
                      height: 2,
                      width: 20,
                      backgroundColor: 'transparent',
                      marginLeft: 20,
                    }}
                  />
                </IonCol>
              ) : null}
              <IonReorder style={{ width: '100%' }}>
                <IonGrid style={{ pading: 0, margin: 0, marginBottom: 10 }}>
                  <IonRow
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      pading: 0,
                      margin: 0,
                    }}
                  >
                    <IonCol sizeXs='4' sizeMd='4'>
                      <h6 style={{ fontWeight: 'bold', color: option.active ? '' : 'grey' }}>
                        {option.name}
                      </h6>
                    </IonCol>
                    <IonCol
                      sizeXs='6'
                      sizeMd='6'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      {option.amount && (
                        <IonRow style={{ width: '100%' }}>
                          {option.interval_count > 1 ? (
                            <h6
                              style={{
                                color: option.active ? '' : 'grey',
                                fontSize: '14px',
                                fontWeight: 'bold',
                              }}
                            >
                              {getPricing(option)}
                            </h6>
                          ) : (
                            <h6
                              style={{
                                color: option.active ? '' : 'grey',
                                fontSize: '14px',
                                fontWeight: 'bold',
                              }}
                            >
                              {Math.abs(option.amount)} every {option.interval_count}{' '}
                              {option.interval_count > 1 ? `${option.interval}s` : option.interval}
                            </h6>
                          )}
                        </IonRow>
                      )}
                      {option.amount && (
                        <IonRow style={{ width: '100%' }}>
                          <h6 style={{ margin: '0', color: 'grey', fontSize: '12px' }}>
                            $
                            {calculateCostPerMonth(
                              option.amount,
                              option.interval,
                              option.interval_count,
                            )}
                            &nbsp;per month
                          </h6>
                        </IonRow>
                      )}
                    </IonCol>
                    {subscriptionPackage_id && (
                      <IonCol>
                        <IonIcon
                          onClick={() => openEditModal(option)}
                          color='primary'
                          icon={pencilOutline}
                        />
                      </IonCol>
                    )}
                  </IonRow>
                </IonGrid>
              </IonReorder>
            </IonItem>
          )
        })}
      <IonModal isOpen={editModalOpen}>
        <CommonHeader
          title={'Edit Option'}
          closeButton={true}
          saveButton={true}
          handleSaveButton={saveUpdatedData}
          saveDisabled={!dataChanged}
          closeModal={closeEditModal}
        ></CommonHeader>

        {/* IonContent for displaying image details */}
        <IonContent className='regularBackground'>
          {/* StandardContainer for consistent container appearance */}
          <StandardContainer>
            <IonGrid>
              <IonItem>
                <IonLabel position='stacked'>Name</IonLabel>
                <IonInput
                  onIonChange={e => changeName(e.detail.value, optionToEdit)}
                  placeholder='Name'
                  type='text'
                  value={name}
                ></IonInput>
              </IonItem>
              {!addOption && (
                <IonItem>
                  <IonLabel>Status</IonLabel>
                  <IonToggle
                    justify='space-between'
                    size='small'
                    checked={priceEnabled ? true : false}
                    onIonChange={e => {
                      changePrice(e.detail.checked, optionToEdit)
                    }}
                  />
                </IonItem>
              )}
            </IonGrid>
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}
