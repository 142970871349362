import React, { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonInput,
  IonItem,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react'

export default function CreateTestDialog({ open, handleClose, businessId }) {
  let [isDisabled, setIsDisabled] = useState(true)
  let [name, setName] = useState('')
  const history = useHistory()
  const createButtonClicked = async e => {
    setIsDisabled(true)
    try {
      let response = await axios.post(`/location`, { name, businessId })
      console.log(response)
      if (response.status === 200) {
        history.push(`locations/${response.data._id}`)
        close()
      } else {
        setIsDisabled(false)
      }
    } catch (error) {
      close()
    }
  }
  const handleLocationName = event => {
    console.log(event.target.value, 'target.valuetarget.value')
    setName(event.target.value)
    setIsDisabled(event.target.value ? false : true)
  }
  const close = () => {
    setName('')
    setIsDisabled('')
    handleClose()
  }
  console.log(isDisabled, 'isDisabledisDisabled')
  return (
    <>
      <IonModal isOpen={open}>
        <IonHeader>
          <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonTitle>Add New Location</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={close}>Close</IonButton>
              <IonButton disabled={isDisabled} onClick={createButtonClicked} color={'primary'}>
                Save
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonItem style={{ margin: '1rem' }}>
          <IonInput
            onIonChange={handleLocationName}
            value={name}
            label='Input with placeholder'
            placeholder='Enter Location Name'
          ></IonInput>
        </IonItem>
      </IonModal>
    </>
  )
}
