import React, { useState, useEffect, useContext } from 'react';
import { Typography, Paper, TextField, Card, Avatar, Grid, Divider } from '@mui/material';
import axios from 'axios'
import { AuthContext } from '../auth'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ImageIcon from '@mui/icons-material/Person';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import HeaderComponent from './headerComponent';


export default function StepPickCustomer({ locationId, updateScheduleData, setStep }) {
    const { businessData } = useContext(AuthContext);
    const [customers, setCustomers] = useState([])
    useEffect(() => {
        getCustomersList()
    }, [])

    const getCustomersList = async () => {
        let locQuery = locationId ? `&locationId=${locationId}` : ''

        let response = await axios.get(`/customer/getList?businessId=${businessData._id}${locQuery}`)
        setCustomers(response.data)
    }

    function handleCustomerSelection(customer) {
        updateScheduleData('customer', customer)
        setStep('location')
    }



    return (
        <>
            <HeaderComponent title="Pick a Customer" showBackButton={false}/>
            {/* <TextField placeholder='Search...' fullWidth size='small'></TextField> */}
            <br></br>
            <SearchTextBox />
            <br></br>

            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {customers.map(e => (
                    <CustomerCard key={e._id} customer={e} handleCustomerSelection={handleCustomerSelection} />
                ))}
            </List>


        </>
    );
}


function CustomerCard({ customer, handleCustomerSelection }) {

    return (
        <>
            <ListItem onClick={()=>{handleCustomerSelection(customer)}} style={{cursor:'pointer'}}>
                <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={customer.firstName + " " + customer.lastName} secondary={customer.phoneNumber} />
            </ListItem>
            <Divider component="li" />
        </>
    )
}

function SearchTextBox() {
    return (
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search for a Customer..."
                inputProps={{ 'aria-label': 'search google maps' }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
        </Paper>
    )
}



// export default function FolderList() {
//   return (
//     <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
//       <ListItem>
//         <ListItemAvatar>
//           <Avatar>
//             <ImageIcon />
//           </Avatar>
//         </ListItemAvatar>
//         <ListItemText primary="Photos" secondary="Jan 9, 2014" />
//       </ListItem>
//       <ListItem>
//         <ListItemAvatar>
//           <Avatar>
//             <WorkIcon />
//           </Avatar>
//         </ListItemAvatar>
//         <ListItemText primary="Work" secondary="Jan 7, 2014" />
//       </ListItem>
//       <ListItem>
//         <ListItemAvatar>
//           <Avatar>
//             <BeachAccessIcon />
//           </Avatar>
//         </ListItemAvatar>
//         <ListItemText primary="Vacation" secondary="July 20, 2014" />
//       </ListItem>
//     </List>
//   );
// }
