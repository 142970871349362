import { IonButton, IonCard, IonSpinner } from '@ionic/react'
import axios from 'axios'
import { useContext, useState } from 'react'
import { AuthContext } from '../../auth'
import { useHistory } from 'react-router'

export default function AcceptedInvitationCard({ invitation, goBack }) {
  const { getBusinessData } = useContext(AuthContext)
  const [navigating, setNavigating] = useState(false)
  const history = useHistory()
  const navigateToBusiness = businessId => {
    setNavigating(true)
    axios.defaults.headers.common['prop-businessId'] = businessId
    localStorage.setItem('defaultBusiness', businessId)
    //when ever am switching business i am removing the default location selected
    localStorage.removeItem('defaultLocation')
    getBusinessData()
    setTimeout(() => {
      history.push('/core/dashboard')
    }, 2000)
  }
  return (
    <IonCard style={{ padding: '1rem' }}>
      <h1 style={{ fontSize: '2.5rem', color: 'black', fontWeight: 'bold', textAlign: 'center' }}>
        Invitation Accepted for {invitation?.business?.name}
      </h1>
      <IonButton
        onClick={() => navigateToBusiness(invitation?.business?._id)}
        disabled={navigating}
        style={{ marginTop: '3rem', marginBottom: '2rem' }}
        expand='full'
      >
        {navigating && <IonSpinner name='lines' style={{ marginRight: '1rem' }} />}
        Go To Business
      </IonButton>
    </IonCard>
  )
}
