import React, { useState } from 'react'
import { IonContent, IonGrid, IonLabel, IonPage, IonRow, IonCol, IonButton } from '@ionic/react'
import StandardContainer from './commonComponents/StandardContainer'
import { colors } from './theme/colors'

const SetupNewBusiness = () => {
  const [selectedItem, setSelectedItem] = useState(null)

  const gridItemStyle = {
    maxHeight: '10rem',
    height: '10rem',
    margin: '10px',
    maxWidth: '45%',
    width: '45%',
    padding: '1rem 10px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    borderRadius: '5px',
    cursor: 'pointer', // Add cursor pointer to indicate clickable
    border: '2px solid #d6d6d6',
  }

  const selectedGridItemStyle = {
    ...gridItemStyle,
    backgroundColor: colors.lightGrey, // Change background color when selected
    border: '2px solid black',
  }

  const GridItem = ({ title, description, type }) => (
    <IonCol
      style={type === selectedItem ? selectedGridItemStyle : gridItemStyle}
      onClick={() => setSelectedItem(type)}
    >
      <IonLabel style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>
        {title}
      </IonLabel>
      <h6>{description}</h6>
    </IonCol>
  )

  return (
    <IonPage id='main-content'>
      <IonContent className='regularBackground'>
        <StandardContainer>
          <IonGrid style={{ padding: '1rem' }}>
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '0.5rem',
                marginTop: '1rem',
              }}
            >
              <IonLabel style={{ fontSize: '1rem', fontWeight: 'bold' }}>SCIXXOR</IonLabel>
            </IonRow>
            <IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <h1 style={{ textAlign: 'center', fontSize: '2.5rem' }}>What brings you here?</h1>
            </IonRow>
            <IonRow
              style={{
                marginTop: '2rem',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}
            >
              <GridItem
                title='Create'
                description='I want to create a new business'
                type={'create'}
              />
              <GridItem
                title='Join'
                description='I am here to join an existing business'
                type={'join'}
              />
            </IonRow>
            {selectedItem ? (
              <IonButton
                routerLink='/setupBusinessDetails'
                style={{ marginTop: '1rem' }}
                expand='full'
                color='dark'
              >
                Next
              </IonButton>
            ) : null}
          </IonGrid>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

export default SetupNewBusiness
