import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, Typography, Select, FormControl, MenuItem, InputLabel } from '@mui/material';


export default function ScheduledDaysInAdvance({daysYouCanScheduleInAdvance, updateDaysInAdvance}) {
  // let [daysInAdvance, setDaysInAdvance] = useState(30)

  // return (
  //   <div><Typography variant="subtitle1">Days to Book in Advance</Typography>
  //   <Typography style={{marginBottom:20, fontSize:12}}>The number of days a client can book in advance.</Typography>
  //   <Typography style={{color:'grey', fontSize:12}}>Default to Location Setting</Typography></div>
  // )


  return (
      <>
    <Typography variant="subtitle1">Days to Book in Advance</Typography>
    <Typography style={{marginBottom:20, fontSize:12}}>The number of days a client can book in advance.</Typography>
    <Grid container>
      <Grid list xs={3}>
      <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth size="small">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={daysYouCanScheduleInAdvance}
          onChange={(e)=>{updateDaysInAdvance(e.target.value)}}
          dense
        >
          <MenuItem value={3}>3 days</MenuItem>
          <MenuItem value={7}>7 days</MenuItem>
          <MenuItem value={14}>14 days</MenuItem>
          <MenuItem value={21}>21 days</MenuItem>
          <MenuItem value={30}>30 days</MenuItem>
          <MenuItem value={40}>40 days</MenuItem>
          <MenuItem value={50}>50 days</MenuItem>
          <MenuItem value={60}>60 days</MenuItem>
          <MenuItem value={90}>90 days</MenuItem>
          <MenuItem value={120}>120 days</MenuItem>
          <MenuItem value={365}>365 days</MenuItem>
        </Select>
      </FormControl>
    </Box>
      </Grid>
    </Grid>
    </>
  );
}
