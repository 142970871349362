import { IonCol, IonItem, IonLabel, IonList, IonSpinner, IonToggle } from '@ionic/react'
import axios from 'axios'
import { useEffect, useState } from 'react'

export default function ManageNotifications({ customer_id }) {
  const [textNotificationsEnabled, setTextNotificationsEnabled] = useState(false)
  const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(false)
  const [customer, setCustomer] = useState()
  const [customerTemp, setCustomerTemp] = useState()
  const [updatingTextNotifications, setUpdatingTextNotifications] = useState(false)
  const [updatingEmailNotifications, setUpdatingEmailNotifications] = useState(false)
  const [loading, setLoading] = useState(true)
  const updateCustomerNotifications = async (field, value) => {
    let customerData = { ...customerTemp }
    const notificationSettings = {
      ...customerData.notificationSettings,
      [field]: value,
    }
    setCustomerTemp(customerData)
    customerData['notificationSettings'] = notificationSettings

    try {
      let response = await axios.patch(`/customer?id=${customer_id}`, customerData)
      if (response.status == 200) {
        field === 'textNotificationsEnabled' && setUpdatingTextNotifications(false)
        field === 'emailNotificationsEnabled' && setUpdatingEmailNotifications(false)
      }
    } catch (error) {}
  }

  const getCustomer = async () => {
    try {
      let response = await axios.get(`/customer?id=${customer_id}`)

      setCustomer(response.data)
      setCustomerTemp(response.data)
      const notificationSettings = response?.data?.notificationSettings
      setTextNotificationsEnabled(notificationSettings?.textNotificationsEnabled)
      setEmailNotificationsEnabled(notificationSettings?.emailNotificationsEnabled)
      setTimeout(() => {
        setLoading(false)
      }, 500)
    } catch (error) {}
  }
  useEffect(() => {
    getCustomer()
  }, [])
  useEffect(() => {
    getCustomer()
  }, [customer_id])

  const toggleTextNotifications = value => {
    if (textNotificationsEnabled !== value) {
      setTextNotificationsEnabled(value)
      setUpdatingTextNotifications(true)
      updateCustomerNotifications('textNotificationsEnabled', value)
    }
  }
  const toggleEmailNotifications = value => {
    if (emailNotificationsEnabled !== value) {
      setEmailNotificationsEnabled(value)
      setUpdatingEmailNotifications(true)
      updateCustomerNotifications('emailNotificationsEnabled', value)
    }
  }

  return (
    <div style={{ padding: '1rem' }}>
      <h3 style={{ fontWeight: 'bold' }}>Notification Settings</h3>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IonSpinner name='dots' />
        </div>
      )}
      {!loading && (
        <>
          <IonList mode='ios' inset={true} style={{ margin: '1rem 0' }}>
            <IonItem>
              <IonCol size='9'>
                <IonLabel>Text Notifications</IonLabel>
              </IonCol>
              <IonCol size='3'>
                {updatingTextNotifications && (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <IonSpinner name='lines' />
                  </div>
                )}
                {!updatingTextNotifications && (
                  <IonToggle
                    justify='space-between'
                    size='small'
                    checked={textNotificationsEnabled}
                    onIonChange={e => {
                      toggleTextNotifications(e.detail.checked)
                    }}
                  />
                )}
              </IonCol>
            </IonItem>
          </IonList>

          <IonList mode='ios' inset={true} style={{ margin: '1rem 0' }}>
            <IonItem>
              <IonCol size='9'>
                <IonLabel>Email Notifications</IonLabel>
              </IonCol>
              <IonCol size='3'>
                {updatingEmailNotifications && (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <IonSpinner name='lines' />
                  </div>
                )}
                {!updatingEmailNotifications && (
                  <IonToggle
                    justify='space-between'
                    size='small'
                    checked={emailNotificationsEnabled}
                    onIonChange={e => {
                      toggleEmailNotifications(e.detail.checked)
                    }}
                  />
                )}
              </IonCol>
            </IonItem>
          </IonList>
        </>
      )}
    </div>
  )
}
