import React, { useState, useEffect, useContext } from 'react';
import { Grid, TextField, InputAdornment } from '@mui/material';
import { IonCard } from '@ionic/react';

export default function DetailsView({ location, updateLocation, checkLocation, locations, locationId }) {

  let updateTaxRate = (value) => {
    updateLocation({ ...location, ...{ purchase: { taxRate: value } } })
  }

  if (!location) return "loading..."


  return (
    <IonCard style={{ padding: 20 }}>
      <Grid container>
        <Grid item xs="6">
          <div style={{ flex: 1, flexDirection: 'row', width: '100%' }}>
            <TextField
              margin="dense"
              id="tax_rate"
              label="TaxRate"
              type="text"
              variant="standard"
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
              value={location?.purchase?.taxRate || ""}
              onChange={(e) => { updateTaxRate(e.target.value) }}
              style={{ flex: 1, marginRight: 10, width: 80 }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </Grid>
      </Grid>
    </IonCard>
  )
}


