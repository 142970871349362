import React, { useCallback, useState, useContext } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import { Redirect, useLocation } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import StorefrontIcon from '@mui/icons-material/Storefront'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Axios from 'axios'
import Copyright from './copyright'
import { useHistory } from 'react-router-dom'
import { AuthContext } from './auth'
import { colors } from './theme/colors'

const classes = {
  paper: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: 10,
    backgroundColor: colors.primaryBlue,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 10,
  },
  submit: {
    margin: 20,
  },
}

export default function SetupBusiness() {
  const location = useLocation()
  const { newBusiness } = location.state || {}

  const { businessData, getBusinessData, getUserData } = useContext(AuthContext)
  const [message, setMessage] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const history = useHistory()

  const handleSubmit = useCallback(async event => {
    setMessage(null)
    setDisabled(true)
    event.preventDefault()
    const { name, locationName } = event.target.elements
    if (!name.value) {
      setMessage('** Business Name is required **')
      return
    }

    if (!locationName.value) {
      setMessage('** Location Name is required **')
      return
    }

    try {
      await Axios.post('/business', { name: name.value, locationName: locationName.value })
      getUserData()
      getBusinessData()
      history.push('/core/dashboard')
    } catch (error) {
      setMessage(error)
      setDisabled(false)
    }
  }, [])

  if (businessData && !newBusiness) {
    return <Redirect noThrow to='/core/dashboard' />
  }

  return (
    <Container component='main' maxWidth='xs' style={{ padding: '2rem 1rem' }}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <StorefrontIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          New Business and Location
        </Typography>
        <Typography variant='body2' color='textSecondary' align='center'>
          Welcome. Let's start by creating a new Business and adding its first location.
        </Typography>
        <Typography variant='body2' color='textSecondary' align='center' style={{ color: 'red' }}>
          {message}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete='name'
                name='name'
                variant='outlined'
                required
                fullWidth
                id='name'
                label='Business Name'
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete='locationName'
                name='locationName'
                variant='outlined'
                required
                fullWidth
                id='locationName'
                label='Location Name'
                autoFocus
              />
            </Grid>
          </Grid>
          <Button
            type='submit'
            disabled={disabled}
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            style={{ height: 50, marginTop: 20 }}
          >
            Continue
          </Button>
          {/* <Grid container justify="flex-end">
            <Grid item>
              <Link href="#" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  )
}
