import React, { useState, useEffect, useContext } from 'react';
import { Typography, Button, Paper, Card, Avatar, Grid, Divider } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import axios from 'axios'
import HeaderComponent from './headerComponent';
import ClockIcon from '@mui/icons-material/AccessTime'


export default function StepPickService({scheduleData, businessId, locationId, updateScheduleData, setStep}) {
    const [employee, setEmployee] = useState([])
    const [locationServices, setLocationServices] = useState([])

    useEffect(()=>{getEmployeesList()},[])

    const getEmployeesList = async () =>{
        let response = await axios.get(`/employee/public?id=${scheduleData.barber._id}`)
        setEmployee(response.data)
    }
    
    useEffect(()=>{getServicesList()},[])

    const getServicesList = async () =>{
        let locQuery = locationId ? `&locationId=${locationId}` : ''
        let response = await axios.get(`/service/getList?businessId=${businessId}${locQuery}`)
        setLocationServices(response.data)
    }

    function handleServiceSelection(service){
        updateScheduleData('service', service)
        setStep('dateTime')
    }


    return (
        <>
            <HeaderComponent title="Pick a Service" handleBack={()=>{setStep('barber')}} progress={{total:5, step:3}}/>
            <ServicesList locationServices={locationServices} employee={employee} handleServiceSelection={handleServiceSelection}/>
        </>
    );
}

function ServicesList({locationServices, employee, handleServiceSelection}){
    if(!locationServices || !employee)return null

    return (
        <Grid container style={{display:'flex', justifyContent:'center'}}>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {locationServices && locationServices.map((row) => {
                if(employee && employee.services && employee.services.includes(row._id)){
                    return <ServiceCard service={row} handleServiceSelection={handleServiceSelection} key={row._id}/>
                }
            })}
        </List>
    </Grid>
    )
}


// function ServicesCard({service, handleServiceSelection}){
//     return (
//         <Grid item style={{width:180, cursor:'pointer'}} onClick={()=>{handleServiceSelection(service)}}>
//             <Card style={{ alignItems:'center', display:'flex', flexDirection:'column', margin:5, padding:20, height:150}} variant="outlined">
//                 {/* <Avatar/> */}
//                 <Typography style={{marginTop:10, textAlign:'center', fontWeight:'600'}} variant={'subtitle1'}>{row?.services?.map((s)=>{
//                   return `${s.name} `
//                 })}</Typography>
//                 <Typography style={{fontSize:14, marginBottom:8}} variant={'p'}>${service.price}</Typography>
//                 <Typography style={{fontSize:12}} variant={'p'}>{service.description}</Typography>
                
//             </Card>
//         </Grid>
//     )
// }

function ServiceCard({service, handleServiceSelection, businessId }) {

    return (
        <>
            <ListItem onClick={()=>{handleServiceSelection(service)}} style={{cursor:'pointer'}}>
                {/* <ListItemAvatar>
                    <Avatar employee={service} businessId={businessId} small />
                </ListItemAvatar> */}
                <ListItemText 
                    primary={service.name} 
                    secondary={<div style={{display:'flex', flexDirection:'column'}}>
                        <span style={{fontSize:13, color:'grey'}}>{service.description}</span>
                        <span style={{fontSize:13, color:'grey'}}><ClockIcon style={{fontSize:13, marginBottom:-2, marginRight:4, color:'#2175EA'}}/>{service.durationMin} min</span>
                        </div>}/>
                    <div style={{border:'1px solid grey', borderRadius:4, padding:'4px 8px'}}>
                        <Typography variant="subtitle2">${service.price}</Typography>
                    </div>
            </ListItem>
            <Divider component="li" />
        </>
    )
}

