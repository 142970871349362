import { IonIcon, IonLabel } from '@ionic/react'
import { chevronBack, chevronForward } from 'ionicons/icons'
import moment from 'moment-timezone'
import React from 'react'

export default function CalendarHeader({
  selectedDate,
  handlePrevWeek,
  loading,
  handleNextWeek,
  setSelectedDate,
}) {
  const currentWeek = moment(selectedDate).startOf('week')
  const weekDays = Array.from({ length: 7 }, (_, i) => moment(currentWeek).add(i, 'days'))

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        backgroundColor: 'white',
        zIndex: 9999,
        position: 'sticky',
        top: 0,
      }}
    >
      <div className='toolbar-header'>
        <>
          <div className='week-dates-container' style={{ margin: '0.3rem 0', width: '100%' }}>
            <IonIcon
              className='arrow-icon'
              style={{ color: 'black', fontSize: '20px', cursor: 'pointer' }}
              icon={chevronBack}
              onClick={handlePrevWeek}
            />
            {weekDays.map((day, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <div className='day'>
                  <IonLabel
                    style={{
                      fontSize: '10px',
                      fontWeight: '500',
                      color: day.isoWeekday() === 6 || day.isoWeekday() === 7 ? 'grey' : 'black',
                    }}
                  >
                    {day.format('ddd').slice(0, 1)}
                  </IonLabel>
                </div>
                <button
                  onClick={() => (loading ? null : setSelectedDate(day._d))}
                  style={{
                    fontSize: 19,
                    fontWeight: '400',
                    width: 38,
                    height: 38,
                    margin: 6,

                    color: day.isSame(selectedDate, 'day')
                      ? 'white'
                      : day.isoWeekday() === 6 || day.isoWeekday() === 7
                      ? 'grey'
                      : '',
                  }}
                  className={`circle-button ${day.isSame(selectedDate, 'day') ? ' active' : ''}`}
                >
                  <div className='date'>{day.format('D')}</div>
                </button>
              </div>
            ))}
            <IonIcon
              className='arrow-icon'
              style={{ color: 'black', fontSize: '20px', cursor: 'pointer' }}
              icon={chevronForward}
              onClick={handleNextWeek}
            />
          </div>
        </>
      </div>
    </div>
  )
}
