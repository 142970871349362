import {
  IonButton,
  IonButtons,
  IonCol,
  IonIcon,
  IonLabel,
  IonList,
  IonReorderGroup,
  IonRow,
} from '@ionic/react'
import { add } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import AddUpdateSubscriptionOption from './AddUpdateSubscriptionOption'
import axios from 'axios'
import SubscriptionOptionItem from './SubscriptionOptionItem'

export default function SubscriptionOptionsList({
  subscriptionPackage,
  pricingOptions,
  addOption,
  addUpdatePricingToList,
  subscriptionPackage_id,
  updateSubscriptionPackage,
}) {
  const [modalOpen, setModalOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [pricingList, setPricingList] = useState(pricingOptions)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [reorderEnabled, setReorderEnabled] = useState(false)
  const openModal = () => setModalOpen(true)
  const closeModal = () => {
    setModalOpen(false)
    setEditMode(false)
    setSelectedOption(false)
    setSelectedIndex(null)
  }
  const [selectedOption, setSelectedOption] = useState(false)
  const handleUpdateOption = (option, index) => {
    setSelectedIndex(index)
    setEditMode(true)
    setSelectedOption(option)
    setModalOpen(true)
  }
  const addUpdatePricing = pricingOption => {
    if (addOption) {
      if (editMode) {
        addUpdatePricingToList('update', pricingOption, selectedIndex)
      } else {
        addUpdatePricingToList('add', pricingOption)
      }
    } else {
      if (editMode) {
        addUpdatePricingToList('update', pricingOption)
      } else {
        addUpdatePricingToList('add', pricingOption)
      }
    }
  }
  const handleReorder = async event => {
    const fromIndex = event.detail.from
    const toIndex = event.detail.to

    const updatedPricingOptions = [...pricingList]
    const [movedItem] = updatedPricingOptions.splice(fromIndex, 1)
    updatedPricingOptions.splice(toIndex, 0, movedItem)
    const updateOrderInput = {
      name: subscriptionPackage.name,
      businessId: subscriptionPackage.businessId,
      prices: updatedPricingOptions,
    }
    try {
      const response = await axios.patch(
        `/subscriptionPackage?id=${subscriptionPackage_id}`,
        updateOrderInput,
      )

      if (response && response.status === 200) {
        event.detail.complete()
        setPricingList(updatedPricingOptions)
      }
    } catch (error) {
      event.detail.complete()
    }
  }

  const handlePriceToggle = (checked, option) => {
    // Find the index of the pricing option in the prices array
    const index = subscriptionPackage.prices.findIndex(
      price => price.stripePriceId === option.stripePriceId,
    )

    if (index !== -1) {
      // Update the 'active' field in the pricing option
      subscriptionPackage.prices[index]['active'] = checked
      subscriptionPackage.prices[index].changed = true
    }

    updateSubscriptionPackage(subscriptionPackage)
  }
  const handleNameChange = (value, option) => {
    // Find the index of the pricing option in the prices array
    const index = subscriptionPackage.prices.findIndex(
      price => price.stripePriceId === option.stripePriceId,
    )

    if (index !== -1) {
      // Update the 'active' field in the pricing option
      subscriptionPackage.prices[index]['name'] = value
    }

    updateSubscriptionPackage(subscriptionPackage)
  }
  useEffect(() => {
    setPricingList(pricingOptions)
  }, [subscriptionPackage, pricingOptions])

  return (
    <>
      <IonRow
        style={{
          margin: '1rem ',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <IonCol size='auto'>
          <h5 style={{ margin: 0, fontWeight: 'bold' }}>Subscription Options</h5>
        </IonCol>
        <IonCol style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} size='auto'>
          <IonButtons>
            <IonButton onClick={openModal} style={{ fontSize: '14px' }}>
              <IonIcon color='primary' icon={add} />
              <IonLabel color='primary'> Add Option</IonLabel>
            </IonButton>
          </IonButtons>
        </IonCol>
      </IonRow>
      {subscriptionPackage_id && (
        <IonButtons style={{ marginLeft: '1rem' }}>
          <IonButton
            onClick={() => {
              setReorderEnabled(!reorderEnabled)
            }}
            style={{ fontSize: '14px' }}
          >
            <IonLabel color='primary'>
              {' '}
              {!reorderEnabled ? 'Reorder Subscriptions' : 'Done with Reorder'}
            </IonLabel>
          </IonButton>
        </IonButtons>
      )}

      <AddUpdateSubscriptionOption
        editMode={editMode}
        optionData={selectedOption}
        modalOpen={modalOpen}
        openModal={openModal}
        closeModal={closeModal}
        addUpdatePricing={addUpdatePricing}
        subscriptionPackage_id={subscriptionPackage_id}
      />
      <IonList inset={true} mode='ios'>
        <IonReorderGroup disabled={!reorderEnabled} onIonItemReorder={handleReorder}>
          <SubscriptionOptionItem
            pricingList={pricingList}
            subscriptionPackage_id={subscriptionPackage_id}
            handleUpdateOption={handleUpdateOption}
            reorderEnabled={reorderEnabled}
            addOption={addOption}
            handleNameChange={handleNameChange}
            handlePriceToggle={handlePriceToggle}
          />
        </IonReorderGroup>
      </IonList>
    </>
  )
}
