import { IonCard, IonIcon, IonLabel, IonText } from '@ionic/react'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { Container, CssBaseline } from '@mui/material'
import { chevronBack, createOutline } from 'ionicons/icons'
import React from 'react'
import { useHistory } from 'react-router'

export default function UserNotice() {
  const history = useHistory()
  const goBackToHome = () => {
    history.push('/homePage')
  }
  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <IonCard
        style={{
          margin: '50px auto',
          padding: '20px',
          borderRadius: '8px',
        }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div class='message-container'>
            <h2>Contact Administrator</h2>
            <IonLabel>
              Please reach out to our administrator for assistance with being added to an existing
              business. Your request to join will be processed promptly by our team. Thank you for
              your cooperation.
            </IonLabel>
          </div>
        </div>
        <div
          style={{
            marginTop: '2rem',
          }}
        >
          <IonIcon color='primary' style={{ marginTop: '2px' }} icon={chevronBack} />
          <IonLabel onClick={goBackToHome} color={'primary'} style={{ marginTop: '2rem' }}>
            Go Back To Home
          </IonLabel>
        </div>
      </IonCard>
    </Container>
  )
}
