import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Button from "@mui/material/Button"
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import GeneralDetails from './details_general'
import axios from 'axios'
import { Alert, Card } from '@mui/material';
import { AuthContext } from '../auth'
import FunAvatar from '../commonComponents/funAvatar'
import { useHistory } from 'react-router-dom';
import { IonBackButton, IonButton, IonButtons, IonCard, IonContent, IonHeader, IonPage, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import StandardContainer from '../commonComponents/StandardContainer';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function Details({ match }) {
    const service_id = match?.params?.id
    const [service, setService] = useState()
    const [serviceTemp, setServiceTemp] = useState()
    const [serviceChanged, setServiceChanged] = useState(false)
    const [locations, setLocations] = useState()
    const [subscriptionPackages, setSubscriptionPackages] = useState()
    const { businessData, locationId } = useContext(AuthContext);
    const history = useHistory()
    const getService = async () => {
        let response = await axios.get(`/service?id=${service_id}`)
        console.log(response.data)
        setService(response.data)
        setServiceTemp(response.data)
    }
    useEffect(() => { getService() }, [service_id])

    const getLocationsList = async () => {
        if (!businessData?._id) return
        let response = await axios.get(`/location/getListByBusinessId?businessId=${businessData._id}`)
        setLocations(response.data)
    }
    useEffect(() => { getLocationsList() }, [businessData])

    const getSubscriptionPackagesList = async () => {
        if (!businessData?._id) return
        let response = await axios.get(`/subscriptionPackage/getList?businessId=${businessData._id}`)
        setSubscriptionPackages(response.data)
    }
    useEffect(() => { getSubscriptionPackagesList() }, [businessData])

    const updateService = (value) => {
        setServiceTemp(value);
        setServiceChanged(true)
    };

    const save = async () => {
        let response = await axios.patch(`/service?id=${service_id}`, serviceTemp)
        await getService()
        setServiceChanged(false)
    }

    const cancel = () => {
        setServiceTemp(service);
        setServiceChanged(false)
    }

    const deleteService = async () => {
        if (window.confirm('Are you sure you want to delete the service?')) {
            // Delete it!
            await axios.delete(`/service?id=${service_id}`)
            history.push(-1)
        }
    }

    if (!service) {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar color="black" style={{ backgroundColor: 'white', color: 'black' }}>
                        <IonButtons slot="start">
                            <IonBackButton />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonSpinner name='dots'/>
                </IonContent>
            </IonPage>)
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="black" style={{ backgroundColor: 'white', color: 'black' }}>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>{serviceTemp?.name || 'loading...'}</IonTitle>
                    {serviceChanged ? <><IonButtons slot="end">
                        <IonButton onClick={cancel}>
                            Cancel
                        </IonButton>
                    </IonButtons>
                        <IonButtons slot="end">
                            <IonButton color="primary" onClick={save}>
                                Save
                            </IonButton>
                        </IonButtons></> : null}
                </IonToolbar>

            </IonHeader>
            <IonContent>
                <StandardContainer>
                    <IonCard style={{ padding: 20 }}>
                        <GeneralDetails service={serviceTemp} updateService={updateService} locations={locations} subscriptionPackages={subscriptionPackages} locationId={locationId} />
                    </IonCard>
                    <IonCard style={{ padding: 20 }}>
                        <Button color="warning" variant="contained" onClick={deleteService}>Delete</Button>
                    </IonCard>
                </StandardContainer>
            </IonContent>
        </IonPage>
    );
}
