import React, { useState, useContext, useEffect } from 'react';
import {
    IonButtons,
    IonButton,
    IonModal,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonList,
    IonItem,
    IonLabel,
    IonTextarea,
    IonSpinner,
    IonCard,
} from '@ionic/react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles
import { AuthContext } from '../auth';
import StandardContainer from './StandardContainer';

function CustomerNotesModal({ isOpen, setOpen, customerId }) {
    const { businessData } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [notesId, setNotesId] = useState(null);
    const [notes, setNotes] = useState('');
    const [lastEditTime, setLastEditTime] = useState(null);
    const [lastEditorName, setLastEditorName] = useState(null);
    const [isSaving, setSaving] = useState(false);
    const [isTextChanged, setTextChanged] = useState(false); // New state to track text changes

    useEffect(() => {
        if (isOpen && customerId) {
            fetchNotes();
        }
    }, [isOpen, customerId]);

    useEffect(() => {
        if (isOpen) {
            setTextChanged(false); // Reset the text changed flag when the modal is opened
        }
    }, [isOpen]);

    const dismiss = () => {
        setOpen(false);
    };

    const fetchNotes = async () => {
        try {
            setLoading(true);
            setErrorMessage(null);
            const response = await axios.get(`/customer/getNotes`, {
                params: {
                    customerId: customerId,
                    businessId: businessData._id
                }
            });
            setNotesId(response.data._id);
            setNotes(response.data?.notes);
            setLastEditTime(response.data?.lastEditTime);
            setLastEditorName(response.data?.lastEditorName);
        } catch (error) {
            setErrorMessage('Sorry, there was an error fetching the data');
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        setSaving(true);
        try {
            await axios.patch(`/customer/updateNotes`, {
                _id: notesId,
                notes: notes
            });
            fetchNotes();
            dismiss();
        } catch (error) {
            alert(error);
        }
        setSaving(false);
    };

    const handleNotesChange = (e) => {
        setNotes(e);
        setTextChanged(true); // Set the text changed flag when the notes are modified
    };

    return (
        <IonModal isOpen={isOpen} className="fullscreen">
            <IonHeader>
                <IonToolbar color="black" style={{ backgroundColor: 'white', color: 'black' }}>
                    <IonButton slot="start" fill="clear" onClick={dismiss}>
                        Close
                    </IonButton>
                    <IonTitle style={{ color: 'black' }}>Customer Notes</IonTitle>
                    {isTextChanged && ( // Only show the save button if the text has changed
                        <IonButton slot="end" fill="clear" onClick={handleSave} disabled={isSaving}>
                            {isSaving ? <IonSpinner name='dots' /> : <>Save</>}
                        </IonButton>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent className="regularBackground">
                <StandardContainer medium={true}>
                    <div style={{backgroundColor:'white'}}>
                    {loading ? (
                        <IonItem>Loading...</IonItem>
                    ) : errorMessage ? (
                        <IonItem>{errorMessage}</IonItem>
                    ) : (
                        <>
                            {/* <IonTextarea style={{ height: '550px' }} value={notes} placeholder="Enter notes here..." onIonChange={handleNotesChange} /> */}

                            <ReactQuill
                                value={notes}
                                onChange={handleNotesChange}
                                theme="snow" // this will import the toolbar with options
                                style={{height:500}}
                            />
                            <IonItem>
                                <IonLabel style={{fontSize:10}}>
                                    Last Edit Time: {lastEditTime} <br />
                                    Last Editor: {lastEditorName}
                                </IonLabel>
                            </IonItem>
                        </>
                    )}
</div>
                </StandardContainer>
            </IonContent>
        </IonModal>
    );
}

export default CustomerNotesModal;
