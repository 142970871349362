import { useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { IonButton, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react'
import DetailsView from './details_general'
import StandardContainer from '../commonComponents/StandardContainer'

let initialState = {
  name: '',
  discountType: 'percentage',
  couponType: 'subscription',
}

export default function CreateTestDialog({ open, handleClose, businessId, locationId }) {
  const [discount, setDiscount] = useState(initialState)
  const history = useHistory()
  const [error, setError] = useState('')
  const [nameError, setNameError] = useState('')
  const handleNameError = error => {
    console.log(error, 'errorerror')
    setNameError(error)
  }
  const handleDiscount = value => {
    setDiscount(value)
    if (value.discountType === 'percentage' && value.discountValue > 100) {
      setError('Percentage discount value cannot be more than 100')
    } else if (value.discountType === 'percentage' && value.discountValue < 0) {
      setError('Percentage discount value cannot be less than 0')
    } else {
      setError('')
    }
  }
  const updateError = error => {
    setError(error)
  }
  const createButtonClicked = async () => {
    const locData = locationId ? { locations: [locationId] } : {}
    try {
      const response = await axios.post(`/discount`, { ...discount })
      if (response.status === 200) {
        // history.push(`discounts/${response.data._id}/details`);
        close()
      }
    } catch (error) {}
  }

  const close = () => {
    setDiscount(initialState)
    handleClose()
  }
  const isDisabled =
    !discount.name ||
    !discount.discountType ||
    !discount.couponType ||
    !discount.discountValue ||
    error != '' ||
    nameError != ''

  return (
    <IonModal onDidDismiss={close} isOpen={open}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Discount</IonTitle>
        </IonToolbar>
      </IonHeader>
      {console.log(isDisabled, 'isDisabledisDisabled')}
      <IonContent className='regularBackground'>
        <StandardContainer>
          <DetailsView
            createMode={true}
            handleNameError={handleNameError}
            discount={discount}
            error={error}
            nameError={nameError}
            updateDiscount={handleDiscount}
          />
          <div style={{ padding: 14 }}>
            <IonButton expand='block' onClick={close} fill='outline' color='dark'>
              Cancel
            </IonButton>
            <IonButton
              expand='block'
              onClick={createButtonClicked}
              disabled={isDisabled}
              color='dark'
            >
              Create
            </IonButton>
          </div>
        </StandardContainer>
      </IonContent>
    </IonModal>
  )
}
