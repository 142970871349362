import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Avatar from '../commonComponents/avatar'
import { stringToColor, pickTextColorBasedOnBgColorAdvanced } from '../utils'
import { useHistory } from 'react-router-dom';
import { IonAvatar, IonIcon, IonItem, IonLabel, IonList, IonListHeader } from '@ionic/react';


export default function ResultsList({ employees = [], locationId, businessId }) {
  const history = useHistory()


  return (
    <>
    <h6 style={{color:'grey', marginLeft:20}}>Employees</h6>
    <IonList inset={true} mode='ios'>
      {/* <IonListHeader>
        <IonLabel>Employees</IonLabel>
      </IonListHeader> */}
      {employees?.map((row) => (
        <IonItem button detail={true} routerLink={`/core/employees/${row._id}`} routerDirection="forward" key={row._id}>
          <IonAvatar slot="start" style={{marginTop:10, marginBottom:10}}>
            <Avatar employee={row} businessId={businessId} small />
          </IonAvatar>
          <IonLabel>
            {/* {`${row.firstName} ${row.lastName}`} */}
            <h4 style={{ fontWeight: '600', color: 'black', fontSize:18 }}>{`${row.firstName} ${row.lastName}`}</h4>
              <p>{row.role}</p>
            
          </IonLabel>
        </IonItem>
      ))}
      {/* <IonItem style={{height:1200}}>
          
        </IonItem> */}

    </IonList>
    </>
  )

}
