import React, { useEffect, useState } from 'react'
import { getAuth, signOut, getIdToken } from 'firebase/auth'
import axios from 'axios'
import LoadingScreen from './loadingScreen'
import { Redirect } from 'react-router'
import { useHistory } from 'react-router-dom'
import RegisterNewUserToBusiness from './registerNewUser'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [firebaseUser, setFirebaseUser] = useState('loading')
  const [userData, setUserData] = useState('loading')
  const [businessList, setBusinessList] = useState('loading')
  const [businessData, setBusinessData] = useState('loading')
  const [locationId, setLocationId] = useState(null)
  const [lastTokenRefresh, setLastTokenRefresh] = useState(0)
  const [bidRetries, setBidRetries] = useState(0)
  const [uidRetries, setUIDRetries] = useState(0)
  const [lidRetries, setLIDRetries] = useState(0)
  const [locationData, setLocationData] = useState(null)
  const history = useHistory()

  useEffect(() => {
    getAuth().onAuthStateChanged(setFirebase)
  }, [])
  useEffect(() => {
    getUserData()
  }, [firebaseUser])
  useEffect(() => {
    getBusinessData()
  }, [userData])

  useEffect(() => {
    const interceptor = axios.interceptors.request.use(
      async config => {
        if (firebaseUser && firebaseUser !== 'loading') {
          const currentTime = Date.now()
          const thirtyMinutes = 30 * 60 * 1000
          const currentToken = config.headers['Authorization']
          const defaultToken = axios.defaults.headers.common['Authorization']

          axios.defaults.headers.common['Authorization'] = `Bearer ${firebaseUser?.accessToken}`
          config.headers['Authorization'] = `Bearer ${firebaseUser?.accessToken}`

          if (currentTime - lastTokenRefresh > thirtyMinutes) {
            const token = await refreshToken()
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            config.headers['Authorization'] = `Bearer ${token}`
            setLastTokenRefresh(currentTime)
          }
          // else if (currentToken !== defaultToken) {
          //   config.headers['Authorization'] = defaultToken
          // }
        }

        return config
      },
      error => {
        return Promise.reject(error)
      },
    )

    return () => {
      axios.interceptors.request.eject(interceptor)
    }
  }, [firebaseUser, lastTokenRefresh])
  // Include firebaseUser and lastTokenRefresh as dependencies

  async function refreshToken() {
    if (firebaseUser) {
      const token = await getIdToken(firebaseUser, true)
      return token
      // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
  }

  async function setFirebase(fb) {
    setFirebaseUser(fb)
    //if we dont have user logged in and if registerNewUser url is opened
    if (window.location.pathname.includes('registerNewUser') && !fb) {
      history.push(window.location.pathname + window.location.search)
      return
    }
    if (fb) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${fb.accessToken}`
      setUserData('loading')
      setBusinessData('loading')
      setLastTokenRefresh(Date.now())
    } else {
      setUserData(null)
      setBusinessData(null)

      history.push('/login')
    }
  }

  async function getUserData() {
    try {
      if (firebaseUser && firebaseUser.uid) {
        // axios.defaults.headers.common['Authorization'] = `Bearer ${firebaseUser.accessToken}`
        let response = await axios.get('/user')
        setUserData(response.data)
      }
    } catch (error) {
      if (uidRetries == 0) {
        setUIDRetries(1)
        setTimeout(getUserData, 10000)
      } else {
        setUserData(null)
      }
    }
  }
  async function getLocations(businessId) {
    try {
      if (businessId) {
        // axios.defaults.headers.common['Authorization'] = `Bearer ${firebaseUser.accessToken}`
        let response = await axios.get(`/location/getList?businessId=${businessId}`)

        const selectedLocation = localStorage.getItem('defaultLocation')
        const locationId = selectedLocation
          ? selectedLocation
          : response.data
          ? response.data[0]._id
          : null

        axios.defaults.headers.common['prop-Locationid'] = locationId
        setLocationId(locationId)
        setLocationData(response.data)
      }
    } catch (error) {
      if (lidRetries == 0) {
        setLIDRetries(1)
        setTimeout(getLocations, 10000)
      } else {
        setUserData(null)
      }
    }
  }

  async function getBusinessData() {
    if (userData && userData != 'loading') {
      try {
        //settin default business id in headers to null
        axios.defaults.headers.common['prop-businessId'] = null
        //get all the lists of business
        let response = await axios.get(`/business/list`)
        if (response.data) {
          let userBusinesses = response.data
          if (userBusinesses && userBusinesses.length > 0) {
            const selectedBusiness = localStorage.getItem('defaultBusiness')

            const businessId = selectedBusiness
              ? selectedBusiness
              : response.data
              ? response.data[0]._id
              : null
            const selectedBusinessObject = userBusinesses.find(obj => obj._id === businessId)

            axios.defaults.headers.common['prop-businessId'] = selectedBusinessObject._id
            setBusinessList(userBusinesses)
            setBusinessData(selectedBusinessObject)
            getLocations(businessId)
          }
        } else {
          if (bidRetries == 0) {
            setBidRetries(1)
            setTimeout(getBusinessData, 10000)
          } else {
            setBusinessData(null)
            history.push('/login')
          }
        }
      } catch (error) {
        setBusinessData(null)
      }
    }
  }

  function logout() {
    signOut(getAuth())
    setFirebaseUser(null)
    setUserData(null)
    setLocationId(null)
    setLocationData(null)
    axios.defaults.headers.common['Authorization'] = null
    localStorage.removeItem('redirectTo')
    localStorage.removeItem('defaultBusiness')
    localStorage.removeItem('defaultLocation')
  }

  // if (!firebaseUser) {
  //
  //   return <Redirect noThrow to='/login' />
  // } else
  if (
    window.location.pathname.includes('registerNewUser') &&
    (firebaseUser === 'loading' || userData === 'loading' || businessData === 'loading')
  ) {
    // Redirect to RegisterNewUser component if the URL contains 'registerNewUser'
    return <RegisterNewUserToBusiness />
  } else if (firebaseUser === 'loading' || userData === 'loading' || businessData === 'loading') {
    return <LoadingScreen />
  } else {
    return (
      <AuthContext.Provider
        value={{
          firebaseUser,
          userData,
          getUserData,
          businessData,
          getBusinessData,
          locationId,
          setLocationId,
          logout,
          locationData,
          setLocationData,
          businessList,
          setBusinessList,
          setBusinessData,
        }}
      >
        {children}
      </AuthContext.Provider>
    )
  }
}
