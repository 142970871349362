import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { colors } from '../theme/colors';
import { IonSpinner } from '@ionic/react';
import StandardCenterCenter from '../commonComponents/StandardCenterCenter';

const ProgressChart = ({ data, firstLoad }) => {
    const [chartData, setChartData] = useState({
        options: {
            chart: {
                type: 'line',
                height: 250,
                toolbar: false
            },
            xaxis: {
                categories: [],
                tickAmount: 7,
                labels: {
                    formatter: function (value) {
                        if (!value) {
                            return '';
                        }
                        const day = value.split('/')[1];
                        return day.startsWith('0') ? day.substring(1) : day;
                    }
                }
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            grid: {
                borderColor: '#f1f1f1',
                show: false
            },
            yaxis: {
                title: {
                    text: 'Appointments',
                },
                max: 0 // Initialize with 0, will be calculated based on data
            },
            tooltip: {
                shared: true,
            },
            legend: {
                show: true,
                offsetY: 0
            },
            colors: ['#A9A9A999', colors.primaryBlue, '#00FA9A'],
        },
        series: []
    });

    useEffect(() => {
        if (!data || !data.thisMonth || !data.lastMonth) {
            return;
        }

        const categories = data.thisMonth.trends.map(item => item.date);
        let lastMonthData = data.lastMonth.trends.map(item => item.cumulativeCount);
        const thisMonthData = data.thisMonth.trends.map(item => item.cumulativeCount);

        // If the previous month has fewer days, fill the remaining days with null
        if (lastMonthData.length < thisMonthData.length) {
            lastMonthData = [...lastMonthData, ...Array(thisMonthData.length - lastMonthData.length).fill(null)];
        }

        const maxLastMonth = Math.max(...lastMonthData.filter(num => num !== null));
        const maxThisMonth = Math.max(...thisMonthData);
        const yAxisMax = Math.ceil(Math.max(maxLastMonth, maxThisMonth) * 1.10);

        setChartData(prevState => ({
            ...prevState,
            options: {
                ...prevState.options,
                xaxis: {
                    ...prevState.options.xaxis,
                    categories: categories
                },
                yaxis: {
                    ...prevState.options.yaxis,
                    max: yAxisMax
                }
            },
            series: [
                { name: 'Previous Month', data: lastMonthData },
                { name: 'Current Month', data: thisMonthData }
            ]
        }));
    }, [data]);

    if (!firstLoad) return null

    

    if (chartData?.series?.length === 0) {
        return <StandardCenterCenter><IonSpinner style={{marginTop:40, marginBottom:40}} name='dots'/></StandardCenterCenter>
    }


    return (
        <Chart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={250}
            style={{ marginBottom: -30, marginTop: -10, marginLeft: -4, marginRight: -10 }}
        />
    );
};

export default ProgressChart;
