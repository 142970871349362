import React, { useState, useEffect, useContext } from 'react';
import { Typography, Button, Paper, Card, Avatar, Grid, Box } from '@mui/material';
import axios from 'axios'
import Review from './reviewOrder';



export default function StepSuccess({ locationId, businessId, updateScheduleData, setStep, scheduleData }) {

    return (
        <>
            <Typography variant="h6" >Success! Thanks for Booking!</Typography>
            <Typography >You will receive an email confirming your appointment.</Typography>
            <Typography ></Typography>
        </>
    );
}


