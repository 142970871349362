import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
  IonToggle,
} from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import StandardContainer from '../commonComponents/StandardContainer';
import { AuthContext } from '../auth';
import axios from 'axios';
import StandardCenterCenter from '../commonComponents/StandardCenterCenter';

export default function EmailRemarketingList({ status }) {
  const { businessData, locationId } = useContext(AuthContext);
  const [emailList, setEmailList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchEmailList = async () => {
    try {
      setIsLoading(true);
      // Replace with your API call
      const response = await axios.get(`/emailRemarketing/${status}List`);
      setEmailList(response.data);
    } catch (error) {
      console.error('Error fetching email list:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {

    fetchEmailList();
  }, [businessData, status]); // Re-fetch when businessData or locationId changes

  const processList = async () => {
    const isConfirmed = window.confirm('Are you sure you want to process the list?');
    if (isConfirmed) {
      try {
        await axios.post('/emailRemarketing/runScheduler')
        fetchEmailList()
      } catch (error) {
        alert(error)
      }
    }
  }

  const sendAll = async () => {
    const isConfirmed = window.confirm('Are you sure you want to send to everyone?');
    if (isConfirmed) {
      try {
        await axios.post('emailRemarketing/sendEmails')
        fetchEmailList()
      } catch (error) {
        alert(error)
      }
    }
  }

  const sendOne = async (id) => {

  }

  const handleSendEmail = async (id) => {
    // Display confirmation dialog
    const isConfirmed = window.confirm('Are you sure you want to send this email?');
    if (isConfirmed) {
      try {
        await axios.post('emailRemarketing/sendOne', { id });
        fetchEmailList(); // Make sure fetchEmailList is defined and accessible
      } catch (error) {
        alert('Failed to send email: ' + error.message);
      }
    }
  };

  const handleDeleteEmail = async (id) => {
    // Display confirmation dialog
    const isConfirmed = window.confirm('Are you sure you want to delete this email?');
    if (isConfirmed) {
      try {
        await axios.post('emailRemarketing/remove', { id });
        fetchEmailList(); // Make sure fetchEmailList is defined and accessible
      } catch (error) {
        alert('Failed to delete email: ' + error.message);
      }
    }
  };


  return (
    <StandardContainer>
      <StandardCenterCenter>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton onClick={processList}>Process List</IonButton>
            </IonCol>
            <IonCol>
              <IonButton color={'danger'} onClick={sendAll}>Send All</IonButton>
            </IonCol>
            <IonCol>
              <IonButton color={'light'} onClick={fetchEmailList}>Refresh List</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>


      </StandardCenterCenter>
      {isLoading ? (
        <IonSpinner name="crescent" />
      ) : null}

      <p style={{ color: 'grey', fontSize: 12, marginLeft: 20 }}>{emailList?.length} emails</p>

      <IonList mode="ios" inset={true}>
        {emailList.map((email) => (
          <IonItem key={email._id}>
            <IonLabel>
              <h2 style={{ fontWeight: '500' }}>{email.customer.firstName} {email.customer.lastName} - {email.customer.email}</h2>
              <p> {
                email.lastAppointmentSelectedDate ? <>
                  Last Appointment: {email.lastAppointmentSelectedDate} - {email.daysSinceLastAppointment.toFixed(0)} days ago`
                </> :
                  "No Last Appointment"
              }</p>
              {email.lastEmailSentDays ? <p>Last Sent: {email.lastEmailSentDate}({email.lastEmailSentDays})</p> : <>{email.status == "sent" ? <p>{new Date(email.sentTime).toString()}</p> : <p>No Previous Emails</p>}</>}
            
            </IonLabel>
            {email.status == "sent" ? null :
              <>
                <IonButton onClick={() => handleSendEmail(email._id)} color="success">Send</IonButton>
                <IonButton onClick={() => handleDeleteEmail(email._id)} color="danger">Delete</IonButton>
              </>
            }
          </IonItem>
        ))}
      </IonList>

    </StandardContainer>
  );
}
