import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import GeneralDetails from './details_general'
import Schedule from './schedule'
import axios from 'axios'
import Purchase from './details_purchase_settings'
import { useHistory } from 'react-router-dom'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import AdvancedDetails from './AdvancedDetails'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Details({ match }) {
  const locationId = match?.params?.id
  const history = useHistory()
  const [tabValue, setTabValue] = React.useState(0)
  const [location, setLocation] = useState()
  const [locationTemp, setLocationTemp] = useState()
  const [locationChanged, setLocationChanged] = useState(false)
  const [activeTab, setActiveTab] = useState('general')

  const getLocation = async () => {
    let response = await axios.get(`/location?id=${locationId}`)
    setLocation(response.data)
    setLocationTemp(response.data)
  }

  useEffect(() => {
    getLocation()
  }, [locationId])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const updateLocation = value => {
    setLocationTemp(value)
    setLocationChanged(true)
  }

  const save = async () => {
    try {
      if (locationTemp.reviewIndex) {
        delete locationTemp.reviewIndex
      }
      await axios.patch(`/location?id=${locationId}`, locationTemp)
      await getLocation()
      setLocationChanged(false)
    } catch (error) {
      alert('There was a problem saving.', error.message)
    }
  }

  const cancel = () => {
    setLocationTemp(location)
    setLocationChanged(false)
  }

  const deleteLocation = async () => {
    if (window.confirm('Are you sure you want to delete the location?')) {
      // Delete it!
      await axios.delete(`/location?id=${locationId}`)
      history.push(-1)
    }
  }
  let body

  if (!location)
    body = (
      <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: 200 }}>
        <IonSpinner name='dots' />
      </div>
    )

  if (activeTab == 'general') {
    body = (
      <GeneralDetails
        location={locationTemp}
        updateLocation={updateLocation}
        locationId={locationId}
      />
    )
  } else if (activeTab == 'schedule') {
    body = <Schedule location={locationTemp} updateLocation={updateLocation} />
  } else if (activeTab == 'purchases') {
    body = <Purchase location={locationTemp} updateLocation={updateLocation} />
  } else if (activeTab == 'advanced') {
    body = <AdvancedDetails deleteLocation={deleteLocation} />
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>{locationTemp ? locationTemp?.name : 'loading...'}</IonTitle>
          {locationChanged ? (
            <>
              <IonButtons slot='end'>
                <IonButton onClick={cancel}>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot='end'>
                <IonButton color='primary' onClick={save}>
                  Save
                </IonButton>
              </IonButtons>
            </>
          ) : null}
        </IonToolbar>
        <IonToolbar color='white' style={{ backgroundColor: 'white' }}>
          <IonSegment
            value={activeTab}
            color='dark'
            onIonChange={e => {
              console.log(e)
              setActiveTab(e?.detail?.value)
            }}
          >
            <IonSegmentButton value='general'>
              <IonLabel>General</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value='schedule'>
              <IonLabel>Schedule</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value='purchases'>
              <IonLabel>Purchases</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value='advanced'>
              <IonLabel>Advanced</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <StandardContainer>{body}</StandardContainer>
        {/* <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" onClick={() => { history.push('/core/locations') }} style={{ cursor: 'pointer' }}>
                        Locations
                    </Link>
                    <Typography color="text.primary">{location.name}</Typography>
                </Breadcrumbs>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                    <div style={{ width: 50 }}><FunAvatar name={`${location.name}`} /></div>
                    <div style={{ flex: 1 }}> <Typography variant="h5">{location.name}</Typography></div>
                </div>

                {locationChanged ? <Button disableElevation align='right' style={{ float: 'right', marginTop: -40, marginRight: 75 }} variant='text' onClick={cancel}>Cancel</Button> : null}
                <Button disableElevation align='right' style={{ float: 'right', marginTop: -40 }} color='primary' variant='contained' disabled={!locationChanged} onClick={save}>Save</Button>
                <br></br>
                <Card>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                <Tab label="General" {...a11yProps(0)} />
                                <Tab label="Schedule" {...a11yProps(1)} />
                                <Tab label="Purchase" {...a11yProps(2)} />
                                <Tab label="Advanced" {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={tabValue} index={0} style={{ backgroundColor: 'white' }}>
                            <GeneralDetails location={locationTemp} updateLocation={updateLocation} locationId={locationId} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <Schedule
                                location={locationTemp}
                                updateLocation={updateLocation}
                            />
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <Purchase
                                location={locationTemp}
                                updateLocation={updateLocation}
                            />
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <Button color="warning" variant="contained" onClick={deleteLocation}>Delete</Button>
                        </TabPanel>
                    </Box>
                </Card> */}
      </IonContent>
    </IonPage>
  )
}
