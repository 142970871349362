import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { storefrontOutline } from 'ionicons/icons'
import React from 'react'

export default function StandardHeader({ title }) {
  return (
    <IonHeader>
      <IonToolbar
        color={'black'}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <IonButtons slot='start'>
          <IonMenuButton>
            <IonIcon icon={storefrontOutline}></IonIcon>
          </IonMenuButton>
        </IonButtons>
        <IonTitle class='ion-text-center' style={{ color: 'black' }}>
          {title}
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  )
}
