import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { ellipsisVerticalOutline } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'
import { Button } from '@mui/material'
import axios from 'axios'
import { convertDataToCSV } from '../utils'
const styles = {
  exportToCSVTitleStyle: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: 'grey',
  },
  moreIconStyle: {
    heght: 22,
    width: 22,
    color: 'grey',
  },
}

export default function ExportCustomersToCSV({ updateErrorMessage, title }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleExportData = async () => {
    console.log('exporting data to csv')
    /* here we are getting data based on the page 
		-- for future purpose if we want to add export for all modules */
    if (title == 'Customers') {
      getCustomersList()
    }
  }

  const getCustomersList = async () => {
    try {
      let response = await axios.get(`/customer/export`)
      console.log(response, 'response of customer==========')
      const formattedCustomerData =
        response.data &&
        response.data.map(item => {
          return {
            firstName: item.firstName ? item.firstName : '-',
            lastName: item.lastName ? item.lastName : '-',
            email: item.email ? item.email : '-',
            phoneNumber: item.phoneNumber ? item.phoneNumber : '-',
          }
        })
      const csvContent = 'data:text/csv;charset=utf-8,' + convertDataToCSV(formattedCustomerData)
      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'customersList.csv')
      document.body.appendChild(link)
      link.click()
      handleClose()
      // setCustomers(response.data)
    } catch (error) {
      updateErrorMessage("You Don't Have Enough Permissions To Export This Data")
    }
    // setLoading(false)
  }
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <IonIcon size='large' style={styles.moreIconStyle} icon={ellipsisVerticalOutline} />
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleExportData} style={styles.exportToCSVTitleStyle}>
          Export TO CSV
        </MenuItem>
      </Menu>
    </div>
  )
}
