import React, { useState, useContext, useEffect, useRef } from 'react'
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonAvatar,
  IonItem,
  IonLabel,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
} from '@ionic/react'
import { AuthContext } from '../../auth'

import axios from 'axios'
import { colors } from '../../theme/colors'
import StandardContainer from '../../commonComponents/StandardContainer'
import CardOnFile from '../../Payment/cardOnFile'
import { chevronBack, close, closeCircle } from 'ionicons/icons'
import {
  AnimatedCheckmark,
  MODES,
} from '../../commonComponents/AnimatedCheckmark/AnimatedCheckmark'
import StandardCenterCenter from '../../commonComponents/StandardCenterCenter'

function FinalizePaymentAndTip({
  isOpen,
  setOpen,
  appointment,
  updateAppointment,
  payment,
  tip,
  setTip,
}) {
  const { businessData } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [stepName, setStepName] = useState('paymentMethod')

  function addTip(value) {
    setTip(value)
  }

  function dismiss() {
    setOpen(false)
    setStepName('paymentMethod')
    setTip(null)
  }

  const finalizePayment = async () => {
    setProcessing(true)
    setErrorMessage(null)
    try {
      //TODO
      setStepName('processingAndSuccess')
      const appointmentPaymentResponse = await axios.post(`/payment/appointment`, {
        appointmentId: appointment._id,
        payment,
        businessId: businessData._id,
      })

      const response = await updateAppointment()
    } catch (error) {
      let message = error?.response?.data?.error?.raw?.message
      setErrorMessage('There was an issue with processing the payment. ' + message + ' (PA-1000)')
    }
    setProcessing(false)
  }

  let body
  if (stepName == 'Tip') {
    body = (
      <TipStep
        payment={payment}
        setStepName={setStepName}
        addTip={addTip}
        tip={tip}
        finalizePayment={finalizePayment}
        customerName={`${appointment?.customer?.firstName} ${appointment?.customer?.lastName}`}
      />
    )
  } else if (stepName == 'paymentMethod') {
    body = <PaymentMethod customer={appointment.customer} setStepName={setStepName} />
  } else if (stepName == 'processingAndSuccess') {
    body = (
      <ProcessingAndSuccess
        dismiss={dismiss}
        processing={processing}
        errorMessage={errorMessage}
        setStepName={setStepName}
      />
    )
  }

  return (
    <IonModal isOpen={isOpen} className='fullscreen'>
      <IonContent className='regularBackground'>
        {stepName == 'paymentMethod' ? (
          <IonButton fill='clear' onClick={() => dismiss()}>
            <IonIcon icon={close} size='large' style={{ color: 'grey' }} />
          </IonButton>
        ) : (
          <>
            {stepName == 'Tip' ? (
              <IonButton fill='clear' onClick={() => setStepName('paymentMethod')}>
                <IonIcon icon={chevronBack} size='large' style={{ color: 'grey' }} />
              </IonButton>
            ) : null}
          </>
        )}
        <StandardContainer medium={stepName == 'paymentMethod' ? true : false}>
          {body}
        </StandardContainer>
      </IonContent>
    </IonModal>
  )
}

function TipButton({ title, subTitle, onClick, selection, id }) {
  let baseStyle = {
    cursor: 'pointer',
    borderRadius: 4,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    height: 110,
  }
  let modifyerStyle = {
    color: colors.black,
    border: `2px solid ${colors.white}`,
    backgroundColor: colors.white,
  }

  if (selection?.id === id) {
    modifyerStyle = {
      backgroundColor: colors.black,
      color: 'white',
      border: `2px solid ${colors.black}`,
      boxShadow: '0px 0px 12px #aaa',
    }
  }
  return (
    <div onClick={onClick} style={{ ...baseStyle, ...modifyerStyle }}>
      <h1 style={{ margin: 0, fontSize: 32 }}>{title}</h1>
      <h6 style={{ margin: 0 }}>{subTitle}</h6>
    </div>
  )
}

function TipStep({ payment, addTip, tip, finalizePayment, customerName }) {
  const [selection, setSelection] = useState()
  const [customTipError, setCustomTipError] = useState(false)

  function itemSelected(tip) {
    addTip(tip.value)
    setSelection(tip)
    if (selection != 'Custom') {
      setCustomTipError(false)
    }
  }
  function addCustomTip(value) {
    if (typeof value === 'number') {
      addTip(value)
      setCustomTipError(false)
    } else if (typeof value === 'string') {
      let parsedValue = parseFloat(value)
      if (!isNaN(parsedValue)) {
        addTip(parsedValue)
        setCustomTipError(false)
      } else {
        setCustomTipError(true)
      }
    } else {
      setCustomTipError(true)
    }
  }

  return (
    <>
      <div
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          display: 'flex',
          paddingTop: 30,
        }}
      >
        <h1 style={{ marginTop: -30, marginBottom: 30 }}>{customerName}</h1>
        <h1 style={{ fontSize: 70, marginBottom: -30, marginTop: -4 }}>
          ${payment?.totalPrice?.toFixed(2)}
        </h1>
        {tip ? (
          <p>
            ${((payment?.totalPrice || 0) - (tip || 0)).toFixed(2)} + ${tip?.toFixed(2)} tip
          </p>
        ) : (
          <p>&nbsp;</p>
        )}
        <h1 style={{ fontSize: 32, marginTop: 30 }}>Add Tip?</h1>
      </div>

      <IonGrid>
        <TipCalculator
          selection={selection}
          totalPrice={payment?.totalPrice - (tip || 0)}
          itemSelected={itemSelected}
        />
        <IonRow>
          <IonCol>
            <TipButton
              title='No Tip'
              onClick={() => {
                itemSelected({ id: 'No Tip', value: 0 })
              }}
              selection={selection}
              id={'No Tip'}
            />
          </IonCol>
          <IonCol>
            <TipButton
              title='Custom'
              onClick={() => {
                itemSelected({ id: 'Custom', value: null })
              }}
              selection={selection}
              id={'Custom'}
            />
          </IonCol>
        </IonRow>
        {selection?.id == 'Custom' ? (
          <>
            <IonRow>
              <IonCol>
                <div style={{ border: `2px solid ${colors.black}`, padding: 10, borderRadius: 4 }}>
                  <CustomInputFocus addCustomTip={addCustomTip} />
                  {/* <IonInput
                                        type='number'
                                        label="Outline input"
                                        labelPlacement="floating"
                                        fill="outline"
                                        placeholder="Tip"
                                        onIonChange={(e)=>{addCustomTip(e.detail.value) }}
                                        >
                                    </IonInput> */}
                </div>
              </IonCol>
            </IonRow>
          </>
        ) : null}
        <IonRow>
          <IonCol>
            {tip != null && !customTipError ? (
              <>
                <IonButton
                  color='dark'
                  expand='block'
                  onClick={finalizePayment}
                  style={{ height: 70 }}
                >
                  Accept & Pay ${payment?.totalPrice?.toFixed(2)}
                </IonButton>
              </>
            ) : null}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <StandardCenterCenter>
              <img
                src='/assets/poweredByStripe_black.svg'
                alt=''
                style={{ height: 26, marginTop: 10, marginBottom: 20 }}
              />
            </StandardCenterCenter>
          </IonCol>
        </IonRow>

        {selection?.id == 'Custom' ? (
          <IonRow>
            <div style={{ height: 300 }} />
          </IonRow>
        ) : null}
      </IonGrid>
    </>
  )
}

function PaymentMethod({ setStepName, customer }) {
  const [isCardOnFile, setIsCardOnFile] = useState(false)
  return (
    <>
      <div style={{ margin: 18, borderRadius: 8 }}>
        <h2>
          {customer.firstName} {customer.lastName}'s Payment Method
        </h2>
      </div>
      <div style={{ backgroundColor: 'white', padding: 10, margin: 16, borderRadius: 8 }}>
        <CardOnFile customerId={customer._id} setIsCardOnFile={setIsCardOnFile} />
      </div>
      {isCardOnFile ? (
        <IonButton
          color='dark'
          expand='block'
          style={{ margin: 16, height: 50 }}
          onClick={() => {
            setStepName('Tip')
          }}
        >
          Go to Tip
        </IonButton>
      ) : null}
    </>
  )
}

function CustomInputFocus({ addCustomTip }) {
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  return (
    <IonInput
      type='number'
      label='Outline input'
      labelPlacement='floating'
      fill='outline'
      placeholder='Tip'
      onIonChange={e => {
        addCustomTip(e.detail.value)
      }}
      ref={inputRef}
    ></IonInput>
  )
}

function TipCalculator({ totalPrice, itemSelected, selection }) {
  // Calculate tip amounts
  let tips

  if (totalPrice < 17) {
    tips = {
      $6: 6,
      $7: 7,
      $8: 8,
    }
  } else {
    tips = {
      '20%': Math.ceil(totalPrice * 0.2),
      '25%': Math.ceil(totalPrice * 0.25),
      '30%': Math.ceil(totalPrice * 0.3),
    }
  }

  return (
    <IonRow>
      {Object.entries(tips).map(([id, value]) => (
        <IonCol key={id}>
          <TipButton
            title={id}
            subTitle={totalPrice >= 17 ? `$${value.toFixed(2)}` : undefined}
            onClick={() => itemSelected({ id, value })}
            selection={selection}
            id={id}
          />
        </IonCol>
      ))}
    </IonRow>
  )
}

function ProcessingAndSuccess({ dismiss, processing, errorMessage, setStepName }) {
  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: 20,
      }}
    >
      <br></br>
      <br></br>
      {errorMessage ? (
        <IonIcon icon={closeCircle} style={{ height: 100, width: 100 }} color='danger' />
      ) : (
        <AnimatedCheckmark mode={processing ? MODES.LOADING : MODES.SUCCESS} />
      )}
      {/* <IonIcon icon={checkmarkCircleOutline} style={{height:100, width:100}} color="success"/> */}
      {processing ? (
        <>
          <br></br>
          <DelayedCancel dismiss={dismiss} />
        </>
      ) : (
        <>
          {errorMessage ? (
            <>
              <h1 style={{ fontSize: 50, marginTop: 0 }}>Oops!</h1>
              <h5>{errorMessage}</h5>
              <br></br>
              <IonButton
                expand='block'
                fill='outline'
                color='dark'
                style={{ width: '100%', height: 70 }}
                onClick={() => {
                  setStepName('Tip')
                }}
              >
                Try Again
              </IonButton>
            </>
          ) : (
            <>
              <h1 style={{ fontSize: 50, marginTop: 0 }}>Success</h1>
              <h5>Thanks!</h5>
              <br></br>
              <br></br>
              <IonButton
                expand='block'
                fill='outline'
                color='dark'
                style={{ width: '100%', height: 70 }}
                onClick={dismiss}
              >
                Done
              </IonButton>
            </>
          )}
        </>
      )}
    </div>
  )
}

const DelayedCancel = ({ dismiss }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, 15000) // 15000ms = 15s

    // Clean up on component unmount
    return () => clearTimeout(timer)
  }, []) // Empty array means this effect runs once on mount, and not on updates

  if (!isVisible) return null

  return (
    <IonButton
      expand='block'
      fill='outline'
      color='dark'
      style={{ width: '100%', height: 70 }}
      onClick={dismiss}
    >
      Cancel
    </IonButton>
  )
}

export default FinalizePaymentAndTip
