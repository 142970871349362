import { useState, useEffect, useContext } from 'react'
import ResultsList from './resultsList'
import axios from 'axios'
import useAxios from 'axios-hooks'
import { AuthContext } from '../auth'
import { IonContent, IonRefresher, IonRefresherContent, useIonViewWillEnter } from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import InviteResultsList from './inviteResultsList'
import { useLocation } from 'react-router'

export default function ActiveUsers({ updateLoader }) {
  const { businessData, locationId } = useContext(AuthContext)
  const [users, setUsers] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  let locQuery = locationId ? `&locationId=${locationId}` : ''

  const [{ data: invites, loading: invitesLoading, error: invitesError }, refetchInvites] =
    useAxios(`/user/invitesList?businessId=${businessData._id}${locQuery}`)
  const location = useLocation()
  useEffect(() => {
    getUsersList()
    refetchInvites()
  }, [locationId])
  useIonViewWillEnter(() => {
    getUsersList()
    refetchInvites()
  })
  useEffect(() => {
    if (location.pathname == '/core/users') {
      getUsersList()
      refetchInvites()
    }
  }, [location])

  const getUsersList = async ignoreLoading => {
    if (!businessData?._id) return
    updateLoader(true)
    setErrorMessage(null)
    try {
      let response = await axios.get(`/user/getList?businessId=${businessData._id}${locQuery}`)
      setUsers(response.data)
    } catch (error) {
      setErrorMessage('Sorry there was an error fetching the data')
    }
    updateLoader(false)
  }

  const closeAddUserDialog = () => {
    // getUsersList()
    refetchInvites()
  }

  async function handleRefresh(event) {
    await getUsersList(true)
    refetchInvites()
    event.detail.complete()
  }

  return (
    <IonContent>
      <StandardContainer>
        <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <ResultsList title={'Active Users'} users={users} />
        <InviteResultsList
          refreshInvites={closeAddUserDialog}
          invites={invites}
          loading={invitesLoading}
        />
      </StandardContainer>
    </IonContent>
  )
}
