import { IonCheckbox, IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react'
import { businessOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { newUserRoles } from '../../StaticData/StaticData'
export default function BusinessPermissions({ updateEntitlements, entitlementsData }) {
  const [isAdmin, setIsAdmin] = useState(false)
  const [isManager, setIsManager] = useState(false)
  const [isProfessional, setIsProfessional] = useState(false)
  const [noPermissions, setNoPermissions] = useState(true)

  const handlePermission = async permission => {
    // Clone the object to avoid mutating the state directly
    const updatedPermissions = { ...entitlementsData }

    // Loop through each location in the "locations" array
    updatedPermissions.locations.forEach(location => {
      // Toggle the permission for each location
      location.entitlements[permission] = true

      // Set all other permissions to false
      Object.keys(location.entitlements).forEach(key => {
        if (key !== permission) {
          location.entitlements[key] = false
        }
      })
    })

    // Set the updated object in the state
    updateEntitlements(updatedPermissions)
  }
  useEffect(() => {
    if (entitlementsData?.locations) {
      const isAdminInAllLocations = entitlementsData.locations.every(
        location => location.entitlements.admin,
      )
      const isManagerInAllLocations = entitlementsData.locations.every(
        location => location.entitlements.manager,
      )
      const isProfessionalInAllLocations = entitlementsData.locations.every(
        location => location.entitlements.professional,
      )
      const noPermissionsInAllLocations = entitlementsData.locations.every(
        location => location.entitlements.noPermissions,
      )

      setIsAdmin(isAdminInAllLocations)
      setIsManager(isManagerInAllLocations)
      setIsProfessional(isProfessionalInAllLocations)
      setNoPermissions(noPermissionsInAllLocations)
    }
  }, [entitlementsData])

  return (
    <IonGrid>
      <IonRow style={{ display: 'flex', alignItems: 'end' }}>
        <IonIcon style={{ marginRight: '10px' }} icon={businessOutline} size='large' />
        <h6 style={{ margin: '0', color: 'black', fontWeight: 'bold' }}>Business Permissions</h6>
      </IonRow>
      <IonRow
        style={{
          padding: '10px',
          backgroundColor: '#ffd1d1',
          borderRadius: '10px',
          color: '#d60000',
          marginTop: '1rem',
        }}
      >
        <IonLabel>
          WARNING! Any Permissions set here will apply to every location regardless of what the
          location permissions are.
        </IonLabel>
      </IonRow>
      <IonRow>
        {newUserRoles.map((item, index) => {
          return (
            <IonRow style={{ width: '100%' }}>
              <IonCol style={{ width: '100%' }}>
                <h6 style={{ margin: '5px', fontWeight: 'bold', color: 'black', fontSize: '1rem' }}>
                  {item.role}
                </h6>
                <IonRow style={{ width: '100%' }} onClick={() => handlePermission(item.value)}>
                  <IonCol size='10'>
                    <h6 style={{ margin: '5px', fontSize: '12px' }}>{item.description}</h6>
                  </IonCol>
                  {}
                  <IonCol>
                    <IonCheckbox
                      style={{ height: '1.5rem', width: '1.5rem' }}
                      checked={
                        item.value === 'admin'
                          ? isAdmin
                          : item.value === 'manager'
                          ? isManager
                          : item.value === 'professional'
                          ? isProfessional
                          : item.value === 'noPermissions'
                          ? noPermissions
                          : false
                      }
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          )
        })}
      </IonRow>
    </IonGrid>
  )
}
