import React, { useRef, useState, useEffect } from 'react';
import { IonContent, IonItem, IonLabel, IonList, IonModal } from '@ionic/react';
import AppTypeahead from './AppTypeahead';
import axios from 'axios'

const fruits = [
  { text: 'Apple', value: 'apple' },
  { text: 'Apricot', value: 'apricot' },
  { text: 'Banana', value: 'banana' },
  { text: 'Blackberry', value: 'blackberry' },
  { text: 'Blueberry', value: 'blueberry' },
  { text: 'Cherry', value: 'cherry' },
  { text: 'Cranberry', value: 'cranberry' },

];

function FilterModal({isOpen, setIsOpen, setFilteredItems, businessId, locationId }) {
  const [selectedFruitsText, setSelectedFruitsText] = useState('0 Items');
  const [selectedFruits, setSelectedFruits] = useState([]);
  const [employees, setEmployees] = useState([])
  useEffect(()=>{
      getEmployeesList()
  },[])

  const getEmployeesList = async () =>{
      // let locQuery = (scheduleData && scheduleData.location && scheduleData.location._id || null) ? `&locationId=${scheduleData.location._id}` : ''
      let response = await axios.get(`/employee/getPublicList?businessId=${businessId}`)
      setEmployees(response.data)
  }

  
  const formatData = (data) => {
    if (data.length === 1) {
      const fruit = fruits.find(fruit => fruit.value === data[0]);
      return fruit.text;
    }
  
    return `${data.length} items`;
  }
  
  const fruitSelectionChanged = (fruits) => {
    setSelectedFruits(fruits);
    // setSelectedFruitsText(formatData(fruits));
    setFilteredItems(fruits)
    setIsOpen(false)
  }

  return (
    <>
      {/* <IonContent color="light">
        <IonList inset={true}>
          <IonItem button={true} detail={false} id="select-fruits">
            <IonLabel>Favorite Fruits</IonLabel>
            <div slot="end" id="selected-fruits">{ selectedFruitsText }</div>
          </IonItem>
        </IonList>
      </IonContent> */}
      
      <IonModal isOpen={isOpen}>
        <AppTypeahead
          title="Filter by Employee"
          items={employees}
          selectedItems={selectedFruits}
          onSelectionCancel={() => {setIsOpen(false)}}
          onSelectionChange={fruitSelectionChanged}
          setIsOpen={setIsOpen}
        />
      </IonModal>
    </>
  );
}
export default FilterModal;