import {
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonLabel,
  IonModal,
  IonSpinner,
} from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/StandardContainer'
import { AuthContext } from '../../auth'
import GetLocationDataBasedOnLocationId from '../../Utils/GetLocationDataBasedOnLocationId'
import axios from 'axios'
import { limitDecimalPlaces } from '../../utils'
import NoShowChargesForm from './NoShowChargesForm'

export default function NoShowButton({ appointment, getAppointment }) {
  const [chargesModalOpen, setChargesModalOpen] = useState(false)
  const { locationId, locationData } = useContext(AuthContext)
  const [defaultChargeEnabled, setDefaultChargeEnabled] = useState(false)
  const [customChargeValue, setCustomChargeValue] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)
  const locationNoShowSettings = selectedLocationData?.appointmentSettings?.noShowSettings
  const getCancellationCharges = () => {
    // Assuming appointment.payment.totalprice is the total price
    let totalPrice = appointment?.payment?.totalPrice
    let defaultCancellationCharges = 0
    // Check the type property and apply discount accordingly
    if (locationNoShowSettings?.type === 'fixed') {
      // Subtract the fixed value
      defaultCancellationCharges = locationNoShowSettings?.value
    } else if (locationNoShowSettings?.type === 'percentage') {
      // Subtract the percentage
      const percentage = locationNoShowSettings?.value / 100
      defaultCancellationCharges = totalPrice * percentage
    }
    return defaultCancellationCharges
  }
  const defaultCancellationCharges = getCancellationCharges()

  const toggleDefaultCharge = () => {
    setDefaultChargeEnabled(!defaultChargeEnabled)
    setCustomChargeValue('')
    setError('')
  }

  const handleCustomChargeValue = value => {
    if (value > appointment?.payment?.totalPrice) {
      setError('Charge value cannot be more than appointment total price')
    } else {
      setError('')
    }
    setCustomChargeValue(value)
  }
  const openChargesModal = () => {
    setChargesModalOpen(true)
  }
  const closeChargesModal = () => {
    setChargesModalOpen(false)
  }

  const handleChargeAndUpdateStatus = async () => {
    setLoading(true)

    let noShowCharge = 0
    let customizedNoShowCharge = !defaultChargeEnabled

    if (locationNoShowSettings?.charge) {
      noShowCharge = parseFloat(
        defaultChargeEnabled ? defaultCancellationCharges : customChargeValue,
      )
    } else {
      customizedNoShowCharge = false
    }

    try {
      const input = {
        appointmentId: appointment?._id,
        status: 'No Show',
        paidExternal: true,
        noShowCharge,
        customizedNoShowCharge,
      }

      await axios.patch(`/appointment/updateStatus?id=${appointment?._id}`, input)

      const paymentObjectWithNoShowCharge = {
        ...appointment?.payment,
        noShowCharge,
      }

      await axios.patch(`/appointment?id=${appointment?._id}`, {
        payment: paymentObjectWithNoShowCharge,
      })

      await getAppointment()
    } catch (error) {
      // Handle error
    }

    setLoading(false)
  }
  useEffect(() => {
    let formattedValue = limitDecimalPlaces(customChargeValue, 2)
    setCustomChargeValue(formattedValue)
  }, [customChargeValue])

  return (
    <>
      <IonButton onClick={openChargesModal} expand='block' color={'medium'} fill='solid'>
        No Show
      </IonButton>
      <IonModal isOpen={chargesModalOpen} onDidDismiss={closeChargesModal}>
        <CommonHeader title={'No Show'}></CommonHeader>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <IonCard style={{ padding: '1rem' }}>
              <h5 style={{ fontWeight: 'bold', color: 'black' }}>Mark as No-Show</h5>
              <p>This appointment will be marked as no-show</p>
              {/* to be shown only if no show charge settings are enabled in location settings */}
              {locationNoShowSettings?.charge && (
                <>
                  <NoShowChargesForm
                    defaultChargeEnabled={defaultChargeEnabled}
                    toggleDefaultCharge={toggleDefaultCharge}
                    appointment={appointment}
                    defaultCancellationCharges={defaultCancellationCharges}
                    customChargeValue={customChargeValue}
                    handleCustomChargeValue={handleCustomChargeValue}
                    error={error}
                  />
                </>
              )}

              <IonButtons
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IonButton style={{ color: 'grey' }} onClick={closeChargesModal}>
                  Dismiss
                </IonButton>
                <IonButton
                  color={'primary'}
                  fill='solid'
                  onClick={handleChargeAndUpdateStatus}
                  disabled={
                    locationNoShowSettings?.charge
                      ? !((defaultChargeEnabled === true || customChargeValue != '') && error == '')
                      : false
                  }
                  style={{ padding: '0 1rem', margin: '0 1rem' }}
                >
                  <IonLabel
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '1rem',
                    }}
                  >
                    {loading && <IonSpinner style={{ marginRight: '0.5rem' }} name='lines' />}Mark
                    as No-Show
                  </IonLabel>
                </IonButton>
              </IonButtons>
            </IonCard>
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}
