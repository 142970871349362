import React, { useState, useEffect, useContext } from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/ChevronLeft';

export default function HeaderComponent({ title, handleBack, showBackButton = true, progress = null }) {

    console.log(showBackButton)

    return (
        <>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{}}></div>
            </div>

            <div style={{ flexDirection: 'row', display: 'flex' }}>

                {showBackButton ? <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleBack}
                    aria-label="close"
                    variant='outlined'
                    style={{ marginLeft: 4, marginRight: 4, marginTop: -4 }}
                >
                    <CloseIcon />
                </IconButton> : null}
                <Typography variant="h5">{title}</Typography>
            </div>
            <div>
                <ProgressComponent progress={progress} />
            </div>
        </>
    );
}


function ProgressComponent({ progress, props }) {
    if (!progress) return null

    let complete =  progress.step / progress.total * 100

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft:2 }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" value={complete} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${progress.step}/${progress.total}`}</Typography>
                </Box>
            </Box>
        </>
    )
}