import React, { useState, useEffect, useContext } from 'react';
import GeneralDetails from './details_general'
import axios from 'axios'
import { AuthContext } from '../auth'
import { IonBackButton, IonButton, IonButtons, IonCard, IonContent, IonHeader, IonPage, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import StandardContainer from '../commonComponents/StandardContainer';
import FeaturesList from './enrolledEmailsList';
import StandardCenterCenter from '../commonComponents/StandardCenterCenter';


export default function Details({ match }) {
    let corporateDeal_id = match?.params?.id
    const [value, setValue] = React.useState(0);
    const [corporateDeal, setCorporateDeal] = useState()
    const [corporateDealTemp, setCorporateDealTemp] = useState()
    const [corporateDealChanged, setCorporateDealChanged] = useState(false)
    const { businessData, locationId } = useContext(AuthContext);
    const history = useHistory()


    const getCorporateDeal = async () => {
        let response = await axios.get(`/corporateDeal?id=${corporateDeal_id}`)
        console.log(response.data)
        setCorporateDeal(response.data)
        setCorporateDealTemp(response.data)
    }
    useEffect(() => { getCorporateDeal() }, [corporateDeal_id])



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const updateCorporateDeal = (value) => {
        setCorporateDealTemp(value);
        setCorporateDealChanged(true)
    };

    const save = async () => {
        let response = await axios.patch(`/corporateDeal?id=${corporateDeal_id}`, corporateDealTemp)
        await getCorporateDeal()
        setCorporateDealChanged(false)
    }

    const cancel = () => {
        setCorporateDealTemp(corporateDeal);
        setCorporateDealChanged(false)
    }

    const deleteCorporateDeal = async () => {
        if (window.confirm('Are you sure you want to delete the subscription package?')) {
            // Delete it!
            await axios.delete(`/corporateDeal?id=${corporateDeal_id}`)
            history.push(-1)
        }
    }

    // if (!corporateDeal) return <IonPage><IonContent></IonContent></IonPage>

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="black" style={{ backgroundColor: 'white', color: 'black' }}>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>{corporateDealTemp ? corporateDealTemp?.name : 'loading...'}</IonTitle>
                    {corporateDealChanged ? <><IonButtons slot="end">
                        <IonButton onClick={cancel}>
                            Cancel
                        </IonButton>
                    </IonButtons>
                        <IonButtons slot="end">
                            <IonButton color="primary" onClick={save}>
                                Save
                            </IonButton>
                        </IonButtons></> : null}
                </IonToolbar>

            </IonHeader>

            <IonContent>
                {corporateDeal ?
                    <StandardContainer>
                        <GeneralDetails corporateDeal={corporateDealTemp} updateCorporateDeal={updateCorporateDeal} locationId={locationId} businessData={businessData} />
                        <FeaturesList corporateDeal={corporateDealTemp} updateCorporateDeal={updateCorporateDeal} />
                        <div style={{ padding: 10, marginTop: 200 }}>
                            <IonButton color="warning" fill='solid' expand='block' onClick={deleteCorporateDeal}>Delete</IonButton>
                        </div>
                    </StandardContainer>
                    :
                    <StandardCenterCenter>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <IonSpinner name='dots' />
                    </StandardCenterCenter>
                }
            </IonContent>
        </IonPage>
    );
}
