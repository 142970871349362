import * as React from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, IconButton, Chip } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import AppointmentScheduler from '../scheduleAnAppointment/stepController'
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateTestDialog({ open, handleClose, businessId, locationId, selectedAppointment }) {

  const [errorMessage, setErrorMessage] = React.useState()
  const [status, setStatus] = React.useState(selectedAppointment && selectedAppointment.status || 'Scheduled')

  React.useEffect(()=>{
    setStatus(selectedAppointment && selectedAppointment.status || null)
  },[selectedAppointment && selectedAppointment.status])

  if (!open || !selectedAppointment) {
    return null
  }

  const cancelAppointment = async () => {
    setErrorMessage(null)
    let answer = window.confirm("Cancel the Appointment")
    if (answer) {
      try {
        let response = await axios.patch(`/appointment/cancel`, { appointmentId: selectedAppointment._id })
        console.log(response.data)
        handleClose()
      } catch (error) {
        setErrorMessage("Sorry there was an error. Please try again later. (AP-100)")
      }
    }
  }

  const updateStatus = async (event) => {
      setErrorMessage(null)

      try {
        let response = await axios.patch(`/appointment/updateStatus`, { appointmentId: selectedAppointment._id, status: event.target.value })
        setStatus(event.target.value)
      } catch (error) {
        setErrorMessage("Sorry there was an error. Please try again later. (AP-101)")
      }
  }

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogActions>
        <IconButton onClick={handleClose} size="medium" color="primary" style={{ zIndex: 10000 }}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogActions>
      <DialogContent style={{ marginTop: -50, backgroundColor: 'transparent' }}>
        {/* <div style={{width:500, height:1}}></div> */}
        <Typography variant='subtitle2' style={{ color: 'red' }}>{errorMessage}</Typography>
        <List sx={{ width: '100%', maxWidth: 360 }}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={selectedAppointment.customer.firstName} src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary={`${selectedAppointment.customer.firstName} ${selectedAppointment.customer.lastName}`}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {selectedAppointment.status}
                  </Typography>
                  {"  "}{selectedAppointment.startTimeText.replace(' PM', '').replace(' AM', '')} - {selectedAppointment.endTimeText}
                </React.Fragment>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={<React.Fragment>
                <IsNew customer={selectedAppointment.customer} />
                <br></br>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Barber: {selectedAppointment.barber.firstName} {selectedAppointment.barber.lastName}
                </Typography>
                <br></br>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Service: {selectedAppointment?.services?.map((s)=>{
                  return `${s.name} `
                })}
                </Typography>
                <br></br>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Duration: {selectedAppointment.durationMin}min
                </Typography>
              </React.Fragment>}
            />
          </ListItem>

          <Divider />
        </List>
        <Grid container spacing={1} style={{ marginTop: 6 }}>
          <Grid xs item>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Status"
                onChange={updateStatus}
              >
                <MenuItem value={'Scheduled'}>Scheduled</MenuItem>
                <MenuItem value={'Arrived'}>Arrived</MenuItem>
                <MenuItem value={'In Progress'}>In Progress</MenuItem>
                <MenuItem value={'Complete'}>Complete</MenuItem>
                <MenuItem value={'Canceled'}>Canceled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs item>
            <Button style={{ width: '100%' }} color="warning" onClick={() => { cancelAppointment() }}>✕ Cancel</Button>
          </Grid>
          <Grid xs item>
            <Button style={{ width: '100%' }} color="success"> $ Charge</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog >
  );
}

function IsNew({ customer }) {
  if (!customer.numberOfAppointments) {
    return <Chip style={{ fontWeight: '600', fontSize: 9, heigth: 10, borderColor: '#58BAF8', color: '#58BAF8', marginTop: -10, marginBottom: 6 }} variant="outlined" size="small" label="1ST APPT" />
  } else {
    return <span></span>
  }
}