import React, { useState, useEffect, useContext } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import axios from 'axios'

import { Typography, Grid, Box, Button, Chip, IconButton } from '@mui/material'

import SimpleDialog from './addNewSchedule'
import DeleteIcon from '@mui/icons-material/Delete'
import { IonButton } from '@ionic/react'

export default function ModifiedSchedule({ standardSchedule, locationId, employeeId, businessId }) {
  console.log(employeeId, 'employeeIdemployeeId')
  const [open, setOpen] = React.useState(false)
  const [modifiedSchedule, setModifiedSchedule] = React.useState()

  const getModifiedSchedule = async () => {
    let response = await axios.get(`/employee/modifiedSchedule?employeeId=${employeeId}`)
    console.log(response.data)
    setModifiedSchedule(response.data)
  }

  React.useEffect(() => {
    getModifiedSchedule()
  }, [employeeId])

  const handleSave = async data => {
    console.log('innnnnnnnnnnnnnnnnnnnnnn')
    try {
      await axios.post(`/employee/modifiedSchedule`, {
        ...{ locationId, businessId, employeeId },
        ...data,
      })
      getModifiedSchedule()
      handleClose()
    } catch (error) {
      alert('Sorry there was an issue saving. Try again later.')
    }
  }

  const handleDelete = async id => {
    try {
      if (window.confirm('Are you sure you want to delete the modified schedule?')) {
        // Delete it!
        try {
          await axios.delete(`/employee/modifiedSchedule?id=${id}`)
          getModifiedSchedule()
        } catch (error) {
          window.alert('Looks like there was an issue. Try again later.')
        }
      }
    } catch (error) {}
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = value => {
    setOpen(false)
  }

  let data = modifiedSchedule

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <Box display='flex' alignItems='center'>
            <h5>Modified Schedule</h5>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display='flex' justifyContent='flex-end'>
            <IonButton color='primary' onClick={handleClickOpen} fill='clear'>
              + Add
            </IonButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ marginBottom: 20, fontSize: 12 }}>
            Modify your future schedule by changing hours or marking a day off.
          </Typography>
        </Grid>
      </Grid>
      <TableContainer>
        <Table aria-label='simple table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: 'grey', fontSize: 12 }}>DATE</TableCell>
              <TableCell style={{ color: 'grey', fontSize: 12 }}>OPEN</TableCell>
              <TableCell style={{ color: 'grey', fontSize: 12 }}>OPEN TIME</TableCell>
              <TableCell style={{ color: 'grey', fontSize: 12 }}>CLOSE TIME</TableCell>
              <TableCell style={{ color: 'grey', fontSize: 12 }}>DELETE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map(d => (
                <TableRow key={d._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    <div style={{ minWidth: 90 }}>{d.dateText}</div>
                  </TableCell>
                  <TableCell>
                    {d.enabled ? (
                      <Chip
                        size='small'
                        label='Working'
                        style={{ backgroundColor: '#2175EA', color: 'white' }}
                      />
                    ) : (
                      <Chip size='small' label='Day Off' />
                    )}
                  </TableCell>
                  <TableCell>
                    {d.enabled ? d.startTime : <div style={{ width: 90, height: 1 }} />}
                  </TableCell>
                  <TableCell>
                    {d.enabled ? d.endTime : <div style={{ width: 90, height: 1 }} />}
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label='delete'>
                      <DeleteIcon
                        fontSize='small'
                        onClick={() => {
                          handleDelete(d._id)
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        standardSchedule={standardSchedule}
        handleSave={handleSave}
      />
    </>
  )
}
