import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { editAppointmentTimeIntervals } from '../../StaticData/StaticData'

const times = editAppointmentTimeIntervals

export default function TimeSelector({ dayKey, fieldKey, value, handleChange }) {
  const valueChanged = event => {
    handleChange(dayKey, fieldKey, event.target.value)
  }

  return (
    <FormControl
      variant='standard'
      sx={{ minWidth: 90, maxHeight: 40, margin: 0, fontSize: 10 }}
      margin='dense'
      size='small'
    >
      <Select
        labelId='demo-select-small'
        id='demo-select-small'
        value={value}
        label={fieldKey}
        onChange={valueChanged}
        style={{ fontSize: 14 }}
      >
        {times.map(v => (
          <MenuItem value={v}>{v}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
