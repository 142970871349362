import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import {
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
} from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import { AuthContext } from '../auth'
import { getFormattedMediumDate } from '../utils'
import Header from '../commonComponents/header'
import TrendChart from './trendsChart'
import { colors } from '../theme/colors'
import MiniHeader from '../commonComponents/MiniHeader'
import LocationStats from './statsCard'
import LineChart from './progressChart'
import DefaultMenu from '../commonComponents/SideMenu/DefaultMenu'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'
import NoPermissionsCard from '../commonComponents/NoPermissionsCard'

const styles = {
  topCard: { padding: 20, margin: 0, minWidth: 160 },
  topCardNumber: { marginBottom: 0, marginTop: 4 },
  topCardText: { fontSize: 10, fontWeight: '700', marginBottom: 10 },
  circleContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  circleItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  circle: {
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    backgroundColor: '#ddd',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleNumber: {
    fontSize: '24px',
    margin: '0',
  },
}

export default function Dashboard() {
  const { businessData, locationId, userData } = useContext(AuthContext)
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)
  const [appointments, setAppointments] = useState()
  const [projectedData, setProjectedData] = useState()
  const [trends, setTrends] = useState({
    data: null,
    loading: false,
    error: null,
  })
  const [projectedValue, setProjectedValue] = useState('--')
  const [apptSummary, setApptSummary] = useState({
    'Complete': 0,
    'In Progress': 0,
    'No Show': 0,
    'Scheduled': 0,
    'Arrived': 0,
  })
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [walkinsWaitingList, setWalkinsWaitingList] = useState()
  const [walkinsList, setWalkinsList] = useState()

  const getAppointmentsList = async () => {
    if (!businessData?._id) return
    setLoading(true)
    setErrorMessage(null)
    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      let date = getFormattedMediumDate(new Date())
      let response = await axios.get(
        `/appointment/getList?businessId=${businessData._id}${locQuery}&dateText=${date}`,
      )
      //
      setAppointments(response.data)

      let d = {
        'Complete': 0,
        'In Progress': 0,
        'No Show': 0,
        'Scheduled': 0,
        'Arrived': 0,
        'Total': 0,
        'Canceled': 0,
      }
      for (let a of response?.data) {
        d[a.status]++
        d['Total']++
      }
      setApptSummary(d)
    } catch (error) {
      setErrorMessage('There was an issue fetching the data')
    }
    setLoading(false)
  }

  const parseApptWaitingList = async () => {
    let wwl = appointments?.filter(
      a => a.type == 'Walkin' && (a.status == 'Scheduled' || a.status == 'Arrived'),
    )
    let walkinList = appointments?.filter(a => a.type == 'Walkin')
    setWalkinsWaitingList(wwl)
    setWalkinsList(walkinList)
  }

  const getTrends = async () => {
    setTrends(prevState => ({ ...prevState, loading: true, error: null }))
    try {
      const response = await axios.get(
        `/dashboard/appointmentTrends?businessId=${businessData?._id}${
          locationId ? `&locationId=${locationId}` : ''
        }`,
      )
      setTrends({ data: response.data, loading: false, error: null })
    } catch (error) {
      setTrends({ data: null, loading: false, error: error })
    }
  }

  const getProjectedData = async () => {
    setProjectedData({ ...projectedData, ...{ loading: true } })

    // Get the current date, year, and month
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1 // JavaScript months are 0-indexed

    // Construct the URL with query parameters
    let url = `/dashboard/projectedTrends?businessId=${businessData?._id}&year=${currentYear}&month=${currentMonth}`
    if (locationId) {
      url += `&locationId=${locationId}`
    }

    // Make the API request
    let response
    try {
      response = await axios.get(url)
    } catch (error) {}

    // Update state with the response data
    setProjectedData({ data: response?.data, loading: false })
    let sumOfApptThisMonth = response?.data?.thisMonth?.trends?.reduce(
      (accumulator, currentValue) => accumulator + currentValue.count,
      0,
    )

    setProjectedValue(sumOfApptThisMonth)
    let pvalue = projectTotalAppointments(
      sumOfApptThisMonth +
        (apptSummary?.Total || 0) -
        (apptSummary?.['No Show'] || 0) -
        (apptSummary?.['Canceled'] || 0) -
        (apptSummary?.['Complete'] || 0),
    )
    setProjectedValue(pvalue)
  }

  useEffect(() => {
    parseApptWaitingList()
  }, [appointments, locationId])

  useEffect(() => {
    getTrends()
    getAppointmentsList()
    getProjectedData()
  }, [businessData, locationId])

  // const [customerTrends, fetchCustTrends] = useAxios(`/dashboard/appointmentTrends?businessId=${businessData?._id}${locationId ? `&locationId=${locationId}` : ''}`)
  // useEffect(() => { fetchTrends() }, [businessData])

  useEffect(() => {
    const interval = setInterval(() => {
      getTrends()
      getAppointmentsList()
      getProjectedData()
    }, 20000)
    return () => clearInterval(interval)
  }, [])

  async function handleRefresh(event) {
    await getTrends()
    await getAppointmentsList()
    getProjectedData()
    event.detail.complete()
  }

  let totalAppts =
    (apptSummary?.Total || 0) - (apptSummary?.['No Show'] || 0) - (apptSummary?.['Canceled'] || 0)

  return (
    <>
      <DefaultMenu />

      <IonPage id='main-content'>
        <Header title={'Dashboard'} />
        <IonContent>
          <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <StandardContainer>
            {userEntitlements?.noPermissions ? (
              <NoPermissionsCard />
            ) : (
              <>
                <div style={{ marginTop: 10, marginLeft: 10, marginBottom: -10 }}>
                  <MiniHeader title='Today' />
                </div>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonCard style={styles.topCard} className='flat'>
                        <div style={styles.circleContainer}>
                          <CircleNumber
                            title='SCHEDULED'
                            value={apptSummary?.['Scheduled']}
                            color={colors.purple}
                          />
                          <CircleNumber
                            title='ARRIVED'
                            value={apptSummary?.['Arrived']}
                            color={colors.orange}
                          />
                          <CircleNumber
                            title='PROGRESS'
                            value={apptSummary?.['In Progress']}
                            color={colors.primaryBlue}
                          />
                          <CircleNumber
                            title='COMPLETE'
                            value={apptSummary?.Complete}
                            color={colors.green}
                          />
                        </div>
                        <div style={{ height: 1, width: '100%', backgroundColor: '#d6d6d6' }} />
                        <IonGrid style={{ marginBottom: -14, padding: 0, marginTop: 4 }}>
                          <IonRow style={{ margin: 0, padding: 0 }}>
                            <IonCol>
                              <div
                                style={{
                                  marginTop: 4,
                                  fontWeight: '700',
                                  color: 'grey',
                                  fontSize: 12,
                                }}
                              >
                                TOTAL: {totalAppts}
                              </div>
                            </IonCol>
                            <IonCol>
                              <div
                                style={{
                                  marginTop: 4,
                                  fontWeight: '700',
                                  color: 'grey',
                                  fontSize: 12,
                                }}
                              >
                                WALK-INS: {walkinsList?.length || 0}
                              </div>
                            </IonCol>
                            <IonCol>
                              {walkinsWaitingList?.length > 0 ? (
                                <div
                                  style={{
                                    backgroundColor: colors.orange,
                                    marginLeft: 0,
                                    marginRight: -10,
                                    width: 134,
                                    textAlign: 'center',
                                    borderRadius: 20,
                                    padding: 4,
                                    fontSize: 12,
                                    fontWeight: '700',
                                    color: 'white',
                                  }}
                                >
                                  {walkinsWaitingList?.length} Walk-in
                                  {walkinsWaitingList?.length != 1 ? 's' : ''} Waiting
                                </div>
                              ) : null}
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol></IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <div style={{ marginTop: 10, marginBottom: -10 }}>
                        <MiniHeader title='Trends' />
                      </div>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <IonCard style={styles.topCard} className='flat'>
                        <h6 style={{ margin: 0, color: 'black' }}>Appointment Trends</h6>
                        <TrendChart
                          data={trends?.data?.trends}
                          statusKeys={['Scheduled', 'In Progress', 'Complete', 'Arrived']}
                          colors={[colors.purple, colors.primaryBlue, colors.green, colors.orange]}
                        />
                      </IonCard>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <IonCard style={styles.topCard} className='flat'>
                        <IonGrid style={{ margin: 0, padding: 0 }}>
                          <IonRow style={{ margin: 0, padding: 0 }}>
                            <IonCol style={{ margin: 0, padding: 0 }}>
                              <h6 style={{ margin: 0, color: 'black' }}>Projections</h6>
                            </IonCol>
                            <IonCol size='auto' sytle={{ margin: 0, padding: 0 }}>
                              <h6 style={{ margin: 0, color: 'black' }}>{projectedValue}</h6>
                            </IonCol>
                          </IonRow>
                        </IonGrid>

                        <LineChart
                          data={projectedData?.data}
                          firstLoad={projectedValue ? true : false}
                        />
                      </IonCard>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <IonCard style={styles.topCard} className='flat'>
                        <h6 style={{ margin: 0, color: 'black' }}>Cancels & No Shows</h6>
                        <TrendChart
                          data={trends?.data?.trends}
                          statusKeys={['Canceled', 'No Show']}
                          colors={[colors.orange, colors.grey]}
                        />
                      </IonCard>
                    </IonCol>
                  </IonRow>
                  {userEntitlements?.isAdmin && (
                    <IonRow>
                      <IonCol>
                        <LocationStats businessId={businessData?._id} />
                      </IonCol>
                    </IonRow>
                  )}
                  {/* <IonRow>
                            <IonCol>
                                <IonCard style={styles.topCard}>
                                    <h5 style={{ margin: 0 }}>New Customers</h5>
                                    <TrendChart
                                        data={trends?.data?.newCustomers}
                                        statusKeys={['Canceled', 'No Show']}
                                        colors={[colors.orange, colors.grey]}
                                    />
                                </IonCard>
                            </IonCol>
                        </IonRow> */}
                </IonGrid>
              </>
            )}
          </StandardContainer>
        </IonContent>
      </IonPage>
    </>
  )
}

const getTodaysDate = () => {
  const today = new Date()
  const day = String(today.getDate()).padStart(2, '0')
  const month = String(today.getMonth() + 1).padStart(2, '0') // Month is 0-based, so we need to add 1
  const year = today.getFullYear()

  return `${month}-${day}-${year}`
}

function CircleNumber({ title, value, color }) {
  let circleColor = {}
  if (value > 0) {
    circleColor = { backgroundColor: color || '#ddd' }
  }
  return (
    <div style={styles.circleItem}>
      <div style={{ ...styles.circle, ...circleColor }}>
        <h1 style={styles.circleNumber}>{value}</h1>
      </div>
      <h5 style={styles.topCardText}>{title}</h5>
    </div>
  )
}

function projectTotalAppointments(sumOfApptThisMonth = 0) {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const daysPassed = currentDate.getDate()

  // Function to count Sundays
  const countSundays = (year, month, totalDays) => {
    let sundays = 0
    for (let day = 1; day <= totalDays; day++) {
      if (new Date(year, month, day).getDay() === 0) {
        // 0 is Sunday
        sundays++
      }
    }
    return sundays
  }

  // Counting the number of Sundays passed and total Sundays in the month
  const sundaysPassed = countSundays(currentYear, currentMonth, daysPassed)
  const totalSundays = countSundays(
    currentYear,
    currentMonth,
    new Date(currentYear, currentMonth + 1, 0).getDate(),
  )

  // Adjusting days passed and total days in the month to exclude Sundays
  const adjustedDaysPassed = daysPassed - sundaysPassed
  const adjustedTotalDays = new Date(currentYear, currentMonth + 1, 0).getDate() - totalSundays

  // Calculate the average daily appointments (excluding Sundays)
  const averageDailyAppointments = sumOfApptThisMonth / adjustedDaysPassed

  // Projecting the total appointments for the month (excluding Sundays)
  const projectedTotal = averageDailyAppointments * adjustedTotalDays

  return Math.round(projectedTotal)
}
