import {
  IonCard,
  IonCol,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
} from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import axios from 'axios'
import { getMonthRange } from '../Utils/GetDates'
import { getAllDaysInCurrentMonth, getDaysInCurrentMonth } from '../utils'
import { GetXAxisMonths } from '../Utils/GetXAxisMonths'
import Chart from 'react-apexcharts'
import { AuthContext } from '../auth'

export default function FilledCapacity() {
  let noOfMonths = 0
  const { locationId, businessData } = useContext(AuthContext)
  const [capacityByDayOfWeek, setCapacityByDayOfWeek] = useState(false)

  const [loading, setLoading] = useState(false)

  const [series, setSeries] = useState([])
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 250,
      stacked: true,
      toolbar: false,
    },
    xaxis: {
      categories: [],
      tickAmount: 5,
    },
    colors: ['#ffac33'], // Add your custom colors here

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '90%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    grid: {
      borderColor: '#f1f1f1',
    },
    yaxis: {
      tickAmount: 4,
      labels: {
        formatter: function (value) {
          return `${Math.abs(value)}`
        },
      },
    },
    toolbar: {
      show: false,
      tools: {
        download: false,
      },
    },
    export: {
      show: false,
    },

    legend: {
      show: true,
      offsetY: -10,
    },
  })
  const getFilledCapacityByDay = async () => {
    setLoading(true)
    const monthsRange = getMonthRange(noOfMonths)

    try {
      const response = await axios.get(
        `/appointmentCapacity/day?fromDate=${monthsRange.startDate}&toDate=${monthsRange.endDate}`,
      )

      const data = response.data
      const daysInCurrentMonth = getDaysInCurrentMonth()

      const categoriesResponse = GetXAxisMonths(noOfMonths)

      const months = categoriesResponse.monthNumbers
      // Create series data from the provided API data
      const series = [{ data: [] }]

      categoriesResponse.monthNumbers.forEach(() => {
        series[0].data =
          noOfMonths === 0 || noOfMonths === 1
            ? Array(daysInCurrentMonth).fill(0)
            : Array(months.length).fill(0)
      })

      // Update series data with fetched API data
      if (data) {
        const currentDate = new Date()
        const currentMonth = currentDate.getMonth() + 1 // Adding 1 because months are zero-indexed
        console.log(currentMonth)
        data.forEach(item => {
          if (noOfMonths === 0) {
            const dayIndex = item.month == currentMonth && item.day - 1
            series[0].data[dayIndex] = item.capacity
          }
        })
      }

      setSeries(series)
      setOptions(prevOptions => ({
        ...prevOptions,

        xaxis: {
          ...prevOptions.xaxis,
          categories:
            noOfMonths === 0 || noOfMonths === 1
              ? getAllDaysInCurrentMonth(noOfMonths)
              : categoriesResponse.categories,
        },
      }))
      console.log(series, 'seriesseries')
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const getFilledCapacityByDayOfWeek = async () => {
    setLoading(true)

    const monthsRange = getMonthRange(noOfMonths)
    try {
      const response = await axios.get(
        `/appointmentCapacity/dayOfWeek?fromDate=${monthsRange.startDate}&toDate=${monthsRange.endDate}`,
      )

      setCapacityByDayOfWeek(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  useEffect(() => {
    getFilledCapacityByDay()
    getFilledCapacityByDayOfWeek()
  }, [])
  useEffect(() => {
    getFilledCapacityByDay()
    getFilledCapacityByDayOfWeek()
  }, [locationId, businessData])

  return (
    <IonPage>
      <CommonHeader title={'Filled Capacity'} backIcon={true} />
      <IonContent>
        <StandardContainer>
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IonSpinner name='dots' />
            </div>
          )}
          {!loading && (
            <IonCard style={{ padding: '0 1rem' }}>
              <h2 style={{ color: 'black' }}>By Day</h2>
              <Chart options={options} series={series} type='bar' height={200} />
            </IonCard>
          )}
          {!loading && (
            <IonList style={{ padding: '1rem' }} mode='ios' inset={true}>
              <h2>By Days of the Week</h2>

              {daysOfWeek.map((day, index) => {
                let dataItem =
                  capacityByDayOfWeek &&
                  capacityByDayOfWeek.find((item, itemIndex) => item.dayOfWeek == index)

                return (
                  <IonItem>
                    <IonRow style={{ width: '100%' }}>
                      <IonCol>
                        <IonLabel>{day}</IonLabel>
                      </IonCol>
                      <IonCol style={{ display: 'flex' }} class='ion-justify-content-end'>
                        <IonLabel>
                          {dataItem
                            ? dataItem.averageCapacity !== null
                              ? dataItem.averageCapacity.toFixed(3) + '%'
                              : '-'
                            : '-'}
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonItem>
                )
              })}
            </IonList>
          )}
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
