export function calculatePayment(
  services = [],
  products = [],
  discounts = [],
  subscriptionPackageId,
  tipAmount = 0,
  tipId,
  taxRate = 0.05,
  cashTipAmount = 0,
) {
  let payment = {
    tipAmount,
    tipId,
    taxes: 0,
    taxRate,
    productsTotal: 0,
    servicesTotal: 0,
    otherDiscounts: 0,
    subscriptionDiscount: 0,
    subscriptionDiscountForProducts: 0,
    discountsTotal: 0,
    totalPrice: 0,
    cashTipAmount: 0,
    discounts: [],
  }

  //Services Total & Subscription Discounts
  for (let s of services) {
    payment.servicesTotal += parseFloat(s.price)
    if (
      subscriptionPackageId &&
      s?.includedInSubscriptions?.some(subscription => subscription._id === subscriptionPackageId)
    ) {
      payment.discounts.push({
        name: 'Subscriber Discount - ' + s.name,
        amount: parseFloat(s.price),
      })
      payment.subscriptionDiscount += parseFloat(s.price)
    }
  }

  taxRate = 0.05

  //Products
  for (let p of products) {
    let price = parseFloat(p.price)
    payment.productsTotal += price

    console.log(subscriptionPackageId, p)
    if (subscriptionPackageId) {
      let discount = price * 0.15
      payment.discounts.push({ name: 'Subscriber Discount (15%) - ' + p.name, amount: discount })
      payment.subscriptionDiscount += discount
      payment.subscriptionDiscountForProducts += discount
      // payment.taxes += (price - discount) * taxRate
    }
    // else {
    //     payment.taxes += (price * taxRate)
    // }
  }

  //Discounts
  for (let d of discounts) {
    if (d.discountType == 'fixed') {
      let valueLeft = valueOfServicesAndPaymentsAndTax(payment)
      let totalOff = parseFloat(d.discountValue) || 0
      if (valueLeft < (parseFloat(d.discountValue) || 0)) {
        totalOff = valueLeft
      }
      payment.discounts.push({ name: d.name, amount: totalOff })
      payment.otherDiscounts += totalOff
    } else if (d.discountType == 'percentage') {
      let totalOff =
        valueOfServicesAndPayments(payment) * ((parseFloat(d.discountValue) || 0) / 100)
      payment.discounts.push({
        name: `${d.name} (${d.discountValue}%)`,
        amount: parseFloat(totalOff.toFixed(2)),
      })
      payment.otherDiscounts += parseFloat(totalOff.toFixed(2))
    }
  }

  //Calculate Taxes
  if (payment.productsTotal <= payment.subscriptionDiscountForProducts + payment.otherDiscounts) {
    payment.taxes = 0
  } else {
    let priceToBeTaxed =
      payment.productsTotal - (payment.subscriptionDiscountForProducts + payment.otherDiscounts)
    priceToBeTaxed = priceToBeTaxed < 0 ? 0 : priceToBeTaxed
    payment.taxes = priceToBeTaxed * taxRate
  }

  payment.cashTipAmount = cashTipAmount

  payment.discountsTotal = payment.subscriptionDiscount + payment.otherDiscounts

  payment.totalPrice = calculateTotalPrice(payment)

  if (cashTipAmount) {
    payment.totalPrice = payment.totalPrice + cashTipAmount
  }

  return payment
}

function calculateTotalPrice(payment) {
  let total =
    payment.servicesTotal +
    payment.tipAmount +
    payment.taxes +
    payment.productsTotal -
    payment.discountsTotal

  return total < 0 ? 0 : total
}

function valueOfServicesAndPayments(payment) {
  return payment.servicesTotal + payment.productsTotal - payment.subscriptionDiscount
}

function valueOfServicesAndPaymentsAndTax(payment) {
  return (
    payment.servicesTotal + payment.taxes + payment.productsTotal - payment.subscriptionDiscount
  )
}
