import { Button, TextField, Grid, IconButton } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import CheveronLeftIcon from '@mui/icons-material/ChevronLeft'
import CheveronRightIcon from '@mui/icons-material/ChevronRight'
import { Box } from '@mui/system'
import { IonButton, IonButtons, IonIcon } from '@ionic/react'
import { filterOutline } from 'ionicons/icons'
import dayjs from 'dayjs'

export default function AppointmentListView({
  selectedDate,
  openAddDialog,
  setSelectedDate,
  addDayToDate,
  viewType,
  handleViewType,
  setFilterModalOpen,
  filteredItemsCount,
}) {
  return (
    <>
      <Grid container spacing={1} direction='row' alignItems='center' justifyContent='center'>
        <Grid xs item style={{ alignItems: 'center' }}>
          <Box display='flex' justifyContent='center' alignItems='center'>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='Left'
              variant='outlined'
              style={{ marginRight: 4, color: 'grey' }}
              onClick={() => {
                addDayToDate(-1)
              }}
            >
              <CheveronLeftIcon />
            </IconButton>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size='small'
                format='MMM DD'
                inputFormat='MMM DD'
                value={dayjs(selectedDate)}
                onChange={e => {
                  setSelectedDate(e.$d)
                }}
                renderInput={params => (
                  <TextField size='small' style={{ width: 120 }} {...params} />
                )}
              />
            </LocalizationProvider>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='Right'
              style={{ marginLeft: 4, color: 'grey' }}
              onClick={() => {
                addDayToDate(1)
              }}
            >
              <CheveronRightIcon />
            </IconButton>
            <Button
              onClick={() => {
                setSelectedDate(new Date())
              }}
              style={{ borderColor: '#c0c1c2', color: '#1e1e1e' }}
            >
              Today
            </Button>
          </Box>
        </Grid>
        {/* <Grid>
                    <ToggleButtonGroup
                        size='small'
                        value={viewType}
                        exclusive
                        onChange={handleViewType}
                        aria-label="text alignment"
                        style={{ marginLeft: 20, marginRight: 20 }}
                    >
                        <ToggleButton value="list" aria-label="list">
                            <ListIcon />
                        </ToggleButton>
                        <ToggleButton value="calendar" aria-label="calendar">
                            <CalendarIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid> */}
      </Grid>
      <IonButtons slot='end'>
        <IonButton
          onClick={() => {
            setFilterModalOpen(true)
          }}
        >
          {filteredItemsCount > 0 ? (
            <span
              style={{
                height: 10,
                width: 10,
                backgroundColor: 'red',
                borderRadius: '50%',
                position: 'absolute',
                top: 2,
                right: -4,
              }}
            ></span>
          ) : null}
          <IonIcon slot='icon-only' icon={filterOutline} color='primary' />
        </IonButton>
      </IonButtons>
    </>
  )
}
