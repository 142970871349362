import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Typography, Button, Paper, Card, Avatar, Grid, Box, Fade } from '@mui/material'
import axios from 'axios'

import dayjs from 'dayjs'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { getFormattedMediumDate } from '../utils'
import { IonSpinner } from '@ionic/react'
import StandardCenterCenter from '../commonComponents/StandardCenterContainer'

const styles = {
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default function StepPickDateTime({
  businessId,
  updateScheduleData,
  step,
  stepNext,
  isWalkin,
  scheduleData,
  trackStep,
}) {
  console.log('Render StepPickDateTime')
  const [dateAndTimeData, setDateAndTimeData] = useState()
  const [value, setValue] = useState(new Date())
  const [isLoading, setIsLoading] = useState(true)

  function updateTime(dateValue) {
    setValue(dateValue)
    setIsLoading(true)
    setDateAndTimeData()
  }

  useEffect(() => {
    trackStep('PickDateTime', step, scheduleData)
  }, [])

  useEffect(() => {
    getAvailability()
  }, [value])

  const getAvailability = async () => {
    if (!isWalkin) {
      let services = scheduleData?.services.map(service => service._id)
      let response = await axios.get(
        `/appointment/getAvailable?businessId=${businessId}&locationId=${
          scheduleData?.location?._id || null
        }&barberId=${
          scheduleData?.anyProfessional ? 'undefined' : scheduleData?.barber?._id
        }&durationMin=${scheduleData?.durationMin}&customerId=${
          scheduleData?.customer?._id
        }&selectedDate=${getFormattedMediumDate(value)}&anyProfessional=${
          scheduleData?.anyProfessional
        }&services=${services}`,
      )
      setDateAndTimeData(response.data)
      setIsLoading(false)
    }
  }

  function handleTimeClick(data) {
    updateScheduleData('dateTime', data)
    stepNext()
  }

  return (
    <>
      <Fade in={true}>
        <div>
          {/* <HeaderComponent title={'Pick a time'} handleBack={stepBack} progress={{ total: totalSteps, step: step + 1 }} /> */}
          <DateTimeBody
            value={value}
            setValue={updateTime}
            dateAndTimeData={dateAndTimeData}
            handleTimeClick={handleTimeClick}
            isLoading={isLoading}
          />
        </div>
      </Fade>
    </>
  )
}

function DateTimeBody({ value, setValue, dateAndTimeData, handleTimeClick, isLoading }) {
  let subHeader = (
    <div style={styles.loadingBox}>
      <IonSpinner name='dots' />
    </div>
  )

  if (!isLoading) {
    subHeader =
      dateAndTimeData?.outOfBounds == true
        ? 'SCHEDULE NOT AVAILABLE YET'
        : dateAndTimeData?.locationOpen
        ? 'OPEN'
        : 'CLOSED'
  }

  if (dateAndTimeData?.availableTimes?.length == 0) {
    subHeader = 'No Available Appointments. Please check another day.'
  }

  return (
    <>
      <Box
        style={{
          backgroundColor: 'white',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 30,
        }}
      >
        {/* <div>{value.toISOString()}</div> */}
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              displayStaticWrapperAs='desktop'
              openTo='day'
              value={dayjs(value)}
              minDate={dayjs()}
              onChange={newValue => {
                setValue(newValue)
              }}
              renderInput={params => <TextField {...params} />}
            />
          </LocalizationProvider>
          {/* {JSON.stringify(value)} */}
          {/* MORNING  */}
          <Box
            style={{
              width: 300,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid container style={{ display: 'flex', justifyContent: 'left' }}>
              <Grid item>
                <h6 style={{ marginTop: 0 }}>{subHeader}</h6>
              </Grid>
            </Grid>
            {subHeader == 'CLOSED' ? (
              <>
                <StandardCenterCenter>
                  <Closed />
                </StandardCenterCenter>
              </>
            ) : null}

            <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid item container style={{ display: 'flex' }}>
                {dateAndTimeData?.availableTimes?.map(timeSlot => (
                  <TimeSlotButton
                    available={timeSlot.available}
                    timeSlot={timeSlot}
                    handleTimeClick={handleTimeClick}
                    key={timeSlot.startTimeText}
                  />
                )) || null}
              </Grid>
            </Grid>
          </Box>
          <Box style={{ height: 100, width: '100%' }}></Box>
        </div>
      </Box>
    </>
  )
}

function TimeSlotButton({ timeSlot, handleTimeClick, available }) {
  const activeBorderColor = available ? '#999999' : 'red'
  const activeColor = available ? 'black' : 'red'
  if (!available) {
    return null
  }
  return (
    <Grid
      item
      style={{ margin: 2, marginTop: 4 }}
      onClick={() => {
        handleTimeClick(timeSlot)
      }}
    >
      <Button
        style={{
          width: 95,
          height: 40,
          fontSize: 13,
          fontWeight: '600',
          borderWidth: 1,
          borderRadius: 10,
          borderColor: activeBorderColor,
          color: activeColor,
        }}
        disabled={!available}
        variant='outlined'
      >
        {timeSlot.startTimeText}
        {/* {timeSlot.barber.firstName} */}
      </Button>
    </Grid>
  )
}

function Closed() {
  return (
    <div>
      <img src='/assets/closed.png' width={160} height={160} />
    </div>
  )
}
