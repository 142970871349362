import React, { useState, useEffect, useContext } from 'react';
import { Typography, Button, Paper, Card, Avatar, Grid, Box } from '@mui/material';
import axios from 'axios'
import Review from './reviewOrder';



export default function StepSummary({ locationId, businessId, updateScheduleData, setStep, scheduleData }) {

    return (
        <>
            <Review scheduleData={scheduleData} setStep={setStep} />
        </>
    );
}


