import { useState, useEffect, useContext } from 'react'
import GeneralDetails from './details_general'
import axios from 'axios'
import { AuthContext } from '../auth'
import { useHistory } from 'react-router-dom'
import { IonCard, IonContent, IonModal, IonPage, IonSpinner } from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import getEntitlementsByBusinessAndLocation from '../Utils/getEntitlementsByBusinessAndLocation'
import CommonHeader from '../commonComponents/CommonHeader'
import { checkmark, closeCircleOutline, trashOutline } from 'ionicons/icons'
import BusinessPermissions from './BusinessPermissions'
import LocationPermissions from './LocationPermissions'
import ConfirmationModal from '../commonComponents/ConfirmationModal'

export default function Details({ match }) {
  const user_id = match?.params?.id
  const [user, setUser] = useState()
  const [userTemp, setUserTemp] = useState()
  const [userChanged, setUserChanged] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [enableDisableModalOpen, setEnableDisableModalOpen] = useState(false)
  const [deletingUser, setDeletingUser] = useState(false)
  const [enablingdisablingUser, setEnablingDisablingUser] = useState(false)
  const { businessData, locationId } = useContext(AuthContext)
  const history = useHistory()
  const getUser = async () => {
    let response = await axios.get(`/user/byUserId?id=${user_id}`)

    let userData = { ...response.data }
    const entitlements = getEntitlementsByBusinessAndLocation(
      businessData?._id,
      locationId,
      response?.data?.businesses,
    )

    userData['entitlements'] = entitlements

    setUser(userData)
    setUserTemp(userData)
  }
  useEffect(() => {
    getUser()
  }, [user_id])
  useEffect(() => {
    getUser()
  }, [])

  const updateUser = value => {
    setUserTemp(value)
    setUserChanged(true)
  }

  const openDeleteModal = value => {
    setDeleteModalOpen(true)
  }
  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
  }
  const deleteUser = async () => {
    setDeletingUser(true)
    // Delete it!
    try {
      const deleteResponse = await axios.delete(
        `/user?id=${user_id}&firebaseId=${user?.firebaseId}`,
      )
      if (deleteResponse.status === 200) {
        setDeletingUser(false)

        setDeleteModalOpen(false)
      }
      history.goBack()
    } catch (error) {
      setDeletingUser(false)
      setDeleteModalOpen(false)
    }
  }
  const enableDisableUser = async () => {
    setEnablingDisablingUser(true)
    // disable it!
    const status = userTemp.status == 'Disabled' ? 'enable' : 'disable'
    const enableOrDisableUserUrl = `/user/${status}?id=${user_id}&firebaseId=${user?.firebaseId}`
    try {
      const enableDisableResponse = await axios.patch(enableOrDisableUserUrl)

      if (enableDisableResponse.message == 'Success') {
        setEnablingDisablingUser(false)
        setEnableDisableModalOpen(false)
      }
      history.goBack()
    } catch (error) {
      setEnablingDisablingUser(false)
      setEnableDisableModalOpen(false)
    }
  }
  const handleRefreshUser = async () => {
    await getUser()
  }
  const openEnableDisableModal = value => {
    setEnableDisableModalOpen(true)
  }
  const closeDisableModal = () => {
    setEnableDisableModalOpen(false)
  }
  if (!user) return <IonSpinner name='dots' />
  const menuItems = [
    {
      label: 'Remove User',
      icon: trashOutline, // IonIcon for the "Delete" item
      onClick: () => openDeleteModal(true),
    },
    {
      label: userTemp.status == 'Disabled' ? 'Enable Account' : 'Disable Account',
      icon: userTemp.status == 'Disabled' ? checkmark : closeCircleOutline,
      onClick: () => openEnableDisableModal(true),
    },
    // Add more menu items as needed
  ]

  return (
    <IonPage>
      <CommonHeader backIcon={true} title={'Permissions'} actionIcon={true} menuItems={menuItems} />
      <IonContent className='regularBackground'>
        <StandardContainer>
          <IonCard style={{ padding: '10px' }}>
            <GeneralDetails user={userTemp} updateUser={updateUser} />
          </IonCard>
          <IonCard style={{ padding: '10px' }}>
            <BusinessPermissions user={userTemp} refreshUser={handleRefreshUser} />
          </IonCard>
          <IonCard style={{ padding: '10px' }}>
            <LocationPermissions user={userTemp} refreshUser={handleRefreshUser} />
          </IonCard>
          <IonModal isOpen={deleteModalOpen}>
            <ConfirmationModal
              loading={deletingUser}
              message={'Are You Sure You want to delete this user?'}
              cancel={() => closeDeleteModal()}
              // loading={deletingData}
              save={deleteUser}
              saveText={'Delete'}
            />
          </IonModal>
          <IonModal isOpen={enableDisableModalOpen}>
            <ConfirmationModal
              loading={enablingdisablingUser}
              message={
                userTemp.status == 'Disabled'
                  ? 'Are You Sure You want to enable this user?'
                  : 'Are You Sure You want to disable this user?'
              }
              cancel={() => closeDisableModal()}
              // loading={deletingData}
              save={enableDisableUser}
              saveText={userTemp.status == 'Disabled' ? 'Enable' : 'Disable'}
            />
          </IonModal>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
