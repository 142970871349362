const CheckIsManagerOrAdmin = (userData, businessData, locationId) => {
  let selectedBusiness = false
  let isManager = false
  let isAdmin = false
  let isProfessional = false
  let noPermissions = false

  if (businessData && businessData !== 'loading') {
    selectedBusiness =
      userData &&
      userData !== 'loading' &&
      userData.businesses &&
      userData.businesses.find(item => item._id === businessData._id)

    if (selectedBusiness) {
      // Check for entitlements for a specific location
      const selectedLocation = selectedBusiness.locations.find(loc => loc._id === locationId)

      if (selectedLocation) {
        isManager = selectedLocation?.entitlements?.manager
        isAdmin = selectedLocation?.entitlements?.admin
        isProfessional = selectedLocation?.entitlements?.professional
        noPermissions = selectedLocation?.entitlements?.noPermissions
      }
    }
  }

  return {
    isManager,
    isAdmin,
    isProfessional,
    noPermissions,
  }
}

export default CheckIsManagerOrAdmin
