import { IonCard, IonLabel, IonRow, IonSegment, IonSegmentButton, IonSpinner } from '@ionic/react'
import { useEffect, useState } from 'react'
import commonStyles from '../../theme/commonstyles.module.css'
import axios from 'axios'
import FullRefund from './FullRefund'
import PartialRefund from './PartialRefund'
export default function RefundTransaction({ customer_id, closeRefundModal, paymentDetails }) {
  const [activeTab, setActiveTab] = useState('fullRefund')
  const [processingRefund, setProcessingRefund] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [customerCardData, setCard] = useState(false)
  const [loading, setLoading] = useState(false)
  const getCard = async () => {
    setLoading(true)
    try {
      let response = await axios.get(`/customer/cardStripe?customerId=${customer_id}`)

      setLoading(false)
      setCard(response.data)
    } catch (error) {
      setLoading(false)
    }
  }
  useEffect(() => {
    getCard()
  }, [])
  const handleFullRefund = async () => {
    setProcessingRefund(true)
    setSuccessMessage('')
    setErrorMessage('')
    try {
      const input = {
        paymentIntentId: paymentDetails?.id,
        refundType: 'full',
      }
      const response = await axios.post('/customer/payment/refund', input)
      if (response?.data?.status == 'succeeded') {
        setSuccessMessage('Processed Refund Successfully')
        setErrorMessage('')
        setProcessingRefund(false)
        setTimeout(() => {
          setErrorMessage('')
          setSuccessMessage('')
          closeRefundModal()
        }, 1000)
      } else {
        setSuccessMessage('')
        setProcessingRefund(false)
        setErrorMessage(response?.data?.raw?.message)
        setTimeout(() => {
          setErrorMessage('')
          setSuccessMessage('')
        }, 1000)
      }
    } catch (error) {}
  }
  return (
    <IonCard style={{ padding: '1rem' }}>
      <h4>Refund Transaction</h4>
      <IonSegment
        style={{ display: 'flex', justifyContent: 'flex-start' }}
        value={activeTab}
        color='dark'
        onIonChange={e => {
          setActiveTab(e?.detail?.value)
        }}
      >
        <IonSegmentButton style={{ minWidth: 'fit-content' }} value='fullRefund'>
          <IonLabel>Full Refund</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton style={{ minWidth: 'fit-content' }} value='partialRefund'>
          <IonLabel>Partial Refund</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      {loading && (
        <div className={commonStyles.flexCenter}>
          <IonSpinner name='dots' />
        </div>
      )}
      {!loading && (
        <IonRow style={{ margin: '1rem 0' }} className={`${commonStyles.flexCenter}`}>
          {activeTab == 'fullRefund' && (
            <FullRefund
              cardNumber={customerCardData?.cardDetails?.cardLast4}
              customer_id={customer_id}
              paymentDetails={paymentDetails}
              processingRefund={processingRefund}
              handleFullRefund={handleFullRefund}
              successMessage={successMessage}
              errorMessage={errorMessage}
              closeRefundModal={closeRefundModal}
            />
          )}

          {activeTab == 'partialRefund' && (
            <PartialRefund
              closeRefundModal={closeRefundModal}
              cardNumber={customerCardData?.cardDetails?.cardLast4}
              customer_id={customer_id}
              paymentDetails={paymentDetails}
              processingRefund={processingRefund}
              handleFullRefund={handleFullRefund}
              successMessage={successMessage}
              errorMessage={errorMessage}
            />
          )}
          <IonRow style={{ margin: '0 1rem', textAlign: 'center' }}>
            <h6 style={{ textAlign: 'center', fontSize: '12px' }}>
              Refund takes upto 5 to 10 days to appear on your customer's statement
            </h6>
          </IonRow>
        </IonRow>
      )}
    </IonCard>
  )
}
