import React, { useState, useEffect, useContext } from 'react';
import { Typography, Button, FormControl, InputLabel, MenuItem, Select, TextField, Grid, Switch, FormGroup, FormControlLabel } from '@mui/material';
import axios from 'axios'
import LocationsMultiSelect from './locationsMultiSelect';
import ProfilePicDialog from './profilePicDialog';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '../commonComponents/avatar'
import { IonCard } from '@ionic/react';
import PhoneNumberTextField from '../commonComponents/phoneNumberTextField';

export default function DetailsView({ employee, updateEmployee, checkEmployee, locations, locationId, businessId }) {
  let [profilePicDialogOpen, setProfilePicDialogOpen] = useState(false)
  let [cacheBuster, setCacheBuster] = useState(1)

  let onValueChange = (field, value) => {
    updateEmployee({ ...employee, [field]: value })
  }

  function openProfilePicModal() {
    setProfilePicDialogOpen(true);
  }

  function closeProfilePicModal() {
    setCacheBuster(new Date().getTime())
    setProfilePicDialogOpen(false);
  }



  if (!employee) return "loading..."


  return (
    <>
      <IonCard>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 30 }}>
          <div onClick={openProfilePicModal} style={{ cursor: 'pointer' }}>
            <Avatar employee={employee} businessId={businessId} cacheBuster={cacheBuster} />
            <EditIcon style={{ marginLeft: -20 }} color={'primary'} />
          </div>
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h6">{employee.firstName} {employee.lastName}</Typography>
            <Typography variant="subtitle2"> {employee.role}</Typography>
            <FormGroup>
              <FormControlLabel control={<Switch
                size="small"
                onChange={(e) => { onValueChange('isPublic', e.target.checked) }}
                checked={employee.isPublic ?? false}
                inputProps={{ 'aria-label': 'controlled' }}
              />} label={<Typography variant="subtitle2"> {employee.isPublic ? "Profile Public" : "Profile Private"}</Typography>} />
            </FormGroup>
          </div>
        </div>
        <Grid container padding={2} spacing={8}>
          <Grid item sm={12} md={12}>
            <div style={{ flex: 1, flexDirection: 'row', width: '100%' }}>
              <TextField
                margin="dense"
                id="firstName"
                label="First Name"
                type="text"
                variant="standard"
                value={employee.firstName}
                onChange={(e) => { onValueChange('firstName', e.target.value) }}
                style={{ flex: 1, marginRight: 10 }}
              />

              <TextField
                margin="dense"
                id="lastName"
                label="Last Name"
                type="text"
                variant="standard"
                value={employee.lastName}
                onChange={(e) => { onValueChange('lastName', e.target.value) }}
                style={{ flex: 1 }}
              />
            </div>
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <InputLabel id="demo-simple-select-label"
                variant="standard">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={employee.role}
                label="Role"
                variant="standard"
                onChange={(e) => { onValueChange('role', e.target.value) }}
              >
                <MenuItem value={'Barber'}>Barber</MenuItem>
                <MenuItem value={"Receptionist"}>Receptionist</MenuItem>
                <MenuItem value={"Office"}>Office</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              id="email"
              label="Email"
              type="text"
              fullWidth
              variant="standard"
              value={employee.email}
              onChange={(e) => { onValueChange('email', e.target.value) }}
              style={{ marginTop: 20 }}
            />
            <PhoneNumberTextField
              value={employee.phoneNumber}
              onChange={(e) => { onValueChange('phoneNumber', e.target.value) }}
            />

            <LocationsMultiSelect locations={locations} employeeLocations={employee.locations} onValueChange={onValueChange} locationId={locationId} />
          </Grid>
        </Grid>

        {/* <Typography variant="p">Details {employee.firstName} {employee.lastName}</Typography> */}
        <ProfilePicDialog open={profilePicDialogOpen} handleClose={closeProfilePicModal} locationId={locationId} businessId={businessId} employee={employee} />
      </IonCard>
    </>
  )
}
