import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { Avatar, Button, CircularProgress, Divider, IconButton, ListItemAvatar } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UserIcon from '@mui/icons-material/Person'
import DeleteIcon from '@mui/icons-material/Clear'
import PlaceIcon from '@mui/icons-material/Place';
import DateIcon from '@mui/icons-material/CalendarToday';
import axios from 'axios'
import HeaderComponent from './headerComponent';


export default function Review({scheduleData, setStep}) {

  let [services, setServices] = useState([scheduleData.service])
  let [totalPrice, setTotalPrice] = useState()
  let [errorMessage, setErrorMessage] = useState("")
  let [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  useEffect(()=>{
    let price = 0
    for(let s of services){
      price+= parseFloat(s.price)
    }
    setTotalPrice(price)
  }, [scheduleData])

  async function handleBookAndPay(){
    setErrorMessage("")
    setSubmitButtonDisabled(true)
    let response = await axios.post(`/appointment`, {
      businessId : scheduleData.businessId,
      locationId : scheduleData.location._id,
      customerId : scheduleData.customer._id,
      barberId : scheduleData.barber._id,
      serviceId : scheduleData.service._id,
      selectedDate : scheduleData.dateTime.dateText,
      startTime : scheduleData.dateTime.startTime,
      endTime : scheduleData.dateTime.endTime,
      startTimeText : scheduleData.dateTime.startTimeText,
      endTimeText : scheduleData.dateTime.endTimeText,
    })
   console.log(response)
    if(response.status == 200){
      setStep('success')
    } else {
      setErrorMessage("Sorry there was an issue. Please try again in a minute.")
    }
    setSubmitButtonDisabled(false)

  }


  return (
    <React.Fragment>
      <HeaderComponent title={'Review and Pay'} handleBack={()=>{setStep('dateTime')}} progress={{total:5, step:5}}/>
      <div style={{marginLeft:14, marginRight:14}}>
        <LocationSummary location={scheduleData && scheduleData.location || null} setStep={setStep}/>
        <BarberSummary barber={scheduleData && scheduleData.barber || null} setStep={setStep}/>
        <DateAndTimeSummary dateTime={scheduleData && scheduleData.dateTime || null} services={services} setStep={setStep}/>
      
      <Divider/>
      <br></br>
      <Typography variant="subtitle2" gutterBottom>
        Services
        </Typography>
      <List disablePadding>
        {services.map((service) => (
          <ListItem key={service.name} sx={{ py: 1, px: 0 }} style={{paddingLeft:20}}>
            <ListItemText primary={service.name} secondary={service.description} />
            <Typography variant="body2">${service.price}</Typography>
            <IconButton edge="end" aria-label="edit" onClick={()=>{setStep('barber')}}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
        
        <Divider/>
        
        <ListItem sx={{ py: 1, px: 0 }} style={{paddingLeft:20}}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            ${totalPrice && totalPrice.toFixed(2)}
          </Typography>
        </ListItem>
      </List>
          <br></br>
          <Typography style={{fontSize:12, color:'red'}}>{errorMessage}</Typography>
      <Button fullWidth primary variant='contained' disabled={submitButtonDisabled} onClick={handleBookAndPay}>{submitButtonDisabled ? <CircularProgress/> : "Book Now" }</Button>
      </div>
    </React.Fragment>
  );
}


function BarberSummary({barber, setStep}){

  if(!barber) return null

  return (
    <List dense={true} style={{marginLeft:-16, marginRight:-8}}>
        <ListItem 
        secondaryAction={
                    <IconButton edge="end" aria-label="edit" onClick={()=>{setStep('barber')}}>
                      <EditIcon />
                    </IconButton>
                  }>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:'#e6e6e6'}}>
              <UserIcon style={{color:'#2175EA'}}/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${barber.firstName} ${barber.lastName}`}
            secondary={"Barber"}
          />
        </ListItem>
    </List>
  )
}

function LocationSummary({location, setStep}){

  if(!location) return null

  return (
    <List dense={true}  style={{marginLeft:-16, marginRight:-8}}>
        <ListItem 
        secondaryAction={
                    <IconButton edge="end" aria-label="edit" onClick={()=>{setStep('location')}}>
                      <EditIcon />
                    </IconButton>
                  }>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:'#e6e6e6'}}>
              <PlaceIcon style={{color:'#2175EA'}}/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${location.name}`}
            secondary={`${location.addressStreet1}, ${location.addressCity}, ${location.addressState} ${location.addressZipcode}`}
          />
        </ListItem>
    </List>
  )
}

function DateAndTimeSummary({dateTime, services, setStep}){

  if(!dateTime) return null

  return (
    <List dense={true} style={{marginLeft:-16, marginRight:-8}}>
        <ListItem 
        secondaryAction={
                    <IconButton edge="end" aria-label="edit" onClick={()=>{setStep('dateTime')}}>
                      <EditIcon />
                    </IconButton>
                  }>
          <ListItemAvatar>
            <Avatar style={{backgroundColor:'#e6e6e6'}}>
              <DateIcon style={{color:'#2175EA'}}/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${dateTime.startTimeText}, ${dateTime.dateText}`}
            secondary={`${getDuration(services)} Minutes`}
          />
        </ListItem>
    </List>
  )
}

function getDuration(services){
  let a = 0
  for(let s of services){
    a+= s.durationMin
  }
  return a
}