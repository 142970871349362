import { IonInput, IonLabel, IonRow } from '@ionic/react'
import React from 'react'
import commonStyles from '../theme/commonstyles.module.css'

export default function CustomInput({ label, value, handleInput, clearInput }) {
  return (
    <IonRow style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
      <IonLabel style={{ margin: '10px 0', fontWeight: 'bold' }}>{label}</IonLabel>
      <IonInput
        clearInput={clearInput}
        className={commonStyles.inputStyle}
        name={label}
        placeholder={label}
        value={value}
        onIonChange={e => handleInput(e.detail.value)}
      />
    </IonRow>
  )
}
