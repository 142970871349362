import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import { useState } from 'react'
import ConfirmationModal from '../commonComponents/ConfirmationModal'
import axios from 'axios'
import { useHistory } from 'react-router'

export default function DetailsView({
  handleNameError,
  discount,
  nameError,
  error,
  discountData,
  updateDiscount,
  createMode,
}) {
  const history = useHistory()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deletingData, setDeletingData] = useState(false)
  const openDeleteModal = () => {
    setDeleteModalOpen(true)
  }
  const closeDeletemodal = () => {
    setDeleteModalOpen(false)
  }
  const handleDelete = async () => {
    setDeletingData(true)
    try {
      const response = await axios.delete(`/discount?id=${discount?._id}`)
      if (response && response.status == 200) {
        setDeletingData(false)
        history.goBack()
      }
    } catch (error) {}
  }
  let onValueChange = async (field, value) => {
    if (field === 'discountValue') {
      // Limit decimal places to 2
      value = limitDecimalPlaces(value, 2)
    }

    updateDiscount({ ...discount, [field]: value })

    if (!createMode ? field === 'name' && discountData.name !== value : field === 'name') {
      try {
        const response = await axios.get(`/discount/check?name=${value}`)

        if (response?.data) {
          if (response.data.couponExists) {
            handleNameError('Coupon With this name Exists')
          } else {
            handleNameError('')
          }
        } else {
          handleNameError('')
        }
      } catch (error) {
        handleNameError('')
        console.error('Error checking coupon name:', error)
      }
    }
  }

  // Helper function to limit decimal places
  function limitDecimalPlaces(value, decimalPlaces) {
    const stringValue = value?.toString()
    const decimalIndex = stringValue.indexOf('.')

    if (decimalIndex !== -1) {
      // If there is a decimal point
      const decimalPart = stringValue.substring(decimalIndex + 1, stringValue.length)
      return decimalPart.length > decimalPlaces
        ? parseFloat(stringValue).toFixed(decimalPlaces)
        : value
    }

    return value
  }

  if (!discount) return 'loading...'

  const discountOptions = [
    {
      id: 1,
      label: 'Subscriptions',
      value: 'subscription',
    },
    {
      id: 2,
      label: 'Services & Products',
      value: 'servicesAndProducts',
    },
  ]
  return (
    <>
      <IonList inset={true} mode='ios'>
        <IonItem>
          <IonSelect
            style={{ minWidth: '100%', padding: 0 }}
            value={discount.couponType}
            disabled={!createMode}
            onIonChange={e => {
              onValueChange('couponType', e.detail.value)
            }}
          >
            {discountOptions?.map(item => (
              <IonSelectOption key={item?.value} value={item?.value}>
                <IonLabel>{item?.label}</IonLabel>
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </IonList>

      <IonList inset='true'>
        <IonItem>
          <IonInput
            type='text'
            value={discount.name}
            onIonChange={e => onValueChange('name', e.detail.value)}
            placeholder='Discount Name'
          />
        </IonItem>
      </IonList>
      <IonLabel style={{ marginLeft: '1rem', color: 'red', fontSize: '12px' }}>
        {nameError}
      </IonLabel>
      <div
        style={{
          marginTop: '1rem',
        }}
      >
        <IonLabel
          style={{
            marginLeft: '1rem',
            fontWeight: '600',
            marginBottom: -20,
            color: 'grey',
          }}
        >
          Type
        </IonLabel>
        <IonList inset='true'>
          <IonItem
            disabled={!createMode}
            onClick={() =>
              onValueChange(
                'discountType',
                discount.discountType === 'percentage' ? 'fixed' : 'percentage',
              )
            }
          >
            <IonCheckbox
              slot='start'
              checked={discount.discountType === 'percentage'}
              onIonChange={() =>
                onValueChange(
                  'discountType',
                  discount.discountType === 'percentage' ? 'fixed' : 'percentage',
                )
              }
            ></IonCheckbox>
            <IonLabel>Percentage Discount</IonLabel>
          </IonItem>
          <IonItem
            disabled={!createMode}
            onClick={() =>
              onValueChange(
                'discountType',
                discount.discountType === 'fixed' ? 'percentage' : 'fixed',
              )
            }
          >
            <IonCheckbox
              slot='start'
              checked={discount.discountType === 'fixed'}
              onIonChange={() =>
                onValueChange(
                  'discountType',
                  discount.discountType === 'fixed' ? 'percentage' : 'fixed',
                )
              }
            ></IonCheckbox>
            <IonLabel>Fixed Price Discount</IonLabel>
          </IonItem>
        </IonList>
      </div>
      <IonList inset='true'>
        {discount.discountType === 'percentage' ? (
          <IonItem>
            <IonLabel position='stacked'>Percentage</IonLabel>
            <IonRow style={{ margin: 0, padding: 0 }}>
              <IonCol>
                <IonInput
                  disabled={!createMode}
                  type='number'
                  value={discount.discountValue}
                  onIonChange={e => onValueChange('discountValue', e.detail.value)}
                  placeholder='0'
                  inputmode='decimal'
                />
              </IonCol>
              <IonCol
                size='auto'
                style={{ paddingTop: 13, margin: 0, marginLeft: -5, color: 'grey' }}
              >
                %
              </IonCol>
            </IonRow>
          </IonItem>
        ) : (
          <IonItem>
            <IonLabel position='stacked'>Discount Amount</IonLabel>
            <IonRow style={{ margin: 0, padding: 0 }}>
              <IonCol
                size='auto'
                style={{ paddingTop: 13, margin: 0, marginLeft: -5, color: 'grey' }}
              >
                $
              </IonCol>
              <IonCol>
                <IonInput
                  type='number'
                  value={discount.discountValue}
                  onIonChange={e => onValueChange('discountValue', e.detail.value)}
                  placeholder='0.00'
                  inputmode='decimal'
                />
              </IonCol>
            </IonRow>
          </IonItem>
        )}
      </IonList>
      <IonLabel style={{ margin: '1rem', color: 'red', fontSize: '12px' }}>{error}</IonLabel>
      {discount?._id && (
        <>
          <div style={{ margin: '0 1rem' }}>
            <IonButton color='warning' fill='solid' expand='block' onClick={openDeleteModal}>
              Delete
            </IonButton>
          </div>
          <IonModal isOpen={deleteModalOpen}>
            <ConfirmationModal
              isOpen={deleteModalOpen}
              message={'Are You Sure You want to delete this coupon?'}
              cancel={() => closeDeletemodal()}
              loading={deletingData}
              save={handleDelete}
              saveText={'Delete'}
            />
          </IonModal>
        </>
      )}
    </>
  )
}
