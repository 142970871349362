import { IonImg, IonSpinner } from '@ionic/react'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import Picker from '../commonComponents/Picker'
import GetPickerMonths from '../Utils/GetPickerMonths'
import { getMonthRange } from '../Utils/GetDates'
import InternalRatingAndReviews from './InternalRatingAndReviews'
import ReviewConversionFunnel from './ReviewConversionFunnel'
import { AuthContext } from '../auth'
import DeflectedFeedback from './DeflectedFeedback'

export default function DeflectedReportAndFeedback() {
  const { locationId } = useContext(AuthContext)
  const [feedbackList, setFeedbackList] = useState([])
  const pickerItems = GetPickerMonths()
  const [conversionFunnelData, setConversionFunnelData] = useState(false)
  const [funnelChartData, setFunnelChartData] = useState(false)
  const [ratingReport, setRatingReport] = useState({})
  let initialNumOfMonths = 6
  const [dates, setDates] = useState({})
  const [selectedOption, setSelectedOption] = useState(initialNumOfMonths - 1)
  const [loadingData, setLoadingData] = useState(false)
  const handleSelectedIndex = indexValue => {
    const data = getMonthRange(indexValue)

    setDates({ startDate: data.startDate, endDate: data.endDate })
  }
  const getDeflectedFeedback = async (start, end) => {
    setLoadingData(true)
    try {
      const response = await axios.get(
        `/location/deflectedfeedback?locationId=${locationId}&fromDate=${start}&toDate=${end}`,
      )

      if (response?.data) {
        setLoadingData(false)
        setFeedbackList(response?.data)
      }
    } catch (error) {}
  }
  const getRatingReport = async (start, end) => {
    try {
      const response = await axios.get(
        `/location/report/rating?locationId=${locationId}&fromDate=${start}&toDate=${end}`,
      )
      setRatingReport(response?.data)
    } catch (error) {}
  }
  const getConversionFunnel = async (start, end) => {
    try {
      const response = await axios.get(
        `/location/report/conversionfunnel?locationId=${locationId}&fromDate=${start}&toDate=${end}`,
      )

      if (response?.data) {
        const { sent, clicked, submitted, deflectedFeedbackPercentage } = response?.data || {}
        let newData = { sent, clicked, submitted, deflectedFeedbackPercentage }
        delete newData.deflectedFeedbackPercentage
        const transformedData = Object.entries(newData).map(([key, value]) => {
          let label = ''

          // Define labels based on keys (you can adjust these labels according to your requirement)
          switch (key) {
            case 'sent':
              label = 'Sent'
              break
            case 'clicked':
              label = 'Clicked'
              break
            case 'submitted':
              label = 'Submitted'
              break

            // Add more cases if needed for other keys
            default:
              break
          }

          return {
            id: `step_${key}`,
            value: value, // or Number(value) if needed
            label: label,
          }
        })

        setFunnelChartData(transformedData)
        setConversionFunnelData(response?.data)
      } else {
        setFunnelChartData(false)
      }
    } catch (error) {}
  }
  useEffect(() => {
    const data = getMonthRange(initialNumOfMonths)

    setSelectedOption(initialNumOfMonths - 1)
    getDeflectedFeedback(data.startDate, data.endDate)
    getRatingReport(data.startDate, data.endDate)
    getConversionFunnel(data.startDate, data.endDate)
  }, [])

  useEffect(() => {
    getDeflectedFeedback(dates.startDate, dates.endDate)
    getRatingReport(dates.startDate, dates.endDate)
    getConversionFunnel(dates.startDate, dates.endDate)
  }, [dates])

  return (
    <div>
      <div style={{ padding: '1rem 1rem 0 1rem' }}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Picker
            theme={'light'}
            options={pickerItems}
            selectedItemIndex={initialNumOfMonths}
            selectedItem={selectedOption}
            handleSelectedIndex={handleSelectedIndex}
          />
        </div>
      </div>
      {loadingData && (
        <div style={{ padding: '1rem' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <IonSpinner name='dots' />
          </div>
        </div>
      )}
      {!loadingData && (
        <>
          {feedbackList && feedbackList.length > 0 && (
            <>
              <InternalRatingAndReviews ratingReport={ratingReport} />
              {funnelChartData && (
                <ReviewConversionFunnel
                  conversionFunnelData={conversionFunnelData}
                  funnelChartData={funnelChartData}
                />
              )}
              <DeflectedFeedback feedbackList={feedbackList} />
            </>
          )}

          {feedbackList && feedbackList.length == 0 && (
            <div
              style={{
                padding: '2rem 0',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                margin: '1rem',
              }}
            >
              <h1 style={{ margin: '10px ', fontWeight: 'bold', textAlign: 'center' }}>
                Waiting for your review data to start flowing
              </h1>
              <IonImg
                style={{
                  width: '40%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
                src={'/assets/waitingForFeedback.png'}
              ></IonImg>
            </div>
          )}
        </>
      )}
    </div>
  )
}
