import React, { useState } from 'react';
import { IonButton, IonButtons, IonCheckbox, IonContent, IonHeader, IonItem, IonList, IonTitle, IonSearchbar, IonToolbar } from '@ionic/react';

function AppTypeahead(props) {
  const [filteredItems, setFilteredItems] = useState([...props.items]);
  const [workingSelectedValues, setWorkingSelectedValues] = useState([...props.selectedItems]);
  const {setIsOpen} = props

  const isChecked = (value) => {
    return workingSelectedValues.find(item => item === value) !== undefined;
  }

  const cancelChanges = () => {
    const { onSelectionCancel } = props;
    if (onSelectionCancel !== undefined) {
      onSelectionCancel();
    }
    setIsOpen(false)
  }

  const confirmChanges = () => {
    const { onSelectionChange } = props;
    if (onSelectionChange !== undefined) {
      onSelectionChange(workingSelectedValues);
    }
    
  }

  const searchbarInput = (ev) => {
    console.log(ev.target.value)
    filterList(ev.target.value);
  }

  const filterList = (searchQuery) => {
    if (searchQuery === undefined || searchQuery === null) {
      setFilteredItems([...props.items]);
    } else {
      const normalizedQuery = searchQuery.toLowerCase();
      setFilteredItems(props.items.filter(item => {
        console.log(item)
        return item.firstName.toLowerCase().includes(normalizedQuery) 
        || item.lastName.toLowerCase().includes(normalizedQuery)
        || (item.firstName.toLowerCase() + " " + item.lastName.toLowerCase()).includes(normalizedQuery)
      }));
    }
  }

  function handleReset(){
    const { onSelectionChange } = props;
    onSelectionChange([]);
    setWorkingSelectedValues([])
    setFilteredItems([])
  }

  const checkboxChange = (ev) => {
    const { checked, value } = ev.detail;

    if (checked) {
      setWorkingSelectedValues([...workingSelectedValues, value]);
    } else {
      setWorkingSelectedValues(workingSelectedValues.filter(item => item !== value));
    }
  }

  return (
    <>
      <IonHeader mode={'ios'}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={cancelChanges}>Cancel</IonButton>
          </IonButtons>
          <IonTitle>{props.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={confirmChanges}>Done</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar onIonInput={searchbarInput}></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent color="light" class="ion-padding">
        <div style={{padding:16, paddingTop:0, paddingBottom:0}}>
        <IonButton expand='block' fill='outline' onClick={handleReset}>Reset & Close</IonButton>
        </div>
        <IonList id="modal-list" inset={true}  mode={'ios'}>
          {filteredItems.map(item => (
            <IonItem key={item._id}>
              <IonCheckbox
                
                value={item._id}
                checked={isChecked(item._id)}
                onIonChange={checkboxChange}
              >{`${item.firstName} ${item.lastName}`}</IonCheckbox>
              {`${item.firstName} ${item.lastName}`}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </>
  );
}

export default AppTypeahead;