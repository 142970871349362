import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Avatar, Divider, ListItemAvatar } from '@mui/material'
import UserIcon from '@mui/icons-material/Person'
import PlaceIcon from '@mui/icons-material/Place'
import DateIcon from '@mui/icons-material/CalendarToday'
import axios from 'axios'
import { IonButton, IonSpinner } from '@ionic/react'
import Invoice from '../sharedComponents/invoice'

const styles = {
  listItem: {
    paddingLeft: 0,
  },
}

export default function Review({
  userSubscription,
  scheduleData,
  updateScheduleData,
  stepNext,
  isWalkin,
  step,
  trackStep,
}) {
  let [services, setServices] = useState(scheduleData.services)
  let [errorMessage, setErrorMessage] = useState('')
  let [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  let [cardOnFile, setCardOnFile] = useState('loading')

  useEffect(() => {
    trackStep('ReviewOrder', step, scheduleData)
  }, [])

  async function handleBookAndPay() {
    setErrorMessage('')
    setSubmitButtonDisabled(true)
    try {
      let response = await axios.post(`/appointment`, {
        businessId: scheduleData.businessId,
        locationId: scheduleData.location?._id,
        customerId: scheduleData.customer?._id,
        barberId: scheduleData.barber?._id,
        requestedBarberId: scheduleData?.requestedBarber?._id,
        anyProfessional: scheduleData?.anyProfessional,
        services: scheduleData.services,
        selectedDate: scheduleData.dateTime?.dateText,
        startTime: scheduleData.dateTime?.startTime,
        endTime: scheduleData.dateTime?.endTime,
        startTimeText: scheduleData.dateTime?.startTimeText,
        endTimeText: scheduleData.dateTime?.endTimeText,
        durationMin: scheduleData?.durationMin,
        payment: scheduleData?.payment,
        type: isWalkin ? 'Walkin' : 'Appointment',
      })

      if (response.status == 200) {
        stepNext()
      } else {
        setErrorMessage('Sorry there was an issue. Please try again in a minute.')
      }
      setSubmitButtonDisabled(false)
    } catch (error) {
      setErrorMessage('Sorry there was an issue. Please try again in a minute.')
      setSubmitButtonDisabled(false)
    }
  }

  function handleTipClick(value) {
    updateScheduleData('payment', { ...scheduleData.payment, ...{ tipPercentage: value } })
  }

  return (
    <React.Fragment>
      {/* <HeaderComponent title={'Review and Pay'} handleBack={stepBack} progress={{ total: totalSteps, step: step + 1 }} /> */}
      <div style={{ marginLeft: 14, marginRight: 14 }}>
        {isWalkin ? (
          <>
            <h2>Reserve Your Spot In Line</h2>
            <br></br>
          </>
        ) : (
          <>
            <h5>Details</h5>
            {/* <LocationSummary location={scheduleData && scheduleData.location || null} /> */}
            <BarberSummary
              barber={(scheduleData && scheduleData.barber) || null}
              anyProfessional={scheduleData?.anyProfessional}
            />
            <DateAndTimeSummary
              durationMin={scheduleData?.durationMin}
              dateTime={scheduleData?.dateTime || null}
              services={scheduleData?.services}
            />
          </>
        )}

        <Divider />
        <br></br>
        <h5>Invoice</h5>
        <Invoice appointment={scheduleData} hideHeader={true} />
        <br></br>
        <Typography style={{ fontSize: 12, color: 'red' }}>{errorMessage}</Typography>
        <>
          <IonButton disabled={submitButtonDisabled} onClick={handleBookAndPay} expand='block'>
            {submitButtonDisabled ? (
              <IonSpinner name='dots'></IonSpinner>
            ) : (
              `${isWalkin ? 'Check In Now' : 'Book Now'}`
            )}
          </IonButton>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </>
      </div>
    </React.Fragment>
  )
}

function BarberSummary({ barber, anyProfessional }) {
  if (!barber) return null

  return (
    <List
      dense={true}
      style={{ marginLeft: -16, marginRight: -8, marginTop: -10, marginBottom: -10 }}
    >
      <ListItem
      // secondaryAction={
      //             <IconButton edge="end" aria-label="edit" onClick={()=>{setStep('barber')}}>
      //               <EditIcon />
      //             </IconButton>
      //           }
      >
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: '#e6e6e6' }}>
            <UserIcon style={{ color: '#2175EA' }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          // primary={`${barber?.firstName} ${barber?.lastName?.charAt(0)}`}
          primary={
            anyProfessional
              ? 'Any Professional'
              : `${barber?.firstName} ${barber?.lastName?.charAt(0)}`
          }
        />
      </ListItem>
    </List>
  )
}

function LocationSummary({ location, setStep }) {
  if (!location) return null

  return (
    <List
      dense={true}
      style={{ marginLeft: -16, marginRight: -8, marginTop: -20, marginBottom: -10 }}
    >
      <ListItem
      // secondaryAction={
      //             <IconButton edge="end" aria-label="edit" onClick={()=>{setStep('location')}}>
      //               <EditIcon />
      //             </IconButton>
      //           }
      >
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: '#e6e6e6' }}>
            <PlaceIcon style={{ color: '#2175EA' }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${location.name}`}
          secondary={`${location.addressStreet1}, ${location.addressCity}, ${location.addressState} ${location.addressZipcode}`}
        />
      </ListItem>
    </List>
  )
}

function DateAndTimeSummary({ dateTime, services, durationMin }) {
  if (!dateTime) return null

  return (
    <List
      dense={true}
      style={{ marginLeft: -16, marginRight: -8, marginTop: -20, marginBottom: 0 }}
    >
      <ListItem
      // secondaryAction={
      //             <IconButton edge="end" aria-label="edit" onClick={()=>{setStep('dateTime')}}>
      //               <EditIcon />
      //             </IconButton>
      //           }
      >
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: '#e6e6e6' }}>
            <DateIcon style={{ color: '#2175EA' }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${dateTime.startTimeText}, ${dateTime.dateText}`}
          secondary={`${durationMin} Minutes`}
        />
      </ListItem>
    </List>
  )
}
