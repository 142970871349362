import {
  IonButton,
  IonButtons,
  IonCard,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { Container, CssBaseline } from '@mui/material'
import { checkmark, checkmarkCircle } from 'ionicons/icons'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

export default function HomePage() {
  console.log('innnnnnnnnnnnnnnnnnn')
  const history = useHistory()
  const [signUpModalVisible, setSignUpModalVisible] = useState(false)
  const [selectedSignUpType, setSelectedSignUpType] = useState('own')
  const redirectToLogin = () => {
    history.push('/login')
  }
  const openSignUpModal = () => {
    setSelectedSignUpType('own')
    setSignUpModalVisible(true)
  }
  const handleSelectedSignUpType = type => {
    setSelectedSignUpType(type)
  }
  const handleSubmitSignUp = () => {
    if (selectedSignUpType == 'own') {
      history.push('/signup')
    } else {
      history.push('/notice')
    }
  }
  const closeSignUpModal = () => {
    setSelectedSignUpType('own')
    setSignUpModalVisible(false)
  }
  const pageStyles = {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(to bottom right, #3498db, #9b59b6)',
  }
  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />

      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IonButton onClick={redirectToLogin}>Login</IonButton>
        <IonButton onClick={openSignUpModal}>SignUp</IonButton>
      </div>
      <IonModal isOpen={signUpModalVisible} onDidDismiss={closeSignUpModal}>
        <IonHeader>
          <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonTitle>Sign Up</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={closeSignUpModal}>Close</IonButton>
            </IonButtons>
            <IonButtons slot='end'>
              <IonButton color={'primary'} onClick={handleSubmitSignUp}>
                Go
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonList mode='ios'>
          <IonItem style={{ cursor: 'pointer' }} onClick={() => handleSelectedSignUpType('own')}>
            <IonLabel>Create your own business</IonLabel>
            {selectedSignUpType == 'own' && <IonIcon icon={checkmark}></IonIcon>}
          </IonItem>
          <IonItem
            style={{ cursor: 'pointer' }}
            onClick={() => handleSelectedSignUpType('existing')}
          >
            <IonLabel>Get added to an existing business</IonLabel>
            {selectedSignUpType == 'existing' && <IonIcon icon={checkmark}></IonIcon>}
          </IonItem>
        </IonList>
      </IonModal>
    </Container>
  )
}
