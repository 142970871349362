import React, { useState, useEffect, useContext } from 'react';
import { Typography, Button } from '@mui/material';
import ResultsList from './resultsList';
import axios from 'axios'
import { AuthContext } from '../auth'
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonProgressBar, IonRefresher, IonRefresherContent, IonTitle, IonToolbar } from '@ionic/react';
import { addCircle, bus } from 'ionicons/icons';
import StandardContainer from '../commonComponents/StandardContainer';
import { useHistory } from 'react-router-dom';

export default function CorporateDealListView({ locationId }) {
    const { businessData } = useContext(AuthContext);
    const [corporateDeals, setCorporateDeals] = useState([])
    const [addCorporateDealDialogOpen, setAddCorporateDealDialogOpen] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [createButtonDisabled, setCreateButtonDisabled] = useState(false)
    let history = useHistory()

    useEffect(() => {
        getCorporateDealsList()
    }, [businessData])

    const createButtonClicked = async (e) => {
        setCreateButtonDisabled(true)
        let locData = locationId ? {locations : [locationId]} : {}
        let response = await axios.post(`/corporateDeal`, { name: "New Deal", description:"", businessId: businessData._id, ...locData })
        console.log(response)
        if (response.status === 200) {
          history.push(`/core/corporateDeals/${response.data._id}`)
        } else {
            setCreateButtonDisabled(false)
        }
    
      }

    const getCorporateDealsList = async () => {
        if (!businessData._id) return

        setLoading(true)
        setErrorMessage(null)
        try {
            let locQuery = locationId ? `&locationId=${locationId}` : ''
            let response = await axios.get(`/corporateDeal/getList?businessId=${businessData._id}${locQuery}`)
            setCorporateDeals(response.data)
        } catch (error) {
            setErrorMessage("Sorry there was an issue")
        }
        setLoading(false)
    }

    const openAddCorporateDealDialog = () => {
        setAddCorporateDealDialogOpen(true);
    };
    const closeAddCorporateDealDialog = () => {
        getCorporateDealsList()
        setAddCorporateDealDialogOpen(false);
    };

    async function handleRefresh(event) {
        await getCorporateDealsList()
        event.detail.complete();
    }



    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
                    <IonTitle>Corporate Deals</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={createButtonClicked}>
                            <IonIcon slot="icon-only" icon={addCircle} color="primary" />
                        </IonButton>
                    </IonButtons>
                    {loading ? <IonProgressBar type="indeterminate" /> : null}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <StandardContainer>
                    {errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : null}
                    <ResultsList corporateDeals={corporateDeals} locationId={locationId} />
                </StandardContainer>
            </IonContent>
        </IonPage>
    );
}
