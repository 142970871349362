import { IonCard, IonCheckbox, IonCol, IonInput, IonLabel, IonRow } from '@ionic/react'
import commonStyles from '../../theme/commonstyles.module.css'

export default function NoShowChargesForm({
  defaultChargeEnabled,
  toggleDefaultCharge,
  appointment,
  defaultCancellationCharges,
  customChargeValue,
  handleCustomChargeValue,
  error,
}) {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }}>
        <IonCheckbox
          mode='ios'
          slot='start'
          checked={defaultChargeEnabled}
          onIonChange={toggleDefaultCharge}
        ></IonCheckbox>

        <IonLabel onClick={toggleDefaultCharge} style={{ marginLeft: '0.5rem' }}>
          Charge {appointment?.customer?.firstName} {appointment?.customer?.lastName} a cancelation
          charge of ${defaultCancellationCharges}
        </IonLabel>
      </div>
      {!defaultChargeEnabled && (
        <div>
          <h6 style={{ color: 'black', fontSize: '12px' }}>Custom Charge</h6>
          <IonRow style={{ margin: 0, padding: 0 }}>
            <IonCol>
              <IonInput
                className={commonStyles.inputStyle}
                fill='outline'
                type='number'
                value={customChargeValue}
                onIonChange={e => handleCustomChargeValue(e.detail.value)}
                placeholder='0'
                inputmode='decimal'
              />
            </IonCol>
          </IonRow>
        </div>
      )}
      {error && (
        <IonLabel style={{ margin: '1rem', color: 'red', fontSize: '12px' }}>{error}</IonLabel>
      )}
    </>
  )
}
