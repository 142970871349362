import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import dayjs from 'dayjs'
import { getFormattedMediumDate } from '../../utils'
import TimeSelector from '../timeSelector'
import {
  IonCard,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonToggle,
} from '@ionic/react'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/StandardContainer'
import { colors } from '../../theme/colors'

function getDefaultData() {
  return {
    dateText: getFormattedMediumDate(new Date()),
    dateEpoch: new Date().getTime(),
    enabled: false,
    startTime: null,
    endTime: null,
  }
}

export default function AddBreak({ onClose, open, handleSave, standardSchedule }) {
  const [data, setData] = useState(getDefaultData())
  const [errorMessage, setErrorMessage] = useState('error')
  const [isSaving, setIsSaving] = useState(false)
  const [repeatBreak, setRepeatBreak] = useState(false)
  const [breakType, setBreakType] = useState('daily')
  const [breakInterval, setBreakInterval] = useState('')
  useEffect(() => {
    setData(getDefaultData())
    setIsSaving(false)
    setErrorMessage('')
    setRepeatBreak(false)
    setBreakType('daily')
    setBreakInterval('')
  }, [open])
  const toggleRepeat = value => {
    console.log(value, 'valuevaluevalue')
    setRepeatBreak(value)
  }
  const handleBreakType = value => {
    setBreakType(value)
  }
  const getDateBasedDay = dateStr => {
    const date = new Date(dateStr)
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const dayIndex = date.getDay()
    const day = days[dayIndex]
    return day
  }

  const save = () => {
    setErrorMessage('')

    if (!data.startTime || !data.endTime) {
      setErrorMessage('**Start time and End time must be filled out')
      return
    }
    let newData = { ...data }
    newData.repeat = {
      enabled: repeatBreak,
      type: breakType, //"daily"
      interval: 1,
      occurrences: parseFloat(breakInterval),
    }
    if (breakType == 'weekly') {
      newData.repeat.day = getDateBasedDay(data.dateText)
    }
    setIsSaving(true)
    handleSave(newData)
  }

  const handleClose = () => {
    onClose()
  }

  const handleListItemClick = (value, d) => {
    onClose(value)
  }

  function handleChange(id, fieldKey, newValue) {
    setData({ ...data, ...{ [fieldKey]: newValue } })
  }
  const changeInterval = value => {
    setBreakInterval(value)
  }
  function updateDate(id, date) {
    setData({
      ...data,
      ...{ dateText: getFormattedMediumDate(date), date: date, dateEpoch: date.getTime() },
    })
  }
  const isSaveDisabled =
    isSaving ||
    !data.startTime ||
    !data.endTime ||
    (repeatBreak ? !breakType || !breakInterval : false)
  console.log(isSaveDisabled, 'isSaveDisabled')
  return (
    <>
      <IonModal isOpen={open} onDidDismiss={handleClose}>
        <CommonHeader
          saveButton={true}
          saveDisabled={isSaveDisabled}
          handleSaveButton={save}
          title={'Add a break'}
          closeButton={true}
          closeModal={handleClose}
        />
        <StandardContainer>
          <IonCard style={{ padding: '2rem' }}>
            <IonGrid>
              <IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CalendarSelect dateText={data.dateText} id={data._id} updateDate={updateDate} />
              </IonRow>
              <IonRow
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IonCol style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <TimeSelector
                    dayKey={data._id}
                    fieldKey='startTime'
                    value={data.startTime}
                    handleChange={handleChange}
                    useFifteenMin={true}
                  />
                </IonCol>
                <IonCol style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <TimeSelector
                    dayKey={data._id}
                    fieldKey='endTime'
                    value={data.endTime}
                    handleChange={handleChange}
                    useFifteenMin={true}
                  />
                </IonCol>
              </IonRow>
              <IonRow
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IonCol>
                  <h6>Repeat Breaks</h6>
                </IonCol>
                <IonCol>
                  <IonToggle
                    justify='space-between'
                    size='small'
                    checked={repeatBreak}
                    onIonChange={e => {
                      toggleRepeat(e.detail.checked)
                    }}
                  />
                </IonCol>
              </IonRow>
              {repeatBreak && (
                <>
                  <IonRow>
                    <IonCol>
                      <h6>Type</h6>
                    </IonCol>
                    <IonCol style={{ marginLeft: '1rem' }}>
                      <IonRadioGroup
                        style={{ display: 'flex', alignItems: 'center' }}
                        value={breakType}
                      >
                        <div style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }}>
                          <IonRadio
                            key='daily'
                            slot='start'
                            mode='md'
                            checked={breakType == 'daily' ? true : false}
                            onClick={() => handleBreakType('daily')}
                          ></IonRadio>

                          <IonLabel
                            onClick={() => handleBreakType('daily')}
                            style={{
                              color: breakType == 'daily' ? colors.primaryBlue : '',
                              marginLeft: '0.5rem',
                            }}
                          >
                            Daily
                          </IonLabel>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '1rem' }}>
                          <IonRadio
                            key='weekly'
                            mode='md'
                            slot='start'
                            checked={breakType == 'weekly' ? true : false}
                            onClick={() => handleBreakType('weekly')}
                          ></IonRadio>

                          <IonLabel
                            onClick={() => handleBreakType('weekly')}
                            style={{
                              color: breakType == 'weekly' ? colors.primaryBlue : '',
                              marginLeft: '0.5rem',
                            }}
                          >
                            Weekly
                          </IonLabel>
                        </div>
                      </IonRadioGroup>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <h6>Interval</h6>
                    </IonCol>
                    <IonCol>
                      <IonItem>
                        <IonInput
                          min={0}
                          type='number'
                          // disabled={testingEmail}
                          onIonChange={e => {
                            changeInterval(e.detail.value)
                          }}
                          value={breakInterval}
                          placeholder='Interval'
                        ></IonInput>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </>
              )}
            </IonGrid>
          </IonCard>
        </StandardContainer>
      </IonModal>
    </>
  )
}

function CalendarSelect({ dateText, updateDate, id }) {
  const [value, setValue] = React.useState(dayjs(dateText))

  const handleChange = newValue => {
    updateDate(id, newValue.$d)
    setValue(newValue)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        size='small'
        inputFormat='MM/DD/YYYY'
        value={dayjs(value)}
        onChange={handleChange}
        renderInput={params => <TextField size='small' style={{ width: 150 }} {...params} />}
      />
    </LocalizationProvider>
  )
}
