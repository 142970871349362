import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import {
  IonCard,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSpinner,
  IonAccordion,
  IonAccordionGroup,
} from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import StandardCenterCenter from '../commonComponents/StandardCenterCenter'
import { numberWithCommas } from '../utils'
import { AuthContext } from '../auth'

export default function LocationStats() {
  const { businessData, locationId } = useContext(AuthContext)
  if (!businessData?._id) return 'location null...'

  let { month, year } = getCurrentMonthAndYear()
  return (
    <StandardContainer>
      <h4 style={{ padding: 16, paddingBottom: 0 }}>{getCurrentMonth()} Stats</h4>
      <StatsBlock businessId={businessData?._id} month={month} year={year} />
      <Historical businessId={businessData?._id} />
    </StandardContainer>
  )
}

function getCurrentMonth() {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()

  return months[currentMonth]
}

function getCurrentMonthAndYear() {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() + 1 // Months are zero-based, so we add 1
  const currentYear = currentDate.getFullYear()

  return { month: currentMonth, year: currentYear }
}

function StatsBlock({ businessId, month, year }) {
  const [stats, setStats] = useState()
  const { businessData, locationId } = useContext(AuthContext)

  const [subscriberAverage, setSubscriberAverage] = useState()

  async function getStats() {
    try {
      let r = await axios.get(
        `/location/stats?businessId=${businessData?._id}&month=${month}&year=${year}`,
      )
      setStats(r.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getStats()
  }, [businessData, locationId])

  useEffect(() => {
    const interval = setInterval(() => {
      getStats()
    }, 60000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    let totalAppointments = 0
    let totalSubscribers = 0

    for (let s of stats?.subscriberAppointments || []) {
      totalAppointments += s.totalAppointments
      totalSubscribers += s.countOfSubscribers * (s.numberOfPeopleInPackage || 1)
    }
    setSubscriberAverage(totalAppointments / totalSubscribers)
  }, [stats?.subscriberAppointments])

  return (
    <>
      {stats ? (
        <IonList inset={true} mode='ios'>
          <IonItem key='Info'>
            <IonLabel>
              <strong>Total # of Services</strong>
            </IonLabel>
            <IonLabel slot='end'>{stats?.countAppointments || 0}</IonLabel>
          </IonItem>
          <IonItem key='Tick'>
            <IonLabel>
              <strong>Total Ticket Value</strong>
            </IonLabel>
            <IonLabel slot='end'>${numberWithCommas(stats?.totalPrice || 0)}</IonLabel>
          </IonItem>
          <IonItem key='AvgTick'>
            <IonLabel>
              <strong>Average Ticket Value</strong>
            </IonLabel>
            <IonLabel slot='end'>${stats?.avgTicketValue?.toFixed(2)}</IonLabel>
          </IonItem>
          <IonItem key='AvgTick2'>
            <IonLabel>
              <strong>Avg Ticket Value (+Sub)</strong>
            </IonLabel>
            <IonLabel slot='end'>
              $
              {(
                ((stats?.totalPrice || 0) + (stats?.mrr || 0)) /
                (stats?.countAppointments || 0)
              ).toFixed(2)}
            </IonLabel>
          </IonItem>
          <IonItem key='Ser'>
            <IonLabel>
              <strong>Value of Services</strong>
            </IonLabel>
            <IonLabel slot='end'>${numberWithCommas(stats?.totalValueOfServices || 0)}</IonLabel>
          </IonItem>
          <IonItem key='AvSer'>
            <IonLabel>
              <strong>Average Service Value</strong>
            </IonLabel>
            <IonLabel slot='end'>
              ${((stats?.totalValueOfServices || 0) / (stats?.countAppointments || 0)).toFixed(2)}
            </IonLabel>
          </IonItem>
          <IonItem key='Prod'>
            <IonLabel>
              <strong>Value of Products</strong>
            </IonLabel>
            <IonLabel slot='end'>${stats?.totalValueofProducts || 0}</IonLabel>
          </IonItem>
          <IonItem key='Tip'>
            <IonLabel>
              <strong>Total Tips</strong>
            </IonLabel>
            <IonLabel slot='end'>${numberWithCommas(stats?.totalTips || 0)}</IonLabel>
          </IonItem>
          <IonItem key='AvgTip'>
            <IonLabel>
              <strong>Average Tip</strong>
            </IonLabel>
            <IonLabel slot='end'>
              ${((stats?.totalTips || 0) / (stats?.countAppointments || 0)).toFixed(2)}
            </IonLabel>
          </IonItem>
          <IonItem key='SubSold'>
            <IonLabel>
              <strong>Subscriptions Sold</strong>
            </IonLabel>
            <IonLabel slot='end'>{stats?.countSubscriptionsSold}</IonLabel>
          </IonItem>
          <IonItem key='newMRR'>
            <IonLabel>
              <strong>Added MRR</strong>
            </IonLabel>
            <IonLabel slot='end'>${numberWithCommas(stats?.addedMRR)}</IonLabel>
          </IonItem>
          <IonItem key='SubsCanceled'>
            <IonLabel>
              <strong>Subscriptions Canceled</strong>
            </IonLabel>
            <IonLabel slot='end'>{stats?.countOfSubscriptionsCanceled}</IonLabel>
          </IonItem>
          <IonItem key='lostMrr'>
            <IonLabel>
              <strong>Lost MRR</strong>
            </IonLabel>
            <IonLabel slot='end'>${stats?.lostMrr}</IonLabel>
          </IonItem>
          <IonItem key='MRR'>
            <IonLabel>
              <strong>MRR</strong>
            </IonLabel>
            <IonLabel slot='end'>${numberWithCommas(stats?.mrr)}</IonLabel>
          </IonItem>
          <IonItem key='AppPerSub'>
            <IonLabel>
              <strong>Avg Appt per Sub</strong>
            </IonLabel>
            <IonLabel slot='end'>
              {subscriberAverage.toFixed(2)} (
              {(new Date(year, month, 0).getDate() / subscriberAverage / 7).toFixed(1)} Weeks)
            </IonLabel>
          </IonItem>
        </IonList>
      ) : (
        <StandardCenterCenter>
          {' '}
          <div style={{ margin: 50 }}>
            <IonSpinner name='dots' />
          </div>
        </StandardCenterCenter>
      )}
    </>
  )
}

function Historical({ businessId }) {
  const hist = generateMonthYearArray(1680347469000, true)
  const listenerOut = useRef(null)
  const values = hist.map(i => i.year + ' ' + i.month)
  const [expanded, setExpanded] = useState([])

  const accordionGroupChange = event => {
    const expandedItem = event.detail.value
    setExpanded(expandedItem)

    // Load the content or trigger any necessary actions here based on expandedItem
  }

  return (
    <div>
      {/* <div ref={listenerOut}></div> */}
      <IonAccordionGroup multiple={true} onIonChange={accordionGroupChange} mode='ios'>
        {hist?.map(i => (
          <IonAccordion value={i.year + ' ' + i.month} key={i.year + ' ' + i.month}>
            <IonItem slot='header' color='light'>
              <IonLabel>
                <strong>
                  {convertMonthNumberToText(i.month)} {i.year}
                </strong>
              </IonLabel>
            </IonItem>
            <div slot='content'>
              {expanded.includes(i.year + ' ' + i.month) ? (
                <StatsBlock month={i.month} year={i.year} businessId={businessId} />
              ) : null}
            </div>
          </IonAccordion>
        ))}
      </IonAccordionGroup>
    </div>
  )
}

function convertMonthNumberToText(monthNumber) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return months[monthNumber - 1] || ''
}

function generateMonthYearArray(startDate, excludeCurrentMonth = false) {
  const currentDate = new Date()
  const start = new Date(startDate)
  const monthYearArray = []

  while (currentDate > start) {
    const month = start.getMonth() + 1 // Months are zero-based, so we add 1
    const year = start.getFullYear()

    if (
      !(
        excludeCurrentMonth &&
        currentDate.getMonth() === start.getMonth() &&
        currentDate.getFullYear() === start.getFullYear()
      )
    ) {
      monthYearArray.push({ month, year })
    }

    // Move to the previous month
    start.setMonth(start.getMonth() + 1)
  }

  return monthYearArray.reverse()
}
