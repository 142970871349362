import { IonSelect, IonSelectOption } from '@ionic/react'
import { useState } from 'react'

export default function Picker({ theme, options, selectedItemIndex, handleSelectedIndex }) {
  const [selectedIndex, setSelectedIndex] = useState(selectedItemIndex)

  const handleOptionChange = index => {
    setSelectedIndex(index)
    handleSelectedIndex(index)
  }

  return (
    <>
      <IonSelect
        style={{
          backgroundColor: theme == 'light' ? 'white' : '',
          padding: '0.3rem 1rem',
          border: '1px solid #d6d6d6',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '3rem',
          color: 'black',
          width: '10rem',
          height: '1.5rem',
        }}
        label='Solid select'
        labelPlacement='floating'
        fill='solid'
        value={selectedIndex}
        onIonChange={e => {
          handleOptionChange(e.detail.value)
        }}
      >
        {options.map((item, index) => {
          return <IonSelectOption value={item.value}>{item.text}</IonSelectOption>
        })}
      </IonSelect>
    </>
  )
}
